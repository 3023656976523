import {useState, useEffect, useContext} from 'react'
import {useParams, useHistory} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'

import {PetitionSignBar} from './PetitionSignBar'
import {StartPetitionBanner} from './StartPetitionBanner'
import {StartPetitionBannerMobile} from './StartPetitionBannerMobile'
import {PetitionTabs} from './PetitionTabs'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {ProfileContext} from '../../../../contexts/profilecontext'
import MODAL_NAMES from '../../../../constants/ModalNames'
import {GlobalPrivateModalContext} from '../../../../contexts/GlobalModalContext'

export function PetitionDescription(props) {
  const {
    petition,
    petitionTab,
    setPetitionTab,
    setShowElement,
    setActionPopupContent,
    onClickSign,
  } = props

  const PROFILE_CONTEXT = useContext(ProfileContext)
  const userBasicProfileComplete = PROFILE_CONTEXT.profile?.basicProfileComplete
  const {setIsOpenGlobalModal} = useContext(GlobalPrivateModalContext)
  const setIsOpenCompleteProfile = (o) => setIsOpenGlobalModal(MODAL_NAMES.COMPLETE_PROFILE, o)

  const [loading, setLoading] = useState(false)

  const history = useHistory()

  return (
    // PetitionTabs view='web' currentTab={petitionTab} setCurrentTab={setPetitionTab} />
    <div>
      {petition.cover_photo.source_url && (
        <img
          className='petitionDetails__coverMobileImg mobile-show'
          src={toAbsoluteUrl(petition.cover_photo.source_url)}
        />
      )}

      <div className='petitionDetails__header mobile-show'>
        <div className='petitionDetails__headerWrapM  '>
          <PetitionTabs view='mobile' currentTab={petitionTab} setCurrentTab={setPetitionTab} />

          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/design/modal-x-btn.svg')}
            className='civik-modal-x-btn'
            onClick={() => {
              history.push('/petitions')
            }}
          />
        </div>

        <div className='mobile-show petitionDetailsM__targetBar'>
          {petition.signatures == petition.signatureGoal ? (
            <div>
              <img
                className='petitionDetails__megaphoneIcon'
                src={toAbsoluteUrl('/media/design/hands_clapping_icon.svg')}
              ></img>
              <span className='petitionDetails__socialsBar__txt '>
                This petition successfully reached the target
              </span>
            </div>
          ) : (
            <div>
              <img
                className='petitionDetails__megaphoneIcon'
                src={toAbsoluteUrl('/media/design/megaphone_icon.svg')}
              ></img>
              <span className='petitionDetails__socialsBar__txt '>
                This petition needs{' '}
                <span className='petitionDetails__socialsBar__signGoalTxt'>
                  {petition.signatureGoal - petition.signatures}
                </span>{' '}
                signs to reach target
              </span>
            </div>
          )}
        </div>
      </div>

      <div className='petitionDetails__floatingSocialBar mobile-show'>
        <div className='petitionDetails__floatingSocialBarContent'>
          <CopyToClipboard text={petition.shareURL}>
            <button
              className='petitionDetails__copyLinkBtn '
              onClick={() => {
                if (!userBasicProfileComplete) setIsOpenCompleteProfile(true)
                else {
                  setActionPopupContent('Copied to clipboard')
                  setShowElement(true)
                }
              }}
            >
              <img
                className='petitionDetails__copyLinkIcon'
                src={toAbsoluteUrl('/media/design/copy_link_icon.svg')}
              />
              Copy Link
            </button>
          </CopyToClipboard>

          {!loading && (
            <button
              style={{width: 191, marginTop: 0}}
              className={
                !petition.userSignedPetition
                  ? `petitionDetails__signBar__publishBtn mobile-show`
                  : 'petitionDetails__signBar__publishBtn petitionDetails__signBar__signedBtn mobile-show'
              }
              onClick={petition.userSignedPetition ? null : onClickSign}
              disabled={petition.userSignedPetition === true}
            >
              {petition.userSignedPetition ? 'You Signed' : 'Sign Petition'}
            </button>
          )}
          {loading && (
            <button
              className='petitionDetails__signBar__publishBtn'
              style={{width: 191, marginTop: 0}}
            >
              Please wait...
            </button>
          )}
        </div>
      </div>
      <PetitionTabs view='web' currentTab={petitionTab} setCurrentTab={setPetitionTab} />
      <div className='PetitionDetails'>
        <div className='petitionDetails__innerWrap'>
          <div className='petitionDetails__body'>
            <h1 className='petitionDetails_title'>{petition.title}</h1>

            <div className='petitionDetails__coverImgWrap'>
              {petition.cover_photo.source_url && (
                <img
                  className='petitionDetails__coverImg mobile-hide'
                  src={toAbsoluteUrl(petition.cover_photo.source_url)}
                />
              )}
              <div className='petitionDetails__socialsBar mobile-hide'>
                {petition.signatures == petition.signatureGoal ? (
                  <div className=''>
                    <img
                      className='petitionDetails__megaphoneIcon'
                      src={toAbsoluteUrl('/media/design/hands_clapping_icon.svg')}
                    ></img>
                    <span className='petitionDetails__socialsBar__txt '>
                      This petition successfully reached the target
                    </span>
                  </div>
                ) : (
                  <div className=''>
                    <img
                      className='petitionDetails__megaphoneIcon'
                      src={toAbsoluteUrl('/media/design/megaphone_icon.svg')}
                    ></img>
                    <span className='petitionDetails__socialsBar__txt '>
                      This petition needs{' '}
                      <span className='petitionDetails__socialsBar__signGoalTxt'>
                        {petition.signatureGoal - petition.signatures}
                      </span>{' '}
                      signs to reach target
                    </span>
                  </div>
                )}
                <CopyToClipboard text={petition.shareURL}>
                  <button
                    className='petitionDetails__copyLinkBtn mobile-hide'
                    onClick={() => {
                      if (!userBasicProfileComplete) setIsOpenCompleteProfile(true)
                      else {
                        setActionPopupContent('Copied to clipboard')
                        setShowElement(true)
                      }
                    }}
                  >
                    <img
                      className='petitionDetails__copyLinkIcon'
                      src={toAbsoluteUrl('/media/design/copy_link_icon.svg')}
                    />
                    Copy Link
                  </button>
                </CopyToClipboard>
              </div>
            </div>

            <div className='petitionDetails__toWrap'>
              <span className='petitionDetails__toLabel'>Petition to:</span>
              <span className='petitionDetails__toValue'>{petition.receiver}</span>
            </div>

            <div className='petitionDetails__descWrap mobile-hide'>
              <p className='petitionDetails__desc'>{petition.description}</p>

              <StartPetitionBanner />
            </div>
          </div>
          <PetitionSignBar data={petition} onClickSign={onClickSign} />

          <div className='petitionDetails__descWrap mobile-show'>
            <p className='petitionDetails__desc'>{petition.description}</p>
            <StartPetitionBannerMobile />
          </div>
        </div>
      </div>
    </div>
  )
}
