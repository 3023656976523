/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext, useCallback} from 'react'
import {useHistory} from 'react-router-dom'

import {toAbsoluteUrl} from '../../../_metronic/helpers'

import {LeftSideBar} from '../LeftSideBar'

import {RightSideBar} from '../RightSideBar'
import '../Home.scss'
import './Petitions.scss'

import {ProfilePageContext} from '../../contexts/profilePageContext'

import ROUTE_PATHS from '../../routing/RoutePaths'
import PETITION_SERVICE from '../../services/petition/PetitionService'
import PetitionItem from './components/PetititionItem'
import MODAL_NAMES from '../../constants/ModalNames'
import {GlobalPrivateModalContext} from '../../contexts/GlobalModalContext'
import {ProfileContext} from '../../contexts/profilecontext'

export function Petitions(props) {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const {currentTab, setCurrentTab} = useContext(ProfilePageContext)
  const PROFILE_CONTEXT = useContext(ProfileContext)

  const userBasicProfileComplete = PROFILE_CONTEXT.profile?.basicProfileComplete
  const {setIsOpenGlobalModal} = useContext(GlobalPrivateModalContext)
  const setIsOpenCompleteProfile = (o) => setIsOpenGlobalModal(MODAL_NAMES.COMPLETE_PROFILE, o)

  const [petitions, setPetitions] = useState([] as any)

  const fetchPetitions = async () => {
    try {
      setLoading(true)
      let response = await PETITION_SERVICE.getPetitionFeed()
      // console.log(response)
      setPetitions(response)
      setLoading(false)
      // console.log('fetched .. petitions feed.....', response)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchPetitions()
  }, [])

  if (loading) {
    return (
      <div className='home__wrapper'>
        <div className='Home'>
          <LeftSideBar />

          <div id='Petitions' className='Petitions ' style={{width: 500}}>
            <div className='feed_loadingContainer'>
              <img className='feed__loader' src={toAbsoluteUrl('/media/design/feed_loader.svg')} />
            </div>
          </div>

          <RightSideBar />
        </div>{' '}
      </div>
    )
  }

  return (
    <div className='home__wrapper'>
      <div className='Home'>
        <LeftSideBar />

        <div id='Petitions' className='Petitions'>
          {/* CREATE PETITIONS BANNER */}
          <div className='petitions__createBanner'>
            <img
              className='petitions__createBannerImg'
              src={toAbsoluteUrl('/media/design/petition_logo.svg')}
            ></img>
            <div className='petitions__createBannerTxt'>
              <h2 className='petitions__createBannerTitle'>
                Create an official petition<br className='mobile-hide'></br> or publish a city issue
              </h2>

              <p className='mobile-hide'>
                Petitions can be officially submitted to the City Clerk. Issues will be published
                online only.
              </p>
            </div>

            <button
              className='petitions__createBannerBtn'
              onClick={() => {
                if (!userBasicProfileComplete) setIsOpenCompleteProfile(true)
                else {
                  setCurrentTab('Create Petition')
                  history.push(ROUTE_PATHS.MY_PROFILE)
                }
              }}
            >
              Create
            </button>
          </div>
          {/* PETITIONS POST*/}
          {petitions.map((petition, index) => {
            return <PetitionItem petition={petition} />
          })}
        </div>

        <RightSideBar />
      </div>{' '}
    </div>
  )
}
