import {useContext} from 'react'
import {ActivePetitionContext} from '../../../contexts/ActivePetitionContext'
import {useHistory} from 'react-router-dom'

export const CreatePetitionBottomButtons = ({loading, isLastStep = false, onPreview = null}) => {
  const {activePetition, setActivePetition} = useContext(ActivePetitionContext)
  const history = useHistory()

  return (
    <div
      className='civik-flex-row-wrap civik-poli-btn-wrapper'
      style={{marginTop: 26, marginBottom: 0}}
    >
      {activePetition.step > 1 && (
        <button
          type='button'
          className='btn btn-light btn-active-light-primary mt-7 skip-btn civik-profile-edit-cancel-btn'
          onClick={() => {
            setActivePetition({...activePetition, step: activePetition.step - 1})
          }}
        >
          <span className='indicator-label'>Previous</span>
        </button>
      )}
      {isLastStep && (
        <button
          type='button'
          className='btn btn-light btn-active-light-primary mt-7 skip-btn civik-profile-preview-btn'
          onClick={onPreview}
        >
          <span className='indicator-label'>Preview</span>
        </button>
      )}
      <button
        type='submit'
        id='kt_sign_up_submit'
        className='btn btn-lg btn-primary w-100  mt-7'
        style={{maxWidth: 'fit-content'}}
      >
        {!loading && (
          <span className='indicator-label' style={{fontSize: 15, fontWeight: 500}}>
            {isLastStep ? 'Publish' : 'Save and Continue'}
          </span>
        )}
        {loading && (
          <span className='indicator-progress' style={{display: 'block'}}>
            Please wait...
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        )}
      </button>
    </div>
  )
}
