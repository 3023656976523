/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext, useRef, useCallback} from 'react'
// import {IProfileDetails, profileDetailsInitValues as initialValues} from '../../../modules/profile/components/settings/SettingsModel'
import {useHistory} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'

import {UserContext} from '../../../../contexts/usercontext'
import {ProfileContext} from '../../../../contexts/profilecontext'
import {AccountStepContext} from '../../../../contexts/accountStepContext'

import TextField from '../../reusable/TextField'
import SelectField from '../../reusable/SelectField'

import POLITICIAN_SERIVCE from '../../../../services/politician/PoliticianService'
import AuthService from '../../../../services/auth/AuthService'

import {toAbsoluteUrl} from '../../../../../_metronic/helpers'

import ImageUploading from 'react-images-uploading'
import GooglePlacesAutoCompleteInput from '../../../reusable/GoogleAutocomplete'
import WardDropdowns from '../../../reusable/WardDropdowns'

import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/storage'

// import { storage } from "./firebase";

// import defaultPic from './employee_default_pic.svg';

const profileDetailsSchema = Yup.object().shape({
  fName: Yup.string().required('First name is required'),
  lName: Yup.string().required('Last name is required'),
  address: Yup.string().required('Address is required'),
  office: Yup.string().required('Candidate for is required'),
  // city: Yup.string().required('City is required'),
  // postalCode: Yup.string().required('Postal Code is required'),
  // province: Yup.string().required('Province is required'),
  phoneNumber: Yup.string().required('Phone number is required'),
  wardNumber: Yup.string().required('Ward number is required'),
  wardName: Yup.string().required('Ward name is required'),
})

const initialValues = {
  fName: '',
  lName: '',
  address: '',
  // province: '',
  // city: '',
  phoneNumber: '',
  faxNumber: '',
  // postalCode: '',
  wardNumber: '',
  wardName: '',
  profilePicture: {},
  boundaryUrl: '',
  location_info: {},
  office: '',
}

export function CouncilorProfile() {
  const [initializing, setInitializing] = useState(true)
  const history = useHistory()
  const USER_CONTEXT = useContext(UserContext)
  const PROFILE_CONTEXT = useContext(ProfileContext)
  const STEP_CONTEXT = useContext(AccountStepContext)

  // const uploadRef = useRef(null)

  const [fileUrl, setFileUrl] = React.useState(null)
  const [users, setUsers] = React.useState([])
  const [fileUpload, setFileUpload] = React.useState(null)

  const [loading, setLoading] = useState(false)

  const maxNumber = 69

  const [picUrl, setPictureUrl] = useState('')

  const uploadRef = useRef(null)

  const onImageClick = useCallback(() => {
    uploadRef.current.click()
  }, [uploadRef])

  const onFileUpload = async (e) => {
    try {
      e.persist()

      const file = e.target.files[0]
      console.log('file', file)
      if (file) {
        //  If the file size (calculated in byte form) is than 1MB
        if (file.size > 1.0e6) {
          console.log('IMAGE SIZE MUST BE LESS THAN 1MB')
        }
        if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
          return console.log('IMAGE TYPE MUST BE PNG OR JPG')
        }

        console.log('uploading image now!....')

        let usr_civik_id = PROFILE_CONTEXT.profile._id

        console.log('starting UPLOAD ========')
        const path = `profile_pics/${usr_civik_id}/profile_pic`
        await firebase.storage().ref(path).put(file)
        const setURL = await firebase.storage().ref(path).getDownloadURL()
        setPictureUrl(setURL)
        console.log(`====> setURL is ${setURL} <=======`)
      }
      // uploadTask.on(
      //   'state_changed',
      //   (snapshot) => {
      //     const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
      //     setProgress(progress)
      //   },
      //   (error) => {
      //     console.log(error)
      //   },
      //   async () => {
      //     const setURL = await firebase
      //       .storage()
      //       .ref(`profile_pics/${usr_civik_id}/profile_pic`)
      //       .getDownloadURL()

      //     setPictureUrl(setURL)
      //     console.log(`====> setURL is ${setURL} <=======`)
      //   }
      // )
    } catch (error) {
      console.error(error)
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(async () => {
        try {
          // const file = e.target.files[0]

          // console.log('fileUpload', fileUpload)

          //     const formData = new FormData();
          // formData.append('file', fileUpload);

          const userInput = {
            onboardingStep: 2,
            profile_picture: picUrl,
            first_name: values.fName,
            last_name: values.lName,

            officeAddress: values.address,
            office: values.office,
            // location_region: values.province,
            // location_city: values.city,

            contactNumber: values.phoneNumber, // phone number
            fax: values.faxNumber,
            // postal_code: values.postalCode,

            represented_district_number: values.wardNumber, // ward number
            represented_district_name: values.wardName, // ward name
            boundary_url: values.boundaryUrl,
            location_info: values.location_info,
          }

          console.log('userInput', userInput)
          await POLITICIAN_SERIVCE.updatePendingProfile(userInput)

          setLoading(false)

          //fetch user profile and set it into context
          await fetchProfile()
          // STEP_CONTEXT.setStep(2)
          // go to next page
          history.push('/auth/councilor-about')
        } catch (error) {
          console.log(error)
        }
      }, 1000)
    },
  })

  const fetchProfile = async () => {
    try {
      let userProfile = await AuthService.getCurrentUser()
      PROFILE_CONTEXT.setProfile(userProfile)
    } catch (error) {
      console.log(error)
    }
  }
  const [image, setImage] = useState(null)
  const [progress, setProgress] = useState(0)
  const [isCandidate, setIsCandidate] = useState(
    PROFILE_CONTEXT.profile?.electedOrCandidate === 'candidate'
  )

  const fetchProfileInfo = () => {
    try {
      // retrieve staff using staff _id
      let profile = PROFILE_CONTEXT.profile
      setIsCandidate(profile?.electedOrCandidate === 'candidate')
      console.log('councilor profile ', profile)

      // set form fields
      setPictureUrl(profile.profile_picture)
      formik.values.fName = profile.first_name ? profile.first_name : ''
      formik.values.lName = profile.last_name ? profile.last_name : ''
      formik.values.address = profile.officeAddress ? profile.officeAddress : ''
      formik.values.office = profile.office ? profile.office : 'City Councillor'
      // formik.values.postalCode = profile.postal_code ? profile.postal_code : ''
      // formik.values.province = profile.location_region ? profile.location_region : ''
      // formik.values.city = profile.location_city ? profile.location_city : ''
      formik.values.phoneNumber = profile.contactNumber ? profile.contactNumber : ''
      formik.values.faxNumber = profile.fax ? profile.fax : ''
      formik.values.wardNumber = profile.represented_district_number
      formik.values.wardName = profile.represented_district_name
        ? profile.represented_district_name
        : ''
      formik.values.boundaryUrl = profile.boundary_url ? profile.boundary_url : ''

      setInitializing(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchProfileInfo()
  }, [PROFILE_CONTEXT.profile?._id])

  if (initializing) {
    return <div />
  }
  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signup_form'
    >
      {/* begin::Heading */}
      <div className=' mb-5 w-100'>
        <h1 className='text-dark mb-3' style={{fontSize: '24px'}}>
          Profile
        </h1>
      </div>

      {/* begin::Heading */}
      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}
      {/* TOP SECTION */}
      <div>
        <div className='civik-flex-row-wrap' style={{justifyContent: 'space-between'}}>
          {/* FIRST NAME & LAST NAME */}
          <div style={{width: '100%'}}>
            <div className='mb-6'>
              <TextField
                formik={formik}
                type='text'
                field='fName'
                placeholder='First Name'
                label='First Name'
                isRequired={true}
              />
            </div>
            <div className=' mb-6'>
              <TextField
                formik={formik}
                type='text'
                field='lName'
                placeholder='Last Name'
                label='Last Name'
                isRequired={true}
              />
            </div>
          </div>
          <div className='civik-flex-row mb-6 civik-concil-pic'>
            <div>
              <label className=' col-form-label required fw-bold fs-6'>Profile Picture</label>

              <div className='civik-flex-column'>
                <img
                  className='civik-councilor-profile-pic'
                  src={picUrl ? picUrl : toAbsoluteUrl('/media/design/upload-img-sample.png')}
                  alt=''
                  onClick={onImageClick}
                />
                <progress value={progress} max='100' />
                <input
                  type='file'
                  onChange={onFileUpload}
                  ref={uploadRef}
                  style={{display: 'none'}}
                />
              </div>
            </div>
            {picUrl && (
              <div className='profile-pic-edit-remove-container'>
                <img
                  src={toAbsoluteUrl('/media/design/icon-edit.svg')}
                  alt='edit'
                  className='profile-pic-edit-icon'
                  onClick={onImageClick}
                />

                <div className='profile-pic-remove-text' onClick={() => setPictureUrl('')}>
                  Remove
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* TOP SECTION END */}
      {/* MID SECTION START */}

      <div className=' mb-6'>
        <GooglePlacesAutoCompleteInput
          formik={formik}
          type='text'
          field='address'
          placeholder='Address or City'
          label='Address or City'
          isRequired={true}
          // types={['locality']}
        />
      </div>

      {/* MID SECTION END */}
      {/* BOTTOM SECTION START */}

      <div>
        {/* ROW 1 */}
        {/* removed */}

        {/* ROW 2*/}
        <div className='civik-flex-row-wrap '>
          {/* START */}
          <div className='w-100 mb-6 mr-40-responsive'>
            <TextField
              formik={formik}
              type='text'
              field='phoneNumber'
              placeholder='Phone Number'
              label='Phone Number'
              isRequired={true}
            />
          </div>
          {/* END */}
          {/* START */}
          <div className='w-100 mb-6'>
            <TextField
              formik={formik}
              type='text'
              field='faxNumber'
              placeholder='Fax Number'
              label='Fax Number'
            />
          </div>
          {/* END */}
        </div>

        <div className='civik-flex-row-wrap '>
          {isCandidate && (
            <div className='w-100 mb-6 mr-40-responsive'>
              <SelectField
                formik={formik}
                type='text'
                field='office'
                placeholder='Candidate for'
                label='Candidate for'
                isRequired={true}
                options={[
                  {value: 'City Councillor', label: 'City Councillor'},
                  {value: 'School Board Trustee', label: 'School Board Trustee'},
                  {value: 'Mayor', label: 'Mayor'},
                ]}
              />
            </div>
          )}
          <WardDropdowns address={formik.values.address} formik={formik} />
        </div>
      </div>
      {/* BOTTOM SECTION END */}
      {/* end::Form group */}
      <div className='civik-flex-row' style={{justifyContent: 'flex-end'}}>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100  mt-7'
          style={{maxWidth: 180}}
          // disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Save & Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </form>
  )
}
