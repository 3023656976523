import civikApi from '../../../utils/civikAPI'

const FeedService = {
  getPosts: async (payload) => {
    try {
      let result = []
      result = await civikApi.get(
        `/posts/feed?world=${payload.world}&country=${payload.country}&region=${payload.region}&city=${payload.city}&trending=recent`
      )
      return result
    } catch (error) {
      console.log(error)
    }
  },

  getNewsPosts: async (payload) => {
    try {
      // console.log('fetching news...', payload)
      let result = []
      let newsUrl = `/posts/feed?world=${payload.world ? payload.world : ''}&country=${
        payload.country ? payload.country : ''
      }&region=${payload.region ? payload.region : ''}&city=${
        payload.city ? payload.city : ''
      }&trending=${payload.trending ? 'trending' : 'recent'}&type=news&skipFeatured=true`
      if (payload.limit) {
        newsUrl += `&limit=${payload.limit}`
      }
      if (payload.newssource) {
        newsUrl += `&newssource=${payload.newssource}`
      }
      result = await civikApi.get(newsUrl)
      return result
    } catch (error) {
      console.log(error)
    }
  },

  getNewsSources: async () => {
    try {
      let result = []
      result = await civikApi.get('/posts/newssources')
      result = result.map((source) => {
        return {label: source, value: source}
      })
      return result
    } catch (error) {
      console.log(error)
    }
  },

  getLinkPreview: async (payload) => {
    try {
      let result = ''
      result = await civikApi.post(`/posts/linkpreview`, {link: payload})
      // console.log('result', result)

      return result
    } catch (error) {
      console.log(error)
    }
  },

  likePost: async (payload) => {
    try {
      await civikApi.put(`/posts/${payload}/vote/upvote`)
      return {code: 200, message: 'post liked successfully'}
    } catch (error) {
      console.log(error)
      return {code: error.code, message: error.message}
    }
  },

  unlikePost: async (payload) => {
    try {
      await civikApi.put(`/posts/${payload}/unvote/upvote`)
      return {code: 200, message: 'post liked successfully'}
    } catch (error) {
      console.log(error)
      return {code: error.code, message: error.message}
    }
  },

  reportPost: async (payload) => {
    try {
      let response = await civikApi.put(`/posts/${payload}/flag`)
      return response
    } catch (error) {
      console.log(error)
    }
  },

  deletePost: async (payload) => {
    try {
      let response = await civikApi.delete(`/posts/${payload}`)
      return response
    } catch (error) {
      console.log(error)
    }
  },

  getMyPosts: async (payload) => {
    try {
      console.log('USERS PROFILE ID: ', payload)
      let result = []
      result = await civikApi.get(`/users/${payload}/posts`)
      return result
    } catch (error) {
      console.log(error)
    }
  },

  createComment: async (payload) => {
    try {
      let response = await civikApi.post(`/posts/${payload.postId}/comments`, {
        text: payload.text,
        anonymous: payload.anonymous,
        parent_id: payload.parentId,
      })
      return response
    } catch (error) {
      console.log(error)
    }
  },

  getPostComments: async (payload) => {
    try {
      let result = []
      result = await civikApi.get(`/posts/${payload.postId}/comments?sort=${payload.sortBy}`)

      return {code: 200, data: result}
    } catch (error) {
      // console.log(error)
      return {code: error.code, data: error.message}
    }
  },

  likePostComment: async (payload) => {
    try {
      await civikApi.put(`/comments/${payload}/vote/upvote`)
      return {code: 200, message: 'comment liked successfully'}
    } catch (error) {
      // console.log(error)
      return {code: error.code, data: error.message}
    }
  },

  unlikePostComment: async (payload) => {
    try {
      await civikApi.put(`/comments/${payload}/unvote/upvote`)
      return {code: 200, message: 'comment liked successfully'}
    } catch (error) {
      // console.log(error)
      return {code: error.code, data: error.message}
    }
  },

  getPostDetails: async (postId) => {
    try {
      const post = await civikApi.get(`/posts/${postId}/`)
      return post.post
    } catch (error) {
      // console.log(error)
    }
  },
}

export default FeedService
