/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext, useCallback, useRef} from 'react'
import Modal from 'react-modal'

import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import TextField from '../../../../../app/modules/auth/reusable/TextField'
import SelectField from '../../../../../app/modules/auth/reusable/SelectField'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {PropertyInfoModal} from '../../modals/PropertyInfoModal'
import {VoterInfoModal} from '../../modals/VoterInfoModal'

import FeatherIcon from 'feather-icons-react'

// import './AddressList.scss'

export const initialValues = {}

export const loginSchema = Yup.object().shape({})

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
}

export function ElectorsListTable(props) {
  const [loading, setLoading] = useState(false)
  const [modalIsOpen, setIsOpen] = useState(false)
  const [voterInfoModal, setVoterInfoModal] = useState(false)

  const [modalView, setModalView] = useState('')
  const [postTopic, setPostTopic] = useState('All Districts')

  const [openTopicsDropdown, setOpenTopicsDropdown] = useState(false)
  const topicOpRef = useRef(null)

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(async () => {
        try {
          setLoading(false)
        } catch (error) {
          console.log(error)
        }
      }, 10)
    },
  })

  function afterOpenModal() {}
  function onAfterCompleteModal() {}
  function closeModal() {
    setIsOpen(false)
  }

  function afterOpenVoterModal() {}

  return (
    <div id='Voter'>
      {/* MODAL */}
      <PropertyInfoModal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        modalView={modalView}
      />

      <VoterInfoModal
        isOpen={voterInfoModal}
        onAfterOpen={afterOpenVoterModal}
        onRequestClose={() => {
          setVoterInfoModal(false)
        }}
        style={customStyles}
      />

      {/* CONSOLE */}
      <div className='voterAddrList__tableContanier'>
        {/* TABLE */}
        <div className='voterAddrList__table'>
          <div className='voterAddrList__tableHeader'>
            <span className='voterAddrList__tableHeader__columnLabel electorListTable__columnLabel--1'>
              Voter ID
            </span>
            <span className='voterAddrList__tableHeader__columnLabel electorListTable__columnLabel--2'>
              First Name
            </span>
            <span className='voterAddrList__tableHeader__columnLabel electorListTable__columnLabel--3'>
              Last Name
            </span>
            <span className='voterAddrList__tableHeader__columnLabel electorListTable__columnLabel--4'>
              Property Address
            </span>

            <span className='voterAddrList__tableHeader__columnLabel electorListTable__columnLabel--5'>
              District
            </span>

            <span className='voterAddrList__tableHeader__columnLabel electorListTable__columnLabel--6'>
              Date of Birth
            </span>

            <span className='voterAddrList__tableHeader__columnLabel electorListTable__columnLabel--7'>
              Status
            </span>

            <span className='voterAddrList__tableHeader__columnLabel electorListTable__columnLabel--8'>
              Action
            </span>
          </div>

          <div className='voterAddrList__tableBody'>
            <div className='voterAddrList__tableBody__row'>
              <span
                className='electorListTable__rowItem--1 '
                onClick={() => {
                  setVoterInfoModal(true)
                }}
              >
                123455
              </span>
              <span className='electorListTable__rowItem--2'>Adam</span>
              <span className='electorListTable__rowItem--3'>Harold</span>
              <span
                className='electorListTable__rowItem--4'
                onClick={() => {
                  setModalView('PropertyInfo')
                  setIsOpen(true)
                }}
              >
                752 St. George Street, PO Box 100 Annapolis Royal, NS B0S 1A0
              </span>
              <span className='electorListTable__rowItem--5'>Annapolis</span>
              <span className='electorListTable__rowItem--6'>10/22/1988</span>
              <div className='electorListTable__rowItem--7'>
                <span className='importAddrList__statusLabel importAddrList__statusLabel--success'>
                  Approved
                </span>
              </div>
              <div className='electorListTable__rowItem--8'>
                <FeatherIcon icon={'more-horizontal'} color='#5E6278' onClick={() => {}} />
              </div>
            </div>

            <div className='voterAddrList__tableBody__row'>
              <span className='electorListTable__rowItem--1'>123455</span>

              <span className='electorListTable__rowItem--2'>Adam</span>
              <span className='electorListTable__rowItem--3'>Harold</span>
              <span
                className='electorListTable__rowItem--4'
                onClick={() => {
                  setModalView('PropertyInfo')
                  setIsOpen(true)
                }}
              >
                752 St. George Street, PO Box 100 Annapolis Royal, NS B0S 1A0
              </span>
              <span className='electorListTable__rowItem--5'>Annapolis</span>
              <span className='electorListTable__rowItem--6'>10/22/1988</span>
              <div className='electorListTable__rowItem--7'>
                <span className='importAddrList__statusLabel importAddrList__statusLabel--notused'>
                  Pending
                </span>
              </div>
              <div className='electorListTable__rowItem--8'>
                <FeatherIcon icon={'more-horizontal'} color='#5E6278' onClick={() => {}} />
              </div>
            </div>

            <div className='voterAddrList__tableBody__row'>
              <span className='electorListTable__rowItem--1'>123455</span>

              <span className='electorListTable__rowItem--2'>Adam</span>
              <span className='electorListTable__rowItem--3'>Harold</span>
              <span
                className='electorListTable__rowItem--4'
                onClick={() => {
                  setModalView('PropertyInfo')
                  setIsOpen(true)
                }}
              >
                752 St. George Street, PO Box 100 Annapolis Royal, NS B0S 1A0
              </span>
              <span className='electorListTable__rowItem--5'>Annapolis</span>
              <span className='electorListTable__rowItem--6'>10/22/1988</span>
              <div className='electorListTable__rowItem--7'>
                <span className='importAddrList__statusLabel importAddrList__statusLabel--failed'>
                  Declined
                </span>
              </div>
              <div className='electorListTable__rowItem--8'>
                <FeatherIcon icon={'more-horizontal'} color='#5E6278' onClick={() => {}} />
              </div>
            </div>

            <div className='voterAddrList__tableBody__row'>
              <span className='electorListTable__rowItem--1'>123455</span>

              <span className='electorListTable__rowItem--2'>Adam</span>
              <span className='electorListTable__rowItem--3'>Harold</span>
              <span
                className='electorListTable__rowItem--4'
                onClick={() => {
                  setModalView('PropertyInfo')
                  setIsOpen(true)
                }}
              >
                752 St. George Street, PO Box 100 Annapolis Royal, NS B0S 1A0
              </span>
              <span className='electorListTable__rowItem--5'>Annapolis</span>
              <span className='electorListTable__rowItem--6'>10/22/1988</span>
              <div className='electorListTable__rowItem--7'>
                <span className='importAddrList__statusLabel importAddrList__statusLabel--success'>
                  Approved
                </span>
              </div>
              <div className='electorListTable__rowItem--8'>
                <FeatherIcon icon={'more-horizontal'} color='#5E6278' onClick={() => {}} />
              </div>
            </div>

            <div className='voterAddrList__tableBody__row'>
              <span className='electorListTable__rowItem--1'>123455</span>

              <span className='electorListTable__rowItem--2'>Adam</span>
              <span className='electorListTable__rowItem--3'>Harold</span>
              <span
                className='electorListTable__rowItem--4'
                onClick={() => {
                  setModalView('PropertyInfo')
                  setIsOpen(true)
                }}
              >
                752 St. George Street, PO Box 100 Annapolis Royal, NS B0S 1A0
              </span>
              <span className='electorListTable__rowItem--5'>Annapolis</span>
              <span className='electorListTable__rowItem--6'>10/22/1988</span>
              <div className='electorListTable__rowItem--7'>
                <span className='importAddrList__statusLabel importAddrList__statusLabel--success'>
                  Approved
                </span>
              </div>
              <div className='electorListTable__rowItem--8'>
                <FeatherIcon icon={'more-horizontal'} color='#5E6278' onClick={() => {}} />
              </div>
            </div>

            <div className='voterAddrList__tableBody__row'>
              <span className='electorListTable__rowItem--1'>123455</span>

              <span className='electorListTable__rowItem--2'>Adam</span>
              <span className='electorListTable__rowItem--3'>Harold</span>
              <span
                className='electorListTable__rowItem--4'
                onClick={() => {
                  setModalView('PropertyInfo')
                  setIsOpen(true)
                }}
              >
                752 St. George Street, PO Box 100 Annapolis Royal, NS B0S 1A0
              </span>
              <span className='electorListTable__rowItem--5'>Annapolis</span>
              <span className='electorListTable__rowItem--6'>10/22/1988</span>
              <div className='electorListTable__rowItem--7'>
                <span className='importAddrList__statusLabel importAddrList__statusLabel--success'>
                  Approved
                </span>
              </div>
              <div className='electorListTable__rowItem--8'>
                <FeatherIcon icon={'more-horizontal'} color='#5E6278' onClick={() => {}} />
              </div>
            </div>

            <div className='voterAddrList__tableBody__row'>
              <span className='electorListTable__rowItem--1'>123455</span>

              <span className='electorListTable__rowItem--2'>Adam</span>
              <span className='electorListTable__rowItem--3'>Harold</span>
              <span
                className='electorListTable__rowItem--4'
                onClick={() => {
                  setModalView('PropertyInfo')
                  setIsOpen(true)
                }}
              >
                752 St. George Street, PO Box 100 Annapolis Royal, NS B0S 1A0
              </span>
              <span className='electorListTable__rowItem--5'>Annapolis</span>
              <span className='electorListTable__rowItem--6'>10/22/1988</span>
              <div className='electorListTable__rowItem--7'>
                <span className='importAddrList__statusLabel importAddrList__statusLabel--success'>
                  Approved
                </span>
              </div>
              <div className='electorListTable__rowItem--8'>
                <FeatherIcon icon={'more-horizontal'} color='#5E6278' onClick={() => {}} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* footer */}
      <div className='voterAddrList__footer'>
        <span className='voterAddrList__footer__txt'>
          Showing <span className='civik-primary-blue'>1</span> to{' '}
          <span className='civik-primary-blue'>10</span> of{' '}
          <span className='civik-primary-blue'>399,695</span> Households
        </span>
      </div>
    </div>
  )
}
