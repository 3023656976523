/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useContext, useState} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import firebase from 'firebase/compat/app'

import {SignupCouncilor} from './components/councilor/SignupCouncilor'
import {CouncilorProfile} from './components/councilor/CouncilorProfile'
import {CouncilorAbout} from './components/councilor/CouncilorAbout'
import {CouncilorStaff} from './components/councilor/CouncilorStaff'
import {CouncilorBoards} from './components/councilor/CouncilorBoards'
import {CouncilorImproveAreas} from './components/councilor/CouncilorImproveAreas'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

import {PublicDashboard} from './components/PublicDashboard'

import {ProfileContext} from '../../../app/contexts/profilecontext'
import {AccountStepContext} from '../../../app/contexts/accountStepContext'

import {useHistory} from 'react-router-dom'
import {current} from '@reduxjs/toolkit'
import ROUTE_PATHS from '../../routing/RoutePaths'

import Modal from 'react-modal'
import {UserContext} from '../../contexts/usercontext'
import {onLogout} from '../../helperfunctions/AuthHelpers'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
}

export function AuthCouncilorPages() {
  const history = useHistory()
  const USER_CONTEXT = useContext(UserContext)
  const PROFILE_CONTEXT = useContext(ProfileContext)
  const STEP_CONTEXT = useContext(AccountStepContext)

  useEffect(() => {
    document.body.classList.add('bg-white')

    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  // const [currentStep, setCurrentStep] = useState(1)

  const pages = {
    1: '/auth/councilor-profile',
    2: '/auth/councilor-about',
    3: '/auth/councilor-staff',
    4: '/auth/councilor-boards',
    5: '/auth/councilor-improve-areas',
  }

  const logout = () => {
    onLogout(PROFILE_CONTEXT, history)
  }

  // const setCurrentTab = (tab) => {
  //   console.log('STEP_CONTEXT.step', STEP_CONTEXT.step)

  //   if (tab < STEP_CONTEXT.step) {
  //     return 'fw-bold fs-5'
  //   } else if (tab == STEP_CONTEXT.step) {
  //     return 'fw-bold fs-5 civik-primary-blue'
  //   } else {
  //     return 'fw-bold fs-5 civik-dark-grey'
  //   }
  // }

  useEffect(() => {
    let onboardingStep = PROFILE_CONTEXT.profile.onboardingStep
    console.log('onboardingStep', onboardingStep)

    if (onboardingStep) {
      console.log('redirecting to ...', pages[onboardingStep])
      history.push(pages[onboardingStep])
    }
  }, [USER_CONTEXT.user])

  return (
    <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
      <div className='civik-auth-navbar civik-flex-row' style={{justifyContent: 'space-between'}}>
        <img
          alt='civik blue logo'
          src={toAbsoluteUrl('/media/design/logo-civik-blue-horizontal.svg')}
          className='civik-auth-navbar-logo'
        />

        <a onClick={logout} className='menu-link px-5' style={{cursor: 'pointer'}}>
          Sign Out
        </a>
      </div>
      <div className='d-flex flex-row flex-row-fluid '>
        {/* <AuthSideBanner /> */}

        <div className=' flex-center flex-column flex-column-fluid civik-council-form-container'>
          <div className=' flex-center flex-column flex-column-fluid ' style={{width: '100%'}}>
            {/* <div
              className='civik-flex-row'
              style={{
                marginTop: 10,
                padding: 20,

                justifyContent: 'space-between',
              }}
            >
              <span className={setCurrentTab(1)}>Profile</span>
              <span className={setCurrentTab(2)}>About</span>
              <span className={setCurrentTab(3)}>Staff</span>
              <span className={setCurrentTab(4)}>Committees and Boards</span>
              <span className={setCurrentTab(5)}>Improvement Areas</span>
            </div> */}

            <div
              className='bg-white rounded p-10 p-lg-15 mx-auto civik-council-inner-container'
              style={{width: '100%'}}
            >
              <Switch>
                <Route path='/auth/councilor-profile' component={CouncilorProfile} />
                <Route path='/auth/councilor-about' component={CouncilorAbout} />
                <Route path='/auth/councilor-staff' component={CouncilorStaff} />
                {/* <Route path='/auth/councilor-boards' component={CouncilorBoards} />
                <Route path='/auth/councilor-improve-areas' component={CouncilorImproveAreas} /> */}
                {/* <Route path='/auth/public-dashboard' component={PublicDashboard} /> */}

                {/* <Redirect from='/auth' exact={true} to={pendingStep} />
                <Redirect to={pendingStep} /> */}
              </Switch>
            </div>
          </div>

          {/* <AuthFooter />
          <AuthFooterMobile /> */}
        </div>
      </div>
    </div>
  )
}
