import React, {useContext, useState} from 'react'
import UserPicture from '../../modules/reusable/UserPicture'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {UserContext} from '../../contexts/usercontext'
import {NotLoggedInModalContext} from '../../contexts/NotLoggedInModalContext'
import {GlobalPrivateModalContext} from '../../contexts/GlobalModalContext'
import MODAL_NAMES from '../../constants/ModalNames'

const FeedBar = ({
  customStyling,
  PROFILE_CONTEXT,
  userBasicProfileComplete,
  CREATE_POST_CONTEXT,
}) => {
  const {setIsOpenGlobalModal} = useContext(GlobalPrivateModalContext)
  const setIsOpenCompleteProfile = (o) => setIsOpenGlobalModal(MODAL_NAMES.COMPLETE_PROFILE, o)
  const USER_CONTEXT = useContext(UserContext)
  const NOT_LOGGED_IN_MODAL_CONTEXT = useContext(NotLoggedInModalContext)
  const fully_authenticated =
    USER_CONTEXT.user && USER_CONTEXT.user.loggedIn && USER_CONTEXT.user.verified

  const handleCreatePostClick = (postType) => {
    if (!userBasicProfileComplete) {
      setIsOpenCompleteProfile(true)
    } else {
      CREATE_POST_CONTEXT.setShowModal({visible: true, postType})
    }
  }

  return (
    <div className='feed__bar' style={customStyling}>
      <div className='feed__barTopWrap'>
        <UserPicture profile={PROFILE_CONTEXT.profile} isReply={false} />
        <div
          className='feed__barBtn'
          onClick={() => {
            if (!fully_authenticated) {
              NOT_LOGGED_IN_MODAL_CONTEXT.setIsModalOpen(true)
            } else {
              handleCreatePostClick('Basic Post')
            }
          }}
        >
          <span className='feed__barBtnTxt'>Share your thoughts?</span>
        </div>
      </div>

      <div className='feed__barIconsWrapper'>
        <div
          className='feed__barIcon feed__barIconBtn'
          onClick={() => {
            if (!fully_authenticated) {
              NOT_LOGGED_IN_MODAL_CONTEXT.setIsModalOpen(true)
            } else {
              handleCreatePostClick('Basic Post')
            }
          }}
        >
          <img
            alt=''
            className='navbar__searchIcon'
            src={toAbsoluteUrl('/media/design/my_posts_icon.svg')}
          />
          <span className='feed__barIconTxt'>Create Post</span>
        </div>

        <div
          className='feed__barIcon  feed__barIconBtn'
          onClick={() => {
            if (!fully_authenticated) {
              NOT_LOGGED_IN_MODAL_CONTEXT.setIsModalOpen(true)
            } else {
              handleCreatePostClick('Share News')
            }
          }}
        >
          <img
            alt=''
            className='navbar__searchIcon'
            src={toAbsoluteUrl('/media/design/share_news_icon.svg')}
          />
          <span className='feed__barIconTxt'>Share News</span>
        </div>
      </div>
    </div>
  )
}

export default FeedBar
