/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext, useRef, useCallback} from 'react'
// import {IProfileDetails, profileDetailsInitValues as initialValues} from '../../../modules/profile/components/settings/SettingsModel'
import {useHistory} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'

import ReactPlayer from 'react-player'

import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

import Modal from 'react-modal'

import {UserContext} from '../../contexts/usercontext'

import FeatherIcon from 'feather-icons-react'

import SelectField from '../../modules/auth/reusable/SelectField'
import TextField from '../../modules/auth/reusable/TextField'
import TextAreaField from '../../modules/auth/reusable/TextAreaField.jsx'

import POLITICIAN_SERIVCE from '../../services/politician/PoliticianService'
import {ProfileContext} from '../../contexts/profilecontext'
import AuthService from '../../services/auth/AuthService'
import VOTER_SERVICE from '../../services/voter/VoterService'

import './CreatePostModal.scss'

import {ShareNewsModal} from './ShareNewsModal'
import {CreatePostModal} from './CreatePostModal'

export function CreatePostModalWrapper(props) {
  useEffect(() => {
    console.log('props.postType', props.postType)

    Modal.setAppElement('#Feed')
  }, [props.postType])

  useEffect(() => {
    if (props.isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [props.isOpen])

  if (props.postType == 'Share News') {
    return (
      <ShareNewsModal
        isOpen={props.isOpen}
        onAfterOpen={props.onAfterOpen}
        onRequestClose={props.onRequestClose}
        changePostType={(newPostType) => {
          console.log('newPostType', newPostType)
          props.setPostType(newPostType)
        }}
        fetchFeed={() => {
          props.fetchFeed()
        }}
        updateFeed={(newPost) => {
          props.updateFeed(newPost)
        }}
      />
    )
  }

  return (
    <CreatePostModal
      isOpen={props.isOpen}
      onAfterOpen={props.onAfterOpen}
      onRequestClose={props.onRequestClose}
      changePostType={(newPostType) => {
        console.log('newPostType', newPostType)
        props.setPostType(newPostType)
      }}
      fetchFeed={() => {
        props.fetchFeed()
      }}
      updateFeed={(newPost) => {
        props.updateFeed(newPost)
      }}
    />
  )
}
