import React, {useState, useEffect, useRef} from 'react'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import config from '../../../config'
const apiKey = config.GOOGLE_PLACES_API_KEY

const GooglePlacesAutoCompleteInput = (props) => {
  const {formik, field, placeholder, label, isRequired, noLabel, types=[]} = props
  const [value, setValue] = useState(formik.values[field])
  return (
    <div>
      {!noLabel && (
        <label
          className={
            isRequired
              ? 'form-label fs-6 fw-bold text-dark required'
              : 'form-label fs-6 fw-bold text-dark'
          }
        >
          {label}
        </label>
      )}
      <GooglePlacesAutocomplete
        autocompletionRequest={{
          componentRestrictions: {country: 'ca'},
          types,
        }}
        selectProps={{
          onChange: (newValue) => {
            formik.setFieldValue(field, newValue.label)
            setValue(newValue)
          },
          placeholder: value ? value : placeholder,
          name: field,
          value,
          styles: {
            container: (provided) => ({
              ...provided,
              background: '#f5f8fa',
              borderRadius: 8,
            }),
            control: (provided) => ({
              ...provided,
              'height': 45,
              'paddingLeft': 15,
              'borderRadius': 8,
              'background': '#f5f8fa',

              'border': 'none',
              'width': '100%',
              'color': '#3f4254',

              'fontSize': 14,
              'fontWeight': '500',

              '&:focus': {
                outline: 'none',
              },
            }),
            input: (provided) => ({
              ...provided,
              fontSize: 14,
              fontWeight: '500',
              borderRadius: 8,
            }),
            placeholder: (provided) => ({
              ...provided,
              fontSize: 14,
              color: '#a1a5b7',
            }),
            menu: (provided) => ({
              ...provided,
            }),
          },
          theme: (theme) => ({
            ...theme,
            borderRadius: 8,
            colors: {
              ...theme.colors,
              primary: '#f5f8fa',
            },
          }),
        }}
        apiKey={apiKey}
      />
      {formik.touched[field] && formik.errors[field] && (
        <div className='fv-plugins-message-container'>
          <span role='alert'>{formik.errors[field]}</span>
        </div>
      )}
    </div>
  )
}

export default GooglePlacesAutoCompleteInput
