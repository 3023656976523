import TextField from '../../../../modules/auth/reusable/TextField'
import SelectField from '../../../../modules/auth/reusable/SelectField'

import '../../pages/VoterRegister.scss'
import {useVoterStyles} from '../../../../contexts/VoterStylesContext'

const provinces = [
  {value: 0, label: 'Province'},
  {value: 'Alberta', label: 'Alberta'},
  {value: 'British Columbia', label: 'British Columbia'},
  {value: 'Manitoba', label: 'Manitoba'},
  {value: 'New Brunswick', label: 'New Brunswick'},
  {value: 'Newfoundland and Labrador', label: 'Newfoundland and Labrador'},
  {value: 'Northwest Territories', label: 'Northwest Territories'},
  {value: 'Nova Scotia', label: 'Nova Scotia'},
  {value: 'Nunavut', label: 'Nunavut'},
  {value: 'Ontario', label: 'Ontario'},
  {value: 'Prince Edward Island', label: 'Prince Edward Island'},
  {value: 'Quebec', label: 'Quebec'},
  {value: 'Saskatchewan', label: 'Saskatchewan'},
  {value: 'Yukon', label: 'Yukon'},
]

export function AddressInfo(props) {
  const {formik, setModalView, setIsOpen, view} = props

  const {customTxtClass} = useVoterStyles()

  const VoterRegister = (
    <>
      <div className='voterRegister__addressWrap'>
        <div className='voterRegister__addressInputWrap'>
          <TextField
            formik={formik}
            type='text'
            field='streetAddress'
            placeholder='Street Address'
            label='Street Address'
            isRequired={true}
            view='voter'
          />
          {formik.touched['streetAddress'] && formik.errors['streetAddress'] && (
            <div className='voterRegister__singleInputErr'>
              <div className='TextField__voterErrWrap'>
                <span className='TextField__voterErrTxt' role='alert'>
                  {formik.errors['streetAddress']}
                </span>
              </div>
            </div>
          )}
        </div>
        <div>
          <TextField
            formik={formik}
            type='text'
            field='unitNumber'
            placeholder='Unit Number'
            label='Unit #/P.O.Box'
            view='voter'
          />
        </div>
      </div>

      <div className='voterRegister__locationWrap'>
        <div className='voterRegister__locationInputsWrap'>
          <div className='voterRegister__cityInputWrap'>
            <TextField
              formik={formik}
              type='text'
              field='city'
              placeholder='City'
              label='City'
              isRequired={true}
              view='voter'
            />
          </div>

          <div className='voterRegister__provinceDropdownWrap'>
            <SelectField
              formik={formik}
              type='text'
              field='province'
              placeholder='Province'
              label='Province'
              isRequired={true}
              options={provinces}
              view='voter'
            />
          </div>

          <div className='voterRegister__postalInputWrap'>
            <TextField
              formik={formik}
              type='text'
              field='postalCode'
              placeholder='Postal Code'
              label='Postal Code'
              isRequired={true}
              view='voter'
            />
          </div>
        </div>

        {formik.touched['city'] && formik.errors['city'] && (
          <div className='voterRegister__singleInputErr'>
            <div className='TextField__voterErrWrap'>
              <span className='TextField__voterErrTxt' role='alert'>
                {formik.errors['city']}
              </span>
            </div>
          </div>
        )}
        {formik.touched['province'] && formik.errors['province'] && (
          <div className='voterRegister__singleInputErr'>
            <div className='TextField__voterErrWrap'>
              <span className='TextField__voterErrTxt' role='alert'>
                {formik.errors['province']}
              </span>
            </div>
          </div>
        )}
        {formik.touched['postalCode'] && formik.errors['postalCode'] && (
          <div className='voterRegister__singleInputErr'>
            <div className='TextField__voterErrWrap'>
              <span className='TextField__voterErrTxt' role='alert'>
                {formik.errors['postalCode']}
              </span>
            </div>
          </div>
        )}
      </div>

      <span
        className='voterRegister__addressDisclaimer voterRegister__singleNameTxt'
        style={{fontSize: 14}}
      >
        Can not find your address?{' '}
        <span
          className={
            customTxtClass
              ? 'voterRegister__addressDisclaimerLink' + ' ' + customTxtClass
              : 'voterRegister__addressDisclaimerLink'
          }
          onClick={() => {
            setModalView('AddressDisclaimer')
            setIsOpen(true)
          }}
        >
          Click here
        </span>
      </span>
    </>
  )
  const AdminEdit = (
    <>
      <div className='voterRegister__addressWrap'>
        <div className='voterRegister__addressInputWrap'>
          <TextField
            formik={formik}
            type='text'
            field='streetAddress'
            placeholder='Street Address'
            label='Street Address'
            isRequired={true}
            view='voter'
          />
          {formik.touched['streetAddress'] && formik.errors['streetAddress'] && (
            <div className='voterRegister__singleInputErr'>
              <div className='TextField__voterErrWrap'>
                <span className='TextField__voterErrTxt' role='alert'>
                  {formik.errors['streetAddress']}
                </span>
              </div>
            </div>
          )}
        </div>
        <div>
          <TextField
            formik={formik}
            type='text'
            field='unitNumber'
            placeholder='Unit Number'
            label='Unit #/P.O.Box'
            view='voter'
          />
        </div>
      </div>

      <div className='voterRegister__locationWrap'>
        <div className='voterRegister__locationInputsWrap'>
          <div className='voterRegister__cityInputWrap'>
            <TextField
              formik={formik}
              type='text'
              field='city'
              placeholder='City'
              label='City'
              isRequired={true}
              view='voter'
            />
          </div>

          <div className='voterRegister__provinceDropdownWrap'>
            <SelectField
              formik={formik}
              type='text'
              field='province'
              placeholder='Province'
              label='Province'
              isRequired={true}
              options={provinces}
              view='voter'
            />
          </div>

          <div className='voterRegister__postalInputWrap'>
            <TextField
              formik={formik}
              type='text'
              field='postalCode'
              placeholder='Postal Code'
              label='Postal Code'
              isRequired={true}
              view='voter'
            />
          </div>
        </div>

        {formik.touched['city'] && formik.errors['city'] && (
          <div className='voterRegister__singleInputErr'>
            <div className='TextField__voterErrWrap'>
              <span className='TextField__voterErrTxt' role='alert'>
                {formik.errors['city']}
              </span>
            </div>
          </div>
        )}
        {formik.touched['province'] && formik.errors['province'] && (
          <div className='voterRegister__singleInputErr'>
            <div className='TextField__voterErrWrap'>
              <span className='TextField__voterErrTxt' role='alert'>
                {formik.errors['province']}
              </span>
            </div>
          </div>
        )}
        {formik.touched['postalCode'] && formik.errors['postalCode'] && (
          <div className='voterRegister__singleInputErr'>
            <div className='TextField__voterErrWrap'>
              <span className='TextField__voterErrTxt' role='alert'>
                {formik.errors['postalCode']}
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  )

  const renderView = () => {
    if (view == 'VoterRegister') {
      return VoterRegister
    }
    if (view == 'AdminEdit') {
      return AdminEdit
    }
  }

  return (
    <div className='voterRegister__addressRow'>
      <div className='voterRegister__divider'>
        <span
          className={
            customTxtClass
              ? 'voterRegister__dividerLabel' + ' ' + customTxtClass
              : 'voterRegister__dividerLabel'
          }
        >
          Residential Address
        </span>
      </div>

      {renderView()}
    </div>
  )
}
