import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import ERROR_CODES from '../../constants/ErrorCodes'
import {
  verifyPasswordResetCode,
  confirmPasswordReset,
  getAuth,
  applyActionCode,
} from 'firebase/auth'
import config from '../../../config'
import axios from 'axios'
import environment from '../../../utils/environment'
import civikApi from '../../../utils/civikAPI'

const PoliticianService = {
  updatePendingProfile: async (payload) => {
    try {
      // update pending profile
      await civikApi.put(
        `/politicians/pending`,
        payload
      )

      // return {code: 200, status: 'auth/success', message: 'logged in'}
    } catch (error) {
      console.log(error)
      // return ERROR_CODES.firebaseErrors[error.code]
    }
  },
  updateProfile: async (payload, profileType) => {
    try {
      // update pending profile
      if (profileType === 'pendingPolitician') {
        await civikApi.put(
          `/politicians/pending`,
          payload
        )
      } else {
        await civikApi.put(
          `/politicians/`,
          payload
        )
      }
      // return {code: 200, status: 'auth/success', message: 'logged in'}
    } catch (error) {
      console.log(error)
      // return ERROR_CODES.firebaseErrors[error.code]
    }
  },
}

export default PoliticianService
