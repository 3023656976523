import config from '../config'
const currentEnvironment = config?.ENV ? config.ENV : 'remote_development'
const currentVersion = 'app'

const env = {
  development: {
    rest: 'http://localhost:3001',
  },
  remote_development: {
    rest: 'https://dev.api.civik.ca',
  },
  stage: {
    rest: 'https://stage.api.civik.ca',
  },
  production: {
    rest: 'https://api.civik.ca',
  },
}

const syn = {
  development: {
    syngrafii_origin: 'https://sandbox.syngrafii.com',
  },
  remote_development: {
    syngrafii_origin: 'https://sandbox.syngrafii.com',
  },
  stage: {
    syngrafii_origin: 'https://sign.syngrafii.com',
  },
  production: {
    syngrafii_origin: 'https://sign.syngrafii.com',
  },
}

const ver = {
  app: {
    version: '1.0',
  },
}

const environment = {
  /**
   * Returns the environment endpoints set above
   *
   * @return {String}
   */
  resolveApi: () => {
    return env[currentEnvironment]
  },

  resolveVersion: () => {
    return ver[currentVersion]
  },

  resolveSyngrafii: () => {
    return syn[currentEnvironment].syngrafii_origin
  },
}

export default environment
