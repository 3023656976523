import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import ERROR_CODES from '../../constants/ErrorCodes'
import {
  verifyPasswordResetCode,
  confirmPasswordReset,
  getAuth,
  applyActionCode,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
  checkActionCode,
} from 'firebase/auth'
import civikApi from '../../../utils/civikAPI'
import ROUTE_PATHS from '../../routing/RoutePaths'

const getUserProfileInfo = (user) => {
  user.basicProfileComplete = user.profile_creation_completed
  let communities = ['World', 'Canada']
  if (user.location_city) {
    communities.push(user.location_city)
  }

  if (user.location_region) {
    communities.push(user.location_region)
  }
  user.communities = communities
  return user
}

const AuthService = {
  loginWithEmailAndPassword: async (payload) => {
    try {
      // authenticate firebase
      await firebase.auth().signInWithEmailAndPassword(payload.email, payload.password)
      let user = firebase.auth().currentUser
      if (!user.emailVerified) {
        await AuthService.sendVerificationEmail()
      }
      // authenticate user with civik server and database
      await AuthService.authenticateCivikUser()

      return {code: 200, status: 'auth/success', message: 'logged in'}
    } catch (error) {
      console.log(error)
      return ERROR_CODES.firebaseErrors[error.code]
    }
  },

  isPendingPolitician: async () => {
    try {
      let result = false

      let userProfile = await AuthService.getCurrentUser()
      // if pendingPolitician account
      if (userProfile.user_pendingPolitician) {
        result = true
      }

      return result
    } catch (error) {
      console.log(error)
    }
  },

  getFirebaseCustomClaims: async () => {
    try {
      const idToken = await firebase.auth().currentUser.getIdTokenResult(true)
      const claims = idToken.claims
      if (claims) {
        return claims
      }
      return null
    } catch (error) {
      console.log(error)
    }
  },

  loginWithSocial: async (social, history, setProfile) => {
    try {
      let provider

      if (social === 'facebook') {
        provider = new firebase.auth.FacebookAuthProvider()
      } else if (social === 'google') {
        provider = new firebase.auth.GoogleAuthProvider()
      } else if (social === 'twitter') {
        provider = new firebase.auth.TwitterAuthProvider()
      } else if (social === 'apple') {
        provider = new firebase.auth.OAuthProvider('apple.com')
      } else if (social === 'linkedin') {
        provider = new firebase.auth.OAuthProvider('linkedin.com')
      }

      // authenticate firebase
      // if (window.innerWidth > 1200) {
      //   await firebase.auth().signInWithPopup(provider)
      // } else {
      //   await firebase.auth().signInWithRedirect(provider)
      // }
      await firebase.auth().signInWithPopup(provider)
      // authenticate user with civik server and database
      await AuthService.authenticateCivikUser(history, setProfile)
      return {code: 200, status: 'auth/success', message: 'logged in'}
    } catch (error) {
      console.log(error)
      return ERROR_CODES.firebaseErrors[error.code]
    }
  },

  authenticateCivikUser: async (history = false, setProfile = false) => {
    try {
      // authenticate with allyus (and create user if doesn't exist)
      let profile = await civikApi.get(`/firebase/auth`)
      if (setProfile) {
        profile = getUserProfileInfo(profile)
        setProfile(profile)
      }
      if (history) {
        console.log('LOGIN ---- REDIRECTING TO HOME')
        history.replace(ROUTE_PATHS.HOME)
      }
    } catch (err) {
      console.log(err)
    }
  },

  getCurrentUser: async () => {
    try {
      let user = {}
      user = await civikApi.get(`/me`)
      // console.log('get current user', user)

      if (!user || user.error) {
        console.log('error in getting civik user profile')
        return null
      }
      user = getUserProfileInfo(user)
      return user
    } catch (err) {
      console.log(err)
      return null
    }
  },

  signupWithEmail: async (payload) => {
    try {
      // create user in firebase
      await firebase.auth().createUserWithEmailAndPassword(payload.email, payload.password)

      // send verify email
      await AuthService.sendVerificationEmail()
      // authenticate user with civik server and database
      await AuthService.authenticateCivikUser()

      return {code: 200, status: 'auth/success', message: 'logged in'}
    } catch (error) {
      console.log(error)
      return ERROR_CODES.firebaseErrors[error.code]
    }
  },

  SignupPoliticianWithEmail: async (payload) => {
    try {
      console.log('councilor signup payload ', payload)
      // create user in firebase
      await firebase.auth().createUserWithEmailAndPassword(payload.email, payload.password)
      // create pendingPolitician User
      await civikApi.post(`/auth/poliaccount`, {electedOrCandidate: payload.electedOrCandidate})
      // send verify email
      await AuthService.sendVerificationEmail()

      return {code: 200, status: 'auth/success', message: 'logged in'}
    } catch (error) {
      console.log(error)
      return ERROR_CODES.firebaseErrors[error.code]
    }
  },

  sendVerificationEmail: async (payload) => {
    try {
      let email = firebase.auth().currentUser.email
      await civikApi.post(`/auth/send_verify_email`, {email})
      return {code: 200, status: 'auth/success', message: 'verification email sent'}
    } catch (error) {
      console.log(error)
      return {code: 400, status: 'auth/error', message: 'Error sending verifcation email.'}
    }
  },

  verifyEmail: async (payload) => {
    try {
      const auth = getAuth()
      const {actionCode} = payload
      const actionCodeInfo = await checkActionCode(auth, actionCode)
      const currentUser = auth.currentUser
      if (actionCodeInfo && actionCodeInfo.data?.email === currentUser?.email) {
        if (currentUser.emailVerified) {
          return {code: 200, status: 'auth/success', message: 'email verified'}
        }
        await applyActionCode(auth, actionCode)
        await civikApi.put(`auth/set_email_verified`)
        return {code: 200, status: 'auth/success', message: 'email verified'}
      } else {
        await applyActionCode(auth, actionCode)
        return {
          code: 204,
          status: 'auth/success',
          message: `Email, ${actionCodeInfo.data?.email}, has been successfully verified, but you are not logged in on this browser.`,
        }
      }
    } catch (error) {
      if (ERROR_CODES.firebaseErrors[error.code]) {
        return ERROR_CODES.firebaseErrors[error.code]
      } else {
        return {code: 400, message: error.message}
      }
    }
  },

  sendPasswordResetEmail: async (payload) => {
    try {
      // send password reset email
      await civikApi.post(`/auth/send_password_reset`, {
        email: payload.email,
      })

      return {code: 200, status: 'auth/success', message: 'password reset email successfully sent.'}
    } catch (error) {
      console.log(error)
      if (error.code == 500) {
        return {
          code: 500,
          status: 'server/internal-error',
          message: error.message,
        }
      }
    }
  },

  handlePasswordReset: async (payload) => {
    try {
      const auth = getAuth()
      const {actionCode, newPassword} = payload
      const email = await verifyPasswordResetCode(auth, actionCode)
      await confirmPasswordReset(auth, actionCode, newPassword)
      const currentUser = auth.currentUser
      if (!currentUser || (currentUser && currentUser.email === email)) {
        // sign in with new password
        firebase.auth().signInWithEmailAndPassword(email, newPassword)
        // authenticate user with civik server and database
        await AuthService.authenticateCivikUser()
        return {code: 200, status: 'auth/success', message: 'password reset'}
      } else {
        return {code: 204, status: 'auth/success', message: 'password reset'}
      }
    } catch (error) {
      console.log(error)
      return ERROR_CODES.firebaseErrors[error.code]
    }
  },

  changePassword: async ({oldPassword, newPassword}) => {
    try {
      const auth = getAuth()
      let currentUser = auth.currentUser
      const credential = EmailAuthProvider.credential(currentUser.email, oldPassword)
      await reauthenticateWithCredential(currentUser, credential)
      await updatePassword(currentUser, newPassword)
      return {code: 200, status: 'auth/success', message: 'Successfully changed password.'}
    } catch (error) {
      console.log(error)
      if (ERROR_CODES.firebaseErrors[error.code]) {
        return ERROR_CODES.firebaseErrors[error.code]
      }
      return {
        code: 400,
        status: 'auth/error',
        message: 'Error changing password, please make sure your old password is correct.',
      }
    }
  },

  addEmailSocialLogin: async ({email}) => {
    try {
      await civikApi.put(`/auth/add_email_social`, {
        email: email,
      })
      return {code: 200, status: 'success', message: 'Successfully added email.'}
    } catch (error) {
      console.log(error)
      return {
        code: 400,
        status: 'auth/error',
        message: 'Error adding email.',
      }
    }
  },
}

export default AuthService
