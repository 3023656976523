import React, {useEffect, useState, useContext} from 'react'
import AUTH_SERVICE from '../../../services/auth/AuthService'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {ProfileContext} from '../../../contexts/profilecontext'
import {Link} from 'react-router-dom'

export function PublicDashboard() {
  return (
    <div className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'>
      <h1>Dashboard</h1>
      <Link to='/auth/login'>Login</Link>
    </div>
  )
}
