import React, {useContext, useEffect, useState} from 'react'
import '../News.scss'
import './LocalNews.scss'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import FilterFeedDropdown from '../../../pages/components/FilterFeedDropdown'
import {ProfileContext} from '../../../contexts/profilecontext'
import {FeedLocationContext} from '../../../contexts/feedLocationContext'
import {parseFeedFilters} from '../../../helperfunctions/FeedHelpers'
import FEED_SERVICE from '../../../services/feed/FeedService'
import NewsSectionHeader from './NewsSectionHeader'
import NoNews from './NoNews'
import useMobileView from '../../../hooks/UseMobileView'
import LocalNewsRow from './LocalNewsRow'
import NewsHeadlineCard from './NewsHeadlineCard'

const LocalNews = ({view, fullPageView, setFullPageView, refreshLocalNews}) => {
  const PROFILE_CONTEXT = useContext(ProfileContext)
  const userBasicProfileComplete = PROFILE_CONTEXT.profile?.basicProfileComplete
  const {feedLocation} = useContext(FeedLocationContext)
  const [localNews, setLocalNews] = useState([])
  const [filterOptions, setFilterOptions] = useState([])
  const [postFilter, setPostFilter] = useState('Toronto')
  const [feedLoading, setFeedLoading] = useState(false)

  const isMobileView = useMobileView()

  const getLocalNews = async () => {
    try {
      // Set feed loading state to true
      setFeedLoading(true)
      let response

      if (fullPageView === 'LocalNews') {
        if (view == 'LeftSideColumn') {
          response = await FEED_SERVICE.getNewsPosts({
            city: feedLocation.city,
            limit: 0,
          })
        } else if (view === 'RightSideColumn') {
          // TOP STORIES
          const locationFilter = parseFeedFilters('All', feedLocation)
          response = await FEED_SERVICE.getNewsPosts({
            ...locationFilter,
            trending: true,
            limit: fullPageView === 'LocalNews' && view === 'LeftSideColumn' ? null : 3,
          })
        }
      } else {
        if (view === 'RightSideColumn' || view === 'LeftSideColumn') {
          response = await FEED_SERVICE.getNewsPosts({
            city: feedLocation.city,
            limit: 3,
          })
        }
      }

      // Assuming 'response' is an array containing news posts
      let localNews = response

      // Update news headlines or set it to an empty array if 'response' is falsy
      setLocalNews(localNews || [])

      // Set feed loading state back to false
      setFeedLoading(false)
    } catch (error) {
      // Handle any errors and log them
      console.error(error)
    }
  }

  useEffect(() => {
    console.log('fullPageView', fullPageView)
    console.log('FETCHING THE LOCAL NEWS _________')

    // fetch feed on page load and whenever feedLocation changes
    getLocalNews()
  }, [feedLocation, refreshLocalNews, fullPageView])

  if (feedLoading) {
    return (
      <div className='feed_loadingContainer'>
        <img
          alt='feed loading icon'
          className='feed__loader'
          src={toAbsoluteUrl('/media/design/feed_loader.svg')}
        />
      </div>
    )
  }

  if (!(localNews.length > 0)) {
    return <NoNews section={'Local News'} customStyling={{marginTop: isMobileView ? 26 : 0}} />
  }

  return (
    <>
      <div
        className={`news__localNews__header ${
          fullPageView == 'LocalNews' && 'news__localNews__header--fullpageView'
        }`}
      >
        <div className='news__headlines__header'>
          {fullPageView == 'LocalNews' && view == 'LeftSideColumn' && (
            <div className='news__headlines__header'>
              <div
                className='news__section__chevronRightBtn'
                onClick={() => {
                  setFullPageView('')
                }}
              >
                <span className='news__section__title'>{'< Back'}</span>
              </div>
              <span className='news__section__title'>Local News</span>
              <div></div>
            </div>
          )}

          {fullPageView === 'LocalNews' && view === 'RightSideColumn' && (
            <NewsSectionHeader
              title='Top Stories'
              routeTo='NewsTopStories'
              setFullPageView={setFullPageView}
            />
          )}

          {fullPageView !== 'LocalNews' && view === 'RightSideColumn' && (
            <NewsSectionHeader
              title='Local News'
              routeTo='LocalNews'
              setFullPageView={setFullPageView}
            />
          )}

          {fullPageView !== 'LocalNews' && view === 'LeftSideColumn' && (
            <NewsSectionHeader
              title='Local News'
              routeTo='LocalNews'
              setFullPageView={setFullPageView}
            />
          )}

          {!(fullPageView === 'LocalNews' && view === 'RightSideColumn') ? (
            <FilterFeedDropdown
              customStyling={{
                marginTop: 0,
                marginRight: 0,
                display: 'block',
              }}
              userBasicProfileComplete={userBasicProfileComplete}
              postFilter={postFilter}
              filterOptions={filterOptions}
              getFeed={getLocalNews}
              setPostFilter={setPostFilter}
            />
          ) : null}
        </div>
      </div>

      {fullPageView == 'LocalNews' && view == 'LeftSideColumn' ? (
        <>
          {localNews.map((post, index) => {
            return (
              <NewsHeadlineCard
                post={post}
                key={post._id}
                index={index} // Use the 'index' from the map function
              />
            )
          })}
        </>
      ) : (
        <div className='LocalNews'>
          {localNews.map((post, index) => {
            return <LocalNewsRow post={post} index={index} key={post._id} />
          })}
        </div>
      )}
    </>
  )
}

export default LocalNews
