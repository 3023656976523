import react from 'react'

const PrivacyPolicy = () => {
  return (
    <div className='civik-terms-page'>
    <iframe className='civik-terms-iframe' src='https://docs.google.com/document/d/e/2PACX-1vQqHOHOjjAfZfF72ufVj8aR7F9EDN5OfNq2h4WzyYZcbq8A_dvdSoJdrzM64NjT7mZyhphyUGGrJHio/pub?embedded=true'></iframe>
    </div>
  )
}
export default PrivacyPolicy
