/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext, useCallback} from 'react'
import Modal from 'react-modal'
import './PropertyInfoModal.scss'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
    padding: 0,
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: 3,
  },
}

export function PropertyInfoModal(props) {
  const {modalView} = props

  //   const {customBtnClass, customTxtClass} = useVoterStyles()

  useEffect(() => {
    Modal.setAppElement('#Voter')
  }, [])

  const PropertyInfo = (
    <div className='voterAdminModals__propertyInfo'>
      <div className='voterAdminModals__propertyInfo__banner'>
        <span className='voterAdminModals__propertyInfo__bannerTxt'>
          752 St. George Street, PO Box 100 Annapolis Royal, NS B0S 1A0
        </span>
      </div>

      <span className='voterAdminModals__propertyInfo__dislaimerTxt'>
        There is <span className='civik-primary-blue'>2</span> voters within this property
      </span>

      {/* TABLE */}
      <div className='voterAdminModals__propertyInfo__table'>
        <div className='voterAdminModals__propertyInfo__tableHeader'>
          <span className='voterAdminModals__propertyInfo__tableHeader__columnLabel--1'>
            Voter ID
          </span>

          <span className='voterAdminModals__propertyInfo__tableHeader__columnLabel--2'>
            First Name
          </span>
          <span className='voterAdminModals__propertyInfo__tableHeader__columnLabel--3'>
            Last Name
          </span>

          <span className='voterAdminModals__propertyInfo__tableHeader__columnLabel--4'>
            Status
          </span>
        </div>

        <div className='voterAdminModals__propertyInfo__tableBody'>
          <div className='voterAdminModals__propertyInfo__tableBody__row'>
            <span className='voterAdminModals__propertyInfo__tableBody__rowItem--1'>123455</span>
            <span className='voterAdminModals__propertyInfo__tableBody__rowItem--2'>Adam</span>
            <span className='voterAdminModals__propertyInfo__tableBody__rowItem--3'>Harold</span>
            <span className='voterAdminModals__propertyInfo__tableBody__rowItem--4 '>Approved</span>
          </div>

          <div className='voterAdminModals__propertyInfo__tableBody__row'>
            <span className='voterAdminModals__propertyInfo__tableBody__rowItem--1'>123455</span>
            <span className='voterAdminModals__propertyInfo__tableBody__rowItem--2'>Myles</span>
            <span className='voterAdminModals__propertyInfo__tableBody__rowItem--3'>Harold</span>
            <span className='voterAdminModals__propertyInfo__tableBody__rowItem--4 voterAdminModals__propertyInfo__tableBody__rowItem--4--pending'>
              Pending
            </span>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <Modal
      ariaHideApp={false}
      isOpen={props.isOpen}
      onAfterOpen={props.onAfterOpen}
      onRequestClose={props.onRequestClose}
      style={customStyles}
    >
      <div className='PropertyInfoModal'>
        <div className='propInfoModal__header'>
          {/* TITLE */}
          <span className='propInfoModal__title'>Property Info</span>
          {/* X BUTTON */}
          <img
            src={toAbsoluteUrl('/media/design/modal-x-btn.svg')}
            className='civik-modal-x-btn'
            onClick={props.onRequestClose}
          />
        </div>

        {/* BODY CONTENT */}
        {PropertyInfo}

        <div className='ModalFooter'>
          {/* CANCEL BUTTON */}
          <button className='voterRegister__cancelBtn'>Cancel</button>
          <button
            type='submit'
            className={'modalFooter__primaryBtn'}
            onClick={props.onRequestClose}
          >
            Save
          </button>
        </div>
      </div>
    </Modal>
  )
}
