const LETTER_COLORS = {
    A: {
      background: '#FFF8DD',
      color: '#FFC700',
    },
    B: {
      background: '#E1F4FF',
      color: '#3699FF',
    },
    C: {
      background: '#FAEBFF',
      color: '#9B37B4',
    },
    D: {
      background: '#ECFFDD',
      color: '#ECFFDD',
    },
    E: {
      background: '#DDFFFB',
      color: '#6EB9AB',
    },
    F: {
      background: '#FEEAEB',
      color: '#D42A72',
    },
    G: {
      background: '#CCD6DC',
      color: '#5B6F83',
    },
    H: {
      background: '#D7F4FB',
      color: '#379DB4',
    },
    I: {
      background: '#F4DDFF',
      color: '#6011AF',
    },
    J: {
      background: '#DDF2F0',
      color: '#44AD9A',
    },
    K: {
      background: '#FBF2D1',
      color: '#E8B501',
    },
    L: {
      background: '#FFF3E1',
      color: '#FF8A36',
    },
    M: {
      background: '#E4ECFF',
      color: '#E4ECFF',
    },
    N: {
      background: '#ECFFDD',
      color: '#539B1A',
    },
    O: {
      background: '#DDFFFB',
      color: '#6EB9AB',
    },
    P: {
      background: '#F8F5FF',
      color: '#7239EA ',
    },
    Q: {
      background: '#E4ECEB',
      color: '#3B9282',
    },
    R: {
      background: '#FAEBFF',
      color: '#9B37B4',
    },
    S: {
      background: '#E1F4FF',
      color: '#3699FF',
    },
    T: {
      background: '#ECFFDD',
      color: '#539B1A',
    },
    U: {
      background: '#DDFFFB',
      color: '#6EB9AB',
    },
  
    V: {
      background: '#E1F4FF',
      color: '#3699FF',
    },
    W: {
      background: '#DEEBFF',
      color: '#3744B4',
    },
    X: {
      background: '#D4FDDD',
      color: '#2E9C46',
    },
    Y: {
      background: '#F8F5FF',
      color: '#7239EA',
    },
    Z: {
      background: '#F1FA  FF',
      color: '#00A3FF',
    },
  }

  export default LETTER_COLORS;