import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {Switch, Route, useHistory} from 'react-router-dom'
import React, {useState, useEffect, useContext, useRef, useCallback} from 'react'
import {MenuInner} from '../../../_metronic/layout/components/header/MenuInner'
import FeatherIcon from 'feather-icons-react'
import {MenuInnerWithSub} from '../../../_metronic/layout/components/header/MenuInnerWithSub'
import {MegaMenu} from '../../../_metronic/layout/components/header/MegaMenu'

import './Navbar.scss'

import {ProfileContext} from '../../contexts/profilecontext'
import {UserContext} from '../../contexts/usercontext'
import {ProfilePageContext} from '../../contexts/profilePageContext'
import {FeedLocationContext} from '../../contexts/feedLocationContext'
import {CreatePostContext} from '../../contexts/createPostContext'

import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

import {ChangeCityModal} from '../../pages/modals/ChangeCityModal'
import {profile} from 'console'
import ROUTE_PATHS from '../../routing/RoutePaths'
import {Link} from 'react-router-dom'
import NotificationModal from '../../pages/modals/NotificationModal'
import LETTER_COLORS from '../../constants/LetterColors'
import UserPicture from '../../modules/reusable/UserPicture'

import {GlobalPrivateModalContext} from '../../contexts/GlobalModalContext'
import MODAL_NAMES from '../../constants/ModalNames'
import {PrivacyPolicyLink, TermsLink} from '../../constants/PrivacyTermsLinks'
import {onLogout} from '../../helperfunctions/AuthHelpers'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: '9999999999',
  },
}

export function Navbar() {
  const history = useHistory()
  const [modalIsOpen, setIsOpen] = React.useState(false)
  const [isActivePg, setIsActivePg] = useState('Feed')
  const PROFILE_CONTEXT = useContext(ProfileContext)
  const {profile, setProfile} = PROFILE_CONTEXT
  const USER_CONTEXT = useContext(UserContext)
  const CREATE_POST_CONTEXT = useContext(CreatePostContext)

  const {currentTab, setCurrentTab} = useContext(ProfilePageContext)
  const [openSettingDropdown, setOpenSettingDropdown] = useState(false)
  const [openLocationDropdown, setOpenLocationDropdown] = useState(false)
  const {feedLocation, setFeedLocation} = useContext(FeedLocationContext)
  const [notificationBadgeNum, setNotificationBadgeNum] = useState(0)

  const userBasicProfileComplete = PROFILE_CONTEXT.profile?.basicProfileComplete
  const {setIsOpenGlobalModal} = useContext(GlobalPrivateModalContext)
  const setIsOpenCompleteProfile = (o) => setIsOpenGlobalModal(MODAL_NAMES.COMPLETE_PROFILE, o)

  const logout = () => {
    onLogout(PROFILE_CONTEXT, history)
    setFeedLocation({country: 'Canada'})
  }

  const locationRef = useRef(null)

  const settingRef = useRef(null)

  const [areaId, setAreaId] = useState(null)

  function openModal() {
    setIsOpen(true)
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00'
  }

  function onAfterCompleteModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00'
  }

  function closeModal() {
    setIsOpen(false)
  }

  // TODO
  // may be best to inherit this from props
  function openAuthModal() {
    alert('login')
  }

  // TODO
  // may be best to inherit this from props
  function openCompleteProfileModal() {
    alert('complete profile')
  }

  const fully_authenticated =
    USER_CONTEXT.user && USER_CONTEXT.user.loggedIn && USER_CONTEXT.user.verified

  function onPressCheckAuth(authOnPress) {
    if (fully_authenticated) {
      if (PROFILE_CONTEXT.profile.location_city) {
        authOnPress()
      } else {
        openCompleteProfileModal()
      }
    } else {
      openAuthModal()
    }
  }

  // set feed location to user location if complete
  // TODO change feed viewing location
  useEffect(() => {
    if (profile.location_city) {
      setFeedLocation({
        country: profile.location_country,
        region: profile.location_region,
        city: profile.location_city,
      })
    }
  }, [profile.location_city])

  useEffect(() => {
    console.log('PROFILE_CONTEXT', PROFILE_CONTEXT)

    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (openLocationDropdown && locationRef.current && !locationRef.current.contains(e.target)) {
        setOpenLocationDropdown(false)
      }
    }

    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [openLocationDropdown])

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (openSettingDropdown && settingRef.current && !settingRef.current.contains(e.target)) {
        setOpenSettingDropdown(false)
      }
    }

    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [openSettingDropdown])

  useEffect(() => {
    if (history.location.pathname == '/') {
      setIsActivePg('Feed')
    }

    if (history.location.pathname == '/news') {
      setIsActivePg('News')
    }

    if (history.location.pathname == '/petitions') {
      setIsActivePg('Petitions')
    }
  }, [history])

  return (
    <div className='Navbar ' id='Navbar'>
      <ChangeCityModal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel='Example Modal'
        areaId={areaId}
      />
      <div className='civik-flex-row'>
        <img
          onClick={() => {
            setIsActivePg('News')
            history.push('/news')
          }}
          alt='civik blue logo'
          src={toAbsoluteUrl('/media/design/logo-civik-blue-horizontal.svg')}
          className='navbar__logo'
        />

        {feedLocation.city && (
          <div
            className='navbar__location '
            onClick={() => {
              setOpenLocationDropdown(true)
            }}
          >
            <span>{feedLocation.city}</span>
            <FeatherIcon
              icon={'chevron-down'}
              className='navbar__locationChevronDown'
              color='#7E8299'
            />
          </div>
        )}

        {feedLocation.city && (
          <div>
            {openLocationDropdown && (
              <div className='navbar__dropdown navbar__dropdownLocation' ref={locationRef}>
                <div className='civik-flex-column navbar__dropdownTxtWrap'>
                  <small className='mb-2'>Current City:</small>
                  <span className='navbar__dropdownCity'>{feedLocation.city}</span>
                </div>
                <button
                  className='navbar__dropdownBtn'
                  onClick={() => {
                    setIsOpen(true)
                    setOpenLocationDropdown(false)
                  }}
                >
                  Change City
                </button>
              </div>
            )}
          </div>
        )}

        {/* <div className='civik-flex-row'>
          <div className='navbar__searchIconContainer'>
            <img
              className='navbar__searchIcon'
              src={toAbsoluteUrl('/media/design/search_icon.svg')}
            />
          </div>
          <input className='navbar__searchInput' type='text' placeholder='' />
        </div> */}
      </div>
      {/* begin::Navbar LINKS */}
      {/* {header.left === 'menu' && (
            <div className='d-flex align-items-stretch' id='kt_header_nav'>
              <Header />
            </div>
          )} */}
      {/* {header.left === 'page-title' && (
            <div className='d-flex align-items-center' id='kt_header_nav'>
              <DefaultTitle />
            </div>
          )} */}
      <div className='d-flex align-items-center navbar__pageLinksWrap' id='kt_header_nav'>
        {isActivePg == 'News' ? (
          <div
            className='navbar__petitionBtnActive'
            onClick={() => {
              history.push('/news')
            }}
          >
            <img
              alt='petition active icon'
              className='navbar__petitionBtnIcon'
              src={toAbsoluteUrl('/media/design/news_icon_mobile_active.svg')}
            />
            <span className='navbar__activeBtnTxt'>News</span>
          </div>
        ) : (
          <div
            className='navbar__petitionBtnDefault'
            onClick={() => {
              setIsActivePg('News')
              history.push('/news')
            }}
          >
            <img
              alt='petition default icon'
              className='navbar__petitionBtnIcon'
              src={toAbsoluteUrl('/media/design/news_icon_mobile_default.svg')}
            />
            <span className='navbar__defaultBtnTxt'>News</span>
          </div>
        )}

        {isActivePg == 'Petitions' ? (
          <div
            className='navbar__petitionBtnActive'
            onClick={() => {
              history.push('/petitions')
            }}
          >
            <img
              alt='petition active icon'
              className='navbar__petitionBtnIcon'
              src={toAbsoluteUrl('/media/design/petition_active_icon.svg')}
            />
            <span className='navbar__activeBtnTxt'>Petitions</span>
          </div>
        ) : (
          <div
            className='navbar__petitionBtnDefault'
            onClick={() => {
              setIsActivePg('Petitions')
              history.push('/petitions')
            }}
          >
            <img
              alt='petition default icon'
              className='navbar__petitionBtnIcon'
              src={toAbsoluteUrl('/media/design/petition_default_icon.svg')}
            />
            <span className='navbar__defaultBtnTxt'>Petitions</span>
          </div>
        )}

        {isActivePg == 'Feed' ? (
          <div
            className='navbar__feedBtnActive'
            onClick={() => {
              history.push(ROUTE_PATHS.HOME)
            }}
          >
            <img
              alt='home active icon'
              className='navbar__feedBtnIcon'
              src={toAbsoluteUrl('/media/design/icon_feed_active.svg')}
            />
            <span className='navbar__activeBtnTxt'>Feed</span>
          </div>
        ) : (
          <div
            className='navbar__feedBtnDefault'
            onClick={() => {
              setIsActivePg('Feed')
              history.push(ROUTE_PATHS.HOME)
            }}
          >
            <img
              alt='home default icon'
              className='navbar__feedBtnIcon'
              src={toAbsoluteUrl('/media/design/icon_feed_default.svg')}
            />
            <span className='navbar__feedBtnTxt'>Feed</span>
          </div>
        )}
      </div>
      {/* NAVBAR RIGHT SIDE ITEMS */}
      <div className='d-flex align-items-stretch flex-shrink-0' style={{alignItems: 'center'}}>
        {/* {fully_authenticated ? (
          <> */}
        <div>
          {/* begin::Menu- wrapper */}
          <div
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
            className='d-flex align-items-center navbar__notifWrap'
          >
            <div className='civik-nav-icon-container  '>
              <img
                alt='notification bell icon'
                src={toAbsoluteUrl('/media/design/notif_bell_icon.svg')}
                style={{width: 14}}
              />
            </div>
            {notificationBadgeNum && notificationBadgeNum > 0 ? (
              <div className='notification-badge'>{notificationBadgeNum}</div>
            ) : null}
          </div>
          <NotificationModal setBadgeNum={setNotificationBadgeNum} />
          {/* end::Menu wrapper */}
        </div>

        {!PROFILE_CONTEXT.profile?.first_name && (
          <div
            className='civik-nav-icon-container navbar__elipsisBtn'
            style={{marginLeft: 15}}
            onClick={() => {
              setOpenSettingDropdown(true)
            }}
          >
            <img
              alt='elipsis icon'
              src={toAbsoluteUrl('/media/design/elipsis_icon.svg')}
              style={{width: 14}}
            />
          </div>
        )}

        {openSettingDropdown && (
          <div
            className={
              !PROFILE_CONTEXT.profile?.first_name
                ? `navbar__dropdown navbar__dropdownElipsis navbar__settingDropdown`
                : `navbar__dropdown navbar__dropdownPhoto navbar__settingDropdown`
            }
            ref={settingRef}
          >
            <div className='civik-flex-column  '>
              <span
                className='navbar__dropdownIconBtn navbar__dropdownIconBtnProfile'
                onClick={() => {
                  history.push(ROUTE_PATHS.MY_PROFILE)
                  setOpenSettingDropdown(false)
                }}
              >
                Profile
              </span>
              <span
                className='navbar__dropdownIconBtn'
                onClick={() => {
                  setCurrentTab('Settings')
                  setOpenSettingDropdown(false)
                  history.push(ROUTE_PATHS.MY_PROFILE)
                }}
              >
                Account Settings
              </span>

              <span
                className='navbar__dropdownIconBtn'
                onClick={() => {
                  if (!userBasicProfileComplete) setIsOpenCompleteProfile(true)
                  else {
                    CREATE_POST_CONTEXT.setShowModal({visible: true, postType: 'Basic Post'})
                    setOpenSettingDropdown(false)
                    history.push(ROUTE_PATHS.HOME)
                  }
                }}
              >
                Create a Post
              </span>

              <span
                className='navbar__dropdownIconBtn'
                onClick={() => {
                  if (!userBasicProfileComplete) setIsOpenCompleteProfile(true)
                  else {
                    setCurrentTab('Create Petition')
                    setOpenSettingDropdown(false)
                    history.push('/myprofile')
                  }
                }}
              >
                Create a Petition
              </span>

              <div className='navbar__dropdownGroupSeperator'></div>
              <a
                className='navbar__dropdownIconBtn navbar__dropdownIconBtnPrivacy'
                href={PrivacyPolicyLink}
                target='_blank'
                rel='noopener noreferrer'
              >
                Privacy Policy
              </a>
              <a
                className='navbar__dropdownIconBtn'
                href={TermsLink}
                target='_blank'
                rel='noopener noreferrer'
              >
                Terms & Conditions
              </a>
            </div>
            <div className='navbar__dropdownGroupSeperator'></div>
            <button
              className=' navbar__dropdownIconBtn navbar__dropdownIconBtnLogout'
              onClick={logout}
            >
              Logout
            </button>{' '}
          </div>
        )}

        {PROFILE_CONTEXT.profile?.first_name && (
          <div
            className='navbar__elipsisContainer navbar__PhotoContainer'
            onClick={() => {
              setOpenSettingDropdown(true)
            }}
          >
            <span className='navbar__elipsisGreetingTxt'>
              Hi, {PROFILE_CONTEXT.profile.first_name}
            </span>
            <UserPicture
              isReply={false}
              profile={PROFILE_CONTEXT.profile}
              styleClasses={{
                profilePic: 'navbar__elipsisProfileImg',
                letterWrap: 'navbar__elipsisNameLetterWrap',
                nameLetter: ' navbar__elipsisNameLetter',
              }}
            />
          </div>
        )}

        {/* </>
        ) : (
          // TODO : style
          <Link to={ROUTE_PATHS.LOGIN} className='civik-link'>
            Login / Sign Up
          </Link>
        )} */}

        <button className='civik-nav-vote-btn fw-bold ml-50'>Current Elections</button>

        {/* <Topbar /> */}
      </div>
    </div>
  )
}
