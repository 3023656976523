export const EditIcon = (props) => {
  let {className, fill} = props
  className = className ? className : ''
  fill = fill ? fill : '#6C7289'
  return (
    <svg
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21.7269 4.99912C22.3043 5.57652 22.3138 6.50973 21.7482 7.09872L12.8989 16.3143C12.6236 16.601 12.2458 16.7667 11.8484 16.7751L8.12514 16.8532L8.20358 13.1147C8.21173 12.7263 8.37019 12.3563 8.64563 12.0824L17.6962 3.0836C18.2825 2.50068 19.2299 2.50203 19.8145 3.08663L21.7269 4.99912Z'
        fill={fill}
      />
      <path
        opacity='0.3'
        d='M12.945 1.5C13.5249 1.5 13.995 1.9701 13.995 2.55C13.995 3.1299 13.5249 3.6 12.945 3.6H5.7C4.5402 3.6 3.6 4.5402 3.6 5.7V18.3C3.6 19.4598 4.5402 20.4 5.7 20.4H18.3C19.4598 20.4 20.4 19.4598 20.4 18.3V13.05C20.4 12.4701 20.8701 12 21.45 12C22.0299 12 22.5 12.4701 22.5 13.05V18.3C22.5 20.6196 20.6196 22.5 18.3 22.5H5.7C3.3804 22.5 1.5 20.6196 1.5 18.3V5.7C1.5 3.3804 3.3804 1.5 5.7 1.5H12.945Z'
        fill={fill}
      />
    </svg>
  )
}
