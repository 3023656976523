/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import PetitionService from '../../../services/petition/PetitionService'
import {useHistory, useLocation} from 'react-router-dom'
import './PetitionSign.scss'
import environment from '../../../../utils/environment'

const syngrafii_origin = environment.resolveSyngrafii()

export function PetitionIframe(props) {
  const history = useHistory()
  const {state} = useLocation()
  const [signed, setSigned] = useState(false)
  const {signLink, petition_id} = state as {signLink: string; petition_id: string}
  // console.log(signLink, petition_id)

  window.addEventListener(
    'message',
    async (event) => {
      console.log('event', event)
      // Validate origin
      if (event.origin !== syngrafii_origin || !event.data) return
      // Handle action
      switch (event.data.action) {
        case 'signing_close':
          // User has closed package editor
          // navigate back to petition details page
          history.replace(`/petitions/${petition_id}`)
          break
        case 'signing_sent':
          // User has sent package, closing package editor
          // update civik backend to mark petition as signed
          if (!signed) {
            setSigned(true)
            await PetitionService.markPetitionSigned(petition_id)
            // navigate back to petition details page
            history.replace(`/petitions/${petition_id}`)
          }

          break
      }
    },
    false
  )
  // TODO add back buttons, styling

  return (
    <div className='PetitionSign'>
      <iframe src={signLink} title='Sign Petition' width={800} height={800} />
    </div>
  )
}
