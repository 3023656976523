import firebase from 'firebase/compat/app'

export const uploadToFirebase = async (file, path) => {
  try {
    await firebase.storage().ref(path).put(file)
    const downloadURL = await firebase.storage().ref(path).getDownloadURL()
    return {downloadURL, path}
  } catch (error) {
    console.log(error)
    return null
  }
}

export const validateFile = async (e, maxSize, fileTypes) => {
  try {
    e.persist()
    const file = e.target.files[0]
    // console.log('file', file)
    if (!file) return
    //  If the file size (calculated in byte form) is than maxsize
    if (maxSize && file.size > maxSize) {
      console.log('IMAGE SIZE MUST BE LESS THAN 1MB')
    }
    if (fileTypes && !fileTypes.includes(file.type)) {
      console.log('INVALID FILE TYPE')
      return
    }

    return file
  } catch (error) {
    console.error(error)
  }
}
