const ERROR_CODES = {
  firebaseErrors: {
    'auth/user-not-found': {code: 400, status: 'auth/user-not-found', message: 'User not found.'},
    'auth/wrong-password': {
      code: 400,
      status: 'auth/wrong-password',
      message: 'Password is incorrect.',
    },
    'auth/internal-error': {
      code: 400,
      status: 'auth/internal-error',
      message: 'Something went wrong on our side.',
    },
    'auth/too-many-requests': {
      code: 400,
      status: 'auth/too-many-requests',
      message: 'Account is disabled for too many failed attempts, please reset your password.',
    },
    'auth/invalid-action-code': {
      code: 400,
      status: 'auth/invalid-action-code',
      message: 'Link has expired or already been used. ',
    },
    'auth/email-already-in-use': {
      code: 400,
      status: 'auth/email-already-in-use',
      message: 'Email address is already in use.',
    },
    'auth/account-exists-with-different-credential': {
      code: 400,
      status: 'auth/account-exists-with-different-credential',
      message: 'An account associated with this email address already exists. You may have signed up with another social provider tied to this email or signed up with this email and a password directly. ',
    },
    'auth/invalid-password': {
      code: 400,
      status: 'auth/invalid-password',
      message: 'The provided value for the password user property is invalid. It must be a string with at least six characters.',
    },
  },
}

export default ERROR_CODES
