import React, {useState, useEffect, useContext, useCallback} from 'react'
import {useVoterStyles} from '../../../../../contexts/VoterStylesContext'
import './ModalFooter.scss'

export function ModalFooter(props) {
  const [loading, setLoading] = useState(false)
  const {customBtnClass} = useVoterStyles()

  return (
    <div className='ModalFooter'>
      {/* CANCEL BUTTON */}
      <button
        type='submit'
        className={
          customBtnClass
            ? 'modalFooter__primaryBtn' + ' ' + customBtnClass
            : 'modalFooter__primaryBtn'
        }
        onClick={props.onRequestClose}
      >
        OK
      </button>
    </div>
  )
}
