/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext, useCallback, useRef} from 'react'
import * as Yup from 'yup'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import Modal from 'react-modal'
import {ProfileContext} from '../../../../../contexts/profilecontext'
import {useVoterStyles} from '../../../../../contexts/VoterStylesContext'
import './ImportAddressListModal.scss'

import {FilePond, registerPlugin} from 'react-filepond'
// Import FilePond styles
import 'filepond/dist/filepond.min.css'

import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/storage'
import {ModalWrapper} from '../../../../voter/reusable/modals/modal_wrapper/ModalWrapper'

import {v4 as uuidv4} from 'uuid'
import {ModalHeader} from '../../../../voter/reusable/modals/modal_header/ModalHeader'
import {ModalFooter} from '../../../../voter/reusable/modals/modal_footer/ModalFooter'

const cityOptions = [
  {label: 'Select City', value: ''},
  {label: 'Toronto', value: 'Toronto'},
  {label: 'Mississauga', value: 'Mississauga'},
]

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
    padding: '0px',
    width: '100%',
    maxWidth: 600,
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: 3,
  },
}

const profileDetailsSchema = Yup.object().shape({})

export function ImportAddressListModal(props) {
  const {modalView} = props

  const [loading, setLoading] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const PROFILE_CONTEXT = useContext(ProfileContext)
  const [files, setFiles] = useState([])
  const [picPath, setPicturePath] = useState('')
  const [picUrl, setPictureUrl] = useState('')
  const [localFileData, setLocalFileData] = useState(null)
  const {customBtnClass} = useVoterStyles()
  const [currentView, setCurrentView] = useState('UploadFile')

  const [uploadErr, setUploadErr] = useState(null)

  // const onEditStaff = useCallback(() => MicroModal.show('modal-1'), [])

  const uploadRef = useRef(null)

  const onImageClick = useCallback(() => {
    uploadRef.current.click()
  }, [uploadRef])

  const onFileUpload = async (e) => {
    try {
      //   setIsUploading(true)
      e.persist()

      const file = e.target.files[0]
      setLocalFileData(file)
      console.log('file', file)

      //    If the file size (calculated in byte form) is than 100MB
      if (file.size > 102400) {
        setUploadErr('IMAGE SIZE MUST BE LESS THAN 100MB')
        setCurrentView('fileTooBigErr')
        return console.log('IMAGE SIZE MUST BE LESS THAN 100MB')
      }
      if (file.type !== 'text/csv') {
        setCurrentView('wrongFileTypeErr')
        return console.log('IMAGE TYPE MUST BE CSV')
      }

      setCurrentView('UploadInProgress')

      console.log('uploading image now!....')

      // let usr_civik_id = PROFILE_CONTEXT.profile._id

      console.log('starting UPLOAD ========')
      //Firebase path for post media:
      //posts/{{random uuid for post}}/media/{{a different random uuid for each media item}}

      console.log(URL.createObjectURL(file))

      console.log('done')

      //   setIsUploading(false)

      setCurrentView('UploadSuccess')
    } catch (error) {
      console.error(error)
    }
  }

  /* VIEW #1:UPLOAD FILE  */
  const uploadFileView = (
    <>
      {/* HEADER */}
      <ModalHeader onRequestClose={props.onRequestClose} headerLabel={'Address List'} />
      <div>
        <div className='addImageModal__uploadContainer' onClick={onImageClick}>
          <img src={toAbsoluteUrl('/media/design/file_upload_icon.svg')} />
          <h4 className='addImageModal__uploadTxt'>File Uploader</h4>
          <span className='addImageModal__uploadSubTxt'>
            {/* Drag & Drop files here 5MB Max (jpg, gif, png) */}
            {/* Note temp until drag and drop */}
            Drag & Drop files here 100MB Max (csv)
          </span>
        </div>

        <input type='file' onChange={onFileUpload} ref={uploadRef} style={{display: 'none'}} />
      </div>
      {uploadErr && (
        <div>
          <span>{uploadErr}</span>
        </div>
      )}
    </>
  )

  /* VIEW #2: UPLOAD IN PROGRESS   */
  const inProgressView = (
    <>
      {/* HEADER */}
      <ModalHeader onRequestClose={props.onRequestClose} headerLabel={'Address List'} />
      <div className='importAddrListM__inProgress'>
        <span className='importAddrListM__inProgress__title'>Uploading in progress...</span>
        <span className='importAddrListM__inProgress__subtitle'>
          Please wait.. do not close this window!
        </span>
      </div>
    </>
  )

  /* VIEW #3: UPLOADED SUCCESS (CONFIRM SCREEN) */
  const uploadSuccessView = (
    <>
      {/* HEADER */}
      <ModalHeader onRequestClose={props.onRequestClose} headerLabel={'Address List'} />
      <div className='importAddrListM__uploadSuccess'>
        <span className='importAddrListM__uploadSuccess__title'>
          Address list has been uploaded successfuly
        </span>
        <span className='importAddrListM__uploadSuccess__subtitle'>
          Do you want to update the registration portal with the new imported list?
        </span>

        <div className='importAddrListM__uploadSuccess__btnWrap'>
          <button
            onClick={() => {
              // SAVE FILE
              // close modal
              props.onRequestClose()
            }}
            type='button'
            className='importAddrListM__uploadSuccess__btn importAddrListM__uploadSuccess__noBtn'
          >
            NO
          </button>
          <button
            onClick={() => {
              props.onRequestClose()
              props.onUpdateAddressModal()
            }}
            type='button'
            className='importAddrListM__uploadSuccess__btn importAddrListM__uploadSuccess__yesBtn'
          >
            YES
          </button>
        </div>
      </div>
    </>
  )

  const fileTooBigErr = (
    <>
      {/* HEADER */}
      <ModalHeader onRequestClose={props.onRequestClose} headerLabel={'Uploading Error'} />
      <div className='importAddrListM__fileTooBigErr'>
        <img
          className='importAddrListM__fileTooBigErr__warningIcon'
          src={toAbsoluteUrl('/media/design/warning_icon_red.svg')}
        />
        <span className='importAddrListM__fileTooBigErr__txt'>
          The file is too large to upload. The maximum supported file size is 100 MB{' '}
        </span>
      </div>
      <ModalFooter onRequestClose={props.onRequestClose} />
    </>
  )

  const wrongFileTypeErr = (
    <>
      {/* HEADER */}
      <ModalHeader onRequestClose={props.onRequestClose} headerLabel={'Uploading Error'} />
      <div className='importAddrListM__wrongFileTypeErr'>
        <img
          className='importAddrListM__wrongFileTypeErr__warningIcon'
          src={toAbsoluteUrl('/media/design/warning_icon_red.svg')}
        />
        <span className='importAddrListM__wrongFileTypeErr__txt'>
          The file couldn’t be uploaded because it isn’t in the supported format or the file might
          be corrupted
        </span>

        <span className='importAddrListM__wrongFileTypeErr__subTxt'>
          Please try again using supported file type. Accepted file format is{' '}
          <span className='civik-primary-blue'>CSV</span>.{' '}
        </span>
      </div>
      <ModalFooter onRequestClose={props.onRequestClose} />
    </>
  )

  const resetModal = () => {
    try {
      setPictureUrl(null)
      setPicturePath(null)
      setCurrentView('UploadFile')
    } catch (error) {
      console.log(error)
    }
  }

  const renderView = () => {
    if (currentView == 'UploadFile') {
      return uploadFileView
    }
    if (currentView == 'UploadInProgress') {
      return inProgressView
    }
    if (currentView == 'UploadSuccess') {
      return uploadSuccessView
    }
    // ERRORS
    if (currentView == 'fileTooBigErr') {
      return fileTooBigErr
    }
    if (currentView == 'wrongFileTypeErr') {
      return wrongFileTypeErr
    }
  }

  //   useEffect(() => {
  //     renderView()
  //   }, [currentView])

  useEffect(() => {
    resetModal()
  }, [props.onRequestClose])

  useEffect(() => {
    // Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
    Modal.setAppElement('#ImportAddressList')
  }, [])

  return (
    <Modal
      ariaHideApp={false}
      isOpen={props.isOpen}
      onAfterOpen={props.onAfterOpen}
      onRequestClose={props.onRequestClose}
      style={customStyles}
      contentLabel='Example Modal'
    >
      <form className=''>{renderView()}</form>
    </Modal>
  )
}
