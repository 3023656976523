import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {AuthPage} from '../modules/auth'
import {Navbar} from '../resuable/navbar/Navbar'
import ROUTE_PATHS from './RoutePaths'
import {Home} from '../pages/Home'
import {Error404} from '../modules/errors/components/Error404'
import PetitionPDF from '../pages/petitions/petition_details/PetitionPDF'
import Terms from '../pages/Terms'
import PrivacyPolicy from '../pages/PrivacyPolicy'
import {News} from '../modules/news/News'
import PrivateRoutesModals from './PrivateRoutesModals'

function PublicHome() {
  return (
    <div>
      <Navbar />
      <Switch>
        <Route exact path={ROUTE_PATHS.HOME} component={Home} />
        {/* <Route exact path='*'>
          <Redirect to={ROUTE_PATHS.HOME} />
        </Route> */}
      </Switch>
    </div>
  )
}

export function PublicRoutes() {
  return (
    <div style={{backgroundColor: '#FFF', height: '100vh'}}>
      {' '}
      <PrivateRoutesModals>
        <Switch>
          <Route path='/news' component={News} />

          <Route path='/auth/' component={AuthPage} />

          <Route path='/petitions/pdf/:petition_id' component={PetitionPDF} />
          <Route path='/terms' component={Terms} />
          <Route path='/privacypolicy' component={PrivacyPolicy} />
          {/* <Route path={ROUTE_PATHS.HOME} component={PublicHome} /> */}
          <Redirect to={'/news'} />
        </Switch>{' '}
      </PrivateRoutesModals>
    </div>
  )
}
