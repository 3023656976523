import React, {useContext, useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import CopyToClipboard from 'react-copy-to-clipboard'
import {NotLoggedInModalContext} from '../../../contexts/NotLoggedInModalContext'
import {UserContext} from '../../../contexts/usercontext'
import {CustomSVG} from '../../../resuable/navbar/CustomSVG'
import './PostActionBar.scss'
import FEED_SERVICE from '../../../services/feed/FeedService'
import MODAL_NAMES from '../../../constants/ModalNames'
import {GlobalPrivateModalContext} from '../../../contexts/GlobalModalContext'
import {useHistory} from 'react-router-dom'
import {ProfileContext} from '../../../contexts/profilecontext'

/**
 * The purpose of this component is to provide the
 * ability to like, comment, and share a certain post
 * given the post object, index, and view (headline, feed, etc.)
 * @param post the post object
 * @param index the index of the post in the list
 * @param view the view of the post that is used within (headline, feed, etc.)
 * @param setNumLikes set the number of likes on the post (using local parent state)
 * @param numLikes the number of likes on the post (using local parent state)
 * @returns
 */
function PostActionBar({post, index, view, setNumLikes, numLikes, setNewCommentTrigger}) {
  const history = useHistory()
  const [actionPopupContent, setActionPopupContent] = useState('Copied to clipboard')
  const [showElement, setShowElement] = React.useState(false)
  const NOT_LOGGED_IN_MODAL_CONTEXT = useContext(NotLoggedInModalContext)
  const USER_CONTEXT = useContext(UserContext)
  const fully_authenticated =
    USER_CONTEXT.user && USER_CONTEXT.user.loggedIn && USER_CONTEXT.user.verified
  const [likeDisabled, setLikedDisabled] = useState<boolean>()
  const [liked, setLiked] = useState(post.userPostVote ? true : false)
  // const [setCommentIndex] = useState(null)
  const {setIsOpenGlobalModal} = useContext(GlobalPrivateModalContext)
  const setIsOpenWrongCommunity = (o) => setIsOpenGlobalModal(MODAL_NAMES.WRONG_COMMUNITY, o)
  const setIsOpenCompleteProfile = (o) => setIsOpenGlobalModal(MODAL_NAMES.COMPLETE_PROFILE, o)
  const PROFILE_CONTEXT = useContext(ProfileContext)
  const userBasicProfileComplete = PROFILE_CONTEXT.profile?.basicProfileComplete
  const userInCommunity = PROFILE_CONTEXT.profile?.communities?.includes(post.community)

  const handleLikeClick = () => {
    if (!fully_authenticated) {
      NOT_LOGGED_IN_MODAL_CONTEXT.setIsModalOpen(true)
      return
    }

    if (!userBasicProfileComplete) {
      setIsOpenCompleteProfile(true)
    } else if (!userInCommunity) {
      setIsOpenWrongCommunity(true)
    } else if (likeDisabled) {
      console.log('like disabled')
    } else if (liked) {
      unlikePost(post._id)
    } else {
      likePost(post._id)
    }
  }

  const likePost = async (postId) => {
    setLikedDisabled(true)
    setLiked(true)
    setNumLikes(numLikes + 1)
    let response = await FEED_SERVICE.likePost(postId)
    if (response.code !== 200) {
      setLiked(false)
      setNumLikes(numLikes - 1)
    }
    setLikedDisabled(false)
  }

  const unlikePost = async (postId) => {
    setLikedDisabled(true)
    setLiked(false)
    setNumLikes(numLikes - 1)
    let response = await FEED_SERVICE.unlikePost(postId)
    if (response.code !== 200) {
      setLiked(true)
      setNumLikes(numLikes + 1)
    }
    setLikedDisabled(false)
  }

  const handleCommentClick = (e, mobile = false) => {
    if (!fully_authenticated) {
      NOT_LOGGED_IN_MODAL_CONTEXT.setIsModalOpen(true)
      return
    }

    if (!userBasicProfileComplete) {
      setIsOpenCompleteProfile(true)
    } else if (!userInCommunity) {
      setIsOpenWrongCommunity(true)
    } else {
      // setCommentIndex(index)
      setNewCommentTrigger(true)
      if (mobile) {
        history.push(`/posts/${post._id}`)
      }
    }
  }

  const handleMobileCommentClick = (e) => {
    // handleCommentClick(e, true)
    if (!fully_authenticated) {
      NOT_LOGGED_IN_MODAL_CONTEXT.setIsModalOpen(true)
      return
    }

    if (!userBasicProfileComplete) {
      setIsOpenCompleteProfile(true)
    } else if (!userInCommunity) {
      setIsOpenWrongCommunity(true)
    }

    history.push(`/posts/${post._id}`)
  }

  const handleShareClick = () => {
    if (!userBasicProfileComplete) {
      setIsOpenCompleteProfile(true)
    } else if (!userInCommunity) {
      setIsOpenWrongCommunity(true)
    } else {
      // Implement your logic for sharing here
    }
  }

  useEffect(() => {
    setTimeout(function () {
      setShowElement(false)
    }, 5000)
  }, [showElement])

  return (
    <div
      className={`feed__postActionBar ${view == 'feed' && 'feed__postActionBar--homeFeed'} ${
        view == 'top-story' && 'postActionBar--topStory'
      }`}
    >
      {showElement && <div className='feed__postCopiedTooltip'>{actionPopupContent}</div>}

      <div
        className={`feed__postActionBarBtn ${view == 'feed' && 'feed__postActionBarBtn--homeFeed'}`}
        onClick={handleLikeClick}
      >
        <CustomSVG
          className={
            liked
              ? 'feed__postActionBarBtnImg feed__postActionBarBtnLiked'
              : 'feed__postActionBarBtnImg'
          }
          view={liked ? 'like-active' : 'like-default'}
          width={view == 'feed' ? '25' : '28'}
          height={view == 'feed' ? '25' : '28'}
        />

        {view == 'feed' && (
          <span
            className={
              liked
                ? 'feed__postActionBarBtnTxt feed__postActionBarBtnTxtLiked'
                : 'feed__postActionBarBtnTxt'
            }
          >
            Like
          </span>
        )}
      </div>

      <div
        className={`feed__postActionBarBtn ${
          view == 'feed' && 'feed__postActionBarBtn--homeFeed'
        } mobile-hide`}
        onClick={
          view == 'headline' || view == 'top-story' ? handleMobileCommentClick : handleCommentClick
        }
      >
        <img
          alt=''
          className='feed__postActionBarBtnImg'
          src={toAbsoluteUrl('/media/design/comment_default_icon.svg')}
          width={view == 'feed' ? '25' : '28'}
          height={view == 'feed' ? '25' : '28'}
        />
        {view == 'feed' && <span className='feed__postActionBarBtnTxt'>Comment</span>}
      </div>

      <div className='feed__postActionBarBtn mobile-show' onClick={handleMobileCommentClick}>
        <img
          alt=''
          className='feed__postActionBarBtnImg'
          src={toAbsoluteUrl('/media/design/comment_default_icon.svg')}
          width={view == 'feed' ? '25' : '28'}
          height={view == 'feed' ? '25' : '28'}
        />
        {view == 'feed' && <span className='feed__postActionBarBtnTxt'>Comment</span>}
      </div>

      {post.shareURL && (
        <CopyToClipboard text={post.shareURL}>
          <div
            className={`feed__postActionBarBtn ${
              view == 'feed' && 'feed__postActionBarBtn--homeFeed'
            }`}
            onClick={() => {
              if (!fully_authenticated) {
                NOT_LOGGED_IN_MODAL_CONTEXT.setIsModalOpen(true)
                return
              }

              if (!userBasicProfileComplete) setIsOpenCompleteProfile(true)
              else if (!userInCommunity) setIsOpenWrongCommunity(true)
              else {
                setActionPopupContent('Copied to clipboard')
                setShowElement(true)
              }
            }}
          >
            <img
              alt=''
              className='feed__postActionBarBtnImg'
              src={toAbsoluteUrl('/media/design/share_default_icon.svg')}
              width={view == 'feed' ? '25' : '28'}
              height={view == 'feed' ? '25' : '28'}
            />
            {view == 'feed' && <span className='feed__postActionBarBtnTxt'>Share</span>}
          </div>
        </CopyToClipboard>
      )}
    </div>
  )
}

export default PostActionBar
