import {Redirect, Route, Switch} from 'react-router-dom'
import {Profile} from '../pages/profile/Profile'
import {Petitions} from '../pages/petitions/Petitions'
import PetitionPDF from '../pages/petitions/petition_details/PetitionPDF'
import {PetitionDetails} from '../pages/petitions/petition_details/PetitionDetails'
import {PetitionIframe} from '../pages/petitions/petition_details/PetitionIframe'
import {Home} from '../pages/Home'
import {PublicProfile} from '../pages/profile/PublicProfile'
import {Navbar} from '../resuable/navbar/Navbar'
import ROUTE_PATHS from './RoutePaths'
import PrivateRoutesModals from './PrivateRoutesModals'
import Post from '../pages/Post'
import {AuthPage} from '../modules/auth'
import Terms from '../pages/Terms'
import PrivacyPolicy from '../pages/PrivacyPolicy'
import {MobileNavbar} from '../resuable/navbar/MobileNavbar'
import './PrivateRoutes.scss'
import {ActivePetitionProvider} from '../contexts/ActivePetitionContext'
import {PetitionPreview} from '../pages/petitions/petition_details/PetitionPreview'
import {News} from '../modules/news/News'

export function PrivateRoutes() {
  return (
    // <Suspense fallback={<FallbackView />}>
    <ActivePetitionProvider>
      <PrivateRoutesModals>
        <Switch>
          {/* if user is logged in and clicks verify email link for other email */}
          <Route path='/auth/' render={(props) => <AuthPage {...props} verifyOnly={true} />} />

          <div className='privateRoutes__container'>
            <Navbar />
            <Switch>
              {/* TODO: check this still works with latest styles*/}
              <Route exact path='/petitions' component={Petitions} />
              <Route exact path='/petitions/drafts/preview' component={PetitionPreview} />

              <Route exact path='/petitions/pdf/:petition_id' component={PetitionPDF} />
              <Route exact path='/petitions/:petition_id' component={PetitionDetails} />
              <Route exact path='/petitions/:petition_id/sign' component={PetitionIframe} />

              <Route exact path={'/posts/:postId'} component={Post} />

              <Route exact path={ROUTE_PATHS.HOME} component={Home} />
              <Route exact path={ROUTE_PATHS.FEED} component={Home} />

              <Route exact path={'/news'} component={News} />

              <div className='profile__outerWrap'>
                <Route exact path={ROUTE_PATHS.MY_PROFILE} component={Profile} />
                <Route exact path='/u/:handle' component={PublicProfile} />
                <Route exact path='/p/:handle' component={PublicProfile} />
                <Route exact path='/pending/p/:handle' component={PublicProfile} />
                <Route path='/terms' component={Terms} />
                <Route path='/privacypolicy' component={PrivacyPolicy} />
              </div>

              {/* <Route exact path='*' component={Error404} /> */}

              <Redirect to={ROUTE_PATHS.HOME} />

              {/* <PrivateRoute exact path="*" component={NotFound} /> */}
            </Switch>
            <MobileNavbar />
          </div>
        </Switch>
      </PrivateRoutesModals>
    </ActivePetitionProvider>
  )
}
