export function PetitionTabs(props) {
  const {view, currentTab, setCurrentTab} = props

  if (view == 'mobile') {
    return (
      <>
        {currentTab == 'Description' ? (
          <div className='petitionDetials__tabsWrap '>
            <div className='petitionDetails__tabWrapM petitionDetails__activeDescTabM'>
              <span
                className={'petitionDetials__activeTab petitionDetails__tabTxtM'}
                onClick={() => {
                  setCurrentTab('Description')
                }}
              >
                Description
              </span>

              <div className='petitionDetails__tabIndicatorActiveM'></div>
            </div>
            <div className='petitionDetails__tabWrapM'>
              <span
                className={'petitionDetials__nonActiveTab petitionDetails__tabTxtM '}
                onClick={() => {
                  setCurrentTab('Supporters')
                }}
              >
                Supporters
              </span>{' '}
            </div>
          </div>
        ) : (
          <div className='petitionDetials__tabsWrap '>
            <div className='petitionDetails__tabWrapM '>
              <span
                className={'petitionDetials__nonActiveTab petitionDetails__tabTxtM '}
                onClick={() => {
                  setCurrentTab('Description')
                }}
              >
                Description
              </span>
            </div>
            <div className='petitionDetails__tabWrapM petitionDetails__activeSupportTabM'>
              <span
                className={'petitionDetials__activeTab petitionDetails__tabTxtM'}
                onClick={() => {
                  setCurrentTab('Supporters')
                }}
              >
                Supporters
              </span>

              <div className='petitionDetails__tabIndicatorActiveM'></div>
            </div>
          </div>
        )}
      </>
    )
  }

  return (
    <div className='petitionDetials__tabsWrap mobile-hide' style={{boxShadow: 'none'}}>
      <div className='petitionDetails__tabWrap '>
        <span
          className={
            currentTab == 'Description'
              ? 'petitionDetials__activeTab'
              : 'petitionDetials__nonActiveTab'
          }
          onClick={() => {
            setCurrentTab('Description')
          }}
        >
          Description
        </span>

        {currentTab == 'Description' && <div className='petitionDetails__tabIndicatorActive'></div>}
      </div>

      <div className={currentTab == 'Supporters' && 'petitionDetails__activeTab'}>
        <span
          className={
            currentTab == 'Supporters'
              ? 'petitionDetials__activeTab'
              : 'petitionDetials__nonActiveTab'
          }
          onClick={() => {
            setCurrentTab('Supporters')
          }}
        >
          Supporters
        </span>

        {currentTab == 'Supporters' && <div className='petitionDetails__tabIndicatorActive'></div>}
      </div>
    </div>
  )
}
