import React, {useContext} from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {UserContext} from '../../../contexts/usercontext'
import {NotLoggedInModalContext} from '../../../contexts/NotLoggedInModalContext'
import './PostReactions.scss'
const PostReactions = ({view, numLikes, feed, history}) => {
  const USER_CONTEXT = useContext(UserContext)
  const fully_authenticated =
    USER_CONTEXT.user && USER_CONTEXT.user.loggedIn && USER_CONTEXT.user.verified
  const NOT_LOGGED_IN_MODAL_CONTEXT = useContext(NotLoggedInModalContext)

  const handleCommentClick = () => {
    if (!fully_authenticated) {
      NOT_LOGGED_IN_MODAL_CONTEXT.setIsModalOpen(true)
      return
    }

    history.push(`/posts/${feed._id}`)
  }

  return (
    <div
      className={`feed__postReactions ${view == 'feed' && 'feed__postReactions--homeFeed'} ${
        view == 'top-story' && 'feed__postReactions--topStory'
      }`}
    >
      <div className='feed__postReactionsStatsWrap'>
        <img
          alt=''
          className='feed__postReactionsLikeImg'
          src={toAbsoluteUrl('/media/design/like_icon_blue.svg')}
        />
        <span className='feed__postReactionsStatsTxt'>{numLikes}</span>
      </div>

      <div
        className={`feed__postReactionsCommentWrap ${
          view == 'feed' && 'feed__postReactionsCommentWrap--homeFeed'
        }`}
        onClick={handleCommentClick}
        style={{cursor: 'pointer'}}
      >
        <img
          alt=''
          src={toAbsoluteUrl('/media/design/commented_icon.svg')}
          width={20}
          style={{marginLeft: 20}}
        />
        <span className='feed__postReactionsCommentStat'>{feed.no_of_comments}</span>
        <span className='feed__postReactionsCommentTxt'>Comments</span>
      </div>
    </div>
  )
}

export default PostReactions
