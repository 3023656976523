import {useState} from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import './AdminSidebar.scss'
import {Link, useHistory} from 'react-router-dom'

export function AdminSidebar(props) {
  const history = useHistory()

  const [currentTab, setCurrentTab] = useState('Dashboard')

  return (
    <div className='AdminSidebar'>
      <img
        className='adminSidebar__logo'
        src={toAbsoluteUrl('/media/design/nova_scotia_admin_logo.svg')}
        alt='Elections Nova Scotia'
      />

      <hr className='AdminSidebar__divider'></hr>

      <div className='adminSideBar__tabContainer'>
        {currentTab == 'Dashboard' ? (
          <div className='adminSideBar__tabActiveWrap'>
            <img
              src={toAbsoluteUrl('/media/design/admin_dashboard_active_icon.svg')}
              alt='dashboard icon'
            />
            <span className='adminSideBar__tabActiveTxt'>Dashboard</span>
          </div>
        ) : (
          <div
            className='adminSideBar__tabInactiveWrap'
            onClick={() => {
              history.push('/voter-registration/admin-dashboard')
              setCurrentTab('Dashboard')
            }}
          >
            <img
              src={toAbsoluteUrl('/media/design/admin_dashboard_icon.svg')}
              alt='dashboard icon'
            />
            <span className='adminSideBar__tabInactiveTxt'>Dashboard</span>
          </div>
        )}

        {currentTab == 'Address List' ? (
          <div className='adminSideBar__tabActiveWrap'>
            <img
              src={toAbsoluteUrl('/media/design/admin_map_active_icon.svg')}
              alt='dashboard icon'
            />
            <span className='adminSideBar__tabActiveTxt'>Address List</span>
          </div>
        ) : (
          <div
            className='adminSideBar__tabInactiveWrap'
            onClick={() => {
              history.push('/voter-registration/admin-addresslist')
              setCurrentTab('Address List')
            }}
          >
            <img src={toAbsoluteUrl('/media/design/admin_map_icon.svg')} alt='dashboard icon' />
            <span className='adminSideBar__tabInactiveTxt'>Address List</span>
          </div>
        )}

        {currentTab == 'Electors List' ? (
          <div className='adminSideBar__tabActiveWrap'>
            <img
              src={toAbsoluteUrl('/media/design/admin_electors_active_icon.svg')}
              alt='dashboard icon'
            />
            <span className='adminSideBar__tabActiveTxt'>Electors List</span>
          </div>
        ) : (
          <div
            className='adminSideBar__tabInactiveWrap'
            onClick={() => {
              history.push('/voter-registration/admin-electorlist')
              setCurrentTab('Electors List')
            }}
          >
            <img
              src={toAbsoluteUrl('/media/design/admin_electors_icon.svg')}
              alt='dashboard icon'
            />
            <span className='adminSideBar__tabInactiveTxt'>Electors List</span>
          </div>
        )}

        {currentTab == 'Duplication List' ? (
          <div className='adminSideBar__tabActiveWrap'>
            <img
              src={toAbsoluteUrl('/media/design/admin_duplicate_active_icon.svg')}
              alt='dashboard icon'
            />
            <span className='adminSideBar__tabActiveTxt'>Duplication List</span>
          </div>
        ) : (
          <div
            className='adminSideBar__tabInactiveWrap'
            onClick={() => {
              history.push('/voter-registration/admin-duplicatelist')
              setCurrentTab('Duplication List')
            }}
          >
            <img
              src={toAbsoluteUrl('/media/design/admin_duplicate_icon.svg')}
              alt='dashboard icon'
            />
            <span className='adminSideBar__tabInactiveTxt'>Duplication List</span>
          </div>
        )}
      </div>
    </div>
  )
}
