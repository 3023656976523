// paths reused often
const ROUTE_PATHS = {
    HOME: '/',
    FEED: '/feed',
    LOGIN: '/auth/login',
    MY_PROFILE: '/myprofile',
    VERIFY: '/auth/verify',
    PUBLIC_PROFILE: '/u/:handle',
    POLITICIAN_PROFILE: '/p/:handle',
    COUNCILLOR_PROFILE_START: '/auth/councilor-profile',
    COUNCILOR_SIGN_UP_PAGES: {
      1: '/auth/councilor-profile',
      2: '/auth/councilor-about',
      3: '/auth/councilor-staff',
      4: '/auth/councilor-boards',
      5: '/auth/councilor-improve-areas',
    }
}

export default ROUTE_PATHS;