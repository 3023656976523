/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {DateTime} from 'luxon'

type Props = {
  className?: string
  history: Array<any>
}

const TableRow = ({item}) => {
  return (
    <tr className='civik-light-btm-dashed-border '>
      <td>
        <span className='fw-bold ps-4  d-block fs-6 civik-dark-text'>
          {item.dateUploaded
            ? DateTime.fromISO(item.dateUploaded).toLocaleString(DateTime.DATETIME_SHORT)
            : ''}
        </span>
      </td>
      <td>
        <span className='fw-bold d-block fs-6 civik-dark-text'>{item.filename}</span>
      </td>
    </tr>
  )
}
const VoterListHistory: React.FC<Props> = (props) => {
  const {className, history} = props
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-4 mb-1'>Upload History</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold bg-light civik-dark-text'>
                <th className='ps-4 min-w-125px rounded-start'>Date Uploaded</th>
                <th className='min-w-125px'>Uploaded File Name</th>
                <th className='min-w-200px text-end rounded-end'></th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {history?.map((item) => (
                <TableRow item={item} />
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export default VoterListHistory
