import {Link} from 'react-router-dom'

export const Terms = ({formik, fieldName}) => (
  <div className='form-check form-check-custom form-check-solid' style={{marginTop: 33}}>
    <input
      className='form-check-input'
      type='checkbox'
      id='kt_login_toc_agree'
      {...formik.getFieldProps(fieldName)}
    />
    <label className='form-check-label fw-bold text-gray-700 fs-6' htmlFor='kt_login_toc_agree'>
      I Agree to the{' '}
      <Link to='/terms' className='ms-1 link-primary' target='_blank' rel='noopener noreferrer'>
        terms and conditions
      </Link>
      .
    </label>
  </div>
)
