import {toAbsoluteUrl} from '../../../../_metronic/helpers'

import ROUTE_PATHS from '../../../routing/RoutePaths'

import {useVoterStyles} from '../../../contexts/VoterStylesContext'

import '../pages/VoterRegister.scss'

export function VoterNavbar(props) {
  const {customLogoImg} = useVoterStyles()

  return (
    <div className='voter__navbar'>
      <img
        src={
          customLogoImg
            ? toAbsoluteUrl(customLogoImg)
            : toAbsoluteUrl('/media/design/nova_scotia_logo.svg')
        }
        alt='Elections Nova Scotia'
      />

      <a href={ROUTE_PATHS.HOME} className='voter__navbarBackLink'>
        {'<'} Back to <span className='civik-blue'>CiviK</span>
      </a>
    </div>
  )
}
