/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useContext} from 'react'

import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {ActivePetitionContext} from '../../../../../contexts/ActivePetitionContext'

import {AddKeypointModal} from './AddKeypointModal'
import {DeleteIcon, EditIcon} from '../../../../../../assets/Icons'

export function KeyPoints(props) {
  const {activePetition, setActivePetition} = useContext(ActivePetitionContext)
  const [action, setAction] = useState('ADD')
  const [actionIndex, setActionIndex] = useState(null)

  const [modalIsOpen, setIsOpen] = useState(false)

  const deleteKeypoint = (index) => {
    try {
      // delete at nth index
      let keypointsClone = activePetition.keypoints.slice()
      keypointsClone.splice(index, 1)
      setActivePetition({
        ...activePetition,
        keypoints: keypointsClone,
      })
    } catch (error) {
      console.log(error)
    }
  }

  function afterOpenModal() {}

  function closeModal() {
    setIsOpen(false)
  }

  return (
    <div style={{marginTop: 39}}>
      <AddKeypointModal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        actionIndex={actionIndex}
        action={action}
      />
      <h1 className='createPetition__details__headerTitle'>Keypoints</h1>

      {activePetition.keypoints.map((keypoint, index) => {
        return (
          <div className='createPetition__point' key={index}>
            <div>
              <img className='' src={toAbsoluteUrl('/media/design/blue_point.svg')} alt='point' />
              <span className='createPetition__pointTxt'>{keypoint}</span>
            </div>

            <div className='createPetition__pointOuterWrap'>
              <div
                className='createPetition__pointIconContainer'
                onClick={() => {
                  setAction('EDIT')
                  setActionIndex(index)
                  setIsOpen(true)
                }}
              >
                <EditIcon className='createPetition__pointEditIcon' fill='#6C7289' />
              </div>
              <div
                className='createPetition__pointIconContainer'
                onClick={() => {
                  deleteKeypoint(index)
                }}
              >
                <DeleteIcon className='createPetition__pointDeleteIcon' fill='#6C7289' />
              </div>
            </div>
          </div>
        )
      })}

      <div className='createPetition__btnContainer'>
        <div></div>
        <div
          className='createPetition__btnWrap'
          onClick={() => {
            setAction('ADD')
            setActionIndex(null)
            setIsOpen(true)
          }}
        >
          <span className='createPetition__btnTxt'>Add Keypoint</span>
          <img
            className='createPetition__btnImg'
            src={toAbsoluteUrl('/media/design/add_plus_blue.svg')}
            alt='create petition'
          />
        </div>
      </div>
    </div>
  )
}
