/* eslint-disable jsx-a11y/anchor-is-valid */
import {useContext, useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import AUTH_SERVICE from '../../../services/auth/AuthService'
import CONFIG from '../../../../config.json'

import {ProfileContext} from '../../../contexts/profilecontext'

export function Login() {
  const history = useHistory()
  const {setProfile} = useContext(ProfileContext)
  const [error, setError] = useState(null)

  async function onSocialLogin(socialType) {
    try {
      setError(null)
      console.log('socialType', socialType)

      let response = await AUTH_SERVICE.loginWithSocial(socialType, history, setProfile)

      // if successful
      if (response && response.code === 200) {
        console.log('succesfully logged in')
      } else if (response?.code === 400) {
        setError(response.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className=''>
      <div
        className=''
        style={{
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',

          alignSelf: 'center',
        }}
      >
        <form
          className='form  civik-login-form civik-auth-form'
          noValidate
          id='kt_login_signin_form'
        >
          {/* begin::Heading */}
          <div className='login__titleTxtWrap mb-10'>
            <h1 className='text-dark mb-3' style={{fontSize: '24px'}}>
              Welcome to Civik
            </h1>
            <div className='login__linkWrap'>
              <span className='login__newHereTxt'>New Here?</span>{' '}
              <Link to='/auth/signup' className='link-primary fw-bolder' style={{fontSize: '16px'}}>
                Create an Account
              </Link>
            </div>
          </div>
          {/* begin::Heading */}
        </form>

        <div className='form '>
          <button
            className='civik-primary-btn  '
            onClick={async () => {
              await onSocialLogin('google')
            }}
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/design/civik-icon-google.svg')}
              className='civik-btn-icon'
            />
            Login with Google
          </button>

          <button
            className='civik-primary-btn'
            onClick={async () => {
              await onSocialLogin('facebook')
            }}
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/design/civik-icon-facebook.svg')}
              className='civik-btn-icon'
            />
            Login with Facebook
          </button>

          {/* <a
            className='civik-primary-btn'
            href={`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${CONFIG.LINKEDIN_CLIENT_ID}&redirect_uri=${CONFIG.LINKEDIN_REDIRECT_URI}&scope=r_liteprofile`}
            // onClick={async () => {
            //   await onSocialLogin('linkedin')
            //   // await onLinkedinLogin()
            // }}
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/design/civik-icon-linkedin.svg')}
              className='civik-btn-icon'
            />
            Login with Linkedin
          </a> */}

          <button
            className='civik-primary-btn'
            onClick={async () => {
              await onSocialLogin('twitter')
            }}
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/design/civik-icon-twitter.svg')}
              className='civik-btn-icon'
            />
            Login with Twitter
          </button>

          <button
            className='civik-primary-btn'
            onClick={async () => {
              await onSocialLogin('apple')
            }}
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/design/icon-Apple.svg')}
              className='civik-btn-icon'
            />
            Login with Apple
          </button>
        </div>

        <div className='civik-or-separator' style={{width: 270}}>
          {' '}
          <hr></hr>
          <span>OR</span>
          <hr></hr>{' '}
        </div>

        <div className='text-center'>
          <Link to='/auth/loginWithEmail' className='civik-link'>
            Login with Email
          </Link>
        </div>
        {error && (
          <div className='mb-lg-15 alert alert-danger mb-10 mt-10' style={{maxWidth: 270}}>
            <div className='alert-text font-weight-bold'>{error}</div>
          </div>
        )}

        <Link to='/auth/signup-options' className='civik-council-signup-btn civik-flex-row'>
          <img
            alt='councilor building'
            src={toAbsoluteUrl('/media/design/councilor-icon.svg')}
            className='civik-council-icon'
          />
          <div className='civik-flex-column'>
            <strong className='civik-dark-blue'>Create Councillor/Candidate Account</strong>
            <span className='civik-light-grey fs-8 fw-bold mobile-hide'>
              Create City Councellor or Candidate account
            </span>
          </div>
        </Link>
      </div>
    </div>
  )
}
