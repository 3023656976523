import React from 'react'

type Props = {
  fill?: string
  className?: string
}

const PublicIcon = (props: Props) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill={props.fill ? props.fill : 'none'}
    xmlns='http://www.w3.org/2000/svg'
    className={props.className}
  >
    <path
      d='M19.0393 15.9463L16.6764 15.0904C16.5048 15.0281 16.3118 15.0781 16.1866 15.2183C15.0282 16.5354 13.5501 17.2606 12.0248 17.2606C10.4994 17.2606 9.02131 16.5354 7.86292 15.2195C7.73889 15.0789 7.54596 15.0281 7.3731 15.0916L5.01026 15.9476C2.61119 16.8146 1 19.1746 1 21.8192V22.5214C1 22.7854 1.20593 22.9998 1.4595 22.9998H22.5904C22.844 22.9998 23.0499 22.7854 23.0499 22.5214V21.8196C23.0499 19.1746 21.4383 16.815 19.0393 15.9463Z'
      fill='#3F4254'
      // fill={props.fill} className={props.class}
    />
    <path
      d='M12.0247 16.3044C9.76111 16.3044 7.74988 14.401 6.91475 11.5199C6.15759 11.4724 5.59375 10.461 5.59375 9.13038C5.59375 8.04649 5.9678 7.17536 6.51511 6.8634C6.51314 6.8224 6.51235 6.78018 6.51235 6.73918C6.51235 3.57402 8.98465 1 12.0247 1C15.0648 1 17.5371 3.57402 17.5371 6.73918C17.5371 6.79002 17.5351 6.84044 17.5324 6.89127C18.0994 7.21636 18.4557 8.07232 18.4557 9.13038C18.4557 10.461 17.8915 11.472 17.1347 11.5195C16.2996 14.4006 14.2884 16.3039 12.0247 16.3039V16.3044ZM7.2392 10.4869C7.29511 10.4869 7.35023 10.4963 7.40102 10.5184C7.53687 10.5767 7.65184 10.6869 7.68767 10.8349C8.33065 13.5344 10.0737 15.348 12.0251 15.348C13.9765 15.348 15.7192 13.5344 16.3626 10.8349C16.3984 10.6865 16.4996 10.5652 16.6347 10.5078C16.7717 10.4504 16.9252 10.4639 17.0501 10.5443C17.2009 10.5652 17.5379 10.0564 17.5379 9.13038C17.5379 8.2531 17.2284 7.75092 17.0989 7.69927C17.0942 7.70009 17.0886 7.70009 17.0851 7.70132C16.9493 7.7128 16.8114 7.66483 16.7138 7.56276C16.6165 7.4615 16.5677 7.31884 16.5823 7.17536L16.5953 7.05566C16.6063 6.95235 16.6193 6.847 16.6193 6.73918C16.6193 4.10203 14.5585 1.95639 12.0255 1.95639C9.49258 1.95639 7.43174 4.10203 7.43174 6.73918C7.43174 6.84741 7.44473 6.95235 7.45575 7.05689L7.46875 7.17618C7.48371 7.31884 7.43489 7.46109 7.33724 7.56358C7.23998 7.66606 7.10099 7.71198 6.96594 7.70132C6.96121 7.70132 6.95688 7.7005 6.95216 7.69927C6.82262 7.75092 6.51314 8.2531 6.51314 9.13038C6.51314 10.0564 6.85766 10.5652 6.97263 10.5652C7.05059 10.5143 7.14627 10.4869 7.23998 10.4869H7.2392Z'
      fill='#3F4254'
    />
  </svg>
)

export default PublicIcon
