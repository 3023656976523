/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useRef, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'

import {useActivePetition} from '../../../contexts/ActivePetitionContext'

import TextAreaField from '../../../../app/modules/auth/reusable/TextAreaField'
import {KeyPoints} from './components/step2/KeyPoints'
import {SupportingDocuments} from './components/step2/SupportingDocuments'
import {CreatePetitionBottomButtons} from './BottomButtons'

const petitionValidationStep2chema = Yup.object().shape({
  description: Yup.string().required('Petition description is required'),
})

export function Step2(props) {
  const {loading, setLoading} = props
  const {activePetition, setActivePetition} = useActivePetition()
  const inputBodyRef = useRef(null)

  const initialValues = {
    description: activePetition.description,
  }

  const formik = useFormik({
    initialValues,
    validationSchema: petitionValidationStep2chema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      try {
        const data = {
          step: 3,
        }
        setActivePetition({...activePetition, ...data})
      } catch (error) {
        console.error(error)
      }
    },
  })

  useEffect(() => {
    setActivePetition({...activePetition, description: formik.values.description})
  }, [formik.values.description])

  return (
    <form
      className='civik-wh-card civik-profile-edit-right-bar profileEdit__rightEditForm'
      onSubmit={formik.handleSubmit}
      noValidate
    >
      {/* DESCRIPTION*/}
      <div className='createPetition__details__header'>
        <h1 className='createPetition__details__headerTitle'>Petition Description</h1>

        <span className='createPetition__details__headerSubtxt'>
          Explain the issue and why you care about it. Showing how it will impact you, your family,
          or your community will make people more likely to support your petition.
        </span>

        <TextAreaField
          formik={formik}
          field='description'
          placeholder='Petition Description'
          isRequired={true}
          noLabel={true}
          inputRef={inputBodyRef}
          customClass='createPetition__petitionDescInput'
        />
      </div>
      {/* DESCRIPTION END */}

      <KeyPoints />

      <SupportingDocuments />

      <CreatePetitionBottomButtons loading={loading} />
    </form>
  )
}
