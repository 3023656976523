const POST_TYPE_COLORS = {
  'General': {borderColor: '#3699FF', color: '#3699FF'},
  'Public Health': {borderColor: '#50CD89', color: '#50CD89'},
  'Paramedic Services': {borderColor: '#F1416C', color: '#F1416C'},
  'Roads': {borderColor: '#5E6278', color: '#5E6278'},
  'Municipal': {borderColor: '#F1BC00', color: '#F1BC00'},
  'Transportation': {borderColor: '#5F5CF1', color: '#5F5CF1'},
  'Waste': {borderColor: '#AA7762', color: '#AA7762'},
  'Fire Service': {borderColor: '#E30000', color: '#E30000'},
  'Economic & Development': {borderColor: '#0BB7AF', color: '#0BB7AF'},
  'Water Division': {borderColor: '#71B7FF', color: '#71B7FF'},
  'Municipal Licensing': {borderColor: '#773BF6', color: '#773BF6'},
  'Parks, Foresty & Recreation': {borderColor: '#18AC00', color: '#18AC00'},
  'City Events': {borderColor: '#FF5F01', color: '#FF5F01'},
  'Featured': {borderColor: '#143A62', color: '#143A62'},
}

export default POST_TYPE_COLORS
