import React, {useState} from 'react'

import TextField from '../../../../../app/modules/auth/reusable/TextField'
import SelectField from '../../../../../app/modules/auth/reusable/SelectField'

import '../../pages/VoterRegister.scss'

const calendarMonths = [
  {value: 0, label: 'Month'},
  {value: 'January', label: 'January'},
  {value: 'February', label: 'February'},
  {value: 'March', label: 'March'},
  {value: 'April', label: 'April'},
  {value: 'May', label: 'May'},
  {value: 'June', label: 'June'},
  {value: 'July', label: 'July'},
  {value: 'August', label: 'August'},
  {value: 'September', label: 'September'},
  {value: 'October', label: 'October'},
  {value: 'November', label: 'November'},
  {value: 'December', label: 'December'},
]

export function UserInfo(props) {
  const {formik} = props

  const [loading, setLoading] = useState(false)
  const [singleName, setSingleName] = useState(false)

  const [formattedDays, setFormattedDays] = useState([{value: 0, label: 'Day'}])

  var getDaysInMonth = function (month, year) {
    // Here January is 1 based
    //Day 0 is the last day in the previous month
    return new Date(year, month, 0).getDate()
    // Here January is 0 based
    // return new Date(year, month+1, 0).getDate();
  }

  const formatDays = (monthIndex) => {
    console.log('formatting days... for: ', monthIndex)
    let currentYear = new Date().getFullYear()
    let maxDays = getDaysInMonth(monthIndex, currentYear)

    console.log('max days in ', monthIndex, 'are: ', maxDays)
    let daysList = [{value: 0, label: 'Day'}]

    for (let i = 1; i <= maxDays; i++) {
      daysList.push({value: i, label: i.toString()})
    }

    console.log('daysList', daysList)

    return daysList
  }

  const formatYears = () => {
    let currentYear = new Date().getFullYear()
    let legalAgeMax = currentYear - 18
    let yearsList = [{value: 0, label: 'Year'}]

    for (let i = legalAgeMax; i >= 1910; i--) {
      yearsList.push({value: i, label: i.toString()})
    }
    return yearsList
  }

  return (
    <div className='voterRegister__bascInfoSection'>
      <div className='voterRegister__nameRow'>
        <div className='voterRegister__nameInputsWrap'>
          <TextField
            formik={formik}
            type='text'
            field='firstName'
            placeholder='First Name'
            label='First Name'
            isRequired={true}
            view='voter'
          />
          <div className='voterRegister__middleNameWrap'>
            <TextField
              formik={formik}
              type='text'
              field='middleName'
              placeholder='Middle Name'
              label='Middle Name'
              isRequired={false}
              view='voter'
              disabled={singleName}
            />{' '}
          </div>
          <TextField
            formik={formik}
            type='text'
            field='lastName'
            placeholder='Last Name'
            label='Last Name'
            isRequired={singleName ? false : true}
            view='voter'
            disabled={singleName}
          />{' '}
        </div>

        {/* ERROR MESSAGES */}
        <div
          //add spacing based on if there was input errors
          className={
            (formik.touched['firstName'] && formik.errors['firstName']) ||
            (formik.touched['lastName'] && formik.errors['lastName'])
              ? 'voterRegister__inputErrorsWrap'
              : ''
          }
        >
          {formik.touched['firstName'] && formik.errors['firstName'] && (
            <div className='TextField__voterErrWrap'>
              <span className='TextField__voterErrTxt' role='alert'>
                {formik.errors['firstName']}
              </span>
            </div>
          )}

          {!singleName && formik.touched['lastName'] && formik.errors['lastName'] && (
            <div className='TextField__voterErrWrap'>
              <span className='TextField__voterErrTxt' role='alert'>
                {formik.errors['lastName']}
              </span>
            </div>
          )}
        </div>

        <div className='voterRegister__singleNameWrap'>
          <input
            id='kt_login_toc_agree'
            className='form-check-input'
            type='checkbox'
            checked={singleName}
            onChange={() => {
              if (singleName) {
                props.onSingleName(false)
                setSingleName(false)
              } else {
                props.onSingleName(true)
                // set single name only
                setSingleName(true)
                // clear middle name and last name
                formik.values.middleName = ''
                formik.values.lastName = ''
                // clear last name errors
                formik.touched.lastName = false
                formik.errors.lastName = false
              }
            }}
          />

          <span className='voterRegister__singleNameTxt'>
            Single Name? (I have a legally registered single name)
          </span>
        </div>
      </div>
      {/* DATE OF BIRTH ROW */}
      <div className='voterRegister__birthRow'>
        <div>
          <SelectField
            formik={formik}
            type='text'
            field='birthMonth'
            placeholder='Month'
            label='Date of Birth'
            isRequired={true}
            options={calendarMonths}
            view='voter'
            customOnChange={(e) => {
              // set dropdown value
              formik.values.birthMonth = e.target.value
              console.log('formik', formik.values.birthMonth)
              console.log('value', e.target.value)
              console.log('change')

              //   setSelectedMonth(e.target.value)
              //   let monthSelected = e.target.value
              let monthIndex = calendarMonths.map((item) => item.value).indexOf(e.target.value)
              console.log('monthIndex', monthIndex)
              setFormattedDays(formatDays(monthIndex))
            }}
          />
          {((formik.touched['birthMonth'] && formik.errors['birthMonth']) ||
            (formik.touched['birthDay'] && formik.errors['birthDay']) ||
            (formik.touched['birthYear'] && formik.errors['birthYear'])) && (
            <div className='SelectField__voterErrWrap'>
              <span className='TextField__voterErrTxt' role='alert'>
                Date of birth is required!
              </span>
            </div>
          )}
        </div>

        <div className='voterRegister__selectedDayWrap'>
          <SelectField
            formik={formik}
            type='text'
            field='birthDay'
            placeholder='Day'
            label=''
            isRequired={true}
            noLabel={true}
            options={formattedDays}
            view='voter'
          />
        </div>

        <div className='voterRegister__selectedDayWrap'>
          <SelectField
            formik={formik}
            type='text'
            field='birthYear'
            placeholder='Year'
            label=''
            isRequired={true}
            noLabel={true}
            options={formatYears()}
            view='voter'
          />
        </div>
      </div>
    </div>
  )
}
