import React, {useState, useEffect, useContext, useRef, useCallback} from 'react'
import {ProfileHeader} from '../../modules/profile/ProfileHeader'
import {useHistory} from 'react-router-dom'
import {ProgressBar} from 'react-bootstrap-v5'

import {ProfileContext} from '../../contexts/profilecontext'
import {ProfilePageContext} from '../../contexts/profilePageContext'

import {toAbsoluteUrl} from '../../../_metronic/helpers'

import ReadMoreText from '../../../app/modules/reusable/ReadMoreText'
import dateFormat, {masks} from 'dateformat'

import PETITION_SERVICE from '../../services/petition/PetitionService'

import './Profile.scss'
import {useLocation} from 'react-router-dom'
import {GlobalPrivateModalContext} from '../../contexts/GlobalModalContext'
import UserPicture from '../../modules/reusable/UserPicture'

import './MyPetitions.scss'
import PetitionItem from '../petitions/components/PetititionItem'
import DraftPetition from '../petitions/components/DraftPetition'

// TODO possibly different file for each tab?

export function MyPetitions(props) {
  interface StateType {
    from: {pathname: string}
    isEdit: Boolean
  }
  const {state} = useLocation<StateType>()
  // const {profile, setProfile} = useContext(ProfileContext)
  const {currentTab, setCurrentTab} = useContext(ProfilePageContext)
  const [myPetitions, setMyPetitions] = useState([])
  const [signedPetitions, setSignedPetitions] = useState([])

  const PROFILE_CONTEXT = useContext(ProfileContext)
  const [petitionTab, setPetitionTab] = useState('Created')

  const fetchMyPetitions = async () => {
    try {
      let response = await PETITION_SERVICE.getMyPetitions(PROFILE_CONTEXT.profile._id)
      console.log('fetched .. my petitions.....', response)
      setMyPetitions(response)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchMySignedPetitions = async () => {
    try {
      let response = await PETITION_SERVICE.getMySignedPetitions()
      if (response) {
        setSignedPetitions(response)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchMySignedPetitions()
    fetchMyPetitions()
  }, [])

  return (
    <div className='Profile form w-100 fv-plugins-bootstrap5 fv-plugins-framework'>
      <ProfileHeader
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        profile={PROFILE_CONTEXT.profile}
      />

      <div
        className='civik-flex-row mb-10'
        style={{justifyContent: 'space-between', alignItems: 'center', marginTop: 30}}
      >
        <div className='civik-flex-row'>
          <h3 className='profile__title'>My Petitions</h3>
          {/* Tabs */}
          <div className='myPetitions__tabs'>
            {petitionTab == 'Created' ? (
              <span className={`myPetitions__activeTab`}>Created ({myPetitions.length})</span>
            ) : (
              <span
                className='myPetitions__nonActiveTab'
                onClick={() => {
                  setPetitionTab('Created')
                }}
                style={{marginRight: 20}}
              >
                Created ({myPetitions.length})
              </span>
            )}

            {petitionTab == 'Signed' ? (
              <span className={`myPetitions__activeTab`}>Signed ({signedPetitions.length})</span>
            ) : (
              <span
                className='myPetitions__nonActiveTab'
                onClick={() => {
                  setPetitionTab('Signed')
                }}
              >
                Signed ({signedPetitions.length})
              </span>
            )}
          </div>
        </div>
      </div>
      {/* Tab Content */}
      <div
        className='petitions__postContainer'
        style={{display: 'flex', flexFlow: 'column', alignItems: 'center', width: '100%'}}
      >
        {petitionTab == 'Created' ? (
          <>
            <DraftPetition />
            {myPetitions.map((petition, index) => {
              return <PetitionItem petition={petition} />
            })}
          </>
        ) : (
          signedPetitions.map((petition, index) => {
            return <PetitionItem petition={petition} />
          })
        )}
      </div>
    </div>
  )
}
