/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'

import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {PasswordMeterComponent} from '../../../../../_metronic/assets/ts/components'

import AUTH_SERVICE from '../../../../services/auth/AuthService'
import CONFIG from '../../../../../config.json'

import {UserContext} from '../../../../contexts/usercontext'

import TextField from '../../reusable/TextField'

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  changepassword: '',
  acceptTerms: false,
}

const registrationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  changepassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    }),
  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})

export function SignupCouncilor(props) {
  const history = useHistory()
  const location = useLocation()
  const USER_CONTEXT = useContext(UserContext)

  const [loading, setLoading] = useState(false)
  const [signupType, setSignupType] = useState('')

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(async () => {
        const userInput = {
          email: values.email,
          password: values.password,
          electedOrCandidate: location.state === 'Councillor' ? 'elected' : 'candidate',
          onboardingStep: 1,
        }

        let response = await AUTH_SERVICE.SignupPoliticianWithEmail(userInput)

        // if successful
        if (response && response.code === 200) {
          console.log('succesfully logged in')
        }
        // error
        else if (response && response.code === 400) {
          setLoading(false)
          setSubmitting(false)
          setStatus(response.message)
        }

        setLoading(false)
      }, 1000)
    },
  })

  useEffect(() => {
    // if (!props.location.state) {
    //   history.push('/auth/signup-options')
    // }

    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
      <div className='d-flex flex-row flex-row-fluid '>
        {/* <AuthSideBanner /> */}

        <form
          className='form w-100 civik-login-email-form'
          onSubmit={formik.handleSubmit}
          noValidate
          id='kt_login_signup_form'
        >
          <div className=' mb-10'>
            {/* <h1 className='text-dark mb-3' style={{fontSize: '24px', marginTop: 20}}>
              Login Details ({props.location.state})
            </h1> */}

            <h1 className='text-dark mb-3' style={{fontSize: '24px', marginTop: 20}}>
              Login Details (Candidate)
            </h1>

            <div className='fw-bold' style={{fontSize: 15, color: '#7E8299'}}>
              <span>Already a user?</span>{' '}
              <Link
                to='/auth/loginWithEmail'
                className='link-primary fw-bolder'
                style={{fontSize: 15}}
              >
                Login Here
              </Link>
            </div>
          </div>
          {formik.status && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          )}
          <div className='fv-row mb-5'>
            <TextField
              formik={formik}
              type='email'
              field='email'
              placeholder='Enter email'
              label='Email'
              isRequired={true}
            />
          </div>
          <div className='mb-10 fv-row' data-kt-password-meter='true'>
            <div className='mb-1'>
              <div className='position-relative mb-3'>
                <TextField
                  formik={formik}
                  type='password'
                  field='password'
                  placeholder='Enter password'
                  label='Password'
                  isRequired={true}
                />
              </div>
              {/* begin::Meter */}
              <div
                className='d-flex align-items-center mb-3'
                data-kt-password-meter-control='highlight'
              >
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
              </div>
              {/* end::Meter */}
            </div>
          </div>
          <div className='fv-row mb-10' style={{marginTop: -10}}>
            <TextField
              formik={formik}
              type='password'
              field='changepassword'
              placeholder='Confirm password'
              label='Confirm Password'
              isRequired={true}
            />
          </div>
          <div className='fv-row mb-10'>
            <div className='form-check form-check-custom form-check-solid'>
              <input
                className='form-check-input'
                type='checkbox'
                id='kt_login_toc_agree'
                {...formik.getFieldProps('acceptTerms')}
              />
              <label
                className='form-check-label fw-bold text-gray-700 fs-6'
                htmlFor='kt_login_toc_agree'
              >
                I Agree the{' '}
                <Link
                  to='/terms'
                  className='ms-1 link-primary'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  terms and conditions
                </Link>
                .
              </label>
              {formik.touched.acceptTerms && formik.errors.acceptTerms && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.acceptTerms}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* end::Form group */}
          <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-primary w-100 mb-5 '
              disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
            >
              {!loading && <span className='indicator-label'>Save & Continue</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>{' '}
        </form>
      </div>
    </div>
  )
}
