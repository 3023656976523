import {useContext, useEffect, useState} from 'react'
import {ProgressBar} from 'react-bootstrap-v5'

import {toAbsoluteUrl} from '../../../../_metronic/helpers'

import dateFormat from 'dateformat'
import ReadMoreText from '../../../../app/modules/reusable/ReadMoreText'

import UserPicture from '../../../modules/reusable/UserPicture'
import {ProfileContext} from '../../../contexts/profilecontext'
import {ProfilePageContext} from '../../../contexts/profilePageContext'
import {useActivePetition} from '../../../contexts/ActivePetitionContext'

export const DraftPetition = (props) => {
  const PROFILE_CONTEXT = useContext(ProfileContext)
  const {currentTab, setCurrentTab} = useContext(ProfilePageContext)
  const {activePetition, setActivePetition} = useActivePetition()

  const [draftPetition, setDraftPetition] = useState(activePetition)

  useEffect(() => {
    setDraftPetition(activePetition)
  }, [activePetition])

  if (!draftPetition) {
    return <div style={{display: 'none'}} />
  }

  return (
    <div className='petitions__post' style={{maxWidth: 600}}>
      <div>
        <div className='petitions__postHeader'>
          <span className='petitions__postHeaderLabel'>Petition to:</span>
          <span className='petitions__postHeaderValue'>
            {PROFILE_CONTEXT.profile.location_city} City Council
          </span>
        </div>

        <div className='petitions_postBody'>
          <div className='petitions_postBodyTxtWrap'>
            <h3 className='petitions_postBodyTxtTitle'>{draftPetition.title}</h3>

            <div className='civik-flex-row'>
              <ReadMoreText
                text={draftPetition.description}
                className='petitions_postBodyTxtParagraph petitions_bodyTxt'
              />
              {draftPetition.cover_photo && draftPetition.cover_photo.source_url && (
                <img
                  className='petitions_postBodyImg mobile-show'
                  src={
                    draftPetition.cover_photo && draftPetition.cover_photo.source_url
                      ? draftPetition.cover_photo.source_url
                      : ''
                  }
                  style={draftPetition.description ? {} : {marginLeft: 0}}
                ></img>
              )}
            </div>
            <div
              className='civik-flex-row'
              style={{justifyContent: 'space-between', alignItems: 'center', marginTop: 18}}
            >
              <div className='petitions_postBodyOrganizedBy'>
                <UserPicture
                  isReply={false}
                  profile={PROFILE_CONTEXT.profile}
                  styleClasses={{
                    profilePic: ' petitions__organizerImg ',
                    nameLetter: ' navbar__barNameLetter petitions__organizerImg',
                    letterWrap: 'feed__postCommentBarLetterWrap petitions__organizerImg',
                  }}
                  // privacyType={privacyType}
                />

                <div className='petitions_postBodyOrganizedByTxt'>
                  <small className='petitions_postBodyOrganizedByLabel'>Organized By</small>
                  <span className='petitions_postBodyOrganizedByValue'>
                    {PROFILE_CONTEXT.profile.full_name}
                  </span>
                </div>
              </div>
              <div className='petitions__statusLabel petitions__statusLabelDraft'>
                <span className='petitions__statusLabelTxt '>Draft</span>
              </div>{' '}
            </div>

            <div
              className='civik-flex-row'
              style={{justifyContent: 'space-between', marginRight: 100}}
            >
              <div className='petitions__dateCreatedWrap'>
                <span className='petitions__dateCreatedLabel'>Date Created</span>
                <span className='petitions__dateCreatedVal'>
                  {' '}
                  {dateFormat(draftPetition.draftCreatedAt, 'mmm dS, yyyy')}
                </span>
              </div>
            </div>
          </div>
          {draftPetition.cover_photo && draftPetition.cover_photo.source_url && (
            <img
              className='petitions_postBodyImg mobile-hide'
              src={
                draftPetition.cover_photo && draftPetition.cover_photo.source_url
                  ? draftPetition.cover_photo.source_url
                  : ''
              }
            ></img>
          )}
        </div>
      </div>

      <div className='petitions_postFooter'>
        <img
          className='petitions_postFooterImg'
          src={toAbsoluteUrl('/media/design/petition_grey_icon.svg')}
        ></img>

        <div className='petitions_postFooterProgress'>
          <div className='petitions_postFooterProgressTxt'>
            <span className='petitions_postFooterProgressLabel'>
              <strong className='petitions_postFooterSigned'>0 </strong>
              of{' '}
              <strong className='petitions_postFooterSignedMax'>
                {draftPetition.signatureGoal}
              </strong>{' '}
              Signed this Petition
            </span>
            <span className='petitions_postFooterProgressPerc'>0%</span>
          </div>
          <ProgressBar className='petitions_postFooterProgressBar' now={0} />
        </div>

        <button
          className='petitions__postStatusBtn petitions__postStatusBtnDraft mobile-hide'
          onClick={() => {
            setCurrentTab('Create Petition')
          }}
        >
          Continue Editing
        </button>
      </div>
      <div className='petitions__postStatusBtnWrap'>
        <div style={{width: '100%'}}></div>
        <button
          className='petitions__postStatusBtn petitions__postStatusBtnDraft mobile-show'
          onClick={() => {
            setCurrentTab('Create Petition')
          }}
        >
          Continue Editing
        </button>
      </div>
    </div>
  )
}
export default DraftPetition
