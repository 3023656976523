/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext, useCallback} from 'react'
import {useHistory} from 'react-router-dom'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import 'firebase/compat/auth'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

import {VoterNavbar} from '../reusable/VoterNavbar'

import './VerifyMethod.scss'

import {Checkbox, Radio, Switch} from 'pretty-checkbox-react'
import '@djthoms/pretty-checkbox'

import {useVoterStyles} from '../../../contexts/VoterStylesContext'

export function VerifyMethod() {
  const history = useHistory()
  const [checkedOption, setCheckedOption] = useState(null)
  const [formError, setFormError] = useState(false)
  const {customBtnClass, customTxtClass, customBannerImg} = useVoterStyles()

  const onSubmit = () => {
    try {
      console.log('checkedOption', checkedOption)
      if (checkedOption) {
        history.push('/voter-registration/confirm-code')
      } else {
        setFormError(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className='VerifyMethod'>
      {/* NAVBAR */}
      <VoterNavbar />

      {/* FORM */}
      <div className='verifyMethod__formWrapper'>
        <img
          className='voterRegister__topBanner'
          src={toAbsoluteUrl(
            customBannerImg ? customBannerImg : '/media/design/voter_ns_top_banner.png'
          )}
        />

        <div className='verifyMethod__form'>
          <div className='verifyMethod__formHeader'>
            <span className='verifyMethod__formHeaderTitle'>Another one step!</span>
            <span className='verifyMethod__formHeaderSubTitle'>
              we will send a verification code to you, select where you would like the code to be
              sent.
            </span>
          </div>

          <div className='voterVerify__checkboxOuterWrap'>
            <div className='voterVerify__checkboxContainer'>
              <div className='voterVerify__checkboxWrap'>
                <Radio
                  name='a'
                  shape='round'
                  color={customTxtClass ? 'danger' : 'primary'} // temporary FIX (FOR INE)
                  onChange={() => {
                    setCheckedOption('email')
                  }}
                >
                  <span className='voterVerify__checkboxTxt'>Send to email my address (Email)</span>
                </Radio>
              </div>
              <div className='voterVerify__checkboxWrap'>
                <Radio
                  name='a'
                  shape='round'
                  color={customTxtClass ? 'danger' : 'primary'} // temporary FIX (FOR INE)
                  onChange={() => {
                    setCheckedOption('sms')
                  }}
                >
                  <span className='voterVerify__checkboxTxt'>Send to my phone (SMS)</span>
                </Radio>
              </div>
              <div className='voterVerify__checkboxWrap'>
                <Radio
                  name='a'
                  shape='round'
                  color={customTxtClass ? 'danger' : 'primary'} // temporary FIX (FOR INE)
                  onChange={() => {
                    setCheckedOption('both')
                  }}
                >
                  <span className='voterVerify__checkboxTxt'> Send to both</span>
                </Radio>
              </div>
            </div>
          </div>

          {formError && (
            <div className='align-center'>
              <span className='TextField__voterErrTxt ' role='alert'>
                You must select one of the above options!
              </span>
            </div>
          )}

          <button
            type='button'
            className={
              customBtnClass
                ? 'voterVerify__sendBtn' + ' ' + customBtnClass
                : 'voterVerify__sendBtn'
            }
            onClick={onSubmit}
          >
            Send
          </button>
        </div>
      </div>
    </div>
  )
}
