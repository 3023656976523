import React, {useState, useRef, useEffect, useContext} from 'react'
import FeatherIcon from 'feather-icons-react'
import {ChangeCityModal} from '../modals/ChangeCityModal'
import {FeedLocationContext} from '../../contexts/feedLocationContext'
import {GlobalPrivateModalContext} from '../../contexts/GlobalModalContext'
import MODAL_NAMES from '../../constants/ModalNames'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: '9999999999',
  },
}

function FilterFeedDropdown({
  userBasicProfileComplete,
  postFilter,
  filterOptions,
  setPostFilter,
  getFeed,
  customStyling,
}) {
  const {setIsOpenGlobalModal} = useContext(GlobalPrivateModalContext)
  const setIsOpenCompleteProfile = (o) => setIsOpenGlobalModal(MODAL_NAMES.COMPLETE_PROFILE, o)
  const [openFilterDropdown, setOpenFilterDropdown] = useState(false)
  const filterOpRef = useRef(null)
  const [openCityModal, setOpenCityModal] = useState(false)
  const {feedLocation, setFeedLocation} = useContext(FeedLocationContext)

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (openFilterDropdown && filterOpRef.current && !filterOpRef.current.contains(e.target)) {
        setOpenFilterDropdown(false)
      }
    }

    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [openFilterDropdown])

  return (
    <div className='feed__postFilterBar' style={customStyling}>
      <div
        className='feed__postFilterDropdown'
        onClick={() => {
          // !userBasicProfileComplete ? setIsOpenCompleteProfile(true) : setOpenCityModal(true)
          // Note: for now allowing user to change city without completing profile
          setOpenCityModal(true)
        }}
      >
        <span className='feed__postFilterDropdownTxt'>{feedLocation.city}</span>
        <FeatherIcon className='feed__postFilterDropdownChevron' icon={'chevron-down'} width='13' />
      </div>

      <ChangeCityModal
        isOpen={openCityModal}
        onAfterOpen={() => {}}
        onRequestClose={() => {
          setOpenCityModal(false)
        }}
        style={customStyles}
        contentLabel='Example Modal'
        areaId={null}
      />
    </div>
  )
}

export default FilterFeedDropdown
