import civikApi from '../../../utils/civikAPI'
import firebase from 'firebase/compat/app'
import 'firebase/compat/database'
import AuthService from '../auth/AuthService'

const NotificationService = {
  getNotifications: async ({setNotifications, setBadgeNum, resetBadgeNum=false}) => {
    let notifications = await civikApi.get(`/notifications`)
    var badge = 0
    if (!resetBadgeNum) {
      badge = notifications ? notifications.filter((o) => !o.details.clicked).length : 0
    }
    setNotifications(notifications)
    setBadgeNum(badge)
  },
  listenNotifications: async ({setNotifications, setBadgeNum, resetBadgeNum}) => {
    try {
      const currentUser = firebase.auth().currentUser
      const firebase_id = currentUser.uid
      firebase
        .database()
        .ref('notifications/' + firebase_id + '/notifications')
        .on('value', async function (snapshot) {
          console.log('fetching public notifications')
          NotificationService.getNotifications({setNotifications, setBadgeNum, resetBadgeNum})
        })
    } catch (error) {
      console.log('notification error' , error)
    }
  },
  listenNotificationsAnon: async ({setNotifications, setBadgeNum, resetBadgeNum}) => {
    try {
      const claims = await AuthService.getFirebaseCustomClaims()
      let anon_id = claims.anon_id
      if (anon_id) {
        firebase
          .database()
          .ref('notifications/' + anon_id + '/notifications')
          .on('value', async function (snapshot) {
            console.log('fetching anon notifications')
             NotificationService.getNotifications({setNotifications, setBadgeNum, resetBadgeNum})
          })
      }
    } catch (error) {
      console.log('notification error' , error)
    }
  },
  clickNotification: async ({notificationId, setNotifications, setBadgeNum, resetBadgeNum}) => {
    try {
      await civikApi.put(`/notifications/${notificationId}/clicked`)
      await NotificationService.getNotifications({setNotifications, setBadgeNum, resetBadgeNum})
    } catch (error) {
      console.log(error)
    }
  },
}
export default NotificationService
