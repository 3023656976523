/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState, useContext} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {Dropdown1} from '../../../_metronic/partials'
import {useLocation} from 'react-router-dom'
import {ProfileContext} from '../../contexts/profilecontext'
import {ProfilePageContext} from '../../contexts/profilePageContext'
import './ProfileHeader.scss'
import AdminService from '../../services/admin/AdminService'
import {GlobalPrivateModalContext} from '../../contexts/GlobalModalContext'
import MODAL_NAMES from '../../constants/ModalNames'

export function ProfileHeader(props) {
  const location = useLocation()
  const currentUser = useContext(ProfileContext).profile
  const [adminApproved, setAdminApproved] = useState(false)
  const {profile, publicProfile} = props
  const userBasicProfileComplete = currentUser?.basicProfileComplete
  const {setIsOpenGlobalModal} = useContext(GlobalPrivateModalContext)
  const setIsOpenCompleteProfile = (o) => setIsOpenGlobalModal(MODAL_NAMES.COMPLETE_PROFILE, o)

  const isCouncilorType = () => {
    if (profile?.electedOrCandidate == 'elected') {
      return 'City Councillor'
    } else if (profile?.electedOrCandidate == 'candidate' && profile?.office) {
      return `${profile.office} Candidate`
    } else if (profile?.electedOrCandidate == 'candidate') {
      return `City Candidate`
    } else {
      return null
    }
  }

  // TODO: style
  const adminApprovePoli = async () => {
    let res = await AdminService.approvePendingProfile(profile._id)
    if (res?.code == 200) {
      alert('Approved successfully')
      setAdminApproved(true)
    } else {
      alert('Error approving politician')
    }
  }

  if (!profile) {
    return <div />
  }

  return (
    <div className='card mb-5 mb-xl-10 profile-header-wrap'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='me-7 mb-4'>
            <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
              <img
                src={
                  profile.profile_picture
                    ? profile.profile_picture
                    : toAbsoluteUrl('/media/design/upload-img-sample.png')
                }
                onError={({currentTarget}) => {
                  currentTarget.onerror = null // prevents looping
                  currentTarget.src = toAbsoluteUrl('/media/design/upload-img-sample.png')
                }}
                alt='profile'
              />

              <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
            </div>
          </div>

          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                    {profile.first_name && profile.last_name && profile.full_name}
                  </a>
                  {profile.addressVerified && (
                    <img src={toAbsoluteUrl('/media/design/verified_green_icon.svg')} />
                  )}
                  {isCouncilorType() && isCouncilorType().includes('Candidate') && (
                    <div className='civik-candidate-label'>{isCouncilorType()}</div>
                  )}

                  {isCouncilorType() && !isCouncilorType().includes('Candidate') && (
                    <div className='civik-politician-label'>{isCouncilorType()}</div>
                  )}
                </div>

                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <div className='d-flex align-items-center text-gray-400  me-5 mb-2'>
                    {profile.location_city && profile.location_region && (
                      <div>
                        <KTSVG
                          path='/media/icons/duotune/general/gen018.svg'
                          className='svg-icon-4 me-1'
                        />
                        {profile.location_city}, {profile.location_region}
                      </div>
                    )}
                  </div>
                  {profile.email && !publicProfile && isCouncilorType() == null && (
                    <div className='d-flex align-items-center text-gray-400  mb-2'>
                      <div>
                        <KTSVG
                          path='/media/icons/duotune/communication/com011.svg'
                          className='svg-icon-4 me-1'
                        />
                        {profile.email}
                      </div>
                    </div>
                  )}
                  {isCouncilorType() !== null && (
                    <div className='d-flex align-items-center text-gray-400  mb-2'>
                      <div>
                        <KTSVG
                          path='/media/icons/duotune/general/gen018.svg'
                          className='svg-icon-4 me-1'
                        />
                        {/* NOTE: some wards are named Ward Num so might have to revisit */}
                        {profile.represented_district_name} / Ward{' '}
                        {profile.represented_district_number}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {!publicProfile && (
                <div className='d-flex my-4'>
                  {/* <a href='#' className='btn btn-sm btn-light me-2' id='kt_user_follow_button'>
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr012.svg'
                      className='svg-icon-3 d-none'
                    />

                    <span className='indicator-label'>Create a Petiiton</span>
                    <span className='indicator-progress'>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  </a> */}

                  {/* <div className='me-0'>
                  <button
                    className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                  >
                    <i className='bi bi-three-dots fs-3'></i>
                  </button>
                  <Dropdown1 />
                </div> */}
                </div>
              )}
            </div>

            <div className='d-flex flex-wrap flex-stack'>
              <div className='d-flex flex-column flex-grow-1 pe-8'>
                <div className='d-flex flex-wrap'>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      {/* <KTSVG
                        path='/media/icons/duotune/arrows/arr066.svg'
                        className='svg-icon-3 svg-icon-success me-2'
                      /> */}
                      <div className='fs-2 fw-bolder'>{profile.numPosts}</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>Posts</div>
                  </div>

                  {/* <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr065.svg'
                        className='svg-icon-3 svg-icon-danger me-2'
                      />
                      <div className='fs-2 fw-bolder'>75</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>Projects</div>
                  </div> */}
                  {/* 
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr066.svg'
                        className='svg-icon-3 svg-icon-success me-2'
                      />
                      <div className='fs-2 fw-bolder'>60%</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>Success Rate</div>
                  </div> */}
                </div>
              </div>

              {/* <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                  <span className='fw-bold fs-6 text-gray-400'>Profile Compleation</span>
                  <span className='fw-bolder fs-6'>50%</span>
                </div>
                <div className='h-5px mx-3 w-100 bg-light mb-3'>
                  <div
                    className='bg-success rounded h-5px'
                    role='progressbar'
                    style={{width: '50%'}}
                  ></div>
                </div>
              </div> */}
              {profile.user_pendingPolitician && currentUser.admin && (
                <button
                  className='btn btn-sm btn-success me-3'
                  onClick={adminApprovePoli}
                  disabled={adminApproved}
                >
                  {' '}
                  Approve{' '}
                </button>
              )}
            </div>
          </div>
          <button
            className='profileHeader__createPetitionBtn mobile-hide'
            onClick={() => {
              !userBasicProfileComplete
                ? setIsOpenCompleteProfile(true)
                : props.setCurrentTab('Create Petition')
            }}
          >
            Create Petition
          </button>
        </div>

        <div className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item'>
              <a
                onClick={() => {
                  props.setCurrentTab('About')
                }}
                className={
                  `nav-link text-active-primary me-6 civik-profile-header-tab ` +
                  (props.currentTab === 'About' && 'active')
                }
              >
                About
              </a>
            </li>
            <li className='nav-item'>
              <a
                onClick={() => {
                  props.setCurrentTab('My Posts')
                }}
                className={
                  `nav-link text-active-primary me-6 civik-profile-header-tab ` +
                  (props.currentTab === 'My Posts' && 'active')
                }
              >
                {publicProfile ? 'Posts' : 'My Posts'}
              </a>
            </li>
            <li className='nav-item'>
              <a
                onClick={() => {
                  props.setCurrentTab('My Petitions')
                }}
                className={
                  `nav-link text-active-primary me-6 civik-profile-header-tab ` +
                  (props.currentTab === 'My Petitions' && 'active')
                }
              >
                {publicProfile ? 'Petitions' : 'My Petitions'}
              </a>
            </li>

            {!publicProfile && (
              <li className='nav-item'>
                <a
                  onClick={() => {
                    props.setCurrentTab('Settings')
                  }}
                  className={
                    `nav-link text-active-primary me-6 civik-profile-header-tab ` +
                    (props.currentTab === 'Settings' && 'active')
                  }
                >
                  Settings
                </a>
              </li>
            )}
            {!publicProfile && (
              <li className='nav-item'>
                <a
                  onClick={() => {
                    props.setCurrentTab('Help & Support')
                    // @ts-ignore: Unreachable code error
                    window.openSupportWidget()
                  }}
                  className={
                    `nav-link text-active-primary me-6 civik-profile-header-tab ` +
                    (props.currentTab === 'Help & Support' && 'active')
                  }
                >
                  Help & Support
                </a>
              </li>
            )}
            {!publicProfile && currentUser?.admin && (
              <li className='nav-item'>
                <a
                  onClick={() => {
                    props.setCurrentTab('Voter List')
                  }}
                  className={
                    `nav-link text-active-primary me-6 civik-profile-header-tab ` +
                    (props.currentTab === 'Voter List' && 'active')
                  }
                >
                  Voter List
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}
