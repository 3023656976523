import {useState, useEffect, useContext} from 'react'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import './TermsError.scss'

export function TermsError() {
  return (
    <div className='TermsError'>
      <img
        className='termsError__warningIcon'
        src={toAbsoluteUrl('/media/design/Icon-Warning.svg')}
      ></img>
      <span className='termsError__warningTxt'>
        You must agree to terms and conditions before publishing.
      </span>
    </div>
  )
}
