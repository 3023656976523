import React, {FC} from 'react'

import {useHistory} from 'react-router-dom'
import ROUTE_PATHS from '../../routing/RoutePaths'
import GenericModal from './GenericModal'


const WhyCompleteProfileText = (
  <div>
    Our Civik platform is built around community activities and engagements and you will be able to
    engage, post, and comment on the community issues and feeds where you live.
    <br />
    <br />
    For example, if you live in Toronto and have a verified address in Toronto you will be able to
    comment, post, and engage on all Toronto issues and feeds - in addition to those in your
    province and Canada in general.
  </div>
)

interface Props {
  isOpen: Boolean,
  setIsOpen: Function,
}

const CompleteProfileModal: FC<Props> = ({isOpen, setIsOpen}) => {

  const history = useHistory()

  return (
    <GenericModal
      title='Please Complete Your Profile'
      id='finish-profile-feed-id'
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      includeCancel
      buttonTextOk='Take me there'
      onClickOk={() => { 
        history.push(ROUTE_PATHS.MY_PROFILE, {isEdit:true})
        setIsOpen(false)
      }} 
      body={WhyCompleteProfileText}
    />
  )
}
export default CompleteProfileModal
