import React, {useState, useEffect, useContext} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'

import Modal from 'react-modal'
import TextField from '../../../../../modules/auth/reusable/TextField'

import {ActivePetitionContext} from '../../../../../contexts/ActivePetitionContext'

import './AddKeypointModal.scss'
import '../../../CreatePetitionModal.scss'
import CivikStyles from '../../../../../../styles/civikstyles'

const customStyles = {
  ...CivikStyles.modalCustomStyles,
}
const initialValues = {newKeypoint: ''}

const formSchema = Yup.object().shape({
  newKeypoint: Yup.string().required('Keypoint is required'),
})

export function AddKeypointModal(props) {
  const {activePetition, setActivePetition} = useContext(ActivePetitionContext)

  const [loading, setLoading] = useState(false)

  const addKeypoint = (newKeypoint) => {
    try {
      // add new staff to list
      let tempKeypoints = activePetition.keypoints.slice()
      tempKeypoints.push(newKeypoint)

      setActivePetition({...activePetition, keypoints: tempKeypoints})
    } catch (error) {
      console.log(error)
    }
  }

  const editKeypoint = (index, updatedKeypoint) => {
    try {
      let tempKeypoints = activePetition.keypoints.slice()
      tempKeypoints[index] = updatedKeypoint

      setActivePetition({
        ...activePetition,
        keypoints: tempKeypoints,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: formSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      // if not editing
      if (props.action == 'ADD') {
        addKeypoint(values.newKeypoint)
      } else if (props.action == 'EDIT') {
        editKeypoint(props.actionIndex, values.newKeypoint)
      }

      setLoading(false)
      props.onRequestClose()
    },
  })

  const resetModal = () => {
    try {
      Object.keys(formik.values).forEach((key) => {
        formik.values[key] = ''
      })

      Object.keys(formik.touched).forEach((key) => {
        formik.touched[key] = false
      })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    resetModal()
  }, [props.onRequestClose])

  useEffect(() => {
    // Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
    Modal.setAppElement('#CreatePetition')
  }, [])

  const fetchKeypoint = () => {
    try {
      // set form fields
      formik.values.newKeypoint = activePetition.keypoints[props.actionIndex]
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (props.action == 'EDIT') {
      fetchKeypoint()
    }
  }, [props.actionIndex])

  return (
    <Modal
      ariaHideApp={false}
      isOpen={props.isOpen}
      onAfterOpen={props.onAfterOpen}
      onRequestClose={props.onRequestClose}
      style={customStyles}
      contentLabel='Example Modal'
    >
      <form
        id='CreatePostModal '
        className='CreatePostModal'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div className='createPetitionM__header  '>
          <h1 className=' createPetitionM__headerTitle'>
            {props.action == 'ADD' ? `Add` : `Edit`} Keypoint
          </h1>

          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/design/modal-x-btn.svg')}
            className='civik-modal-x-btn'
            onClick={props.onRequestClose}
          />
        </div>

        <div className='addKeypoint__contentWrap'>
          <TextField
            formik={formik}
            type='text'
            field='newKeypoint'
            placeholder='Keypoint'
            label=''
          />
        </div>

        <div className='addKeypoint__footerContainer '>
          <hr className='createPetitionM__footerDivider'></hr>

          <div className='addKeypoint__footer'>
            <div></div>
            <div className='addKeypoint__footerBtnWrap'>
              <button
                type='button'
                className='addKeypoint__footerCancelBtn'
                onClick={props.onRequestClose}
              >
                <span className='indicator-label fw-bolder'>Cancel</span>
              </button>
              <button
                type='submit'
                className='createPetitionM__footerBtn'
                style={loading ? {width: 'fit-content', paddingLeft: 10, paddingRight: 10} : {}}
              >
                {!loading && <span className='indicator-label fw-bolder'>Save</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}

                {/* <span className='indicator-label fw-bolder'>Save</span> */}
              </button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}
