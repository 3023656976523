import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import './VoterAdminNavbar.scss'

export function VoterAdminNavbar(props) {
  return (
    <div className='VoterAdminNavbar'>
      <img
        src={toAbsoluteUrl('/media/design/nova_scotia_admin_logo.svg')}
        alt='Elections Nova Scotia'
      />
    </div>
  )
}
