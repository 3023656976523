import {useEffect, useState, useContext} from 'react'
import {useHistory} from 'react-router-dom'
import useQuery from '../../../routing/UseQuery'
import {ResetPassword} from './ResetPassword'
import {AccountVerifyLink} from './AccountVerifyLink'
import {Redirect} from 'react-router-dom'
import AUTH_SERVICE from '../../../services/auth/AuthService'
import firebase from 'firebase/compat/app'
import AuthService from '../../../services/auth/AuthService'
import 'firebase/compat/auth'
import {UserContext} from '../../../contexts/usercontext'
import ROUTE_PATHS from '../../../routing/RoutePaths'
import {ProfileContext} from '../../../contexts/profilecontext'


function AuthVerifyLinkPage() {
  const currentuser = firebase.auth().currentUser

  const history = useHistory()
  const USER_CONTEXT = useContext(UserContext)
  const PROFILE_CONTEXT = useContext(ProfileContext)

  useEffect(() => {
    const checkEmailVerified = async () => {
      if (currentuser && currentuser.emailVerified) {
        const claims = await AuthService.getFirebaseCustomClaims()

        let userProfile = await AuthService.getCurrentUser()
        if (userProfile) PROFILE_CONTEXT.setProfile(userProfile)

        if (claims?.pendingPolitician) {
          console.log('email is verified.. redirecting to politiician signup')
          USER_CONTEXT.setUser({loggedIn: true, verified: true, secondarySignup: true})
          history.push(ROUTE_PATHS.COUNCILLOR_PROFILE_START)
        } else {
          USER_CONTEXT.setUser({loggedIn: true, verified: true, secondarySignup: false})
          history.push(ROUTE_PATHS.HOME)
        }
      } else if (!currentuser) {
        history.replace(ROUTE_PATHS.LOGIN)
      }
    }
    checkEmailVerified()
  }, [])

  return <AccountVerifyLink />
}

export default AuthVerifyLinkPage;