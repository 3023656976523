/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext, useCallback, useRef} from 'react'
// import {IProfileDetails, profileDetailsInitValues as initialValues} from '../../../modules/profile/components/settings/SettingsModel'

import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {ProfileContext} from '../../../contexts/profilecontext'

import FEED_SERVICE from '../../../services/feed/FeedService'
import Picker from 'emoji-picker-react'

import {Link} from 'react-router-dom'
import '../../../pages/Comments.scss'
import LETTER_COLORS from '../../../constants/LetterColors'
import CommentPrivacyModal from './PrivacyModal'
import {GlobalPrivateModalContext} from '../../../contexts/GlobalModalContext'
import MODAL_NAMES from '../../../constants/ModalNames'
import UserPicture from '../../reusable/UserPicture'
import './CreateReply.scss'

type Props = {
  comments: Array<any>
  index: number
  setComments: Function
  childrenComments: Array<any>
  commentStarter: string
  setCommentStarter: Function
  postId: String
  commentParentId?: string
  setCommentParentId: Function
  community: String

  clearReplies: Function
  parentCommentUser: {
    _id: String
    full_name?: string
  }

  parentComment: {
    anonymous: boolean
    anon_username: string
  }
}

const CreateReply = ({
  comments,
  index,
  setComments,
  childrenComments,
  commentStarter,
  setCommentStarter,
  postId,
  commentParentId,
  setCommentParentId,
  community,

  clearReplies,
  parentComment,
  parentCommentUser,
}: Props) => {
  const commentRef = useRef(null)

  const PROFILE_CONTEXT = useContext(ProfileContext)
  const [isLoading, setIsLoading] = useState(false)
  const [newComment, setNewComment] = useState('')

  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const [isOpenPrivacyType, setIsOpenPrivacyType] = useState(false)
  const [privacyType, setPrivacyType] = useState('public')

  const emojiPickerRef = useRef(null)
  const privacyModalRef = useRef(null)

  const [chosenEmoji, setChosenEmoji] = useState(null)

  const {setIsOpenGlobalModal} = useContext(GlobalPrivateModalContext)

  const setIsOpenCompleteProfile = (o) => setIsOpenGlobalModal(MODAL_NAMES.COMPLETE_PROFILE, o)
  const userBasicProfileComplete = PROFILE_CONTEXT.profile?.basicProfileComplete

  const setIsOpenWrongCommunity = (o) => setIsOpenGlobalModal(MODAL_NAMES.WRONG_COMMUNITY, o)
  const userInCommunity = PROFILE_CONTEXT.profile?.communities?.includes(community)

  const getProfileLink = (user) => {
    if (user) {
      if (user.user_politician && user.nameUrl) {
        return `/p/${user.nameUrl}`
      } else if (user.nameUrl) {
        return `/u/${user.nameUrl}`
      }
    }
    return '#'
  }

  const handleCommentSubmit = async (e) => {
    try {
      e.preventDefault()
      setIsLoading(true)
      console.log(newComment)
      console.log('form submitted ✅')

      console.log('commentParentId', commentParentId)

      const userInput = {
        postId: postId,
        text: commentStarter + ' ' + newComment,
        anonymous: privacyType === 'anonymous' ? true : false,
        parentId: commentParentId,
      }

      let response = await FEED_SERVICE.createComment(userInput)
      console.log('comment result', response)

      setIsLoading(false)

      setNewComment('')
      setCommentStarter('')

      comments[index].children = [response, ...childrenComments];
      setComments(comments)

      setCommentParentId(null)
      clearReplies()

      // /posts/:post_id/comments
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  // listen for trigger of tag username from comment reply button
  // useEffect(() => {
  //   setNewComment(commentStarter)
  // }, [commentStarter])

  useEffect(() => {
    console.log('parentComment', parentComment)
    console.log('parentCommentUser', parentCommentUser)

    setNewComment(' ')
  }, [])

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (emojiPickerRef.current && !emojiPickerRef.current.contains(e.target)) {
        setShowEmojiPicker(false)
      }
      if (privacyModalRef.current && !privacyModalRef.current.contains(e.target)) {
        setIsOpenPrivacyType(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [showEmojiPicker, isOpenPrivacyType])

  useEffect(() => {
    commentRef.current.focus()
  }, [commentParentId])

  return (
    <form className='' onSubmit={handleCommentSubmit} style={{marginLeft: 38}}>
      {isLoading ? (
        <div className='feed__postCommentsPosted feed__postCommentsReply'>
          <div className='createReply__imgWrap'>
            <UserPicture
              profile={PROFILE_CONTEXT.profile}
              styleClasses={{
                profilePic: 'feed__postCommentBarProfileImg',
                nameLetter: ' navbar__barNameLetter',
                letterWrap: 'feed__postCommentBarLetterWrap ',
              }}
              privacyType={privacyType}
              isReply={true}
            />
          </div>

          <div className='feed__postCommentsPostedContent'>
            <p className='feed__postCommentsPostedTxt'>
              <strong>
                {privacyType === 'anonymous'
                  ? 'Anonymous'
                  : PROFILE_CONTEXT.profile.first_name + ` ` + PROFILE_CONTEXT.profile.last_name}
              </strong>
              <br></br>
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>{' '}
            </p>
          </div>
        </div>
      ) : (
        <div className='feed__postCommentsPosted feed__postCommentsReply'>
          <div className='createReply__imgWrap'>
            <UserPicture
              profile={PROFILE_CONTEXT.profile}
              styleClasses={{
                profilePic: 'feed__postCommentBarProfileImg',
                nameLetter: ' navbar__barNameLetter',
                letterWrap: 'feed__postCommentBarLetterWrap ',
              }}
              privacyType={privacyType}
              isReply={true}
            />
          </div>
          <div className='feed__postCommentsPostedContent'>
            <p className='feed__postCommentsPostedTxt'>
              <strong>
                {privacyType === 'anonymous'
                  ? 'Anonymous'
                  : PROFILE_CONTEXT.profile.first_name + ` ` + PROFILE_CONTEXT.profile.last_name}
              </strong>
              <br></br>

              <div className='createReply__inputWrapper'>
                <Link to={getProfileLink(parentCommentUser)} className='createReply__tagLink'>
                  <span className='createReply__tag'>
                    @
                    {parentComment.anonymous
                      ? parentComment.anon_username
                      : parentCommentUser.full_name}{' '}
                  </span>
                </Link>

                <input
                  ref={commentRef}
                  className='feed__postCommentBarInput createReply__input'
                  type='text'
                  placeholder=''
                  name='newComment'
                  value={newComment}
                  onChange={(event) => {
                    // if the user removes the tag then, remove reply trigger
                    let reply = event.target.value

                    // if (reply.length < commentStarter.length - 1) {
                    //   clearReplies()
                    // }

                    if (reply.length < 1) {
                      clearReplies()
                    }

                    setNewComment(event.target.value)
                  }}
                  autoComplete='off'
                  onFocus={() => {
                    if (!userBasicProfileComplete) setIsOpenCompleteProfile(true)
                    else if (!userInCommunity) setIsOpenWrongCommunity(true)
                  }}
                />
                <div className='civik-flex-row' style={{marginRight: 10}}>
                  <div>
                    <div
                      className='feed__postCommentBarImgIcon'
                      onClick={() =>
                        !userBasicProfileComplete
                          ? setIsOpenCompleteProfile(true)
                          : !userInCommunity
                          ? setIsOpenWrongCommunity(true)
                          : setIsOpenPrivacyType(true)
                      }
                    >
                      <img src={toAbsoluteUrl('/media/design/elipsis_icon.svg')} alt='ellipsis' />
                    </div>
                    {isOpenPrivacyType && (
                      <CommentPrivacyModal
                        isOpen={isOpenPrivacyType}
                        setIsOpen={setIsOpenPrivacyType}
                        setPrivacyType={setPrivacyType}
                        modalRef={privacyModalRef}
                        privacyType={privacyType}
                        addedClassName='createReply__privacyDropdown'
                      />
                    )}
                  </div>

                  <div className='emojiPicker'>
                    {showEmojiPicker && (
                      <div ref={emojiPickerRef}>
                        <Picker
                          onEmojiClick={(event, emojiObject) => {
                            setChosenEmoji(emojiObject)

                            setNewComment(newComment + ' ' + emojiObject.emoji)
                            // setCommentIndex(index)
                            setShowEmojiPicker(false)
                          }}
                          pickerStyle={{
                            position: 'absolute',
                            zIndex: '999999999',
                            marginTop: '25px',
                          }}
                        />
                      </div>
                    )}
                    <img
                      alt='emoji-picker'
                      className='feed__postCommentBarEmojiIcon'
                      src={toAbsoluteUrl('/media/design/post_add_emoji_icon.svg')}
                      onClick={() => {
                        !userBasicProfileComplete
                          ? setIsOpenCompleteProfile(true)
                          : !userInCommunity
                          ? setIsOpenWrongCommunity(true)
                          : setShowEmojiPicker(true)
                      }}
                    />
                  </div>
                </div>
              </div>
            </p>
          </div>
        </div>
      )}
    </form>
  )
}

export default CreateReply
