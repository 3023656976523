import React from 'react'
import lodash from 'lodash'

const ReadMoreText = ({text, className}) => {
  const [clamped, setClamped] = React.useState(true)
  const [showButton, setShowButton] = React.useState(true)
  const containerRef = React.useRef(null)
  const handleClick = () => setClamped(!clamped)

  React.useEffect(() => {
    const hasClamping = (el) => {
      const {clientHeight, scrollHeight} = el
      return clientHeight !== scrollHeight
    }

    const checkButtonAvailability = () => {
      if (containerRef.current) {
        // Save current state to reapply later if necessary.
        const hadClampClass = containerRef.current.classList.contains('clamp')
        // Make sure that CSS clamping is applied if aplicable.
        if (!hadClampClass) containerRef.current.classList.add('clamp')
        // Check for clamping and show or hide button accordingly.
        setShowButton(hasClamping(containerRef.current))
        // Sync clamping with local state.
        if (!hadClampClass) containerRef.current.classList.remove('clamp')
      }
    }

    const debouncedCheck = lodash.debounce(checkButtonAvailability, 50)

    checkButtonAvailability()
    window.addEventListener('resize', debouncedCheck)

    return () => {
      window.removeEventListener('resize', debouncedCheck)
    }
  }, [containerRef])

  return (
    <>
      <span ref={containerRef} className={className + (clamped ? ' clamp' : '')}>
        {text}
      </span>
      {showButton && clamped && (
        <span
          onClick={handleClick}
          className={`${className} civik-primary-blue civik-primary-blue-link`}
        >
          {' '}
          Read {clamped ? 'More' : 'Less'}
        </span>
      )}
    </>
  )
}

export default ReadMoreText
