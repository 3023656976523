import {useState} from 'react'

const RedirectToArticle = ({url, children, customStyling = {}}) => {
  const [isHovered, setIsHovered] = useState(false)

  const handleClick = () => {
    window.open(url, '_blank') // Open the URL in a new tab/window
  }
  const containerStyle = {
    transition: 'opacity 0.2s ease-in-out',
    opacity: isHovered ? 0.7 : 1,
    cursor: 'pointer',
    ...customStyling,
  }

  return (
    <div
      style={containerStyle}
      onClick={handleClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {children}
    </div>
  )
}

export default RedirectToArticle
