import React, {FC} from 'react'

import GenericModal from './GenericModal'


const WrongCommunityText = (
  <div>
    Our Civik platform is built around community activities and engagements and you will be able to
    engage, post, and comment on the community issues and feeds where you live. 
    <br />
    <br />
    For example, if you live in Toronto and have a verified address in Toronto you will be able to
    comment, post, and engage on all Toronto issues and feeds - in addition to those in your
    province and Canada in general.
  </div>
)

interface Props {
  isOpen: Boolean,
  setIsOpen: Function,
}

const WrongCommunityModal: FC<Props> = ({isOpen, setIsOpen}) => {


  return (
    <GenericModal
      title='You can only interact with content in your communities'
      id='wrong-community-feed-id'
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      buttonTextOk='Ok'
      onClickOk={() => { 
        setIsOpen(false)
      }} 
      body={WrongCommunityText}
    />
  )
}
export default WrongCommunityModal
