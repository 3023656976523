import {PrivacyPolicyLink, TermsLink} from '../../../constants/PrivacyTermsLinks'

export function AuthFooter() {
  return (
    <div className='auth-footer hide-mobile'>
      <a href={TermsLink} target='_blank' rel='noopener noreferrer' className='   fw-bolder fs-6'>
        Terms & Conditions
      </a>

      <a
        href={PrivacyPolicyLink}
        target='_blank'
        rel='noopener noreferrer'
        className='   fw-bolder fs-6'
      >
        Privacy policy
      </a>

      <a
        onClick={() => {
          // @ts-ignore: Unreachable code error
          window.openSupportWidget()
        }}
        className='   fw-bolder fs-6'
      >
        Need Help?
      </a>
    </div>
  )
}
