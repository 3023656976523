import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../redux/AuthCRUD'
import AUTH_SERVICE from '../../../services/auth/AuthService'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Wrong email format').required('Email is required'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(async () => {
        const userInput = {
          email: values.email,
        }
        // send password reset email
        let response = await AUTH_SERVICE.sendPasswordResetEmail(userInput)

        // success
        if (response && response.code == 200) {
          setLoading(false)
          console.log('email sent!')
          setHasErrors(false)
          setStatus('')
        }

        // error
        if (response && response.code == 500) {
          setLoading(false)
          setSubmitting(false)
          setStatus(response.message)
        }
      }, 1000)
    },
  })

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className=' mb-10 civik-auth-form-width'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3' style={{fontSize: '24px'}}>
            Reset Password
          </h1>
          {/* end::Title */}

          {/* begin::Link */}
          <span className='fw-bold ' style={{fontSize: 15, color: '#7E8299'}}>
            Enter your email to reset your password.
          </span>
          {/* end::Link */}
        </div>

        {formik.status && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              Sorry, looks like there are some errors detected, please try again.
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>Sent password reset. Please check your email</div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
          <input
            type='email'
            placeholder=''
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='civik-reset-btn-wrap'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary fw-bolder'
            disabled={!formik.isValid}
          >
            {!loading && <span className='indicator-label'>Reset</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login' className='civik-cancel-reset-pass'>
            Cancel & go back to login
          </Link>{' '}
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
