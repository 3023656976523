import React, {useState, useEffect, useContext, useRef, useCallback} from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

import Modal from 'react-modal'
import CivikStyles from '../../../../styles/civikstyles'

import './PublishPetitionModal.scss'
import '../CreatePetitionModal.scss'

const customStyles = {
  ...CivikStyles.modalCustomStyles,
}
export function PublishPetitionModal(props) {
  useEffect(() => {
    // Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
    Modal.setAppElement('#createPetition__step3')
  }, [])

  return (
    <Modal
      ariaHideApp={false}
      isOpen={props.isOpen}
      onAfterOpen={props.onAfterOpen}
      onRequestClose={props.onRequestClose}
      style={customStyles}
      contentLabel='Example Modal'
    >
      <div className='PublishPetitionModal'>
        <div className='createPetitionM__header  '>
          <h1 className=' createPetitionM__headerTitle'>Publish petition</h1>

          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/design/modal-x-btn.svg')}
            className='civik-modal-x-btn'
            onClick={props.onRequestClose}
          />
        </div>

        <div className='publishPetiiton__body'>
          <span className='publishPetiiton__bodyTxt'>
            Preview your petition to make sure everything looks good. Once you published the
            official petiton you can not go back and change any of the petition content.
          </span>
        </div>

        <div className='publishPetition__footer '>
          <div></div>

          <div>
            <button
              type='button'
              className='publishPetition__previewBtn'
              onClick={() => {
                props.onPreview()
              }}
            >
              <span className='indicator-label'>Preview</span>
            </button>
            <button
              type='button'
              className='publishPetition__footerBtn'
              onClick={() => {
                props.onSubmit()
              }}
            >
              <span className='indicator-label fw-bolder'>Publish</span>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
