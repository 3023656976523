import dateFormat from 'dateformat'
import NewsGuardLabel from '../../../pages/newsGuardLabel/NewsGuardLabel'

const NewsHeadlinerTxt = ({customStyling, postDetails}) => {
  return (
    <div className='news__topStory__card__headliner' style={customStyling}>
      <span className='news__topStory__card__headliner__sourceTxt'>
        {postDetails?.linkPreview?.publisher}
      </span>
      <span className='news__topStory__card__headliner__divider'>|</span>

      <span className='news__topStory__card__headliner__sourceTxt'>
        {dateFormat(postDetails.created_at, 'mmm dS')}
      </span>
      <div style={{marginBottom: -10}}>
        <NewsGuardLabel post={postDetails} hideSource hideLogo />
      </div>
    </div>
  )
}

export default NewsHeadlinerTxt
