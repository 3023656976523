import {useEffect, useState, useContext} from 'react'
import {useHistory} from 'react-router-dom'
import useQuery from '../../../routing/UseQuery'
import {ResetPassword} from './ResetPassword'
import {AccountVerifyLink} from './AccountVerifyLink'
import {Redirect} from 'react-router-dom'
import AUTH_SERVICE from '../../../services/auth/AuthService'
import firebase from 'firebase/compat/app'
import AuthService from '../../../services/auth/AuthService'
import 'firebase/compat/auth'
import {UserContext} from '../../../contexts/usercontext'
import ROUTE_PATHS from '../../../routing/RoutePaths'
import { ProfileContext } from '../../../contexts/profilecontext'

// TODO: this verification code could move to the accountverify file or go to a seperate component
// depending on what user flow we want and design

export function AuthAction() {
  let query = useQuery()
  const mode = query.get('mode')
  const actionCode = query.get('oobCode')
  const history = useHistory()
  const [error, setError] = useState(null)
  const [status, setStatus] = useState(null)
  const USER_CONTEXT = useContext(UserContext)
  const PROFILE_CONTEXT = useContext(ProfileContext)

  useEffect(() => {
    const checkEmailVerified = async () => {
      let resp = await AUTH_SERVICE.verifyEmail({actionCode})
      if (resp && resp.code === 200) {
        console.log('verify email resp', resp)
        setError(false);
        setStatus(false)
        const claims = await AuthService.getFirebaseCustomClaims();

        let userProfile = await AuthService.getCurrentUser()
        if (userProfile) PROFILE_CONTEXT.setProfile(userProfile)
        
        if (claims?.pendingPolitician) {
          console.log('email is verified.. redirecting to politiician signup')
          USER_CONTEXT.setUser({loggedIn: true, verified: true, secondarySignup: true})
          history.push(ROUTE_PATHS.COUNCILLOR_PROFILE_START)
        } else {
          USER_CONTEXT.setUser({loggedIn: true, verified: true, secondarySignup: false})
          history.push(ROUTE_PATHS.HOME)
        }
      } 
      else if (resp && resp.code === 204) {
        setStatus(resp.message)
      }
      else if (resp && resp.code === 400) {
        setError(resp.message)
      }
    }
    if (mode === 'verifyEmail') {
      checkEmailVerified()
    }
  }, [])
  switch (mode) {
    case 'resetPassword':
      return <ResetPassword actionCode={actionCode} />
    case 'verifyEmail':
      return <AccountVerifyLink error={error} status={status} />
    default:
      return <Redirect to={ROUTE_PATHS.HOME} />
  }
}
