import {useState, useEffect, useContext} from 'react'
import {useHistory} from 'react-router-dom'
import {ProfilePageContext} from '../../../../contexts/profilePageContext'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'

export function StartPetitionBannerMobile(props) {
  const history = useHistory()
  const {currentTab, setCurrentTab} = useContext(ProfilePageContext)

  return (
    <div
      className='petitions__createBanner'
      style={{
        marginTop: 30,
        width: '105%',
        overflowX: 'hidden',
        marginLeft: '-2.5%',
      }}
    >
      <img
        className='petitions__createBannerImg'
        src={toAbsoluteUrl('/media/design/petition_logo.svg')}
      ></img>
      <div className='petitions__createBannerTxt'>
        <h2 className='petitions__createBannerTitle '>
          Create an official petition<br className='mobile-hide'></br> or publish a city issue
          {/* <span className='mobile-show petitions__createBannerReadMore'>More info</span> */}
        </h2>

        <p className='mobile-hide'>
          Petitions can be officially submitted to the City Clerk. Issues will be published online
          only.
        </p>
      </div>

      <button
        className='petitions__createBannerBtn mobile-show'
        onClick={() => {
          setCurrentTab('Create Petition')
          history.push('/myprofile')
        }}
      >
        Create
      </button>
    </div>
  )
}
