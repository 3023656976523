import React, {useContext, useEffect, useState} from 'react'
import '../News.scss'
import './NewsHeadlines.scss'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {ProfileContext} from '../../../contexts/profilecontext'

import {FeedLocationContext} from '../../../contexts/feedLocationContext'
import FEED_SERVICE from '../../../services/feed/FeedService'
import {parseFeedFilters} from '../../../helperfunctions/FeedHelpers'
import NewsSectionHeader from './NewsSectionHeader'
import useMobileView from '../../../hooks/UseMobileView'
import Slider from 'react-slick'
import NewsHeadlineCard from './NewsHeadlineCard'
import NoNews from './NoNews'
import FeedFilterCustomOptions from '../../feed/FeedFilterCustomOptions'

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  // customPaging: customPaging, // Use the customPaging function
}

const NewsHeadlines = ({fullPageView, setFullPageView}) => {
  const [newsHeadlines, setNewsHeadlines] = useState([])

  const [postFilter, setPostFilter] = useState('Filter By')
  const [filterOptions, setFilterOptions] = useState([])

  const [feedLoading, setFeedLoading] = useState(false)
  const {feedLocation} = useContext(FeedLocationContext)

  const isMobileView = useMobileView()

  const getHeadlines = async () => {
    try {
      // Set feed loading state to true
      setFeedLoading(true)

      let filters
      const locationFilter = parseFeedFilters('All', feedLocation)

      filters = locationFilter
      if (postFilter && postFilter !== 'Filter By') {
        filters.newssource = postFilter
      }
      let response = await FEED_SERVICE.getNewsPosts(filters)

      // Update news headlines or set it to an empty array if response is falsy
      setNewsHeadlines(response || [])

      // Set feed loading state back to false
      setFeedLoading(false)
    } catch (error) {
      // Handle any errors and log them
      console.error(error)
    }
  }

  useEffect(() => {
    getHeadlines()
  }, [feedLocation, postFilter, fullPageView])

  useEffect(() => {
    const getNewsSourceFilter = async () => {
      const filters = await FEED_SERVICE.getNewsSources()
      setFilterOptions(filters || [])
    }
    getNewsSourceFilter()
  }, [])

  if (feedLoading) {
    return (
      <div className='feed_loadingContainer'>
        <img
          className='feed__loader'
          src={toAbsoluteUrl('/media/design/feed_loader.svg')}
          alt='loading'
        />
      </div>
    )
  }

  if (!(newsHeadlines.length > 0)) {
    return <NoNews section={'Headlines'} customStyling={{marginTop: 26}} />
  }

  return (
    <>
      <div
        className='news__headlines'
        style={{marginTop: fullPageView == 'NewsHeadlines' ? 0 : 27}}
      >
        {fullPageView == 'NewsHeadlines' ? (
          <div className='news__headlines__header'>
            <div
              className='news__section__chevronRightBtn'
              onClick={() => {
                setFullPageView('')
              }}
            >
              <span className='news__section__title'>{'< Back'}</span>
            </div>

            <span className='news__section__title'>Headlines</span>

            <FeedFilterCustomOptions
              postFilter={postFilter}
              filterOptions={filterOptions}
              setPostFilter={setPostFilter}
              getFeed={null}
            />
          </div>
        ) : (
          <div className='news__headlines__header' style={{marginTop: isMobileView ? 57 : 27}}>
            <NewsSectionHeader
              title='Headlines'
              routeTo='NewsHeadlines'
              setFullPageView={setFullPageView}
            />

            <FeedFilterCustomOptions
              postFilter={postFilter}
              filterOptions={filterOptions}
              setPostFilter={setPostFilter}
              getFeed={null}
            />
          </div>
        )}

        {isMobileView && !fullPageView ? (
          <Slider {...settings}>
            {newsHeadlines.map((post, index) => {
              return <NewsHeadlineCard post={post} key={post._id} index={index} />
            })}
          </Slider>
        ) : (
          <>
            {newsHeadlines.map((post, index) => {
              return <NewsHeadlineCard post={post} key={post._id} index={index} />
            })}
          </>
        )}
      </div>
    </>
  )
}

export default NewsHeadlines
