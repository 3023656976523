/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext, useCallback, useRef} from 'react'
import Modal from 'react-modal'

import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import TextField from '../../../../../app/modules/auth/reusable/TextField'
import SelectField from '../../../../../app/modules/auth/reusable/SelectField'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {PropertyInfoModal} from '../../modals/PropertyInfoModal'
import FeatherIcon from 'feather-icons-react'
import './AddressList.scss'

export const initialValues = {}

export const loginSchema = Yup.object().shape({})

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
}

const districtOptions = [
  {value: 0, label: 'All Districts'},
  {value: '1. Annapolis', label: '1. Annapolis'},
  {value: '2. Antigonish', label: '2. Antigonish'},
  {value: '3. Argyle', label: '3. Argyle'},
  {value: '4. Bedford Basin', label: '4. Bedford Basin'},
  {value: '5. Bedford South', label: '5. Bedford South'},
  {value: '6. Cape Breton Centre-Whitney Pier', label: '6. Cape Breton Centre-Whitney Pier'},
  {value: '7. Cape Breton East', label: '7. Cape Breton East'},
  {value: "8. Chester-St. Margaret's", label: "8. Chester-St. Margaret's"},
  {value: '9. Clare', label: '9. Clare'},
  {value: '10. Clayton Park West', label: '10. Clayton Park West'},
  {value: '11. Colchester-Musquodoboit Valley', label: '11. Colchester-Musquodoboit Valley'},
  {value: '12. Colchester North', label: '12. Colchester North'},
  {value: '13. Cole Harbour', label: '13. Cole Harbour'},
  {value: '14. Cole Harbour-Dartmouth', label: '14. Cole Harbour-Dartmouth'},
  {value: '15. Cumberland North', label: '15. Cumberland North'},
  {value: '16. Cumberland South', label: '16. Cumberland South'},
  {value: '17. Dartmouth East', label: '17. Dartmouth East'},
  {value: '18. Dartmouth North', label: '18. Dartmouth North'},
  {value: '19. Dartmouth South', label: '19. Dartmouth South'},
  {value: '20. Digby-Annapolis', label: '20. Digby-Annapolis'},
  {value: '21. Eastern Passage', label: '21. Eastern Passage'},
  {value: '22. Eastern Shore', label: '22. Eastern Shore'},
  {value: '23. Fairview-Clayton Park', label: '23. Fairview-Clayton Park'},
  {value: '24. Glace Bay-Dominion', label: '24. Glace Bay-Dominion'},
  {value: '25. Guysborourgh-Tracadie', label: '25. Guysborourgh-Tracadie'},
  {value: '26. Halifax Armdale', label: '26. Halifax Armdale'},
  {value: '27. Halifax Atlantic', label: '27. Halifax Atlantic'},
  {value: '28. Halifax Chebucto', label: '28. Halifax Chebucto'},
  {value: '29. Halifax Citadel-Sable Island', label: '29. Halifax Citadel-Sable Island'},
  {value: '30. Halifax Needham', label: '30. Halifax Needham'},
  {value: '31. Hammonds Plains-Lucasville', label: '31. Hammonds Plains-Lucasville'},
  {value: '32. Hants East', label: '32. Hants East'},
  {value: '33. Hants West', label: '33. Hants West'},
  {value: '34. Inverness', label: '34. Inverness'},
  {value: '35. Kings North', label: '35. Kings North'},
  {value: '36. Kings South', label: '36. Kings South'},
  {value: '37. Kings West', label: '37. Kings West'},
  {value: '38. Lunenburg', label: '38. Lunenburg'},
  {value: '39. Lunenburg West', label: '39. Lunenburg West'},
  {value: '40. Northside-Westmount', label: '40. Northside-Westmount'},
  {value: '41. Pictou Centre', label: '41. Pictou Centre'},
  {value: '42. Pictou East', label: '42. Pictou East'},
  {value: '43. Pictou West', label: '43. Pictou West'},
  {value: '44. Preston', label: '44. Preston'},
  {value: '45. Queens', label: '45. Queens'},
  {value: '46. Richmond', label: '46. Richmond'},
  {value: '47. Sackville-Cobequid', label: '47. Sackville-Cobequid'},
  {value: '48. Sackville-Uniacke', label: '48. Sackville-Uniacke'},
  {value: '49. Shelburne', label: '49. Shelburne'},
  {value: '50. Sydney-Membertou', label: '50. Sydney-Membertou'},
  {value: '51. Timberlea-Prospect', label: '51. Timberlea-Prospect'},
  {
    value: '52. Truro-Bible Hill-Millbrook-Salmon River	',
    label: '52. Truro-Bible Hill-Millbrook-Salmon River	',
  },
  {value: '53. Victoria-The Lakes', label: '53. Victoria-The Lakes'},
  {value: '54. Waverley-Fall River-Beaver Bank', label: '54. Waverley-Fall River-Beaver Bank'},
  {value: '55. Yarmouth', label: '55. Yarmouth'},
]

export function AddressList(props) {
  const [loading, setLoading] = useState(false)
  const [modalIsOpen, setIsOpen] = useState(false)
  const [modalView, setModalView] = useState('')
  const [postTopic, setPostTopic] = useState('All Districts')

  const [openTopicsDropdown, setOpenTopicsDropdown] = useState(false)
  const topicOpRef = useRef(null)

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(async () => {
        try {
          setLoading(false)
        } catch (error) {
          console.log(error)
        }
      }, 10)
    },
  })

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (openTopicsDropdown && topicOpRef.current && !topicOpRef.current.contains(e.target)) {
        setOpenTopicsDropdown(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [openTopicsDropdown])

  function afterOpenModal() {}
  function onAfterCompleteModal() {}
  function closeModal() {
    setIsOpen(false)
  }

  return (
    <>
      {/* MODAL */}
      <PropertyInfoModal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        modalView={modalView}
      />
      {/* CONSOLE */}
      <div className='voterAddrList__tableContanier'>
        {/* ACTION BAR */}
        <div className='voterAddrList__tableActionWrap'>
          <TextField
            formik={formik}
            type='text'
            field='username'
            placeholder=''
            label='Username'
            isRequired={false}
            noLabel={true}
            view='admin_searchbar'
            customStyling={{width: 287}}
          />

          {/* <SelectField
            formik={formik}
            type='text'
            field='mailingProvince'
            placeholder='Province'
            label=''
            isRequired={false}
            noLabel={true}
            options={districtOptions}
            view='admin_dropdown'
          /> */}
          <div>
            <div
              className='addressList__districtDropdown '
              onClick={() => {
                setOpenTopicsDropdown(true)
              }}
            >
              <span className={postTopic == 'Select a topic' ? '' : 'createPostModal_optionTxt'}>
                {postTopic}
              </span>
              <FeatherIcon icon={'chevron-down'} style={{marginLeft: 5}} width='20' color='' />
            </div>

            {openTopicsDropdown && (
              <div
                className='navbar__dropdown addressList__districtDropdown__options'
                ref={topicOpRef}
              >
                <div className='civik-flex-column dropdown__txtWrap'>
                  <div className='civik-flex-column ' onClick={() => {}}>
                    {districtOptions.map((topic, index) => {
                      return (
                        <div
                          key={index}
                          className='dropdown__iconOptionWrap '
                          onClick={() => {
                            setPostTopic(topic.label)
                            setOpenTopicsDropdown(false)
                          }}
                        >
                          <span className='createPostModal_optionTxt'>{topic.label}</span>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* TABLE */}
        <div className='voterAddrList__table'>
          <div className='voterAddrList__tableHeader'>
            <span className='voterAddrList__tableHeader__columnLabel voterAddrList__tableHeader__columnLabel--1'>
              Property ID
            </span>
            <span className='voterAddrList__tableHeader__columnLabel voterAddrList__tableHeader__columnLabel--2'>
              Property Address
            </span>
            <span className='voterAddrList__tableHeader__columnLabel voterAddrList__tableHeader__columnLabel--3'>
              District
            </span>
            <span className='voterAddrList__tableHeader__columnLabel voterAddrList__tableHeader__columnLabel--4'>
              No. of Reg. Voters
            </span>
          </div>

          <div className='voterAddrList__tableBody'>
            <div className='voterAddrList__tableBody__row'>
              <span className='voterAddrList__tableBody__rowItem--1'>123455</span>
              <span
                className='voterAddrList__tableBody__rowItem--2'
                onClick={() => {
                  setModalView('PropertyInfo')
                  setIsOpen(true)
                }}
              >
                752 St. George Street, PO Box 100 Annapolis Royal, NS B0S 1A0
              </span>
              <span className='voterAddrList__tableBody__rowItem--3'>Annapolis</span>
              <span className='voterAddrList__tableBody__rowItem--4'>2</span>
            </div>

            <div className='voterAddrList__tableBody__row'>
              <span className='voterAddrList__tableBody__rowItem--1'>123455</span>
              <span className='voterAddrList__tableBody__rowItem--2'>
                752 St. George Street, PO Box 100 Annapolis Royal, NS B0S 1A0
              </span>
              <span className='voterAddrList__tableBody__rowItem--3'>Annapolis</span>
              <span className='voterAddrList__tableBody__rowItem--4'>2</span>
            </div>

            <div className='voterAddrList__tableBody__row'>
              <span className='voterAddrList__tableBody__rowItem--1'>123455</span>
              <span className='voterAddrList__tableBody__rowItem--2'>
                752 St. George Street, PO Box 100 Annapolis Royal, NS B0S 1A0
              </span>
              <span className='voterAddrList__tableBody__rowItem--3'>Annapolis</span>
              <span className='voterAddrList__tableBody__rowItem--4'>2</span>
            </div>

            <div className='voterAddrList__tableBody__row'>
              <span className='voterAddrList__tableBody__rowItem--1'>123455</span>
              <span className='voterAddrList__tableBody__rowItem--2'>
                752 St. George Street, PO Box 100 Annapolis Royal, NS B0S 1A0
              </span>
              <span className='voterAddrList__tableBody__rowItem--3'>Annapolis</span>
              <span className='voterAddrList__tableBody__rowItem--4'>2</span>
            </div>

            <div className='voterAddrList__tableBody__row'>
              <span className='voterAddrList__tableBody__rowItem--1'>123455</span>
              <span className='voterAddrList__tableBody__rowItem--2'>
                752 St. George Street, PO Box 100 Annapolis Royal, NS B0S 1A0
              </span>
              <span className='voterAddrList__tableBody__rowItem--3'>Annapolis</span>
              <span className='voterAddrList__tableBody__rowItem--4'>2</span>
            </div>

            <div className='voterAddrList__tableBody__row'>
              <span className='voterAddrList__tableBody__rowItem--1'>123455</span>
              <span className='voterAddrList__tableBody__rowItem--2'>
                752 St. George Street, PO Box 100 Annapolis Royal, NS B0S 1A0
              </span>
              <span className='voterAddrList__tableBody__rowItem--3'>Annapolis</span>
              <span className='voterAddrList__tableBody__rowItem--4'>2</span>
            </div>

            <div className='voterAddrList__tableBody__row'>
              <span className='voterAddrList__tableBody__rowItem--1'>123455</span>
              <span className='voterAddrList__tableBody__rowItem--2'>
                752 St. George Street, PO Box 100 Annapolis Royal, NS B0S 1A0
              </span>
              <span className='voterAddrList__tableBody__rowItem--3'>Annapolis</span>
              <span className='voterAddrList__tableBody__rowItem--4'>2</span>
            </div>
          </div>
        </div>
      </div>

      {/* footer */}
      <div className='voterAddrList__footer'>
        <span className='voterAddrList__footer__txt'>
          Showing <span className='civik-primary-blue'>1</span> to{' '}
          <span className='civik-primary-blue'>10</span> of{' '}
          <span className='civik-primary-blue'>399,695</span> Households
        </span>
      </div>
    </>
  )
}
