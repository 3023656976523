import civikApi from '../../../utils/civikAPI'

const PetitionService = {
  markPetitionSigned: async (petition_id) => {
    try {
      let result = await civikApi.put(`/petitions/${petition_id}/sign`)
      return result
    } catch (error) {
      console.log(error)
      return {code: error.code, message: error.message}
    }
  },
  getPetitionDetails: async (petition_id) => {
    try {
      let result = await civikApi.get(`/petitions/${petition_id}`)
      return result
    } catch (error) {
      console.log(error)
    }
  },
  getPetitionSupporters: async (petition_id) => {
    try {
      let result = await civikApi.get(`/petitions/${petition_id}/supporters`)
      return result
    } catch (error) {
      console.log(error)
    }
  },
  getPetitionSignLink: async (petition_id) => {
    try {
      let result = await civikApi.get(`/petitions/${petition_id}/signLink`)
      return result
    } catch (error) {
      console.log(error)
    }
  },
  getPetitionFeed: async () => {
    try {
      let result = await civikApi.get(`/petitions/feed`)
      return result
    } catch (error) {
      console.log(error)
    }
  },
  getMyPetitions: async (profile_id) => {
    try {
      let result = []
      result = await civikApi.get(`/users/${profile_id}/petitions`)
      return result
    } catch (error) {
      console.log(error)
    }
  },

  getMySignedPetitions: async () => {
    try {
      let result = []
      result = await civikApi.get(`/users/me/signedpetitions`)
      console.log(result)
      return result
    } catch (error) {
      console.log(error)
    }
  },

  createPetition: async (payload) => {
    try {
      console.log('payload', payload)
      let response = await civikApi.post(`/petitions/official`, payload)
      return response
    } catch (error) {
      console.log(error)
    }
  },
}

export default PetitionService
