/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext, useCallback} from 'react'
import {AdminSidebar} from './reusable/AdminSidebar'
import {useFormik} from 'formik'
import './AdminAddressList.scss'
import TextField from '../../../app/modules/auth/reusable/TextField'
import SelectField from '../../../app/modules/auth/reusable/SelectField'
import * as Yup from 'yup'
import {PropertyInfoModal} from './modals/PropertyInfoModal'
import {AddressList} from './pages/address_list/AddressList'
import {ImportAddressList} from './pages/address_list/ImportAddressList'
import {ExportAddressList} from './pages/address_list/ExportAddressList'
import {AdminTopbar} from './reusable/AdminTopbar'
const districtOptions = [
  {value: 0, label: 'All Districts'},
  {value: '1. Annapolis	', label: '1. Annapolis	'},
]

export const initialValues = {}

export const loginSchema = Yup.object().shape({})

export function AdminAddressList(props) {
  const [loading, setLoading] = useState(false)
  const [modalIsOpen, setIsOpen] = useState(false)
  const [modalView, setModalView] = useState('')
  const [currentTab, setCurrentTab] = useState('Address List')

  function afterOpenModal() {}
  function onAfterCompleteModal() {}
  function closeModal() {
    setIsOpen(false)
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(async () => {
        try {
          setLoading(false)
        } catch (error) {
          console.log(error)
        }
      }, 10)
    },
  })

  const renderView = () => {
    console.log('currentTab', currentTab)
    if (currentTab == 'Address List') {
      return <AddressList />
    }
    if (currentTab == 'Import Address List') {
      return <ImportAddressList />
    }
    if (currentTab == 'Export List') {
      return <ExportAddressList />
    }
  }

  useEffect(() => {
    renderView()
  }, [currentTab])

  return (
    <>
      {' '}
      <AdminSidebar />
      <AdminTopbar />
      <div className='VoterAddressList' id='Voter'>
        {/* CONTENT */}
        <div className='voterAddrList__container'>
          {/* TABS */}
          <div className='voterAddrList__tabWrap'>
            <span
              onClick={() => {
                setCurrentTab('Address List')
              }}
              className={
                currentTab == 'Address List'
                  ? 'voterAddrList__activeTab'
                  : 'voterAddrList__inactiveTab'
              }
            >
              Address List
            </span>

            <span
              onClick={() => {
                setCurrentTab('Import Address List')
              }}
              className={
                currentTab == 'Import Address List'
                  ? 'voterAddrList__activeTab'
                  : 'voterAddrList__inactiveTab'
              }
            >
              Import Address List
            </span>

            <span
              onClick={() => {
                setCurrentTab('Export List')
              }}
              className={
                currentTab == 'Export List'
                  ? 'voterAddrList__activeTab'
                  : 'voterAddrList__inactiveTab'
              }
            >
              Export List
            </span>
          </div>

          {/* BODY CONTENT */}
          {renderView()}
        </div>
      </div>
    </>
  )
}
