import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Switch, Route, useHistory} from 'react-router-dom'
import ROUTE_PATHS from '../../../routing/RoutePaths'
export function AuthNavbar() {
  const history = useHistory()
  return (
    <div className='civik-auth-navbar show-mobile'>
      <img
        onClick={() => {
          history.push(ROUTE_PATHS.LOGIN)
        }}
        alt='civik blue logo'
        src={toAbsoluteUrl('/media/design/logo-civik-blue-horizontal.svg')}
        className='civik-auth-navbar-logo'
      />
    </div>
  )
}
