/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

import PetitionService from '../../../services/petition/PetitionService'
import './PetitionSign.scss'
import LETTER_COLORS from '../../../constants/LetterColors'
// TODO add query param for auth from backend

export function PetitionPDF(props) {
  let {petition_id} = useParams() as {petition_id: string}
  const [petition, setPetition] = useState(null)

  const getPetition = async () => {
    let petitionData = await PetitionService.getPetitionDetails(petition_id)
    setPetition(petitionData)
  }

  useEffect(() => {
    getPetition()
  }, [petition_id])

  if (!petition) {
    return <div />
  }
  return (
    <div className='PetitionSign' id='petition-pdf-id'>
      <div className='petitionSign__pdf'>
        <div className='petitionsSign__pdfHeader'>
          <h1 className='petitionsSign__pdfHeaderTitle'>Public Petition</h1>

          <div className='petitionsSign__pdfOrganizer'>
            {petition.user.profile_picture ? (
              <img
                className='feed__postCommentBarProfileImg'
                src={petition.user.profile_picture}
                alt='profile'
              />
            ) : (
              <div
                className={
                  'navbar__barNameLetterWrap feed__postCommentLetterWrapper feed__postCommentBarProfileImg'
                }
                style={{
                  background:
                    LETTER_COLORS[petition.user?.first_name.charAt(0).toUpperCase()].background,
                  cursor: petition.user?.nameUrl ? 'pointer' : '',
                  position: 'relative',
                }}
              >
                <span
                  className={'navbar__barNameLetter feed__postCommentUsrLetter'}
                  style={{
                    color: LETTER_COLORS[petition.user?.first_name.charAt(0).toUpperCase()].color,
                  }}
                >
                  {petition.user?.first_name.charAt(0).toUpperCase()}
                </span>
              </div>
            )}
            <div className='petitionsSign__pdfOrganizerTxt'>
              <span className='petitionsSign__pdfOrganizeTxtLabel'>Organzied By</span>
              <span className='petitionsSign__pdfOrganizeTxtValue'>{petition.user.full_name}</span>
            </div>
          </div>

          <div className='petitionsSign__pdfDate'>
            <span className='petitionsSign__pdfDateTxtLabel'>Date Submitted</span>
            <span className='petitionsSign__pdfDateTxtValue'>
              {new Date(petition.createdAt).toDateString()}
            </span>
          </div>
        </div>

        <div className='petitionsSign__pdfDetails'>
          <div className='petitionsSign__pdfDetailsTxt'>
            <span className='petitionsSign__pdfDetailsTxtLabel'>To:</span>
            <span className='petitionsSign__pdfDetailsTxtValue petitionsSign__pdfTo'>
              {petition.receiver}
            </span>
          </div>
          {/* lets remove this don't need to show ward anymore */}
          {/* <div className='petitionsSign__pdfDetailsTxt petitionsSign__pdfDetailsWardRow'>
            <span className='petitionsSign__pdfDetailsTxtLabel'>Ward #:</span>
            <span className='petitionsSign__pdfDetailsTxtValue petitionsSign__pdfWardNumber'>
              City of Toronto council , Executive Committee
            </span>
          </div> */}
        </div>

        <h1 className='petitionsSign__pdfTitle'>{petition.title}</h1>

        <h2 className='petitionsSign__pdfDescriptionLabel'>Description:</h2>

        <p className='petitionsSign__pdfDescriptionValue'>{petition.description}</p>

        <h2 className='petitionsSign__pdfDescriptionLabel'>Key Points:</h2>
        <div className='petitionsSign__pdfKeyPointsWrap'>
          {petition.keypoints?.map((point) => (
            <div className='petitionsSign__pdfKeyPoint'>
              <img
                className='petitionsSign__pdfKeyPointImg'
                src={toAbsoluteUrl('/media/design/keypoint_icon.svg')}
              />
              <span className='petitionsSign__pdfKeyPointTxt'>{point}</span>
            </div>
          ))}
        </div>
        {/* TODO: what to do about attachments */}
        <h2 className='petitionsSign__pdfDescriptionLabel'>Attachments:</h2>
        <div className='petitionsSign__pdfAttachmentWrap'>
          {petition.supportDocs?.map((doc) => (
            <a href={doc.source_url} target='_blank' rel='noopener noreferrer'>
              <span className='petitionsSign__pdfAttachmentTxt'>{doc.file_name}</span>
            </a>
          ))}
        </div>

        <div className='petitionsSign__pdfSignWrap'>
          <span className='petitionsSign__pdfSignTxt'>Signature</span>
          <div className='petitionsSign__pdfSignValue'>
            {'{{s_1:sig_1:o_w_150:o_h_30}}'}
            <br />
            {'{{signer_1:name}}'}
          </div>
        </div>
      </div>
    </div>
  )
}
export default PetitionPDF
