/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState, useContext} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {PublicDashboard} from './components/PublicDashboard'
import {Login} from './components/Login'
import {Signup} from './components/Signup'
import {LoginWithEmail} from './components/LoginWithEmail'
import AuthVerifyLinkPage from './components/AuthVerifyLinkPage'
import {AuthSideBanner} from './reusable/AuthSideBanner'
import {AuthFooter} from './reusable/AuthFooter'
import {AuthFooterMobile} from './reusable/AuthFooterMobile'
import {AuthNavbar} from './reusable/AuthNavbar'
import {AuthAction} from './components/AuthAction'
import {SignupCouncilor} from './components/councilor/SignupCouncilor'

import {UserContext} from '../../contexts/usercontext'
import {SignupOptions} from './components/councilor/SignupOptions'
import {Error404} from '../errors/components/Error404'
import ROUTE_PATHS from '../../routing/RoutePaths'
import PrivacyPolicy from '../../pages/PrivacyPolicy'
import Terms from '../../pages/Terms'

import '../../pages/voter/reusable/styles/VoterStyles.scss'

export function AuthPage(props) {
  return (
    <Switch>
      <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
        <AuthNavbar />
        <div className='d-flex flex-row flex-row-fluid '>
          <AuthSideBanner />

          <div className='d-flex flex-center flex-column flex-column-fluid civik-auth-page'>
            <div className='d-flex flex-center flex-column flex-column-fluid'>
              <div className='bg-white rounded p-10 p-lg-15 mx-auto'>
                {props.verifyOnly ? (
                  <Switch>
                    {/* if user is logged in and clicks verify email link for other email */}
                    <Route path='/auth/action' component={AuthAction} />
                    <Route path='/auth/verify' component={AuthVerifyLinkPage} />
                    <Redirect to={ROUTE_PATHS.HOME} />
                  </Switch>
                ) : (
                  <Switch>
                    <Route path='/auth/public-dashboard' component={PublicDashboard} />
                    <Route path='/auth/secondary-signup' component={SignupCouncilor} />
                    <Route path='/auth/signup-options' component={SignupOptions} />
                    <Route path='/auth/login' component={Login} />
                    <Route path='/auth/signup' component={Signup} />
                    <Route path='/auth/loginWithEmail' component={LoginWithEmail} />
                    <Route path='/auth/registration' component={Registration} />
                    <Route path='/auth/forgot-password' component={ForgotPassword} />
                    <Route path='/auth/action' component={AuthAction} />
                    <Route path='/auth/verify' component={AuthVerifyLinkPage} />
                    <Route path='/terms' component={Terms} />
                    <Route path='/privacypolicy' component={PrivacyPolicy} />
                    <Redirect to={ROUTE_PATHS.LOGIN} />
                    {/* <Route path='/auth/reset-password' component={ResetPassword} /> */}
                    {/* <Redirect from='/auth' exact={true} to='/auth/login' />
                <Redirect to='/auth/login' /> */}

                    {/* <Redirect to='/error/404' /> */}
                  </Switch>
                )}
              </div>
            </div>

            <AuthFooter />
            <AuthFooterMobile />
          </div>
        </div>
      </div>
    </Switch>
  )
}
