import civikApi from '../../../../utils/civikAPI'

// NOTE: IN PROGRESS: MAY CHANGE

const VoterRegistrationService = {
  registerVoter: async (data) => {
    try {
      const result = await civikApi.post(`/voterlist/register`, data)
      return {success: true, data: result}
    } catch (error) {
      console.log(error)
    }
  },
  // requires firebase admin auth for now
  getRegisteredVoterList: async () => {
    try {
      const result = await civikApi.get(`/voterlist/registeredvoters`)
      return {success: true, data: result}
    } catch (error) {
      console.log(error)
    }
  },
}

export default VoterRegistrationService
