/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext, useCallback, useRef} from 'react'
// import {IProfileDetails, profileDetailsInitValues as initialValues} from '../../../modules/profile/components/settings/SettingsModel'

import FEED_SERVICE from '../services/feed/FeedService'

import './Comments.scss'

import CreateComment from '../modules/feed/comments/CreateComment'
import {useHistory} from 'react-router'
import {CommentItem} from '../modules/feed/comments/CommentItem'
import CreateReply from '../modules/feed/comments/CreateReply'

type Props = {
  postId: string
  numOfComments: number
  sortBy: string
  community: String
  triggerComment: boolean
  comments?: Array<any>
  isSinglePost?: boolean
}

export function Comments(props: Props) {
  const commentRef = useRef(null)
  const [newReply, setNewReply] = useState('')
  const [activeReplies, setActiveReplies] = useState([])

  const history = useHistory()

  const [isLoading, setIsLoading] = useState(false)
  const [comments, setComments] = useState(props.comments ? props.comments : [])
  const [newComment, setNewComment] = useState('')

  const [commentParentId, setCommentParentId] = useState(null)
  const [replyParentId, setReplyParentId] = useState(null)

  const getPostCommments = async (postId, sortBy) => {
    try {
      const userInput = {
        postId: postId,
        sortBy: sortBy,
      }

      let response = await FEED_SERVICE.getPostComments(userInput)

      if (response.code == 200) {
        // console.log('response.data', response.data)
        setComments(response.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  // const sortReplies = (commentsData) => {
  //   try {
  //     // Sort the comments in descending date order
  //     sortCommentsByDate(commentsData, true)
  //     // Generate a tree structure
  //     var commentsInTreeForm = buildTree(commentsData, null)

  //     // Remove replies from first level of comments
  //     commentsInTreeForm = removeRepliesFromFirstLevel(commentsInTreeForm)

  //     // Transform the tree structure to a list
  //     var commentArray = getArrayFromCommentTree(commentsInTreeForm, [])

  //     // Output the result
  //     setComments(commentArray)
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  /**
   * Implementation
   */
  // function buildTree(comments, parentId) {
  //   // console.log('parentId', parentId)
  //   var output = []

  //   for (var i = 0; i < comments.length; i++) {
  //     var comment = comments[i]

  //     if (comment.parent === parentId) {
  //       comment.children = buildTree(comments, comment._id)
  //       output.push(comment)
  //     }
  //   }

  //   if (parentId) {
  //     // First level of comments are in desc date order, replies in asc date
  //     sortCommentsByDate(output, false)
  //   }

  //   return output
  // }

  // function sortCommentsByDate(comments, desc) {
  //   comments.sort(function (first, second) {
  //     if (desc) {
  //       return second.createdAt - first.createdAt
  //     } else {
  //       return first.createdAt - second.createdAt
  //     }
  //   })
  // }

  // function removeRepliesFromFirstLevel(comments) {
  //   var newComments = []

  //   for (var i = 0; i < comments.length; i++) {
  //     var comment = comments[i]

  //     if (!comment.parent) {
  //       newComments.push(comment)
  //     }
  //   }

  //   return newComments
  // }

  // function getArrayFromCommentTree(comments, commentArray) {
  //   if (!commentArray) {
  //     commentArray = []
  //   }

  //   for (var i = 0; i < comments.length; i++) {
  //     var comment = comments[i]

  //     commentArray.push(comment)

  //     if (comment.children.length !== 0) {
  //       getArrayFromCommentTree(comment.children, commentArray)
  //     }
  //   }

  //   return commentArray
  // }

  const triggerReply = (userName, commentId) => {
    try {
      setCommentParentId(commentId)
      setReplyParentId(commentId)
    } catch (error) {
      console.log(error)
    }
  }
  
  useEffect(() => {
    if (props.numOfComments > 0 && !props.comments) {
      // setIsLoading(true)
      getPostCommments(props.postId, props.sortBy)
      // setIsLoading(false)
    } else if (props.comments) {
      setComments(props.comments)
    }
  }, [])

  return (
    <div className='feed__postComments'>
      {!comments ? (
        <span>no comments</span>
      ) : (
        <div>
          {/* Comment list Start */}
          <div style={{maxHeight: 400, overflowX: 'hidden', overflowY: 'auto'}}>
            {comments.length > 0 && (
              <div className='feed__postCommentsHeader'>
                {/* <span className='feed__postCommentsHeaderTxt'>View previous comments</span> */}
                <span className='feed__postCommentsHeaderTxt'>Most recent comments</span>
              </div>
            )}

            {comments.map((comment, index) => {
              if (comment && comment.parent == null) {
                return (
                  <div key={comment._id}>
                    <CommentItem
                      postId={props.postId}
                      community={props.community}
                      comment={comment}
                      triggerReply={triggerReply}
                    />

                    {replyParentId == comment._id && (
                      <CreateReply
                        parentComment={comment}
                        parentCommentUser={comment.user}
                        setComments={setComments}
                        childrenComments={comment.children ? comment.children : []}
                        comments={comments}
                        index={index}
                        commentStarter={newReply}
                        setCommentStarter={setNewReply}
                        postId={props.postId}
                        commentParentId={commentParentId}
                        setCommentParentId={setCommentParentId}
                        community={props.community}
                        clearReplies={() => {
                          setReplyParentId(null)
                        }}
                      />
                    )}

                    {comment.children &&
                      comment.children.length > 0 &&
                      comment.children.map((child, index) => {
                        return (
                          <div key={child._id}>
                            <CommentItem
                              postId={props.postId}
                              community={props.community}
                              comment={child}
                              triggerReply={triggerReply}
                              parent={comment}
                            />
                          </div>
                        )
                      })}
                  </div>
                )
              }
            })}
            {comments.length > 0 && !props.isSinglePost && (
              <div className='feed__postCommentsFooter'>
                <span
                  className='feed__postCommentsHeaderTxt'
                  onClick={() => history.push(`/posts/${props.postId}`)}
                >
                  View more comments
                </span>
              </div>
            )}
          </div>
          {/* Comment list end */}

          {/* Create Comment */}
          <CreateComment
            setComments={setComments}
            comments={comments}
            commentStarter={newComment}
            setCommentStarter={setNewComment}
            postId={props.postId}
            commentParentId={commentParentId}
            setCommentParentId={setCommentParentId}
            community={props.community}
            triggerComment={props.triggerComment}
          />
        </div>
      )}
    </div>
  )
}
