import FeatherIcon from 'feather-icons-react'
import {GlobalPrivateModalContext} from '../../contexts/GlobalModalContext'
import MODAL_NAMES from '../../constants/ModalNames'
import {useContext, useEffect, useRef, useState} from 'react'
import {ProfileContext} from '../../contexts/profilecontext'
import {UserContext} from '../../contexts/usercontext'
import {NotLoggedInModalContext} from '../../contexts/NotLoggedInModalContext'

const FeedFilterCustomOptions = ({postFilter, filterOptions, setPostFilter, getFeed}) => {
  const {setIsOpenGlobalModal} = useContext(GlobalPrivateModalContext)
  const setIsOpenCompleteProfile = (o) => setIsOpenGlobalModal(MODAL_NAMES.COMPLETE_PROFILE, o)
  const PROFILE_CONTEXT = useContext(ProfileContext)
  const userBasicProfileComplete = PROFILE_CONTEXT.profile?.basicProfileComplete
  const [openFilterDropdown, setOpenFilterDropdown] = useState(false)
  const filterOpRef = useRef(null)
  const NOT_LOGGED_IN_MODAL_CONTEXT = useContext(NotLoggedInModalContext)
  const USER_CONTEXT = useContext(UserContext)
  const fully_authenticated =
    USER_CONTEXT.user && USER_CONTEXT.user.loggedIn && USER_CONTEXT.user.verified

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (openFilterDropdown && filterOpRef.current && !filterOpRef.current.contains(e.target)) {
        setOpenFilterDropdown(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [openFilterDropdown])

  return (
    <div className='feed__postFilterBar'>
      <div
        className='feed__postFilterDropdown'
        onClick={() => {
          !fully_authenticated
            ? NOT_LOGGED_IN_MODAL_CONTEXT.setIsModalOpen(true)
            : !userBasicProfileComplete
            ? setIsOpenCompleteProfile(true)
            : setOpenFilterDropdown(true)
        }}
      >
        <span className='feed__postFilterDropdownTxt'>{postFilter}</span>
        <FeatherIcon className='feed__postFilterDropdownChevron' icon={'chevron-down'} width='13' />
      </div>

      {openFilterDropdown && (
        <div className='navbar__dropdown feed__postFilterOptions' ref={filterOpRef}>
          <div className='civik-flex-column dropdown__txtWrap'>
            <div className='civik-flex-column ' onClick={() => {}}>
              {filterOptions.map((topic, index) => {
                return (
                  <div
                    key={index}
                    className='dropdown__iconOptionWrap '
                    onClick={() => {
                      setPostFilter(topic.value)
                      setOpenFilterDropdown(false)
                      if (getFeed) {
                        getFeed(topic)
                      }
                    }}
                  >
                    <span className='feed__postFilterOptionsTxt'>{topic.label}</span>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default FeedFilterCustomOptions
