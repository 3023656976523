/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext, useCallback} from 'react'
import {useHistory} from 'react-router-dom'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import 'firebase/compat/auth'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {VoterNavbar} from '../reusable/VoterNavbar'
import './ConfirmCode.scss'
import '@djthoms/pretty-checkbox'
import TextField from '../../../../app/modules/auth/reusable/TextField'
import VerificationInput from 'react-verification-input'
import {ModalWrapper} from '../reusable/modals/modal_wrapper/ModalWrapper'
import {useVoterStyles, VoterStylesProvider} from '../../../contexts/VoterStylesContext'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
}

export function ConfirmCode() {
  const [checkedOption, setCheckedOption] = useState(null)
  const [loading, setLoading] = useState(false)
  const [code, setCode] = useState(null)
  const [codeError, setCodeError] = useState(null)
  const [modalIsOpen, setIsOpen] = useState(false)
  const [modalView, setModalView] = useState('')
  const {customBtnClass, customTxtClass, customBannerImg} = useVoterStyles()

  const onSubmit = () => {
    try {
      console.log('code', code)

      if (!code || code.length < 6) {
        console.log('You must fill in all the inputs!')
        setCodeError(true)
      } else {
        console.log('success')
        setModalView('ThankYou')
        setIsOpen(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  function afterOpenModal() {}

  function onAfterCompleteModal() {}

  function closeModal() {
    setIsOpen(false)
  }

  return (
    <div className='VerifyMethod' id='Voter'>
      {/* NAVBAR */}
      <VoterNavbar />

      {/* MODAL */}
      <ModalWrapper
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        modalView={modalView}
      />

      {/* FORM */}
      <div className='verifyMethod__formWrapper'>
        <img
          className='voterRegister__topBanner'
          src={toAbsoluteUrl(
            customBannerImg ? customBannerImg : '/media/design/voter_ns_top_banner.png'
          )}
        />

        <div className='confirmCode__form'>
          <div className='verifyMethod__formHeader'>
            <span className='verifyMethod__formHeaderTitle'>Enter code!</span>

            <div className='verifyMethod__formHeaderTxtWrap'>
              <span className='confirmCode__formHeaderTxt'>
                We sent you a{' '}
                <span className={customTxtClass ? customTxtClass : 'civik-primary-blue'}>
                  6-digit verification code
                </span>
              </span>
              <span className='confirmCode__formHeaderTxt'>Please enter the code below,</span>
              <span className='confirmCode__formHeaderTxt'>
                it will expire after five minutes.
              </span>{' '}
            </div>
          </div>

          <div className='confirmCode__inputsWrap'>
            <VerificationInput
              inputProps={{autoComplete: 'one-time-code'}}
              value={code}
              onChange={setCode}
              validChars='0-9'
              classNames={{
                container: 'TextField__confirmCode__container',
                character: 'TextField__confirmCode',
                characterInactive: 'TextField__confirmCode__inactive',
                characterSelected: 'TextField__confirmCode__selected',
              }}
            />

            {codeError && (
              <div className='voterRegister__singleInputErr confirmCode__errorWrap'>
                <span className='TextField__voterErrTxt' role='alert'>
                  You must enter in the entire code!
                </span>
              </div>
            )}
          </div>

          <button
            type='button'
            className={
              customBtnClass ? 'confirmCode__btn' + ' ' + customBtnClass : 'confirmCode__btn'
            }
            onClick={onSubmit}
          >
            Confirm
          </button>

          <div className='confirmCode__formFooter'>
            <span className='confirmCode__formFooterTxt'>
              Didn’t receive the code?{' '}
              <span
                className={
                  customTxtClass
                    ? 'confirmCode__resendLink' + ' ' + customTxtClass
                    : 'civik-primary-blue confirmCode__resendLink'
                }
              >
                Resend
              </span>
            </span>{' '}
          </div>
        </div>
      </div>
    </div>
  )
}
