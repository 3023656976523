/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext, useCallback, useRef} from 'react'
// import {IProfileDetails, profileDetailsInitValues as initialValues} from '../../../modules/profile/components/settings/SettingsModel'

import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {ProfileContext} from '../../../contexts/profilecontext'

import FEED_SERVICE from '../../../services/feed/FeedService'
import Picker from 'emoji-picker-react'

import '../../../pages/Comments.scss'
import LETTER_COLORS from '../../../constants/LetterColors'
import CommentPrivacyModal from './PrivacyModal'
import {GlobalPrivateModalContext} from '../../../contexts/GlobalModalContext'
import MODAL_NAMES from '../../../constants/ModalNames'
import UserPicture from '../../reusable/UserPicture'

type Props = {
  setComments: Function
  comments: Array<any>
  commentStarter: string
  setCommentStarter: Function
  postId: String
  commentParentId?: string
  setCommentParentId: Function
  community: String
  triggerComment: boolean
}

const CreateComment = ({
  setComments,
  comments,
  commentStarter,
  setCommentStarter,
  postId,
  commentParentId,
  setCommentParentId,
  community,
  triggerComment,
}: Props) => {
  const commentRef = useRef(null)

  const PROFILE_CONTEXT = useContext(ProfileContext)
  const [isLoading, setIsLoading] = useState(false)
  const [newComment, setNewComment] = useState('')

  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const [isOpenPrivacyType, setIsOpenPrivacyType] = useState(false)
  const [privacyType, setPrivacyType] = useState('public')

  const emojiPickerRef = useRef(null)
  const privacyModalRef = useRef(null)

  const [chosenEmoji, setChosenEmoji] = useState(null)

  const {setIsOpenGlobalModal} = useContext(GlobalPrivateModalContext)

  const setIsOpenCompleteProfile = (o) => setIsOpenGlobalModal(MODAL_NAMES.COMPLETE_PROFILE, o)
  const userBasicProfileComplete = PROFILE_CONTEXT.profile?.basicProfileComplete

  const setIsOpenWrongCommunity = (o) => setIsOpenGlobalModal(MODAL_NAMES.WRONG_COMMUNITY, o)
  const userInCommunity = PROFILE_CONTEXT.profile?.communities?.includes(community)

  const handleCommentSubmit = async (e) => {
    try {
      e.preventDefault()
      setIsLoading(true)
      console.log(newComment)
      console.log('form submitted ✅')

      console.log('commentParentId', commentParentId)

      const userInput = {
        postId: postId,
        text: newComment,
        anonymous: privacyType === 'anonymous' ? true : false,
        parentId: commentParentId,
      }

      let response = await FEED_SERVICE.createComment(userInput)
      console.log('comment result', response)

      setIsLoading(false)

      setNewComment('')
      setCommentStarter('')

      setComments([response, ...comments])

      setCommentParentId(null)

      // /posts/:post_id/comments
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  // listen for trigger of tag username from comment reply button
  useEffect(() => {
    setNewComment(commentStarter)
  }, [commentStarter])

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (emojiPickerRef.current && !emojiPickerRef.current.contains(e.target)) {
        setShowEmojiPicker(false)
      }
      if (privacyModalRef.current && !privacyModalRef.current.contains(e.target)) {
        setIsOpenPrivacyType(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [showEmojiPicker, isOpenPrivacyType])

  useEffect(() => {
    if (triggerComment) {
      commentRef.current.focus()
      console.log('new comment trigger... now focusing on')
    }
  }, [triggerComment])

  return (
    <div className='feed__postCommentBar'>
      <div>
        <UserPicture
          isReply={false}
          profile={PROFILE_CONTEXT.profile}
          styleClasses={{
            profilePic: 'feed__postCommentBarProfileImg',
            nameLetter: ' navbar__barNameLetter',
            letterWrap: 'feed__postCommentBarLetterWrap ',
          }}
          privacyType={privacyType}
        />
      </div>
      <div className='feed__postCommentBarInputContainer'>
        <form className='feed__postCommentBarInputWrap' onSubmit={handleCommentSubmit}>
          {isLoading ? (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          ) : (
            <input
              ref={commentRef}
              className='feed__postCommentBarInput'
              type='text'
              placeholder=''
              name='newComment'
              value={newComment}
              onChange={(event) => setNewComment(event.target.value)}
              autoComplete='off'
              onFocus={() => {
                if (!userBasicProfileComplete) setIsOpenCompleteProfile(true)
                else if (!userInCommunity) setIsOpenWrongCommunity(true)
              }}
            />
          )}
          <div className='civik-flex-row'>
            <div>
              <div
                className='feed__postCommentBarImgIcon'
                onClick={() =>
                  !userBasicProfileComplete
                    ? setIsOpenCompleteProfile(true)
                    : !userInCommunity
                    ? setIsOpenWrongCommunity(true)
                    : setIsOpenPrivacyType(true)
                }
              >
                <img src={toAbsoluteUrl('/media/design/elipsis_icon.svg')} alt='ellipsis' />
              </div>
              {isOpenPrivacyType && (
                <CommentPrivacyModal
                  isOpen={isOpenPrivacyType}
                  setIsOpen={setIsOpenPrivacyType}
                  setPrivacyType={setPrivacyType}
                  modalRef={privacyModalRef}
                  privacyType={privacyType}
                  addedClassName={''}
                />
              )}
            </div>

            {/* <img
             className='feed__postCommentBarImgIcon'
             src={toAbsoluteUrl('/media/design/post_add_image_icon.svg')}
           /> */}
            <div className='emojiPicker'>
              {showEmojiPicker && (
                <div ref={emojiPickerRef}>
                  <Picker
                    onEmojiClick={(event, emojiObject) => {
                      setChosenEmoji(emojiObject)

                      setNewComment(newComment + ' ' + emojiObject.emoji)
                      // setCommentIndex(index)
                      setShowEmojiPicker(false)
                    }}
                    pickerStyle={{
                      position: 'absolute',
                      zIndex: '999999999',
                      marginTop: '25px',
                    }}
                  />
                </div>
              )}
              <img
                alt='emoji-picker'
                className='feed__postCommentBarEmojiIcon'
                src={toAbsoluteUrl('/media/design/post_add_emoji_icon.svg')}
                onClick={() => {
                  !userBasicProfileComplete
                    ? setIsOpenCompleteProfile(true)
                    : !userInCommunity
                    ? setIsOpenWrongCommunity(true)
                    : setShowEmojiPicker(true)
                }}
              />
            </div>
          </div>
        </form>
        <small className='feed__postCommentEnter'>Press enter to comment.</small>
      </div>
    </div>
  )
}

export default CreateComment
