import React, {useState, useCallback, useRef} from 'react'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {useActivePetition} from '../../../../../contexts/ActivePetitionContext'
import {uploadToFirebase, validateFile} from '../../../../../helperfunctions/FirebaseUploadHelpers'
import {v4 as uuidv4} from 'uuid'

export const CoverPhotoUpload = (props) => {
  const uploadRef = useRef(null)
  const {activePetition, setActivePetition} = useActivePetition()
  const [previewImgURL, setPreviewImgURL] = useState(activePetition.cover_photo.source_url)
  const [isUploading, setIsUploading] = useState(false)

  const onImageClick = useCallback(() => {
    uploadRef.current.click()
  }, [uploadRef])

  const onFileUpload = async (e) => {
    try {
      setIsUploading(true)
      let file = await validateFile(e)
      const uploadPath = `${activePetition.filesFolderPath}/${uuidv4()}`
      const {downloadURL, path} = await uploadToFirebase(file, uploadPath)
      setPreviewImgURL(downloadURL)
      setActivePetition({
        ...activePetition,
        cover_photo: {
          source_url: downloadURL,
          source_path: path,
        },
      })
      setIsUploading(false)
    } catch (error) {
      console.error(error)
      setIsUploading(false)
    }
  }

  return (
    <div>
      <span className='createPetition__details__headerSubtxt'>
        Upload cover photo for your petition to stand out.
      </span>
      <div style={{marginTop: 20}}>
        {isUploading && (
          <div className='addImageModal_uploadingLoading'>
            <h1>Uploading...</h1>
          </div>
        )}

        {previewImgURL && !isUploading && (
          <div className='createPetition__prevImgWrap' onClick={onImageClick}>
            <img className='creatPetition_prevImg' src={previewImgURL} alt='preview' />
          </div>
        )}
        {!previewImgURL && !isUploading && (
          <div className=' createPetition__docUploadWrap' onClick={onImageClick}>
            <img src={toAbsoluteUrl('/media/design/file_upload_icon.svg')} alt='upload' />
            <h4 className='addImageModal__uploadTxt'>File Uploader</h4>
            <span className='addImageModal__uploadSubTxt'>
              Drag & Drop files here 3MB Max (jpg, png)
            </span>
          </div>
        )}
        <input type='file' onChange={onFileUpload} ref={uploadRef} style={{display: 'none'}} />
      </div>
    </div>
  )
}
