import FEED_SERVICE from '../services/feed/FeedService'

const likePost = async (postId, setLiked, setNumLikes, setLikedDisabled) => {
  setLikedDisabled(true)
  setLiked(true)
  setNumLikes((prevNumLikes) => prevNumLikes + 1)

  try {
    const response = await FEED_SERVICE.likePost(postId)
    if (response.code !== 200) {
      setLiked(false)
      setNumLikes((prevNumLikes) => prevNumLikes - 1)
    }
  } catch (error) {
    // Handle any error that occurs during the API call
    console.error(error)
    setLiked(false)
    setNumLikes((prevNumLikes) => prevNumLikes - 1)
  } finally {
    setLikedDisabled(false)
  }
}

const unlikePost = async (postId, setLiked, setNumLikes, setLikedDisabled) => {
  setLikedDisabled(true)
  setLiked(false)
  setNumLikes((prevNumLikes) => prevNumLikes - 1)

  try {
    const response = await FEED_SERVICE.unlikePost(postId)
    if (response.code !== 200) {
      setLiked(true)
      setNumLikes((prevNumLikes) => prevNumLikes + 1)
    }
  } catch (error) {
    // Handle any error that occurs during the API call
    console.error(error)
    setLiked(true)
    setNumLikes((prevNumLikes) => prevNumLikes + 1)
  } finally {
    setLikedDisabled(false)
  }
}

const parseFeedFilters = (feedFilter, feedLocation) => {
  let userInput = {
    world: '',
    country: '',
    region: '',
    city: '',
  }
  switch (feedFilter.communityLevel) {
    case 'All':
      userInput = {
        world: 'World',
        country: feedLocation.country,
        region: feedLocation.region,
        city: feedLocation.city,
      }
      break
    case 'International':
      userInput = {
        ...userInput,
        world: feedFilter.value,
      }
      break
    case 'Federal':
      userInput = {
        ...userInput,
        country: feedFilter.value,
      }
      break
    case 'Provincial':
      userInput = {
        ...userInput,
        region: feedFilter.value,
      }
      break
    case 'Municipal':
      userInput = {
        ...userInput,
        city: feedFilter.value,
      }
      break
    default:
      userInput = {
        world: 'World',
        country: feedLocation.country,
        region: feedLocation.region,
        city: feedLocation.city,
      }
      break
  }
  return userInput
}

export {parseFeedFilters, likePost, unlikePost}
