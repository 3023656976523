import {useHistory} from 'react-router-dom'
import {ProgressBar} from 'react-bootstrap-v5'

import {toAbsoluteUrl} from '../../../../_metronic/helpers'

import ReadMoreText from '../../../../app/modules/reusable/ReadMoreText'
import UserPicture from '../../../modules/reusable/UserPicture'

import dateFormat from 'dateformat'

const getPercentage = (partialValue, totalValue) => {
  try {
    return ((100 * partialValue) / totalValue).toFixed()
  } catch (error) {
    console.log(error)
  }
}

export const PetitionItem = (props) => {
  const history = useHistory()
  const {petition} = props
  return (
    <div className='petitions__post' style={{maxWidth: 600}}>
      <div>
        <div className='petitions__postHeader'>
          <span className='petitions__postHeaderLabel'>Petition to:</span>
          <span className='petitions__postHeaderValue'>{petition.receiver}</span>
        </div>

        <div className='petitions_postBody'>
          <div className='petitions_postBodyTxtWrap'>
            <h3 className='petitions_postBodyTxtTitle'>{petition.title}</h3>

            <div className='civik-flex-row'>
              <ReadMoreText
                text={petition.description}
                className='petitions_postBodyTxtParagraph petitions_bodyTxt'
              />
              {petition.cover_photo && petition.cover_photo.source_url !== '' && (
                <img
                  className='petitions_postBodyImg mobile-show'
                  src={petition.cover_photo.source_url}
                  style={petition.description ? {} : {marginLeft: 0}}
                  alt='petition cover'
                ></img>
              )}
            </div>
            <div
              className='civik-flex-row'
              style={{
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: 18,
              }}
            >
              <div className='petitions_postBodyOrganizedBy'>
                <UserPicture
                  isReply={false}
                  profile={petition.user}
                  styleClasses={{
                    profilePic: ' petitions__organizerImg ',
                    nameLetter: ' navbar__barNameLetter petitions__organizerImg',
                    letterWrap: 'feed__postCommentBarLetterWrap petitions__organizerImg',
                  }}
                  // privacyType={privacyType}
                />

                <div className='petitions_postBodyOrganizedByTxt'>
                  <small className='petitions_postBodyOrganizedByLabel'>Organized By</small>
                  <span className='petitions_postBodyOrganizedByValue'>
                    {petition.user.full_name}
                  </span>
                </div>
              </div>
              <div className='petitions__statusLabel'>
                <span className='petitions__statusLabelTxt'>Published</span>
              </div>{' '}
            </div>

            <div
              className='civik-flex-row'
              style={{justifyContent: 'space-between', marginRight: 100}}
            >
              <div className='petitions__dateCreatedWrap'>
                <span className='petitions__dateCreatedLabel'>Date Created</span>
                <span className='petitions__dateCreatedVal'>
                  {dateFormat(petition.draftCreatedAt, 'mmm dS, yyyy')}
                </span>
              </div>

              <div className='petitions__datePublishedWrap'>
                <span className='petitions__datePublishedLabel'>Date Published</span>
                <span className='petitions__datePublishedVal'>
                  {dateFormat(petition.createdAt, 'mmm dS, yyyy')}{' '}
                </span>
              </div>
            </div>
          </div>
          {petition.cover_photo && petition.cover_photo.source_url !== '' && (
            <img
              alt='petition cover'
              className='petitions_postBodyImg mobile-hide'
              src={petition.cover_photo.source_url}
              style={petition.description ? {} : {marginLeft: 0}}
            ></img>
          )}
        </div>
      </div>

      <div className='petitions_postFooter'>
        <img
          className='petitions_postFooterImg'
          src={toAbsoluteUrl('/media/design/petition_grey_icon.svg')}
        ></img>

        <div className='petitions_postFooterProgress'>
          <div className='petitions_postFooterProgressTxt'>
            <span className='petitions_postFooterProgressLabel'>
              <strong className='petitions_postFooterSigned'>{petition.signatures} </strong>
              of{' '}
              <strong className='petitions_postFooterSignedMax'>
                {petition.signatureGoal}{' '}
              </strong>{' '}
              Signed this Petition
            </span>

            <span className='petitions_postFooterProgressPerc'>
              {getPercentage(petition.signatures, petition.signatureGoal)}%
            </span>
          </div>
          <div
            className={
              petition.signatures >= petition.signatureGoal
                ? 'petitionDetails__progressBarComplete'
                : 'petitionDetails__progressBarInProgress'
            }
          >
            <ProgressBar
              className='petitionDetails__signBar__percentBar petitionItem__signBar'
              now={Number(getPercentage(petition.signatures, petition.signatureGoal))}
            />
          </div>
        </div>

        {!petition.userSignedPetition ? (
          <button
            className='petitions__postStatusBtn petitions__postSignBtn mobile-hide'
            onClick={() => {
              history.push(`/petitions/${petition.id}`)
            }}
          >
            Sign This Petition
          </button>
        ) : (
          <button
            className='petitions__postStatusBtn petitions__postSignedBtn mobile-hide'
            onClick={() => {
              history.push(`/petitions/${petition.id}`)
            }}
          >
            You Signed
          </button>
        )}
      </div>
      <div className='petitions__postStatusBtnWrap'>
        <div style={{width: '100%'}}></div>

        {!petition.userSignedPetition ? (
          <button
            className='petitions__postStatusBtn petitions__postSignBtn mobile-show'
            onClick={() => {
              history.push(`/petitions/${petition.id}`)
            }}
          >
            Sign This Petition
          </button>
        ) : (
          <button
            className='petitions__postStatusBtn petitions__postSignedBtn mobile-show'
            onClick={() => {
              history.push(`/petitions/${petition.id}`)
            }}
          >
            You Signed
          </button>
        )}
      </div>
    </div>
  )
}
export default PetitionItem
