import {useState, useEffect, useContext} from 'react'
import {useParams, useHistory} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'

import {PetitionSignBar} from './PetitionSignBar'
import {StartPetitionBanner} from './StartPetitionBanner'
import {StartPetitionBannerMobile} from './StartPetitionBannerMobile'
import {PetitionTabs} from './PetitionTabs'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {ProfileContext} from '../../../../contexts/profilecontext'
import MODAL_NAMES from '../../../../constants/ModalNames'
import {GlobalPrivateModalContext} from '../../../../contexts/GlobalModalContext'
import UserPicture from '../../../../modules/reusable/UserPicture'

import PetitionService from '../../../../services/petition/PetitionService'
import './PetitionSupporters.scss'

export function PetitionSupporters(props) {
  const history = useHistory()
  let {petition_id} = useParams() as {petition_id: string}

  const {
    petition,
    petitionTab,
    setPetitionTab,
    setShowElement,
    onClickSign,
    setActionPopupContent,
  } = props

  const PROFILE_CONTEXT = useContext(ProfileContext)
  const userBasicProfileComplete = PROFILE_CONTEXT.profile?.basicProfileComplete
  const {setIsOpenGlobalModal} = useContext(GlobalPrivateModalContext)
  const setIsOpenCompleteProfile = (o) => setIsOpenGlobalModal(MODAL_NAMES.COMPLETE_PROFILE, o)

  const [loading, setLoading] = useState(false)

  const [petitionSupporters, setPetitionSupporters] = useState([])

  const getPetitionSupporters = async () => {
    setLoading(true)
    let petitionSupportersData = [] as any
    petitionSupportersData = await PetitionService.getPetitionSupporters(petition_id)
    //if there are any supporters then set them in state
    if (petitionSupportersData) {
      setPetitionSupporters(petitionSupportersData)
    }

    setLoading(false)
  }

  useEffect(() => {
    getPetitionSupporters()
  }, [petition_id])

  return (
    <div>
      <div className='petitionDetails__header mobile-show'>
        <div className='petitionDetails__headerWrapM  '>
          <PetitionTabs view='mobile' currentTab={petitionTab} setCurrentTab={setPetitionTab} />

          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/design/modal-x-btn.svg')}
            className='civik-modal-x-btn'
            onClick={() => {
              history.push('/petitions')
            }}
          />
        </div>

        <div className='mobile-hide petitionDetailsM__targetBar '>
          {petition.signatures == petition.signatureGoal ? (
            <div>
              <img
                className='petitionDetails__megaphoneIcon'
                src={toAbsoluteUrl('/media/design/hands_clapping_icon.svg')}
              ></img>
              <span className='petitionDetails__socialsBar__txt '>
                This petition successfully reached the target
              </span>
            </div>
          ) : (
            <div>
              <img
                className='petitionDetails__megaphoneIcon'
                src={toAbsoluteUrl('/media/design/megaphone_icon.svg')}
              ></img>
              <span className='petitionDetails__socialsBar__txt '>
                This petition needs{' '}
                <span className='petitionDetails__socialsBar__signGoalTxt'>
                  {petition.signatureGoal - petition.signatures}
                </span>{' '}
                signs to reach target
              </span>
            </div>
          )}
        </div>
      </div>

      <div className='petitionDetails__floatingSocialBar mobile-show'>
        <div className='petitionDetails__floatingSocialBarContent'>
          <CopyToClipboard text={petition.shareURL}>
            <button
              className='petitionDetails__copyLinkBtn'
              onClick={() => {
                if (!userBasicProfileComplete) setIsOpenCompleteProfile(true)
                else {
                  setActionPopupContent('Copied to clipboard')
                  setShowElement(true)
                }
              }}
            >
              <img
                className='petitionDetails__copyLinkIcon'
                src={toAbsoluteUrl('/media/design/copy_link_icon.svg')}
              />
              Copy Link
            </button>
          </CopyToClipboard>

          {!loading && (
            <button
              style={{width: 191, marginTop: 0}}
              className={
                !petition.userSignedPetition
                  ? `petitionDetails__signBar__publishBtn mobile-show`
                  : 'petitionDetails__signBar__publishBtn petitionDetails__signBar__signedBtn mobile-show'
              }
              onClick={petition.userSignedPetition ? null : onClickSign}
              disabled={petition.userSignedPetition === true}
            >
              {petition.userSignedPetition ? 'You Signed' : 'Sign Petition'}
            </button>
          )}
          {loading && (
            <button
              className='petitionDetails__signBar__publishBtn'
              style={{width: 191, marginTop: 0}}
            >
              Please wait...
            </button>
          )}
        </div>
      </div>
      <PetitionTabs view='web' currentTab={petitionTab} setCurrentTab={setPetitionTab} />
      {/* START */}
      <div className='PetitionDetails'>
        <div className='petitionDetails__innerWrap'>
          {/* LIST OF SUPPORTERS */}
          <div className='petitionSupporters__list'>
            {petitionSupporters.map((supporter, index) => {
              return (
                <div className='petitionSupporters__profileWrap ' key={index}>
                  <UserPicture
                    profile={supporter}
                    styleClasses={{
                      profilePic: 'petitionSupporters__profileImg',
                      nameLetter: 'petitionSupporter__profileLetter',
                    }}
                  />

                  <div className='petitionSupporters__profileTxtWrap'>
                    <span className='petitionSupporters__name'>{supporter.full_name}</span>
                    <span className='petitionSupporters__location'>
                      {supporter.location_city}, {supporter.location_region}
                    </span>
                  </div>
                </div>
              )
            })}

            {petitionSupporters.length == 0 && <div>There are currently no supporters</div>}
            <div className='petitionDetails__descWrap mobile-hide'>
              <StartPetitionBanner />
            </div>
          </div>

          <PetitionSignBar data={petition} onClickSign={onClickSign} />

          <div className='petitionDetails__descWrap mobile-show'>
            <StartPetitionBannerMobile />
          </div>
        </div>
      </div>
    </div>
  )
}
