/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext} from 'react'
// import {IProfileDetails, profileDetailsInitValues as initialValues} from '../../../modules/profile/components/settings/SettingsModel'
import {useHistory} from 'react-router-dom'
import {Link} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

import {UserContext} from '../../contexts/usercontext'
import {ProfileContext} from '../../contexts/profilecontext'
import {AccountStepContext} from '../../contexts/accountStepContext'
import TextField from '../auth/reusable/TextField'

import POLITICIAN_SERIVCE from '../../services/politician/PoliticianService'
import AuthService from '../../services/auth/AuthService'

import DatePicker from '../reusable/DatePicker'

// const initialValues = {
//   birthDate: '',
//   personalWebsiteURL: '',
//   linkedinURL: '',
//   facebookURL: '',
//   twitterURL: '',
//   instagramURL: '',
//   bio: '',
// }

export function ProfileCouncilorSocialEdit(props) {
  const history = useHistory()
  const USER_CONTEXT = useContext(UserContext)
  const PROFILE_CONTEXT = useContext(ProfileContext)
  const STEP_CONTEXT = useContext(AccountStepContext)

  const [loading, setLoading] = useState(false)
  const [startDate, setStartDate] = useState(
    PROFILE_CONTEXT?.profile?.dob ? new Date(PROFILE_CONTEXT.profile.dob) : new Date()
  )

  const profileDetailsSchema = Yup.object().shape({
    bio: Yup.string().required('Bio is required'),
  })

  const [initialValues, setInitialValues] = useState({
    birthDate: PROFILE_CONTEXT.profile.dob ? PROFILE_CONTEXT.profile.dob : '',
    personalWebsiteURL: PROFILE_CONTEXT.profile.website ? PROFILE_CONTEXT.profile.website : '',
    linkedinURL: PROFILE_CONTEXT.profile.linkedin ? PROFILE_CONTEXT.profile.linkedin : '',
    facebookURL: PROFILE_CONTEXT.profile.facebook ? PROFILE_CONTEXT.profile.facebook : '',
    twitterURL: PROFILE_CONTEXT.profile.twitter ? PROFILE_CONTEXT.profile.twitter : '',
    instagramURL: PROFILE_CONTEXT.profile.instagram ? PROFILE_CONTEXT.profile.instagram : '',
    bio: PROFILE_CONTEXT.profile.bio ? PROFILE_CONTEXT.profile.bio : '',
  })

  const formik = useFormik({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(async () => {
        try {
          const userInput = {
            // onboardingStep: 3,
            dob: startDate,
            website: values.personalWebsiteURL,
            linkedin: values.linkedinURL,
            facebook: values.facebookURL,
            twitter: values.twitterURL,
            instagram: values.instagramURL,
            bio: values.bio,
          }

          console.log('userInput', userInput)
          await POLITICIAN_SERIVCE.updateProfile(userInput, PROFILE_CONTEXT.profile.__t)
          setLoading(false)

          //fetch user profile and set it into context
          await fetchProfile()
          props.onClose()
        } catch (error) {
          console.log(error)
        }
      }, 1000)
    },
  })

  const fetchProfile = async () => {
    try {
      let userProfile = await AuthService.getCurrentUser()
      PROFILE_CONTEXT.setProfile(userProfile)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <form
      className='civik-wh-card civik-profile-edit-right-bar'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signup_form'
    >
      {/* begin::Heading */}
      <div className=' mb-5 w-100'>
        <h1 className='text-dark mb-3' style={{fontSize: '24px'}}>
          About
        </h1>
      </div>
      {/* begin::Heading */}
      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* 1st ROW */}
      <div className='civik-flex-row-wrap '>
        {/* START */}
        <div className='w-100 mb-6 mr-40-responsive'>
          <label className=' col-form-label  fw-bold fs-6'>Birth Date</label>

          <DatePicker startDate={startDate} setStartDate={setStartDate} />
        </div>
        {/* END */}
        {/* START */}
        <div className='w-100 mb-6'>
          <TextField
            formik={formik}
            type='text'
            field='personalWebsiteURL'
            placeholder='Website URL'
            label='Personal Website URL'
            isRequired={false}
          />
        </div>
        {/* END */}
      </div>

      {/* 2nd ROW */}
      <div className='civik-flex-row-wrap '>
        {/* START */}
        <div className='w-100 mb-6 mr-40-responsive'>
          <div className='civik-flex-row'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/design/linkedin-grey.svg')}
              className='logo-default  mr-10'
            />
            <label className=' col-form-label  fw-bold fs-6 '>Linkedin URL</label>
          </div>

          <TextField
            formik={formik}
            type='text'
            field='linkedinURL'
            placeholder='Linkedin URL'
            isRequired={false}
            noLabel={true}
          />
        </div>
        {/* END */}
        {/* START */}
        <div className='w-100 mb-6'>
          <div className='civik-flex-row'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/design/facebook-grey.svg')}
              className='logo-default  mr-10'
            />
            <label className=' col-form-label  fw-bold fs-6'>Facebook URL</label>
          </div>

          <TextField
            formik={formik}
            type='text'
            field='facebookURL'
            placeholder='Facebook URL'
            isRequired={false}
            noLabel={true}
          />
        </div>
        {/* END */}
      </div>

      {/* 3rd ROW */}
      <div className='civik-flex-row-wrap '>
        {/* START */}
        <div className='w-100 mb-6 mr-40-responsive'>
          <div className='civik-flex-row'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/design/twitter-grey.svg')}
              className='logo-default  mr-10'
            />
            <label className=' col-form-label  fw-bold fs-6'>Twitter URL</label>
          </div>
          <TextField
            formik={formik}
            type='text'
            field='twitterURL'
            placeholder='Twitter URL'
            isRequired={false}
            noLabel={true}
          />
        </div>
        {/* END */}
        {/* START */}
        <div className='w-100 mb-6'>
          <div className='civik-flex-row'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/design/instagram-grey.svg')}
              className='logo-default  mr-10'
            />
            <label className=' col-form-label  fw-bold fs-6'>Instagram URL</label>
          </div>

          <TextField
            formik={formik}
            type='text'
            field='instagramURL'
            placeholder='Instagram URL'
            isRequired={false}
            noLabel={true}
          />
        </div>
        {/* END */}
      </div>

      {/* 4th ROW */}
      <div className='w-100 mb-6'>
        {/* <label className=' col-form-label  fw-bold fs-6'>Bio</label> */}
        <TextField
          formik={formik}
          type='text'
          field='bio'
          placeholder='Tell the people about yourself'
          label='Bio'
          isRequired={true}
          style={{height: 150}}
        />
      </div>
      <div className='civik-flex-row' style={{justifyContent: 'flex-end'}}>
        <button
          type='button'
          className='btn btn-light btn-active-light-primary mt-7 skip-btn civik-profile-edit-cancel-btn'
          onClick={() => {
            props.onClose()
          }}
        >
          <span className='indicator-label'>Cancel</span>
        </button>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100  mt-7'
          style={{maxWidth: 180}}
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Update</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </form>
  )
}
