import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

import ROUTE_PATHS from '../routing/RoutePaths'

export const onLogout = (PROFILE_CONTEXT, history) => {
  PROFILE_CONTEXT.setProfile({})
  firebase
    .auth()
    .signOut()
    .then(() => console.log('User signed out!'))
  history.push(ROUTE_PATHS.LOGIN)
  localStorage.clear()
}
