/* eslint-disable jsx-a11y/anchor-is-valid */
import {useContext} from 'react'
import * as Yup from 'yup'
import {Link, useHistory} from 'react-router-dom'
import {useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import AUTH_SERVICE from '../../../services/auth/AuthService'
import CONFIG from '../../../../config.json'
import {UserContext} from '../../../contexts/usercontext'
import {ProfileContext} from '../../../contexts/profilecontext'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Signup() {
  const history = useHistory()
  const USER_CONTEXT = useContext(UserContext)
  const {setProfile} = useContext(ProfileContext)

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setTimeout(async () => {
        const userInput = {
          email: values.email,
          password: values.password,
        }
      }, 1000)
    },
  })

  async function onSocialLogin(socialType) {
    try {
      console.log('socialType', socialType)

      let response = await AUTH_SERVICE.loginWithSocial(socialType, history, setProfile)

      // if successful
      if (response && response.code === 200) {
        console.log('succesfully logged in')
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className=''>
      <div
        className=''
        style={{
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',

          alignSelf: 'center',
        }}
      >
        <form
          className='form  civik-login-form civik-auth-form'
          onSubmit={formik.handleSubmit}
          noValidate
          id='kt_login_signin_form'
        >
          {/* begin::Heading */}
          <div className=' mb-10'>
            <h1 className='text-dark mb-3' style={{fontSize: '24px'}}>
              Join The community!
            </h1>
            <div className='fw-bold' style={{fontSize: 15, color: '#7E8299'}}>
              <span>Already a user?</span>{' '}
              <Link to='/auth/login' className='link-primary fw-bolder' style={{fontSize: 15}}>
                Login Here
              </Link>
            </div>
          </div>
          {/* begin::Heading */}

          {formik.status && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          )}
        </form>

        <div className='form'>
          <button
            className='civik-primary-btn  '
            onClick={async () => {
              await onSocialLogin('google')
            }}
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/design/civik-icon-google.svg')}
              className='civik-btn-icon'
            />
            Join with Google
          </button>

          <button
            className='civik-primary-btn'
            onClick={async () => {
              await onSocialLogin('facebook')
            }}
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/design/civik-icon-facebook.svg')}
              className='civik-btn-icon'
            />
            Join with Facebook
          </button>

          {/* <a
            className='civik-primary-btn'
            href={`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${CONFIG.LINKEDIN_CLIENT_ID}&redirect_uri=${CONFIG.LINKEDIN_REDIRECT_URI}&scope=r_liteprofile`}
            // onClick={async () => {
            //   await onSocialLogin('linkedin')
            //   // await onLinkedinLogin()
            // }}
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/design/civik-icon-linkedin.svg')}
              className='civik-btn-icon'
            />
            Join with Linkedin
          </a> */}

          <button
            className='civik-primary-btn'
            onClick={async () => {
              await onSocialLogin('twitter')
            }}
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/design/civik-icon-twitter.svg')}
              className='civik-btn-icon'
            />
            Join with Twitter
          </button>

          <button
            className='civik-primary-btn'
            onClick={async () => {
              await onSocialLogin('apple')
            }}
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/design/icon-Apple.svg')}
              className='civik-btn-icon'
            />
            Join with Apple
          </button>
        </div>

        <div className='civik-or-separator' style={{width: 270}}>
          {' '}
          <hr></hr>
          <span>OR</span>
          <hr></hr>{' '}
        </div>

        <div className='text-center'>
          <Link to='/auth/registration' className='civik-link'>
            Join with Email
          </Link>
        </div>
      </div>
    </div>
  )
}
