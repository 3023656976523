import {toAbsoluteUrl} from '../../../_metronic/helpers'
import React from 'react';

const ProfileSocials = (props) => {
  const {profile} = props
  return (
    <div className='profile__socials w-100'>
      <h5 className='mb-5'>Socials</h5>

      <div className='civik-flex-column w-100'>
        {profile.facebook && (
          <div className='civik-flex-row mb-5'>
            <img
              src={toAbsoluteUrl('/media/design/fb_grey_icon.svg')}
              alt=''
              style={{marginRight: 20}}
            />

            <span className=' fw-bold '>{profile.facebook}</span>
          </div>
        )}

        {profile.linkedin && (
          <div className='civik-flex-row mb-5'>
            <img
              src={toAbsoluteUrl('/media/design/linkedin_grey_icon.svg')}
              alt=''
              style={{marginRight: 20}}
            />

            <span className=' fw-bold '>{profile.linkedin}</span>
          </div>
        )}

        {profile.twitter && (
          <div className='civik-flex-row mb-5'>
            <img
              src={toAbsoluteUrl('/media/design/twitter_grey_icon.svg')}
              alt=''
              style={{marginRight: 20}}
            />

            <span className=' fw-bold '>{profile.twitter}</span>
          </div>
        )}
        {profile.instagram && (
          <div className='civik-flex-row mb-5'>
            <img
              src={toAbsoluteUrl('/media/design/insta_grey_icon.svg')}
              alt=''
              style={{marginRight: 20}}
            />

            <span className=' fw-bold '>{profile.instagram}</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default ProfileSocials;