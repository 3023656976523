import React, {FC, useState} from 'react'

import CompleteProfileModal from '../pages/modals/CompleteProfileModal'
import WrongCommunityModal from '../pages/modals/WrongCommunityModal'
import {GlobalPrivateModalContext} from '../contexts/GlobalModalContext'

const PrivateRoutesModals: FC = ({children}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [modalName, setModalName] = useState('')

  const setIsOpenGlobalModal = (name, open) => {
    setModalName(name)
    setIsOpen(open)
  }

  const renderModal = () => {
    switch (modalName) {
      case 'completeProfile':
        return <CompleteProfileModal isOpen={isOpen} setIsOpen={setIsOpen} />
      case 'wrongCommunity':
        return <WrongCommunityModal isOpen={isOpen} setIsOpen={setIsOpen} />
      default:
        return <div />
    }
  }

  return (
    <GlobalPrivateModalContext.Provider value={{setIsOpenGlobalModal}}>
      {children}
      {renderModal()}
    </GlobalPrivateModalContext.Provider>
  )
}
export default PrivateRoutesModals
