/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext, useCallback} from 'react'
// import {IProfileDetails, profileDetailsInitValues as initialValues} from '../../../modules/profile/components/settings/SettingsModel'
import {useHistory} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'

import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../_metronic/helpers'
import {PasswordMeterComponent} from '../../_metronic/assets/ts/components'

import Modal from 'react-modal'

import {UserContext} from '../contexts/usercontext'

import FeatherIcon from 'feather-icons-react'
import TextField from '../modules/auth/reusable/TextField'

import POLITICIAN_SERIVCE from '../services/politician/PoliticianService'
import {ProfileContext} from '../contexts/profilecontext'
import AUTH_SERVICE from '../services/auth/AuthService'

import VOTER_SERVICE from '../services/voter/VoterService'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

import {LeftSideBar} from './LeftSideBar'
import {Feed} from './Feed'
import {RightSideBar} from './RightSideBar'
import './Home.scss'

export function Home(props) {
  return (
    <div className='home__wrapper'>
      <div className='Home '>
        <LeftSideBar />

        <Feed />

        <RightSideBar />
      </div>
    </div>
  )
}
