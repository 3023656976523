/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext, useCallback, useRef} from 'react'

import {useFormik} from 'formik'
import * as Yup from 'yup'

import {ActivePetitionContext} from '../../../contexts/ActivePetitionContext'

import FeatherIcon from 'feather-icons-react'
import TextField from '../../../modules/auth/reusable/TextField'

import {topicOptions} from '../../../constants'
import {CreatePetitionBottomButtons} from './BottomButtons'
import {Organizer} from './components/step1/Organizer'

const petitionValidationStep1Schema = Yup.object().shape({
  title: Yup.string().required('Petition title is required'),
  topic: Yup.string().required('Petition topic is required'),
})

export function Step1(props) {
  const {loading, setLoading} = props
  const {activePetition, setActivePetition} = useContext(ActivePetitionContext)
  const [openTopicsDropdown, setOpenTopicsDropdown] = useState(false)
  const topicOpRef = useRef(null)

  const initialValues = {
    title: activePetition.title,
    topic: activePetition.topic,
  }

  const formik = useFormik({
    initialValues,
    validationSchema: petitionValidationStep1Schema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      try {
        const data = {
          step: 2,
        }
        setActivePetition({...activePetition, ...data})
        // console.log('submit 1', activePetition)
      } catch (error) {
        console.error(error)
      }
    },
  })

  useEffect(() => {
    setActivePetition({...activePetition, title: formik.values.title})
  }, [formik.values.title])
  useEffect(() => {
    setActivePetition({...activePetition, topic: formik.values.topic})
  }, [formik.values.topic])

  return (
    <form
      className='civik-wh-card civik-profile-edit-right-bar profileEdit__rightEditForm'
      onSubmit={formik.handleSubmit}
      noValidate
    >
      <h1 className='mb-5'>Petition Main Info</h1>

      <div>
        <div className='civik-flex-row-wrap' style={{justifyContent: 'space-between'}}>
          <div style={{width: '100%'}}>
            <div className='mb-6'>
              <TextField
                formik={formik}
                type='text'
                field='title'
                placeholder='Petition Title'
                label='Petition Title'
                isRequired={true}
              />
            </div>
            <div className=' mb-6'>
              <label className='form-label fs-6 fw-bold text-dark required'>
                Select Petition Topic
              </label>

              <div
                className='postModal__selectTopicDropdown'
                onClick={() => {
                  setOpenTopicsDropdown(true)
                }}
              >
                <span className={formik.values.topic == '' ? '' : 'createPostModal_optionTxt'}>
                  {formik.values.topic === '' ? 'Select a topic' : formik.values.topic}
                </span>
                <FeatherIcon icon={'chevron-down'} style={{marginLeft: 5}} width='20' color='' />
              </div>

              {openTopicsDropdown && (
                <div
                  className='navbar__dropdown dropdown__topicOptions createPetition__topicDropdown'
                  ref={topicOpRef}
                >
                  <div className='civik-flex-column dropdown__txtWrap'>
                    <div className='civik-flex-column '>
                      {topicOptions.map((topicOpt, index) => {
                        return (
                          <div
                            key={index}
                            className='dropdown__iconOptionWrap '
                            onClick={() => {
                              formik.setFieldValue('topic', topicOpt.value)
                              setOpenTopicsDropdown(false)
                            }}
                          >
                            <span className='createPostModal_optionTxt'>{topicOpt.label}</span>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <Organizer />
          </div>
        </div>
      </div>
      <CreatePetitionBottomButtons loading={loading} />
    </form>
  )
}
