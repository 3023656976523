import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {useEffect, useState} from 'react'
import AdminService from '../../services/admin/AdminService'

const DownloadBanner = ({downloadURL}) => (
  <div
    className='profile__councilDisclaimer profile__disclaimerPrimary '
    style={{justifyContent: 'space-between'}}
  >
    <div className='disclaimerInnterContainer'>
      <img
        className='profile__warningIcon'
        src={toAbsoluteUrl('/media/design/file_upload_icon.svg')}
        alt='download'
      />
      <div className='profile__councilDisclaimerTxtWrap'>
        <span className='profile__councilDisclaimerTxtTitle'>
          Download the latest voter list (csv file)
        </span>
        <span className='profile__councilDisclaimerTxtSubtitle profile__councilDisclaimerTxtPrimary'>
          This file can be uploaded to assign voter pins once registered in TIVI
        </span>
      </div>
    </div>
    <div style={{marginRight: 20}}>
      <a className='civik-btn-outline-primary' href={downloadURL} download={`voterlist-${Date()}`}>
        Download File
      </a>
    </div>
  </div>
)

const VoterListDownload = (props) => {
  const [downloadURL, setDownloadURL] = useState(null)

  useEffect(() => {
    const getDowloadUrl = async () => {
      let res = await AdminService.getRegisteredVoterList()
      if (res.success) {
        // @ts-ignore
        const href = URL.createObjectURL(res.data)
        setDownloadURL(href)
      }
    }
    getDowloadUrl()
  }, [])
  return (
    <div className='w-100 civik-wh-card'>
      <DownloadBanner downloadURL={downloadURL} />
    </div>
  )
}

export default VoterListDownload
