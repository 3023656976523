import {useContext} from 'react'
import {ProfileContext} from '../../../../../contexts/profilecontext'
import UserPicture from '../../../../../modules/reusable/UserPicture'

export const Organizer = () => {
  const {profile} = useContext(ProfileContext)

  return (
    <div className='createPetitions__organizerContainer'>
      <div className='createPetitions__organizerWrap'>
        <label className='form-label fs-6 fw-bold text-dark '>Organizer:</label>
        <div className='createPetitions__organizerContentWrap'>
          <UserPicture
            profile={profile}
            styleClasses={{
              profilePic: 'feed__postCommentBarProfileImg createPetitions__organizerImg',
              nameLetter: ' navbar__barNameLetter createPetitions__organizerImg',
              letterWrap: 'feed__postCommentBarLetterWrap createPetitions__organizerImg',
            }}
          />

          <div className='createPetitions__organizerTxtWrap'>
            <span className='createPetitions__organizerTxtLabel'>Organized By</span>
            <span className='createPetitions__organizerTxtVal'>{profile.full_name}</span>
          </div>
        </div>
      </div>
      {/* organizer address */}
      <div className=' createPetitions__organizerAddWrap'>
        <label className='form-label fs-6 fw-bold text-dark '>Organizer Address:</label>
        <div className='createPetitions__organizerContentWrap'>
          {/* organizer img */}

          {/* txt wrap */}
          <div className='createPetitions__organizerAddTxtWrap'>
            <span className='createPetitions__organizerAddTxtVal'>{profile.address}</span>
          </div>
        </div>
      </div>
    </div>
  )
}
