import React from 'react'

import './ProfileBasicInfo.scss'

const ProfileBasicInfo = (props) => {
  const {profile, isCouncilorType, publicProfile} = props
  return (
    <>
      {/* If not a councilor */}
      {isCouncilorType() == null ? (
        <div className='civik-wh-card civik-profile-about-container w-100'>
          <h5 className='mb-5'>Basic Info</h5>

          <div className='civik-flex-column'>
            <div className='profileInfo__row'>
              {profile.full_name ? (
                <span className='civik-dark-grey fw-bold profileInfo__label'>Name</span>
              ) : !publicProfile ? (
                <span className='civik-dark-grey fw-bold profileInfo__label'>
                  please edit profile to add name
                </span>
              ) : null}

              {profile.first_name && profile.last_name ? (
                <span className=' fw-bold '>{profile.full_name}</span>
              ) : !publicProfile ? (
                <span className=' fw-bold '>please edit profile to add name</span>
              ) : null}
            </div>
            {!publicProfile &&
              profile.location_city !== undefined &&
              profile.address &&
              profile.postal_code !== undefined && (
                <>
                  <div className='profileInfo__row'>
                    <div className='profileInfo__label'>
                      <span className='civik-dark-grey fw-bold '>Address</span>
                    </div>
                    <span className=' fw-bold'>{profile.address}</span>
                  </div>

                  <div className='profileInfo__row'>
                    <div className='profileInfo__label'>
                      <span className='civik-dark-grey fw-bold '>Location</span>
                    </div>
                    <span className='fw-bold '>
                      {' '}
                      {profile.location_city}, {profile.location_region}
                    </span>
                  </div>

                  <div className='profileInfo__row'>
                    <div className='profileInfo__label'>
                      <span className='civik-dark-grey fw-bold '>Postal Code</span>
                    </div>
                    <span className='fw-bold '>{profile.postal_code}</span>
                  </div>

                  {!publicProfile && profile.phone && (
                    <div className='profileInfo__row'>
                      <span className='civik-dark-grey fw-bold profileInfo__label'>Phone</span>
                      <span className=' fw-bold '>{profile.phone}</span>
                    </div>
                  )}
                </>
              )}
          </div>
          {/*          
          <div className='civik-flex-row'>
         
            <div className='civik-flex-column'>
              {profile.full_name ? (
                <span className='civik-dark-grey fw-bold mb-5 mr-10'>Name</span>
              ) : !publicProfile ? (
                <span className='civik-dark-grey fw-bold mr-5 mr-10'>
                  please edit profile to add name
                </span>
              ) : null}
              {!publicProfile &&
                profile.location_city !== undefined &&
                profile.address &&
                profile.postal_code !== undefined && (
                  <div className='civik-flex-column'>
                    <span className='civik-dark-grey fw-bold mb-5' style={{marginRight: 50}}>
                      Address
                    </span>

                    <span className='civik-dark-grey fw-bold mb-5' style={{marginRight: 50}}>
                      Location
                    </span>

                    <span className='civik-dark-grey fw-bold mb-5' style={{marginRight: 50}}>
                      Postal Code
                    </span>
                  </div>
                )}
              {publicProfile && profile.location_city !== undefined && (
                <div className='civik-flex-column'>
                  <span className='civik-dark-grey fw-bold mb-5' style={{marginRight: 50}}>
                    Location
                  </span>
                </div>
              )}

              {!publicProfile && profile.phone && (
                <span className='civik-dark-grey fw-bold mb-5' style={{marginRight: 50}}>
                  Phone
                </span>
              )}
            </div>
 //------
            <div className='civik-flex-column'>
              {profile.first_name && profile.last_name ? (
                <span className=' fw-bold mb-5'>{profile.full_name}</span>
              ) : !publicProfile ? (
                <span className=' fw-bold mb-5'>please edit profile to add name</span>
              ) : null}
              {!publicProfile &&
                profile.location_city !== undefined &&
                profile.address &&
                profile.postal_code !== undefined && (
                  <div className='civik-flex-column'>
                    <span className=' fw-bold mb-5'>{profile.address}</span>

                    <span className=' fw-bold mb-5'>
                      {profile.location_city}, {profile.location_region}
                    </span>

                    <span className=' fw-bold mb-5'>{profile.postal_code}</span>
                  </div>
                )}
              {publicProfile && profile.location_city !== undefined && (
                <div className='civik-flex-column'>
                  <span className=' fw-bold mb-5'>
                    {profile.location_city}, {profile.location_region}
                  </span>
                </div>
              )}
              {!publicProfile && profile.phone && (
                <span className=' fw-bold mb-5'>{profile.phone}</span>
              )}
            </div> 
          </div>*/}
        </div>
      ) : (
        // If a councilor
        <div className='civik-wh-card civik-profile-about-container w-100'>
          <h5 className='mb-5'>Basic Info</h5>
          <div className='civik-flex-row'>
            <div className='civik-flex-column'>
              {profile.full_name ? (
                <span className='civik-dark-grey fw-bold mb-5 mr-10'>Name</span>
              ) : !publicProfile ? (
                <span className='civik-dark-grey fw-bold mr-5 mr-10'>
                  please edit profile to add name
                </span>
              ) : null}
              <span className='civik-dark-grey fw-bold mb-5' style={{marginRight: 50}}>
                Ward#:
              </span>
            </div>
            <div className='civik-flex-column'>
              {profile.first_name && profile.last_name ? (
                <span className=' fw-bold mb-5'>{profile.full_name}</span>
              ) : (
                <span className=' fw-bold mb-5'>please edit profile to add name</span>
              )}

              <span className=' fw-bold mb-5'>
                {profile.represented_district_name}, #{profile.represented_district_number}
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ProfileBasicInfo
