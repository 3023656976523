/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext, useCallback, useRef} from 'react'
// import {IProfileDetails, profileDetailsInitValues as initialValues} from '../../../modules/profile/components/settings/SettingsModel'
import {useHistory} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'

import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PasswordMeterComponent} from '../../../_metronic/assets/ts/components'

import Modal from 'react-modal'
import ReactPlayer from 'react-player'

import dateFormat, {masks} from 'dateformat'

import {UserContext} from '../../contexts/usercontext'

import FeatherIcon from 'feather-icons-react'
import TextField from '../../modules/auth/reusable/TextField'

import POLITICIAN_SERIVCE from '../../services/politician/PoliticianService'
import {ProfileContext} from '../../contexts/profilecontext'
import AUTH_SERVICE from '../../services/auth/AuthService'

import VOTER_SERVICE from '../../services/voter/VoterService'
import FEED_SERVICE from '../../services/feed/FeedService'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

import {Comments} from '../Comments'
// import microtip from 'microtip/microtip.css'
// import microtip from 'microtip/microtip.css'

import '../Feed.scss'
import './MyPosts.scss'

import {CreatePostModalWrapper} from '../modals/CreatePostModalWrapper'

import POST_TYPE_COLORS from '../../constants/PostTypeColors'

import {CopyToClipboard} from 'react-copy-to-clipboard'
import PostItem from '../../modules/feed/posts/PostItem'
import LETTER_COLORS from '../../constants/LetterColors'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
}

export function MyPosts(props) {
  const {profileId} = props
  // const PROFILE_CONTEXT = useContext(ProfileContext)
  const [feedLoading, setFeedLoading] = useState(false)
  const [showElement, setShowElement] = React.useState(false)
  const [feedPosts, setFeedPosts] = useState([])

  const getFeed = async () => {
    try {
      // fetch feed
      setFeedLoading(true)

      let response = await FEED_SERVICE.getMyPosts(profileId)

      console.log('my posts', response)

      setFeedPosts(response)

      setFeedLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    setTimeout(function () {
      setShowElement(false)
    }, 5000)
  }, [showElement])

  useEffect(() => {
    getFeed()
  }, [profileId])

  return (
    <div className='feed__postsContainer'>
      {/* POST */}

      {feedLoading ? (
        <div className='feed_loadingContainer'>
          <img className='feed__loader' src={toAbsoluteUrl('/media/design/feed_loader.svg')} />
        </div>
      ) : (
        <div>
          {feedPosts &&
            feedPosts.map((feed, index) => {
              return (
                <PostItem
                  key={feed._id}
                  feed={feed}
                  index={index}
                  profilePage={true}
                  isSinglePost={false}
                />
              )
            })}
        </div>
      )}
    </div>
  )
}
