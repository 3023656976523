import {toAbsoluteUrl} from '../../../../_metronic/helpers'
export function AuthSideBanner() {
  return (
    <div className='civik-side-banner hide-mobile'>
      <div className='civik-side-banner-content-wrapper'>
        <img
          alt='civik side banner logo'
          src={toAbsoluteUrl('/media/design/logo-verical-main.svg')}
          className='civik-side-banner-logo'
        />

        <div className='civik-side-banner-txt-wrapper'>
          <img
            alt='civik side banner txt img'
            src={toAbsoluteUrl('/media/design/side-banner-txt.svg')}
            className='civik-side-banner-txt-svg'
          />
        </div>
      </div>
    </div>
  )
}
