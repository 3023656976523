/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext, useCallback} from 'react'
import {AdminSidebar} from './reusable/AdminSidebar'
import {useFormik} from 'formik'
import './VoterAdminDashboard.scss'
import TextField from '../../../app/modules/auth/reusable/TextField'
import * as Yup from 'yup'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {AdminTopbar} from './reusable/AdminTopbar'

export function VoterAdminDashboard(props) {
  const [loading, setLoading] = useState(false)

  return (
    <>
      <AdminSidebar />
      <AdminTopbar />
      <div className='VoterAdminDashboard'>
        {/* <AdminSidebar />
      <AdminTopbar /> */}
        {/* DASHBOARD CONTENT */}
        <div className='adminDash__content'>
          <div className='adminDash__statCard adminDash__pendApproveCard'>
            <img
              className='adminDash__statCardIcon'
              src={toAbsoluteUrl('/media/design/admin_pending_approve_icon.svg')}
              alt='pending approve icon'
            />
            <span className='adminDash__statCardValue '>125000</span>
            <span className='adminDash__statCardLabel'>Pending Approval</span>
          </div>

          <div className='adminDash__statCard adminDash__regVotersCard'>
            <img
              className='adminDash__statCardIcon'
              src={toAbsoluteUrl('/media/design/admin_reg_voters_icon.svg')}
              alt='pending approve icon'
            />
            <span className='adminDash__statCardValue '>750556</span>
            <span className='adminDash__statCardLabel'>Registerd Voters</span>
          </div>

          <div className='adminDash__statCard adminDash__declineVotersCard'>
            <img
              className='adminDash__statCardIcon'
              src={toAbsoluteUrl('/media/design/admin_decline_icon.svg')}
              alt='pending approve icon'
            />
            <span className='adminDash__statCardValue '>2300</span>
            <span className='adminDash__statCardLabel'>Declined</span>
          </div>

          <div className='adminDash__statCard adminDash__successVotersCard'>
            <img
              className='adminDash__statCardIcon'
              src={toAbsoluteUrl('/media/design/admin_success_icon.svg')}
              alt='pending approve icon'
            />
            <span className='adminDash__statCardValue'>435689</span>
            <span className='adminDash__statCardLabel'>Voted Successfuly</span>
          </div>
        </div>
      </div>
    </>
  )
}
