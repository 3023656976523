/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext} from 'react'
import {useHistory} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import * as auth from '../redux/AuthRedux'
import {register} from '../redux/AuthCRUD'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'

import AUTH_SERVICE from '../../../services/auth/AuthService'
import CONFIG from '../../../../config.json'

import {UserContext} from '../../../contexts/usercontext'
import {ProfileContext} from '../../../contexts/profilecontext'

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  changepassword: '',
  acceptTerms: false,
}

const registrationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  changepassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    }),
  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})

export function Registration() {
  const history = useHistory()
  const USER_CONTEXT = useContext(UserContext)
  const {setProfile} = useContext(ProfileContext)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setError(null)
      setTimeout(async () => {
        const userInput = {
          email: values.email,
          password: values.password,
        }

        let response = await AUTH_SERVICE.signupWithEmail(userInput)

        // if successful
        if (response && response.code === 200) {
          console.log('succesfully logged in')
        }

        // error
        if (response && response.code === 400) {
          setLoading(false)
          setSubmitting(false)
          setStatus(response.message)
        }

        setLoading(false)
      }, 1000)
    },
  })

  async function onLinkedinLogin() {
    try {
      let linkedinWindow = window.open(
        `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${CONFIG.LINKEDIN_CLIENT_ID}&redirect_uri=${CONFIG.LINKEDIN_REDIRECT_URI}&scope=r_liteprofile,`,
        '',
        'width=320,height=320'
      )

      console.log(linkedinWindow)
    } catch (error) {
      console.log(error)
    }
  }

  async function onSocialLogin(socialType) {
    try {
      setError(null)
      console.log('socialType', socialType)

      let response = await AUTH_SERVICE.loginWithSocial(socialType, history, setProfile)

      // if successful
      if (response && response.code === 200) {
        console.log('succesfully logged in')
      } else if (response?.code === 400) {
        setError(response.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <form
      className='form w-100 civik-login-email-form civik-auth-form'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signup_form'
    >
      {/* begin::Heading */}
      <div className=' mb-10'>
        <h1 className='text-dark mb-3' style={{fontSize: '24px'}}>
          Join The community!
        </h1>
        <div className='fw-bold' style={{fontSize: 15, color: '#7E8299'}}>
          <span>Already have an account?</span>{' '}
          <Link to='/auth/login' className='link-primary fw-bolder' style={{fontSize: 15}}>
            Login Here
          </Link>
        </div>
      </div>
      {/* begin::Heading */}
      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}
      <div className='fv-row mb-5'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          //   placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      <div className='mb-10 fv-row' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Password</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='Password'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
          {/* end::Meter */}
        </div>
        {/* <div className='text-muted'>
            Use 8 or more characters with a mix of letters, numbers & symbols.
          </div> */}
      </div>
      {/* end::Form group */}
      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-10' style={{marginTop: -10}}>
        <label className='form-label fw-bolder text-dark fs-6 '>Confirm Password</label>
        <input
          type='password'
          placeholder='Password confirmation'
          autoComplete='off'
          {...formik.getFieldProps('changepassword')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
            },
            {
              'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
            }
          )}
        />
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}
      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='form-check form-check-custom form-check-solid'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <label
            className='form-check-label fw-bold text-gray-700 fs-6'
            htmlFor='kt_login_toc_agree'
          >
            I Agree the{' '}
            <Link
              to='/terms'
              className='ms-1 link-primary'
              target='_blank'
              rel='noopener noreferrer'
            >
              terms and conditions
            </Link>
            .
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.acceptTerms}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* end::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5 '
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          {!loading && <span className='indicator-label'>Sign Up</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>{' '}
      <div className='civik-or-separator'>
        {' '}
        <hr></hr>
        <span>OR</span>
        <hr></hr>{' '}
      </div>
      <div className='civik-social-login-wrapper'>
        <img
          onClick={async () => {
            await onSocialLogin('google')
          }}
          alt='Logo'
          src={toAbsoluteUrl('/media/design/civik-icon-google.svg')}
          className='civik-social-btn'
        />

        <img
          onClick={async () => {
            await onSocialLogin('facebook')
          }}
          alt='Logo'
          src={toAbsoluteUrl('/media/design/civik-icon-facebook.svg')}
          className='civik-social-btn'
        />

        {/* <img
          onClick={async () => {
            await onLinkedinLogin()
          }}
          alt='Logo'
          src={toAbsoluteUrl('/media/design/civik-icon-linkedin.svg')}
          className='civik-social-btn'
        /> */}

        <img
          onClick={async () => {
            await onSocialLogin('twitter')
          }}
          className='civik-social-btn'
          alt='Logo'
          src={toAbsoluteUrl('/media/design/civik-icon-twitter.svg')}
        />

        <img
          onClick={async () => {
            await onSocialLogin('apple')
          }}
          className='civik-social-btn'
          alt='Logo'
          src={toAbsoluteUrl('/media/design/icon-Apple.svg')}
        />
      </div>
      {error && (
        <div className='mb-lg-15 alert alert-danger mb-10 mt-10' style={{maxWidth: 350}}>
          <div className='alert-text font-weight-bold'>{error}</div>
        </div>
      )}
    </form>
  )
}
