/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext, useCallback} from 'react'

import Modal from 'react-modal'

import './ModalWrapper.scss'
import '../VoterAdminModals.scss'
import {ModalHeader} from '../modal_header/ModalHeader'
import {ModalFooter} from '../modal_footer/ModalFooter'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {render} from '@testing-library/react'

import {useVoterStyles} from '../../../../../contexts/VoterStylesContext'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
    padding: 0,
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: 3,
  },
}

export function ModalWrapper(props) {
  const {modalView, headerLabel} = props

  const {customBtnClass, customTxtClass} = useVoterStyles()

  useEffect(() => {
    Modal.setAppElement('#Voter')
  }, [])

  const UpdateAddressList = (
    <div className='voterAdminModals__thankYou'>
      <img
        className='voterAdminModals__thankYou__img'
        src={toAbsoluteUrl('/media/design/thumbs_up_green_icon.svg')}
      />

      <span className='voterAdminModals__thankYou__label'>Thank You!</span>
      <span className='voterAdminModals__thankYou__subtitle'>
        Address list database has been updated successfuly
      </span>
    </div>
  )

  const SecurityDisclaimer = (
    <div className='voterRegModals__securityDisclaimer'>
      <svg
        width='32'
        height='32'
        viewBox='0 0 32 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className='voterRegModals__securityDisc__icon'
      >
        <path
          d='M5 14.3375V7C5 6.73478 5.10536 6.48043 5.29289 6.29289C5.48043 6.10536 5.73478 6 6 6H26C26.2652 6 26.5196 6.10536 26.7071 6.29289C26.8946 6.48043 27 6.73478 27 7V14.3375C27 24.8375 18.0875 28.3125 16.3125 28.9C16.1109 28.9747 15.8891 28.9747 15.6875 28.9C13.9125 28.3125 5 24.8375 5 14.3375Z'
          stroke={customTxtClass ? '#CE0082' : '#3699FF'} //temporary FIX
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M21.5 13L14.1625 20L10.5 16.5'
          stroke={customTxtClass ? '#CE0082' : '#3699FF'} //temporary FIX
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>

      <span className='voterRegModals__securityDisc__txt'>
        The security of the voter registration and overall voting process under the controlled by
        Elections Nova Scotia Board.
      </span>
    </div>
  )

  const ThankYou = (
    <div className='voterRegModals__thankYou'>
      <svg
        width='32'
        height='32'
        viewBox='0 0 32 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className='voterRegModals__securityDisc__icon'
      >
        <path
          d='M5 14.3375V7C5 6.73478 5.10536 6.48043 5.29289 6.29289C5.48043 6.10536 5.73478 6 6 6H26C26.2652 6 26.5196 6.10536 26.7071 6.29289C26.8946 6.48043 27 6.73478 27 7V14.3375C27 24.8375 18.0875 28.3125 16.3125 28.9C16.1109 28.9747 15.8891 28.9747 15.6875 28.9C13.9125 28.3125 5 24.8375 5 14.3375Z'
          stroke={customTxtClass ? '#CE0082' : '#3699FF'} //temporary FIX
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M21.5 13L14.1625 20L10.5 16.5'
          stroke={customTxtClass ? '#CE0082' : '#3699FF'} //temporary FIX
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>
      <span className='voterRegModals__thankYou__label'>Thank You</span>
      <span className='voterRegModals__securityDisc__txt'>
        Your registration has been submitted successfuly. we will send a confirmation email soon
      </span>
    </div>
  )

  const PhotoID = (
    <div className='voterRegModals__photoID'>
      <div className='voterRegModals__photoID__imgWrap'>
        <div className='voterRegModals__photoID__driversWrap'>
          <img
            className='voterRegModals__photoID__drivers'
            src={toAbsoluteUrl('/media/design/sample_drivers_license.png')}
          />
          <span className='voterRegModals__photoID__imgLabel'>Driver Licence</span>
          <hr></hr>
        </div>
        <div className='voterRegModals__photoID__passportWrap'>
          <img
            className='voterRegModals__photoID__passport'
            src={toAbsoluteUrl('/media/design/sample_passport.png')}
          />
          <span className='voterRegModals__photoID__imgLabel'>Canadian Passport</span>
          <hr></hr>
        </div>
      </div>
      <div className='voterRegModals__photoID__pointsWrap'>
        <li className='voterRegModals__photoID__pointTxt'>Your driver's licence</li>
        <li className='voterRegModals__photoID__pointTxt'>Your Canadian passport</li>
        <li className='voterRegModals__photoID__pointTxt'>
          Any other card issued by a Canadian government (federal, provincial/territorial or local)
          with your photo, name and current address
        </li>
      </div>
    </div>
  )

  const AddressDisclaimer = (
    <div className='voterRegModals__addressDisclaimer'>
      <div className='voterRegModals__addressDisclaimer__titleWrap'>
        <span className='voterRegModals__addressDisclaimer__titleTxt'>
          If your Address is not existed in our list.
        </span>
        <span className='voterRegModals__addressDisclaimer__subTitleTxt'>
          Please call us to help you registering.
        </span>
      </div>

      <div className='voterRegModals__addressDisclaimer__numbersWrap'>
        <span
          className={
            customTxtClass
              ? 'voterRegModals__addressDisclaimer__number1Txt' + ' ' + customTxtClass
              : 'voterRegModals__addressDisclaimer__number1Txt'
          }
        >
          902-424-8584
        </span>
        <span
          className={
            customTxtClass
              ? 'voterRegModals__addressDisclaimer__number2Txt' + ' ' + customTxtClass
              : 'voterRegModals__addressDisclaimer__number2Txt'
          }
        >
          1-800-565-1504
        </span>
      </div>

      <span className='voterRegModals__addressDisclaimer__contactUsTxt'>or Contact us</span>

      <button
        className={
          customBtnClass
            ? 'voterRegModals__addressDisclaimer__helpBtn' + ' ' + customBtnClass
            : 'voterRegModals__addressDisclaimer__helpBtn'
        }
      >
        Online Help
      </button>
    </div>
  )

  const renderView = (modalView) => {
    if (modalView == 'UpdateAddressList') {
      return UpdateAddressList
    }
    if (modalView == 'SecurityDisclaimer') {
      return SecurityDisclaimer
    }
    if (modalView == 'ThankYou') {
      return ThankYou
    }
    if (modalView == 'PhotoID') {
      return PhotoID
    }
    if (modalView == 'AddressDisclaimer') {
      return AddressDisclaimer
    }
  }

  return (
    <Modal
      ariaHideApp={false}
      isOpen={props.isOpen}
      onAfterOpen={props.onAfterOpen}
      onRequestClose={props.onRequestClose}
      style={customStyles}
    >
      <div className='ModalWrapper' style={{flexGrow: 1}}>
        <ModalHeader onRequestClose={props.onRequestClose} headerLabel={headerLabel} />

        {/* BODY CONTENT */}
        {renderView(modalView)}

        <ModalFooter onRequestClose={props.onRequestClose} />
      </div>
    </Modal>
  )
}
