export const topicOptions = [
  {label: 'General', value: 'General'},
  {label: 'Public Health', value: 'Public Health'},
  {label: 'Paramedic Services', value: 'Paramedic Services'},
  {label: 'Roads', value: 'Roads'},
  {label: 'Municipal', value: 'Municipal'},
  {label: 'Transportation', value: 'Transportation'},
  {label: 'Waste', value: 'Waste'},
  {label: 'Fire Service', value: 'Fire Service'},
  {label: 'Economic & Development', value: 'Economic & Development'},
  {label: 'Water Division', value: 'Water Division'},
  {label: 'Municipal Licensing', value: 'Municipal Licensing'},
  {label: 'Parks, Foresty & Recreation', value: 'Parks, Foresty & Recreation'},
  {label: 'City Events', value: 'City Events'},
  {label: 'Economic & Development', value: 'Economic & Development'},
]
export default topicOptions
