import {toAbsoluteUrl} from '../../../_metronic/helpers'
import React, {useEffect, useState} from 'react'
import FileUploadModal, {STATUS_TYPES} from '../../pages/modals/FileUploadModal'
import AdminService from '../../services/admin/AdminService'
import VoterListHistory from './VoterListHistory'

const UploadBanner = ({onClickButton}) => (
  <div
    className='profile__councilDisclaimer profile__disclaimerPrimary '
    style={{justifyContent: 'space-between'}}
  >
    <div className='disclaimerInnterContainer'>
      <img
        className='profile__warningIcon'
        src={toAbsoluteUrl('/media/design/file_upload_icon.svg')}
        alt='upload'
      />
      <div className='profile__councilDisclaimerTxtWrap'>
        <span className='profile__councilDisclaimerTxtTitle'>Upload new voter list (csv file)</span>
        <span className='profile__councilDisclaimerTxtSubtitle profile__councilDisclaimerTxtPrimary'>
          Download the file with voter pins when the process is complete.
        </span>
      </div>
    </div>
    <div style={{marginRight: 20}}>
      <button className='civik-btn-outline-primary' onClick={onClickButton}>
        Upload File
      </button>
    </div>
  </div>
)

const VoterListAssignPins = (props) => {
  const {uploadHistory, getUploadHistory} = props
  const history = uploadHistory ? uploadHistory : []

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false)
  const [status, setStatus] = useState(STATUS_TYPES.upload)
  const [downloadURL, setSetDownloadURL] = useState(null)

  const [shouldSendEmails, setShouldSendEmails] = useState(false)

  const uploadVoterListAndGetPins = async (selectedFile) => {
    setStatus(STATUS_TYPES.loading)
    const formData = new FormData()
    formData.set('voterlist', selectedFile)
    if (shouldSendEmails) {
      formData.append('shouldSendEmails', 'true')
    }
    const res = await AdminService.getVoterPins(formData)
    if (res?.success) {
      // @ts-ignore
      const href = URL.createObjectURL(res.data)
      setSetDownloadURL(href)
      setStatus(STATUS_TYPES.success)
    } else {
      setStatus(STATUS_TYPES.error)
    }
  }

  const onCloseModal = () => {
    URL.revokeObjectURL(downloadURL)
    setSetDownloadURL(null)
    setIsUploadModalOpen(false)
    setStatus(STATUS_TYPES.upload)
    setShouldSendEmails(false)
  }

  useEffect(() => {
    if (!isUploadModalOpen) {
      onCloseModal()
      getUploadHistory()
    }
  }, [isUploadModalOpen])

  const renderSendEmailsToggle = () => (
    <div className='py-4 border-top'>
      <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
        <span className='form-check-label fs-6 fw-bold ms-0 me-2 civik-primary-text civik-primary-blue'>
          Automatically Send Emails
        </span>
        <input
          className='form-check-input'
          type='checkbox'
          value='1'
          checked={shouldSendEmails}
          onChange={() => setShouldSendEmails(!shouldSendEmails)}
        />
      </label>
    </div>
  )

  return (
    <div className='w-100 civik-wh-card'>
      <UploadBanner onClickButton={() => setIsUploadModalOpen(true)} />
      <FileUploadModal
        title={'Assign Voter Pins'}
        isOpen={isUploadModalOpen}
        setIsOpen={setIsUploadModalOpen}
        onClickAdd={uploadVoterListAndGetPins}
        status={status}
        downloadUrl={downloadURL}
        customFileUploadDisplayElements={renderSendEmailsToggle}
      />
      <VoterListHistory history={history} />
    </div>
  )
}

export default VoterListAssignPins
