/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext, useCallback} from 'react'
import {useHistory} from 'react-router-dom'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import 'firebase/compat/auth'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {UserInfo} from '../components/registration/UserInfo'
import {ContactInfo} from '../components/registration/ContactInfo'
import {AddressInfo} from '../components/registration/AddressInfo'
import {MailingInfo} from '../components/registration/MailingInfo'
import {UploadPhotoID} from '../components/registration/UploadPhotoID'
import {VoterNavbar} from '../reusable/VoterNavbar'
import './VoterRegister.scss'
import {ModalWrapper} from '../reusable/modals/modal_wrapper/ModalWrapper'
import '../reusable/modals/VoterRegModals.scss'
import {initialValues, fullNameSchema, singleNameSchema} from './VoterRegisterSchema'
import {useVoterStyles} from '../../../contexts/VoterStylesContext'
import VoterRegistrationService from '../services/VoterRegistrationService'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
}

export function VoterRegister(props) {
  const {view} = props

  const [loading, setLoading] = useState(false)
  const [acceptTerms, setAcceptTerms] = useState(false)
  const [singleName, setSingleName] = useState(false)
  const [termsError, setTermsError] = useState(false)
  const [modalIsOpen, setIsOpen] = useState(false)
  const [modalView, setModalView] = useState('')
  const [photoID, setPhotoID] = useState(null)
  const [isPhotoError, setIsPhotoError] = useState(false)
  const [isEmailError, setIsEmailError] = useState(false)
  const history = useHistory()

  const {customBtnClass, customTxtClass, customBannerImg} = useVoterStyles()

  const formik = useFormik({
    initialValues,
    validationSchema: !singleName ? fullNameSchema : singleNameSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(async () => {
        try {
          console.log('photoID', photoID)
          if (photoID == null) {
            console.log('ERROR: photo ID not uploaded!')
            setIsPhotoError(true)
            return
          }

          const userInput = {
            // USER INFO
            firstName: values.firstName,
            middleName: values.middleName,
            lastName: values.lastName,
            singleName: singleName,
            // BIRTH DAYE
            birthMonth: values.birthMonth,
            birthDay: values.birthDay,
            birthYear: values.birthYear,
            dateOfBirth: new Date(`${values.birthDay} ${values.birthMonth} ${values.birthYear}`),

            // CONTACT INFO
            email: values.email,
            confirmEmail: values.confirmEmail,
            phone: values.phoneNumber,

            // RESIDENTIAL ADDRESS
            address: {
              streetAddress: values.streetAddress,
              city: values.city,
              province: values.province,
              postalCode: values.postalCode,
              unitNumberPOBox: values.unitNumber,
            },

            // MAILING ADDRESS
            mailingAddress: {
              streetAddress: values.mailingAddress,
              city: values.mailingCity,
              province: values.mailingProvince,
              postalCode: values.mailingPostalCode,
              unitNumberPOBox: values.mailingUnitNumber,
            },

            photoID: photoID,
          }

          console.log('userInput', userInput)
          let result = await VoterRegistrationService.registerVoter(userInput)
          if (result?.success) {
            history.push('/voter-registration/verify-method')
          }

          setLoading(false)
        } catch (error) {
          console.log(error)
        }
      }, 10)
    },
  })

  function afterOpenModal() {}

  function onAfterCompleteModal() {}

  function closeModal() {
    setIsOpen(false)
  }

  useEffect(() => {
    if (photoID) {
      setIsPhotoError(false)
    }
  }, [photoID])

  return (
    <div className='VoterRegister' id='Voter'>
      {/* NAVBAR */}
      <VoterNavbar />

      {/* MODAL */}
      <ModalWrapper
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        modalView={modalView}
      />

      <div className='voterRegister__formWrapper'>
        <img
          className='voterRegister__topBanner'
          src={toAbsoluteUrl(
            customBannerImg ? customBannerImg : '/media/design/voter_ns_top_banner.png'
          )}
        />

        <form
          className='voterRegister__form'
          onSubmit={(e) => {
            e.preventDefault()
            if (acceptTerms) {
              // submit
              formik.submitForm()
            } else {
              setTermsError(true)
              console.log('YOU must accept terms')
            }
          }}
        >
          {/* HEADER */}
          <div>
            <div className='voterRegister__titleWrap'>
              <span className='voterRegister__title'>Voter Registration</span>

              {/* Security SVG IMG */}
              <svg
                width='32'
                height='32'
                viewBox='0 0 32 32'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                onClick={() => {
                  setModalView('SecurityDisclaimer')
                  setIsOpen(true)
                }}
                className='voterRegister__sheildIcon'
              >
                <path
                  d='M5 14.3375V7C5 6.73478 5.10536 6.48043 5.29289 6.29289C5.48043 6.10536 5.73478 6 6 6H26C26.2652 6 26.5196 6.10536 26.7071 6.29289C26.8946 6.48043 27 6.73478 27 7V14.3375C27 24.8375 18.0875 28.3125 16.3125 28.9C16.1109 28.9747 15.8891 28.9747 15.6875 28.9C13.9125 28.3125 5 24.8375 5 14.3375Z'
                  stroke={customTxtClass && customTxtClass.includes('INE') ? '#CE0082' : '#3699FF'} //temporary FIX
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
                <path
                  d='M21.5 13L14.1625 20L10.5 16.5'
                  stroke={customTxtClass && customTxtClass.includes('INE') ? '#CE0082' : '#3699FF'} //temporary FIX
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>
            </div>
            <span className='voterRegister__subtitle'>
              Please complete all fields to register. Your registration will be submitted for
              approval.
            </span>
          </div>

          {/* ROW 1 - USER INFO */}
          <UserInfo
            formik={formik}
            onSingleName={(value) => {
              console.log('singleName change: ', value)
              setSingleName(value)
            }}
          />

          {/* ROW 2 - CONTACT INFO */}
          <ContactInfo formik={formik} view={'VoterRegister'} />

          {/* ROW 3 - RESIDENTIAL ADDRESS */}
          <AddressInfo
            formik={formik}
            setModalView={setModalView}
            setIsOpen={setIsOpen}
            view={'VoterRegister'}
          />

          {/* ROW 4 - MAILING ADDRESS */}
          <MailingInfo formik={formik} />

          {/* ROW 5 - UPLOAD PHOTO ID */}
          <UploadPhotoID
            setModalView={setModalView}
            setIsOpen={setIsOpen}
            setPhotoID={setPhotoID}
            // photoURL={photoURL}
          />

          {isPhotoError && (
            <div className='voterRegister__singleInputErr'>
              <div className='TextField__voterErrWrap'>
                <span className='TextField__voterErrTxt' role='alert'>
                  Photo ID is required!
                </span>
              </div>
            </div>
          )}

          <hr className='voterRegister__uploadDividerBottom'></hr>

          {/* ROW 6 - TERMS */}
          <div>
            <div className='voterRegister__termsWrap'>
              <input
                id='kt_login_toc_agree'
                className='form-check-input'
                type='checkbox'
                checked={acceptTerms}
                onChange={() => {
                  if (acceptTerms) {
                    setAcceptTerms(false)
                  } else {
                    setAcceptTerms(true)
                    setTermsError(false)
                  }
                }}
              />

              <span className='voterRegister__termsTxt'>
                I declare that I am an eligible voter in the Province of Nova Scotia
              </span>
            </div>
            {termsError && (
              <span className='TextField__voterErrTxt'>
                You have to declare that you are eligable voter in the province of Nova Scotia
              </span>
            )}

            <hr className='voterRegister__termsDividerBottom'></hr>
          </div>

          {/* ROW 7 - FOOTER */}
          <div className='voterRegister__footerWrap'>
            <button className='voterRegister__clearFormBtn'>Clear Form</button>
            <button className='voterRegister__cancelBtn'>Cancel</button>
            <button
              type='submit'
              className={
                customBtnClass
                  ? 'voterRegister__nextBtn' + ' ' + customBtnClass
                  : 'voterRegister__nextBtn'
              }
              onClick={() => {
                formik.submitForm()
              }}
              disabled={loading}
            >
              Next
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
