/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext, useCallback, useRef} from 'react'
// import {IProfileDetails, profileDetailsInitValues as initialValues} from '../../../modules/profile/components/settings/SettingsModel'

import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import FEED_SERVICE from '../../../services/feed/FeedService'

import dateFormat, {masks} from 'dateformat'

import '../../../pages/Comments.scss'

import UserPictureItem from '../../reusable/UserPictureItem'
import MODAL_NAMES from '../../../constants/ModalNames'
import {GlobalPrivateModalContext} from '../../../contexts/GlobalModalContext'
import {ProfileContext} from '../../../contexts/profilecontext'
import {Link} from 'react-router-dom'

type Props = {
  postId: string
  community: String
  comment: any
  triggerReply?: any
  parent?: any
}

export function CommentItem(props: Props) {
  const {comment, triggerReply, community, parent} = props

  const PROFILE_CONTEXT = useContext(ProfileContext)
  const userBasicProfileComplete = PROFILE_CONTEXT.profile?.basicProfileComplete

  const {setIsOpenGlobalModal} = useContext(GlobalPrivateModalContext)
  const setIsOpenCompleteProfile = (o) => setIsOpenGlobalModal(MODAL_NAMES.COMPLETE_PROFILE, o)

  const setIsOpenWrongCommunity = (o) => setIsOpenGlobalModal(MODAL_NAMES.WRONG_COMMUNITY, o)
  const userInCommunity = PROFILE_CONTEXT.profile?.communities?.includes(community)

  const [liked, setLiked] = useState(comment.userCommentVote ? true : false)
  const [numLikes, setNumLikes] = useState(comment.upvotes)
  const [likeDisabled, setLikedDisabled] = useState<boolean>()

  const likeComment = async (commentId) => {
    setLikedDisabled(true)
    setLiked(true)
    setNumLikes(numLikes + 1)
    let response = await FEED_SERVICE.likePostComment(commentId)
    if (response.code !== 200) {
      setLiked(false)
      setNumLikes(numLikes - 1)
    }
    setLikedDisabled(false)
  }

  const unlikeComment = async (commentId) => {
    setLikedDisabled(true)
    setLiked(false)
    setNumLikes(numLikes - 1)
    let response = await FEED_SERVICE.unlikePostComment(commentId)
    if (response.code !== 200) {
      setLiked(true)
      setNumLikes(numLikes + 1)
    }
    setLikedDisabled(false)
  }

  const isChild = comment.parent !== null

  const getCommentText = (commentText) => {
    if (isChild && parent) {
      if (commentText.startsWith(`@${parent.user?.full_name}`)) {
        commentText = commentText.slice(`@${parent.user?.full_name}`.length, commentText.length + 1)
      } else if (commentText.startsWith(`@${parent.anon_username}`)) {
        commentText = commentText.slice(`@${parent.anon_username}`.length, commentText.length + 1)
      } else if (commentText.startsWith(`${parent.user?.full_name}`)) {
        commentText = commentText.slice(`${parent.user?.full_name}`.length, commentText.length + 1)
      } else if (commentText.startsWith(`${parent.anon_username}`)) {
        commentText = commentText.slice(`${parent.anon_username}`.length, commentText.length + 1)
      }
    }
    return commentText
  }

  const getProfileLink = (user) => {
    if (user) {
      if (user.user_politician && user.nameUrl) {
        return `/p/${user.nameUrl}`
      } else if (user.nameUrl) {
        return `/u/${user.nameUrl}`
      }
    }
    return '#'
  }

  return (
    <div
      className={
        !isChild ? 'feed__postCommentsPosted' : 'feed__postCommentsPosted feed__postCommentsReply '
      }
    >
      {!isChild ? (
        <UserPictureItem
          item={comment}
          styleClasses={{
            anonPicStyle: 'feed__postCommentsUserImg',
            profilePicStyle: 'feed__postCommentsUserImg',
            letterPicStyle: ' navbar__barNameLetter feed__postCommentUsrLetter',
            letterPicWrapperStyle: 'navbar__barNameLetterWrap feed__postCommentLetterWrapper',
          }}
        />
      ) : (
        <div className='feed__postCommentsReplyImgWrap'>
          <img
            className='feed__postCommentsReplyImg'
            src={toAbsoluteUrl('/media/design/reply_icon.svg')}
          />

          <UserPictureItem
            item={comment}
            styleClasses={{
              anonPicStyle: 'feed__postCommentsUserImg',
              profilePicStyle: 'feed__postCommentsUserImg',
              letterPicStyle: ' navbar__barNameLetter feed__postCommentUsrLetter',
              letterPicWrapperStyle: 'navbar__barNameLetterWrap feed__postCommentLetterWrapper',
            }}
          />
        </div>
      )}

      <div className='feed__postCommentsPostedContent'>
        <p className='feed__postCommentsPostedTxt'>
          <strong>
            {comment.anonymous
              ? comment.anon_username
              : comment.user?.first_name + ' ' + comment.user?.last_name}
          </strong>
          <br></br>
          {isChild && parent && (
            <Link to={getProfileLink(parent.user)} className='createReply__tagLink'>
              <span className='createReply__tag'>
                {parent.anonymous ? parent.anon_username : parent.user?.full_name}
              </span>
            </Link>
          )}{' '}
          {getCommentText(comment.text)}
        </p>
        <div className='feed__postCommentsActionsWrap'>
          <div className='feed__postCommentsActions'>
            <small
              className={
                liked
                  ? 'feed__postCommentsActionTxt feed__postCommentsLikedText'
                  : 'feed__postCommentsActionTxt'
              }
              onClick={() => {
                !userBasicProfileComplete
                  ? setIsOpenCompleteProfile(true)
                  : !userInCommunity
                  ? setIsOpenWrongCommunity(true)
                  : likeDisabled
                  ? console.log('like disabled')
                  : liked
                  ? unlikeComment(comment._id)
                  : likeComment(comment._id)
              }}
            >
              Like
            </small>
            {!isChild ? (
              <small
                className='feed__postCommentsActionTxt'
                onClick={() => {
                  !userBasicProfileComplete
                    ? setIsOpenCompleteProfile(true)
                    : !userInCommunity
                    ? setIsOpenWrongCommunity(true)
                    : triggerReply(
                        comment.anonymous
                          ? comment.anon_username
                          : comment.user?.first_name + ' ' + comment.user?.last_name,
                        comment._id
                      )
                }}
              >
                Reply
              </small>
            ) : null}

            <small className='feed__postCommentsDateTxt'>
              {' '}
              {dateFormat(comment.createdAt, 'mmmm dS, h:MM TT')}
            </small>
          </div>

          <div className='feed__postCommentsLikeWrap'>
            <img
              className='feed__postCommentsLikeImg'
              src={toAbsoluteUrl('/media/design/like_icon_blue.svg')}
            />
            <small className={'feed__postCommentsLikesTxt'}>{numLikes}</small>
          </div>
        </div>
      </div>
    </div>
  )
}
