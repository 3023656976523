import React, {useContext, useState} from 'react'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import LETTER_COLORS from '../../constants/LetterColors'
import {ProfileContext} from '../../contexts/profilecontext'
import './UserPictureItem.scss'

const getProfileLink = (user) => {
  if (user) {
    if (user.user_politician && user.nameUrl) {
      return `/p/${user.nameUrl}`
    } else if (user.nameUrl) {
      return `/u/${user.nameUrl}`
    }
  }
  return '#'
}

const renderBadge = (item) => {
  if (item.by_politician) {
    return (
      <img
        alt='badge'
        src={toAbsoluteUrl('/media/design/icon-verified-politician.svg')}
        className='badge-icon '
      />
    )
  } else if (item.user.admin) {
    return (
      <img
        alt='badge'
        src={toAbsoluteUrl('/media/design/icon-city-admin.svg')}
        className='badge-icon '
      />
    )
  }
  return <></>
}

type props = {
  item: {
    anonymous?: Boolean
    user?: any
    anon_username?: String
  }
  styleClasses: any
}

const UserPictureItem = ({item, styleClasses = null}: props) => {
  const {profile} = useContext(ProfileContext)
  const userBasicProfileComplete = profile?.basicProfileComplete
  const [imgError, setImgError] = useState(false)

  if (!item.anonymous && item.user?.first_name && (!item.user?.profile_picture || imgError)) {
    return (
      <Link
        to={userBasicProfileComplete ? getProfileLink(item.user) : '#'}
        className={
          styleClasses && styleClasses.letterPicWrapperStyle
            ? styleClasses.letterPicWrapperStyle
            : 'navbar__barNameLetterWrap feed__postCommentLetterWrapper'
        }
        style={{
          background: LETTER_COLORS[item.user?.first_name.charAt(0).toUpperCase()].background,
          cursor: item.user?.nameUrl ? 'pointer' : '',
          position: 'relative',
        }}
      >
        <span
          className={
            styleClasses && styleClasses.letterPicStyle
              ? styleClasses.letterPicStyle
              : 'navbar__barNameLetter feed__postCommentUsrLetter'
          }
          style={{
            color: LETTER_COLORS[item.user?.first_name.charAt(0).toUpperCase()].color,
          }}
        >
          {item.user?.first_name.charAt(0).toUpperCase()}
        </span>
        {renderBadge(item)}
      </Link>
    )
  } else if (!item.anonymous && item.user?.profile_picture && !imgError) {
    return (
      <Link
        to={userBasicProfileComplete ? getProfileLink(item.user) : '#'}
        style={{cursor: item.user?.nameUrl ? 'pointer' : ''}}
      >
        <div style={{position: 'relative'}}>
          <img
            alt='profile-pic'
            className={
              styleClasses && styleClasses.anonPicStyle
                ? styleClasses.anonPicStyle
                : 'feed__postCommentsUserImg'
            }
            src={item.user?.profile_picture}
            onError={({currentTarget}) => {
              currentTarget.onerror = null // prevents looping
              setImgError(true)
            }}
          />
          {renderBadge(item)}
        </div>
      </Link>
    )
  } else if (item.anonymous && item.anon_username)
    return (
      <img
        alt='profile-pic'
        className={
          styleClasses && styleClasses.anonPicStyle
            ? styleClasses.anonPicStyle
            : 'feed__postCommentsUserImg'
        }
        src={`https://api.dicebear.com/7.x/bottts/svg?seed=${item.anon_username}`}
      />
    )
  else {
    return <></>
  }
}
export default UserPictureItem
