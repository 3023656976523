import {useState, useEffect} from 'react'

import TextField from '../../../../../app/modules/auth/reusable/TextField'
import SelectField from '../../../../../app/modules/auth/reusable/SelectField'

import '../../pages/VoterRegister.scss'
import {useVoterStyles} from '../../../../contexts/VoterStylesContext'
const provinces = [
  {value: 0, label: 'Province'},
  {value: 'Alberta', label: 'Alberta'},
  {value: 'British Columbia', label: 'British Columbia'},
  {value: 'Manitoba', label: 'Manitoba'},
  {value: 'New Brunswick', label: 'New Brunswick'},
  {value: 'Newfoundland and Labrador', label: 'Newfoundland and Labrador'},
  {value: 'Northwest Territories', label: 'Northwest Territories'},
  {value: 'Nova Scotia', label: 'Nova Scotia'},
  {value: 'Nunavut', label: 'Nunavut'},
  {value: 'Ontario', label: 'Ontario'},
  {value: 'Prince Edward Island', label: 'Prince Edward Island'},
  {value: 'Quebec', label: 'Quebec'},
  {value: 'Saskatchewan', label: 'Saskatchewan'},
  {value: 'Yukon', label: 'Yukon'},
]

export function MailingInfo(props) {
  const {formik} = props

  const {customTxtClass} = useVoterStyles()

  const [sameAddress, setSameAddress] = useState(false)

  // Same address --- on change
  useEffect(() => {
    formik.values.mailingAddress = formik.values.streetAddress
  }, [sameAddress && formik.values.streetAddress])

  useEffect(() => {
    formik.values.mailingCity = formik.values.city
  }, [sameAddress && formik.values.city])

  useEffect(() => {
    formik.values.mailingProvince = formik.values.province
  }, [sameAddress && formik.values.province])

  useEffect(() => {
    formik.values.mailingPostalCode = formik.values.postalCode
  }, [sameAddress && formik.values.postalCode])

  useEffect(() => {
    formik.values.mailingUnitNumber = formik.values.unitNumber
  }, [sameAddress && formik.values.unitNumber])

  // Same address change once clicked
  const sameAddressChange = () => {
    formik.values.mailingAddress = formik.values.streetAddress
    formik.values.mailingCity = formik.values.city
    formik.values.mailingProvince = formik.values.province
    formik.values.mailingPostalCode = formik.values.postalCode
    formik.values.mailingUnitNumber = formik.values.unitNumber
  }

  return (
    <div className='voterRegister__addressRow'>
      <div className='voterRegister__divider'>
        <span
          className={
            customTxtClass
              ? 'voterRegister__dividerLabel' + ' ' + customTxtClass
              : 'voterRegister__dividerLabel'
          }
        >
          Mailing Address
        </span>
      </div>

      <div className='voterRegister__sameAddressWrap'>
        <input
          id='kt_login_toc_agree'
          className='form-check-input'
          type='checkbox'
          checked={sameAddress}
          onChange={() => {
            if (sameAddress) {
              setSameAddress(false)
            } else {
              setSameAddress(true)
              sameAddressChange()
            }
          }}
        />

        <span className='voterRegister__singleNameTxt'>
          My mailing address is the same as my residential address
        </span>
      </div>
      <div className='voterRegister__addressWrap'>
        <div className='voterRegister__addressInputWrap'>
          <TextField
            formik={formik}
            type='text'
            field='mailingAddress'
            placeholder='Street Address'
            label='Street Address'
            isRequired={true}
            view='voter'
          />
          {formik.touched['mailingAddress'] && formik.errors['mailingAddress'] && (
            <div className='voterRegister__singleInputErr'>
              <div className='TextField__voterErrWrap'>
                <span className='TextField__voterErrTxt' role='alert'>
                  {formik.errors['mailingAddress']}
                </span>
              </div>
            </div>
          )}
        </div>
        <div>
          <TextField
            formik={formik}
            type='text'
            field='mailingUnitNumber'
            placeholder='Unit Number'
            label='Unit #/P.O.Box'
            view='voter'
          />
        </div>
      </div>
      <div className='voterRegister__locationWrap'>
        <div className='voterRegister__locationInputsWrap'>
          <div className='voterRegister__cityInputWrap'>
            <TextField
              formik={formik}
              type='text'
              field='mailingCity'
              placeholder='City'
              label='City'
              isRequired={true}
              view='voter'
            />
          </div>

          <div className='voterRegister__provinceDropdownWrap'>
            <SelectField
              formik={formik}
              type='text'
              field='mailingProvince'
              placeholder='Province'
              label='Province'
              isRequired={true}
              options={provinces}
              view='voter'
            />
          </div>

          <div className='voterRegister__postalInputWrap'>
            <TextField
              formik={formik}
              type='text'
              field='mailingPostalCode'
              placeholder='Postal Code'
              label='Postal Code'
              isRequired={true}
              view='voter'
            />
          </div>
        </div>

        {formik.touched['mailingCity'] && formik.errors['mailingCity'] && (
          <div className='voterRegister__singleInputErr'>
            <div className='TextField__voterErrWrap'>
              <span className='TextField__voterErrTxt' role='alert'>
                {formik.errors['mailingCity']}
              </span>
            </div>
          </div>
        )}
        {formik.touched['mailingProvince'] && formik.errors['mailingProvince'] && (
          <div className='voterRegister__singleInputErr'>
            <div className='TextField__voterErrWrap'>
              <span className='TextField__voterErrTxt' role='alert'>
                {formik.errors['mailingProvince']}
              </span>
            </div>
          </div>
        )}
        {formik.touched['mailingPostalCode'] && formik.errors['mailingPostalCode'] && (
          <div className='voterRegister__singleInputErr'>
            <div className='TextField__voterErrWrap'>
              <span className='TextField__voterErrTxt' role='alert'>
                {formik.errors['mailingPostalCode']}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
