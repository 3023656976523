import axios from 'axios'
import React, {useState, useEffect, useRef} from 'react'
import {geocodeByAddress} from 'react-google-places-autocomplete'
import TextField from '../auth/reusable/TextField'
import SelectField from '../auth/reusable/SelectField'

const WardDropdowns = (props) => {
  const {formik, address} = props
  const [wardOptions, setWardOptions] = useState([])
  const [initializing, setInitializing] = useState(true)

  const getAddressComponentsGoogle = async () => {
    try {
      let results = {}
      results = await geocodeByAddress(address)

      let address_components = results[0].address_components || []
      var location_info = {}
      for (var a of address_components) {
        if (a.types.includes('locality')) {
          location_info.location_city = a.long_name
        } else if (a.types.includes('administrative_area_level_2')) {
          location_info.county = a.long_name
        } else if (a.types.includes('administrative_area_level_1')) {
          location_info.location_region = a.long_name
        } else if (a.types.includes('country')) {
          location_info.location_country = a.long_name
        } else if (a.types.includes('postal_code')) {
          location_info.postal_code = a.long_name
        } else if (a.types.includes('sublocality_level_1')) {
          location_info.location_sublocality = a.long_name
        }
      }
      if (!location_info.location_city && location_info.location_sublocality) {
        location_info.location_city = location_info.location_sublocality
      }
      return location_info
    } catch (error) {
      console.log(error)
    }
  }

  const getWardsByAddress = async () => {
    try {
      if (address) {
        // on address change reset fields except on initial load
        let empty = {
          value: '',
          label: 'Ward Name',
          boundaryUrl: '',
          wardName: '',
          wardNumber: '',
        }
        setWardOptions([])
        if (!initializing) {
          formik.setFieldValue('wardName', empty.wardName)
          formik.setFieldValue('wardNumber', empty.wardNumber)
          formik.setFieldValue('boundaryUrl', empty.boundaryUrl)
        }
        let openNorthBoundarySetURL = 'https://represent.opennorth.ca/boundaries/?sets='
        let location_info = await getAddressComponentsGoogle()
        let city = location_info.location_city
        formik.setFieldValue('location_info', location_info)
        if (city) {
          if (city === 'Toronto') {
            openNorthBoundarySetURL = `https://represent.opennorth.ca/boundaries/?sets=toronto-wards-2018&limit=500`
          } else {
            // NOTE this does not cover all cities
            city = city.toLowerCase()
            openNorthBoundarySetURL = `https://represent.opennorth.ca/boundaries/?sets=${city}-wards&limit=500`
          }
        }

        let data = await axios.get(openNorthBoundarySetURL)
        data = data.data
        if (data.objects?.length) {
          let wardOptions1 = data.objects.slice()
          wardOptions1.sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true }))
          wardOptions1 = wardOptions1.map((obj, i) => ({
            ...obj,
            value: obj.name,
            label: obj.name,
            boundaryUrl: obj.url,
            wardName: obj.name,
            wardNumber: obj.external_id,
          }))
          setWardOptions([empty, ...wardOptions1])
        }
        setInitializing(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onChange = (e) => {
    let wardName = e.target.value
    let i = wardOptions.map((e) => e.wardName).indexOf(wardName)
    let wardOption = wardOptions[i]
    formik.setFieldValue('wardName', wardName)
    formik.setFieldValue('wardNumber', wardOption.wardNumber)
    formik.setFieldValue('boundaryUrl', wardOption.boundaryUrl)
  }

  useEffect(() => {
    getWardsByAddress()
  }, [address])

  if (initializing) {
    return <div />
  }
  // if wardOptions known, provide dropdown and autofill wardNumber
  if (wardOptions.length) {
    return (
      // <div className='civik-flex-row-wrap '>
      <div className='w-100 mb-6'>
        <SelectField
          formik={formik}
          type='text'
          field='wardName'
          placeholder='Ward'
          label='Ward'
          isRequired={true}
          options={wardOptions}
          customOnChange={onChange}
        />
      </div>
      // </div>
    )

    // else if address is filled out but no wardoptions, let them manually enter the info
  } else {
    return (
      <>
        <div className='w-100 mb-6 mr-40-responsive'>
          <TextField
            formik={formik}
            type='text'
            field='wardName'
            placeholder='Ward Name'
            label='Ward Name'
            isRequired={true}
          />
        </div>
        <div className='w-100 mb-6'>
          <TextField
            formik={formik}
            type='text'
            field='wardNumber'
            label='Ward Number'
            placeholder='Ward Number'
            isRequired={true}
          />
        </div>
      </>
    )
  }
  // else show nothing until they fill out address
  return <div />
}
export default WardDropdowns
