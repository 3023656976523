/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext, useCallback} from 'react'
// import {IProfileDetails, profileDetailsInitValues as initialValues} from '../../../modules/profile/components/settings/SettingsModel'
import {useHistory} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'

import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

import Modal from 'react-modal'

import {UserContext} from '../../contexts/usercontext'

import FeatherIcon from 'feather-icons-react'

import SelectField from '../../modules/auth/reusable/SelectField'

import POLITICIAN_SERIVCE from '../../services/politician/PoliticianService'
import {ProfileContext} from '../../contexts/profilecontext'
import AuthService from '../../services/auth/AuthService'
import GooglePlacesAutoCompleteInput from '../../modules/reusable/GoogleAutocomplete'
import {FeedLocationContext} from '../../contexts/feedLocationContext'
import {geocodeByAddress} from 'react-google-places-autocomplete'

import './ChangeCityModal.scss'

const cityOptions = [
  {label: 'Select City', value: ''},
  {label: 'Toronto', value: 'Toronto'},
  {label: 'Mississauga', value: 'Mississauga'},
]

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
}

const initialValues = {
  city: '',
}

const profileDetailsSchema = Yup.object().shape({})

export function ChangeCityModal(props) {
  const [loading, setLoading] = useState(false)
  const PROFILE_CONTEXT = useContext(ProfileContext)

  const {feedLocation, setFeedLocation} = useContext(FeedLocationContext)
  // const onEditStaff = useCallback(() => MicroModal.show('modal-1'), [])

  const getAddressComponentsGoogle = async (address) => {
    try {
      let results = {}
      results = await geocodeByAddress(address)

      let address_components = results[0].address_components || []
      var location_info = {
        city: '',
        region: '',
        country: '',
      }
      for (var a of address_components) {
        if (a.types.includes('locality')) {
          location_info.city = a.long_name
        }  else if (a.types.includes('administrative_area_level_1')) {
          location_info.region = a.long_name
        } else if (a.types.includes('country')) {
          location_info.country = a.long_name
        }  
      }
      return location_info
    } catch (error) {
      console.log(error)
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(async () => {
        const userInput = {
          address: values.city,
        }
        let location_info = await getAddressComponentsGoogle(userInput.address);
        setFeedLocation(location_info);

        setLoading(false)

        // close modal
        props.onRequestClose()
      }, 1000)
    },
  })

  const resetModal = () => {
    try {
      Object.keys(formik.values).forEach((key) => {
        formik.values[key] = ''
      })

      Object.keys(formik.touched).forEach((key) => {
        formik.touched[key] = false
      })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    resetModal()
  }, [props.onRequestClose])

  useEffect(() => {
    // Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
    Modal.setAppElement('#Navbar')
  }, [])

  return (
    <Modal
      ariaHideApp={false}
      isOpen={props.isOpen}
      onAfterOpen={props.onAfterOpen}
      onRequestClose={props.onRequestClose}
      style={customStyles}
      contentLabel='Example Modal'
    >
      <form className='SelectCityModal' style={{flexGrow:1}} onSubmit={formik.handleSubmit} noValidate>
        <div className='civik-modal-header civik-flex-row civik-justify-between'>
          <h1 className='civik-modal-title'>Change City</h1>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/design/modal-x-btn.svg')}
            className='civik-modal-x-btn'
            onClick={props.onRequestClose}
          />
        </div>

        <div className='civik-modal-content' style={{marginTop: 20}}>
          {/* ROW 1 */}
          <div className=' mb-6' >
          <GooglePlacesAutoCompleteInput
              formik={formik}
              type='text'
              field='city'
              placeholder='City'
              label='City'
              isRequired={true}
              types={['locality']}
            />
          </div>

          {/* ROW 4 */}
          <div className='civik-flex-row' style={{justifyContent: 'flex-end'}}>
            <button
              type='submit'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-active-light-primary w-100  mt-7 btn btn-light  '
              style={{maxWidth: 110}}
              onClick={props.onRequestClose}
            >
              <span className='indicator-label'>Cancel</span>
            </button>
            <button
              type='submit'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-primary w-100  mt-7'
              style={{marginLeft: 20, maxWidth: 110}}
            >
              {!loading && <span className='indicator-label'>Save</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </form>
    </Modal>
  )
}
