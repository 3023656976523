/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext, useCallback} from 'react'
// import './AdminElectorsList.scss'
import {AdminSidebar} from '../../reusable/AdminSidebar'
import {useFormik} from 'formik'
// import './AdminAddressList.scss'
import TextField from '../../../../../app/modules/auth/reusable/TextField'
import SelectField from '../../../../../app/modules/auth/reusable/SelectField'
import * as Yup from 'yup'
import {PropertyInfoModal} from '../../modals/PropertyInfoModal'
import {DuplicationListTable} from './DuplicationListTable'
import {ImportAddressList} from '../../pages/address_list/ImportAddressList'
import {ExportAddressList} from '../../pages/address_list/ExportAddressList'
import {AdminTopbar} from '../../reusable/AdminTopbar'

import {AdminDropdown} from '../../reusable/AdminDropdown'

const districtOptions = [
  {value: 0, label: 'All Districts'},
  {value: '1. Annapolis	', label: '1. Annapolis	'},
]

export const initialValues = {}

export const loginSchema = Yup.object().shape({})

export function AdminDuplicationList(props) {
  const [loading, setLoading] = useState(false)
  const [modalIsOpen, setIsOpen] = useState(false)
  const [modalView, setModalView] = useState('')
  const [currentTab, setCurrentTab] = useState('Address List')

  function afterOpenModal() {}
  function onAfterCompleteModal() {}
  function closeModal() {
    setIsOpen(false)
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(async () => {
        try {
          setLoading(false)
        } catch (error) {
          console.log(error)
        }
      }, 10)
    },
  })

  return (
    <>
      {' '}
      <AdminSidebar />
      <AdminTopbar />
      <div className='VoterAddressList' id='Voter'>
        {/* CONTENT */}
        <div className='voterAddrList__container'>
          {/* TABS */}
          <div className='voterElectorList__header'>
            <span className='voterElectorList__header__label'>Duplication List</span>
            <div className='voterElectorList__header__inputsWrap'>
              <TextField
                formik={formik}
                type='text'
                field='search'
                placeholder=''
                label=''
                isRequired={false}
                noLabel={true}
                view='admin_searchbar'
                customStyling={{width: 196, marginRight: 25}}
              />
              <AdminDropdown view='statusFilter' customStyling={{width: 174, marginRight: 25}} />{' '}
              <AdminDropdown view='districts' />{' '}
            </div>
          </div>

          {/* BODY CONTENT */}
          <DuplicationListTable />
        </div>
      </div>
    </>
  )
}
