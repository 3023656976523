/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext, useCallback} from 'react'
// import {IProfileDetails, profileDetailsInitValues as initialValues} from '../../../modules/profile/components/settings/SettingsModel'
import {useHistory} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'

import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'

import Modal from 'react-modal'

import {UserContext} from '../../../contexts/usercontext'

import FeatherIcon from 'feather-icons-react'
import TextField from '../../../modules/auth/reusable/TextField'

import POLITICIAN_SERIVCE from '../../../services/politician/PoliticianService'
import {ProfileContext} from '../../../contexts/profilecontext'
import AUTH_SERVICE from '../../../services/auth/AuthService'

import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
}

const initialValues = {
  oldPassword: '',
  newPassword: '',
  confirmNewPassword: '',
}

const forgotPasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Old Password is required'),
  newPassword: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  confirmNewPassword: Yup.string()
    .required('Password confirmation is required')
    .when('newPassword', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref('newPassword')],
        "Password and Confirm Password didn't match"
      ),
    }),
})

export function ChangePasswordModal(props) {
  const [loading, setLoading] = useState(false)
  const PROFILE_CONTEXT = useContext(ProfileContext)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      setLoading(true)
      setHasErrors(undefined)
      let response = await AUTH_SERVICE.changePassword({
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      })

      // success
      if (response && response.code == 200) {
        setHasErrors(false)
        resetForm()
        setStatus({success: response.message, error: undefined})
      }

      // error
      if (response && response.code == 400) {
        setLoading(false)
        setSubmitting(false)
        setStatus({error: response.message, success: undefined})
      }
      setLoading(false)
    },
  })

  const resetModal = () => {
    formik.resetForm()
  }

  useEffect(() => {
    resetModal()
  }, [props.onRequestClose])

  useEffect(() => {
    // Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
    Modal.setAppElement('#ChangePaswordModal')
  }, [])

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <Modal
      ariaHideApp={false}
      isOpen={props.isOpen}
      onAfterOpen={props.onAfterOpen}
      onRequestClose={props.onRequestClose}
      style={customStyles}
      contentLabel='Example Modal'
    >
      <form
        className='civik-modal'
        onSubmit={formik.handleSubmit}
        noValidate
        id='ChangePasswordForm'
      >
        <div className='civik-modal-header civik-flex-row civik-justify-between'>
          <h1 className='civik-modal-title'>Change Password</h1>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/design/modal-x-btn.svg')}
            className='civik-modal-x-btn'
            onClick={props.onRequestClose}
          />
        </div>

        <div className='civik-modal-content' style={{marginTop: 20}}>
          <div className=' mb-6'>
            <TextField
              formik={formik}
              type='password'
              field='oldPassword'
              placeholder='Old password'
              label='Old password'
            />
          </div>

          {/* ROW 1 */}
          {/* begin::Form group Password */}
          <div className='mb-6 fv-row' data-kt-password-meter='true'>
            <TextField
              formik={formik}
              type='password'
              field='newPassword'
              placeholder='New password'
              label='New password'
            />

            {/* begin::Meter */}
            <div
              className='d-flex align-items-center  mt-5'
              data-kt-password-meter-control='highlight'
            >
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
            </div>
            {/* end::Meter */}

            {/* <div className='text-muted'>
            Use 8 or more characters with a mix of letters, numbers & symbols.
          </div> */}
          </div>
          {/* end::Form group */}

          <div className=' mb-6'>
            <TextField
              formik={formik}
              type='password'
              field='confirmNewPassword'
              placeholder='Confirm password'
              label='Confirm password'
            />
          </div>

          {/* begin::Form group Confirm password */}
          <div className='fv-row mb-5' style={{marginTop: -10}}></div>
          {/* end::Form group */}
          {formik.status && formik.status.error && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status.error}</div>
            </div>
          )}
          {formik.status && formik.status.success && (
            <div className='mb-lg-15 alert alert-success'>
              <div className='alert-text font-weight-bold'>{formik.status.success}</div>
            </div>
          )}
          {/* ROW 4 */}
          <div className='civik-flex-row' style={{justifyContent: 'flex-end'}}>
            <button
              type='reset'
              form='ChangePasswordFormId'
              className='btn btn-lg btn-active-light-primary w-100  mt-7 btn btn-light  '
              style={{maxWidth: 110}}
              onClick={props.onRequestClose}
            >
              <span className='indicator-label'>Cancel</span>
            </button>
            <button
              type='submit'
              className='btn btn-lg btn-primary w-100  mt-7'
              style={{marginLeft: 20, maxWidth: 110}}
              form='ChangePasswordForm'
            >
              {!loading && <span className='indicator-label'>Save</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </form>
    </Modal>
  )
}
