/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useContext} from 'react'
import {useHistory} from 'react-router-dom'

import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'

import {UserContext} from '../../../../contexts/usercontext'

export function SignupOptions() {
  const history = useHistory()
  const USER_CONTEXT = useContext(UserContext)

  const [loading, setLoading] = useState(false)
  const [signupType, setSignupType] = useState('')

  // your link creation
  const navigateToCouncillor = {
    pathname: '/auth/secondary-signup',
    state: 'Councillor',
  }

  const navigateToCandidate = {
    pathname: '/auth/secondary-signup',
    state: 'Candidate',
  }

  return (
    <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
      <div className='d-flex flex-row flex-row-fluid '>
        {/* <AuthSideBanner /> */}

        <div className='form w-100 civik-login-email-form'>
          <div className=' mb-10 civik-flex-column' style={{alignItems: 'center'}}>
            <h1 className='text-dark mb-3 text-center' style={{fontSize: '24px', marginTop: 20}}>
              Select Registration Type
            </h1>

            {/* <div
              onClick={() => {
                history.push(navigateToCouncillor)
              }}
              className='civik-council-signup-btn civik-flex-row'
            >
              <img
                alt='councilor building'
                src={toAbsoluteUrl('/media/design/councillor.svg')}
                className='civik-council-icon w-25px'
              />
              <div className='civik-flex-column'>
                <strong className='civik-dark-blue'>
                  I am currently an active Mayor or Councillor
                </strong>
                <span className='civik-light-grey fs-8 fw-bold mobile-hide'>
                  For recent and active city councillors
                </span>
              </div>
            </div> */}

            <div
              className='civik-council-signup-btn civik-flex-row'
              onClick={() => {
                history.push(navigateToCandidate)
              }}
            >
              <img
                alt='councilor building'
                src={toAbsoluteUrl('/media/design/candidate.svg')}
                className='civik-council-icon w-25px'
              />
              <div className='civik-flex-column'>
                <strong className='civik-dark-blue'>I am a city candidate</strong>
                <span className='civik-light-grey fs-8 fw-bold mobile-hide'>
                  For city candidates who already filed the nomination paper
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
