import React, {useEffect, useState} from 'react'
import RedirectToArticle from './RedirectToArticle'
import NewsHeadlinerTxt from './NewsHeadlinerTxt'
import styled from 'styled-components'
import PostReactions from '../../feed/posts/PostReactions'
import {useHistory} from 'react-router-dom'
import PostActionBar from '../../feed/posts/PostActionBar'
import './NewsTopStoryCard.scss'
import Slider from 'react-slick'
import useMobileView from '../../../hooks/UseMobileView'

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}

function NewsTopStoryCard({post, index, view, customStyling, fullPageView}) {
  const [numLikes, setNumLikes] = useState(post.upvotes)
  const history = useHistory()

  return (
    <div className='news__topStory__card' key={index} style={customStyling}>
      <div className='newsTopStoryCard__content'>
        <RedirectToArticle url={post.url}>
          <img className='news__topStory__card__img' alt='' src={post.preview_image} />
        </RedirectToArticle>
        {view == 'web-fullpage' && (
          <PostReactions view='top-story' numLikes={numLikes} feed={post} history={history} />
        )}
        <NewsHeadlinerTxt customStyling={{}} postDetails={post} />

        <RedirectToArticle url={post.url}>
          <span className='news__topStory__card__description'>
            {post.linkPreview?.title || post.title}
          </span>
        </RedirectToArticle>
      </div>
      {view == 'web-fullpage' && (
        <PostActionBar
          post={post}
          index={index}
          numLikes={numLikes}
          setNumLikes={setNumLikes}
          setNewCommentTrigger={false}
          view='top-story'
        />
      )}
    </div>
  )
}

export default NewsTopStoryCard
