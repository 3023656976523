import React, {useState, useEffect, useContext, useRef, useCallback} from 'react'
import {ProfileHeader} from '../../modules/profile/ProfileHeader'
import {ProfileContext} from '../../contexts/profilecontext'

import {toAbsoluteUrl} from '../../../_metronic/helpers'
import TextField from '../../../app/modules/auth/reusable/TextField'
import SelectField from '../../../app/modules/auth/reusable/SelectField'
import {useFormik} from 'formik'
import * as Yup from 'yup'

import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/storage'

import VOTER_SERVICE from '../../services/voter/VoterService'
import GooglePlacesAutoCompleteInput from '../reusable/GoogleAutocomplete'
import FeatherIcon from 'feather-icons-react'

import {ProfileCouncilorSocialEdit} from './ProfileCouncilorSocialEdit'
import {ProfileCouncilorAboutEdit} from './ProfileCouncilorAboutEdit'

const profileDetailsSchema = Yup.object().shape({
  fName: Yup.string().required('First name is required'),
  lName: Yup.string().required('Last name is required'),
  address: Yup.string().required('Address is required'),
})

const socialDetailsSchema = Yup.object().shape({})

const initialValues = {
  fName: '',
  lName: '',
  address: '',
  // province: '',
  // city: '',
  phoneNumber: '',
  // faxNumber: '',
  // postalCode: '',
  // wardNumber: '',
  // wardName: '',
  profilePicture: {},

  //
}

const initialSocialValues = {
  instagram: '',
  linkedin: '',
  twitter: '',
  facebook: '',
  website: '',
}

export function Profile(props) {
  const {isEdit, setIsEdit} = props
  const {currentTab, setCurrentTab} = props
  const {fetchProfile} = props
  const {loading, setLoading} = props

  const [councilorTab, setCouncilorTab] = useState('Profile Info')
  const [startDate, setStartDate] = useState(new Date())
  const {profile, setProfile} = useContext(ProfileContext)

  const [picUrl, setPictureUrl] = useState('')
  const [initializing, setInitializing] = useState(true)

  const uploadRef = useRef(null)

  const onImageClick = useCallback(() => {
    uploadRef.current.click()
  }, [uploadRef])

  const formik = useFormik({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(async () => {
        try {
          const userInput = {
            profile_picture: picUrl,
            first_name: values.fName,
            last_name: values.lName,

            address: values.address,

            phone: values.phoneNumber, // phone number
          }
          console.log('userInput', userInput)

          await VOTER_SERVICE.updateProfile(userInput)

          // set edit mode off
          setIsEdit(false)
          //fetch user profile and set it into context
          await fetchProfile()
          setLoading(false)
        } catch (error) {
          console.log(error)
        }
      }, 1000)
    },
  })

  const onFileUpload = async (e) => {
    try {

    e.persist()
    const file = e.target.files[0]
    console.log('file', file)
    if (!file) return;
    //  If the file size (calculated in byte form) is than 1MB
    if (file.size > 1.0e6) {
      console.log('IMAGE SIZE MUST BE LESS THAN 1MB')
    }
    if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
      return console.log('IMAGE TYPE MUST BE PNG OR JPG')
    }

    console.log('uploading image now!....')

    let usr_civik_id = profile._id

    console.log('starting UPLOAD ========')
    const path = `profile_pics/${usr_civik_id}/profile_pic`
    await firebase.storage().ref(path).put(file)
    const setURL = await firebase.storage().ref(path).getDownloadURL()
    setPictureUrl(setURL)
    console.log(`====> setURL is ${setURL} <=======`)

    } catch (error) {
      console.error(error)
    }
  }

  const fetchProfileInfoForm = () => {
    try {
      // console.log('voter profile ', profile)
      // set form fields
      setPictureUrl(profile.profile_picture)
      formik.values.fName = profile.first_name ? profile.first_name : ''
      formik.values.lName = profile.last_name ? profile.last_name : ''
      formik.values.address = profile.address ? profile.address : ''
      formik.values.phoneNumber = profile.phone ? profile.phone : ''
    } catch (error) {
      console.log(error)
    }
  }

  //   dob: startDate,
  //   website: values.personalWebsiteURL,
  //   linkedin: values.linkedinURL,
  //   facebook: values.facebookURL,
  //   twitter: values.twitterURL,
  //   instagram: values.instagramURL,
  //   bio: values.bio,
  // }

  useEffect(() => {
    // profile fetched from context that can be used within components
    setInitializing(true)
    fetchProfileInfoForm()
    setInitializing(false)
  }, [])

  if (initializing) {
    return <div />
  }
  return (
    <div className='  form w-100 fv-plugins-bootstrap5 fv-plugins-framework ProfileEdit'>
      <ProfileHeader currentTab={currentTab} setCurrentTab={setCurrentTab} />
      <div className='civik-flex-row civik-profile-edit-content-wrapper'>
        {/* LEFT SIDE BAR */}
        <div
          className='civik-wh-card civik-flex-column civik-profile-edit-left-bar'
          style={{
            width: '30%',
            alignItems: 'center',
            paddingLeft: 50,
            paddingRight: 50,
            height: '100%',
          }}
        >
          <button
            type='submit'
            className='btn btn-light-grey btn-active-light-primary mb-10 mt-5'
            style={{minWidth: '100%'}}
            onClick={async () => {}}
          >
            <span className='fw-bolder civik-dark-grey'>View Profile</span>
          </button>
          {/* normal account */}

          {props.isCouncilorType ? (
            <div>
              <div
                className='civik-flex-row editProfile__tab'
                onClick={() => {
                  setCouncilorTab('Profile Info')
                }}
              >
                <div
                  className='civik-step-number '
                  style={{marginRight: 25, background: '#F1FAFF'}}
                >
                  <FeatherIcon icon={'info'} color='#3699FF' />
                </div>

                <div className='civik-flex-column '>
                  <span className='fw-bold'>Profile Info</span>
                </div>
              </div>

              <div
                className='civik-flex-row editProfile__tab'
                style={{marginTop: 50}}
                onClick={() => {
                  setCouncilorTab('Social Accounts')
                }}
              >
                <div
                  className='civik-step-number '
                  style={{marginRight: 25, background: '#F1FAFF'}}
                >
                  <FeatherIcon icon={'link'} color='#3699FF' />
                </div>

                <div className='civik-flex-column'>
                  <span className='fw-bold'>Social Info</span>
                </div>
              </div>
            </div>
          ) : (
            <div className='civik-flex-row editProfile__tab'>
              <div className='civik-step-number ' style={{marginRight: 25, background: '#F1FAFF'}}>
                <FeatherIcon icon={'info'} color='#3699FF' />
              </div>

              <div className='civik-flex-column '>
                <span className='fw-bold'>Basic Info</span>
              </div>
            </div>
          )}
        </div>

        {/* RIGHT SIDE BAR - EDIT PROFILE FORM */}
        {/* normal account */}

        {props.isCouncilorType ? (
          <>
            {councilorTab == 'Profile Info' && (
              <ProfileCouncilorAboutEdit
                onClose={() => {
                  setIsEdit(false)
                }}
              />
            )}

            {councilorTab == 'Social Accounts' && (
              <ProfileCouncilorSocialEdit
                onClose={() => {
                  setIsEdit(false)
                }}
              />
            )}
          </>
        ) : (
          <form
            className='civik-wh-card civik-profile-edit-right-bar profileEdit__rightEditForm'
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <h1 className='mb-5'>Basic Info</h1>

            <div>
              <div className='civik-flex-row-wrap' style={{justifyContent: 'space-between'}}>
                <div style={{width: '100%'}}>
                  <div className='mb-6'>
                    <TextField
                      formik={formik}
                      type='text'
                      field='fName'
                      placeholder='First Name'
                      label='First Name'
                      isRequired={true}
                    />
                  </div>
                  <div className=' mb-6'>
                    <TextField
                      formik={formik}
                      type='text'
                      field='lName'
                      placeholder='Last Name'
                      label='Last Name'
                      isRequired={true}
                    />
                  </div>
                  <div className='mb-6'>
                    <TextField
                      formik={formik}
                      type='text'
                      field='phoneNumber'
                      placeholder='Phone Number'
                      label='Phone Number'
                      // isRequired={true}
                    />
                  </div>
                </div>
                <div className='civik-flex-row mb-6 civik-concil-pic'>
                  <div>
                    <label className=' col-form-label fw-bold fs-6'>Profile Picture</label>
                    <div className='civik-flex-column'>
                      <img
                        className='civik-councilor-profile-pic'
                        src={picUrl ? picUrl : toAbsoluteUrl('/media/design/upload-img-sample.png')}
                        alt='profile'
                        onClick={onImageClick}
                      />

                      <input
                        type='file'
                        onChange={onFileUpload}
                        ref={uploadRef}
                        style={{display: 'none'}}
                      />
                    </div>
                  </div>
                  {picUrl && (
                    <div className='profile-pic-edit-remove-container'>
                      <img
                        src={toAbsoluteUrl('/media/design/icon-edit.svg')}
                        alt='edit'
                        className='profile-pic-edit-icon'
                        onClick={onImageClick}
                      />

                      <div className='profile-pic-remove-text' onClick={() => setPictureUrl('')}>
                        Remove
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className=' mb-6'>
              <GooglePlacesAutoCompleteInput
                formik={formik}
                type='text'
                field='address'
                placeholder='Address'
                label='Address'
                isRequired={true}
                types={['address']}
              />
            </div>

            <div className='civik-flex-row-wrap civik-poli-btn-wrapper'>
              <button
                type='button'
                className='btn btn-light btn-active-light-primary mt-7 skip-btn civik-profile-edit-cancel-btn'
                onClick={() => {
                  setIsEdit(false)
                }}
              >
                <span className='indicator-label'>Cancel</span>
              </button>

              <button
                type='submit'
                id='kt_sign_up_submit'
                className='btn btn-lg btn-primary w-100  mt-7 save-btn'
              >
                {!loading && <span className='indicator-label'>Update</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  )
}

export default Profile
