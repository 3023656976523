import {KTSVG} from '../../../_metronic/helpers'

const GenericModal = (props) => {
  const {
    id,
    title,
    body,
    buttonTextOk,
    buttonTextCancel,
    isOpen,
    setIsOpen,
    includeCancel,
    onClickOk,
    hideFooter,
  } = props
  if (!isOpen) {
    return <div />
  }
  return (
    <div
      className='modal fade show'
      style={{display: 'block', paddingTop: '12%', backgroundColor: 'rgba(255, 255, 255, 0.75)'}}
      tabIndex={-1}
      id={id ? id : 'kt_modal_civ'}
    >
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header p-3'>
            <h5 className='modal-title'>{title}</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              // data-bs-dismiss='modal'
              aria-label='Close'
              onClick={() => setIsOpen(false)}
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            <p>{body}</p>
          </div>
          {!hideFooter && (
            <div className='modal-footer p-3'>
              {includeCancel && (
                <button type='button' className='btn btn-light' onClick={() => setIsOpen(false)}>
                  {buttonTextCancel ? buttonTextCancel : 'Cancel'}
                </button>
              )}
              <button
                type='button'
                className='btn btn-primary'
                onClick={() => (onClickOk ? onClickOk() : setIsOpen(false))}
              >
                {buttonTextOk ? buttonTextOk : 'Ok'}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default GenericModal
