// import './Feed.scss'

import {useContext, useEffect, useState} from 'react'
import {LeftSideBar} from '../../pages/LeftSideBar'
import {RightSideBar} from '../../pages/RightSideBar'
import {Navbar} from '../../resuable/navbar/Navbar'
import NotLoggedInModal from '../../resuable/navbar/not_logged_in_modal/NotLoggedInModal'
import {PublicNavbar} from '../../resuable/navbar/public_navbar/PublicNavbar'
import './News.scss'
import {UserContext} from '../../contexts/usercontext'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import NewsTopStories from './components/NewsTopStories'
import NewsHeadlines from './components/NewsHeadlines'
import LocalNews from './components/LocalNews'
import FeedBar from '../../pages/components/CreatePostBar'
import ProfileContact from '../profile/ProfileContact'
import {ProfileContext} from '../../contexts/profilecontext'
import {CreatePostContext} from '../../contexts/createPostContext'
import {parseFeedFilters} from '../../helperfunctions/FeedHelpers'
import {FeedLocationContext} from '../../contexts/feedLocationContext'
import {FeedPostContext} from '../../contexts/feedPostContext'
import FEED_SERVICE from '../../services/feed/FeedService'
import NewsContext, {useNews} from '../../contexts/NewsContext'
import {CreatePostModalWrapper} from '../../pages/modals/CreatePostModalWrapper'
import {NotLoggedInModalContext} from '../../contexts/NotLoggedInModalContext'
import useMobileView from '../../hooks/UseMobileView'
import useTabletView from '../../hooks/UseTabletView'
import {MobileNavbar} from '../../resuable/navbar/MobileNavbar'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
}

export function News(props) {
  const USER_CONTEXT = useContext(UserContext)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const fully_authenticated =
    USER_CONTEXT.user && USER_CONTEXT.user.loggedIn && USER_CONTEXT.user.verified
  const PROFILE_CONTEXT = useContext(ProfileContext)
  const userBasicProfileComplete = PROFILE_CONTEXT.profile?.basicProfileComplete
  const CREATE_POST_CONTEXT = useContext(CreatePostContext)
  const NOT_LOGGED_IN_MODAL_CONTEXT = useContext(NotLoggedInModalContext)
  const [refreshLocalNews, setRefreshLocalNews] = useState(false)
  const [fullPageView, setFullPageView] = useState(null)
  function closePostModal() {
    CREATE_POST_CONTEXT.setShowModal({visible: false, postType: 'Create Post'})
  }
  const isTabletView = useTabletView()
  const isMobileView = useMobileView()

  return (
    <>
      <MobileNavbar />
      <div id='Feed' className='' style={{backgroundColor: '#f2f3f7', height: '100%'}}>
        {!fully_authenticated && <PublicNavbar />}

        {!fully_authenticated && NOT_LOGGED_IN_MODAL_CONTEXT.isModalOpen && <NotLoggedInModal />}

        <div className='NewsContainer'>
          <div
            className='News'
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            <div className='news__leftColumn'>
              {fullPageView == 'LocalNews' && (
                <LocalNews
                  view='LeftSideColumn'
                  fullPageView={fullPageView}
                  setFullPageView={setFullPageView}
                  refreshLocalNews={refreshLocalNews}
                />
              )}

              {fullPageView !== 'NewsHeadlines' && fullPageView !== 'LocalNews' && (
                <NewsTopStories fullPageView={fullPageView} setFullPageView={setFullPageView} />
              )}

              {fullPageView !== 'NewsTopStories' && fullPageView !== 'LocalNews' && (
                <NewsHeadlines fullPageView={fullPageView} setFullPageView={setFullPageView} />
              )}

              {isTabletView &&
                fullPageView !== 'NewsTopStories' &&
                fullPageView !== 'NewsHeadlines' && (
                  <LocalNews
                    view='LeftSideColumn'
                    fullPageView={fullPageView}
                    setFullPageView={setFullPageView}
                    refreshLocalNews={refreshLocalNews}
                  />
                )}
            </div>

            <div className='news__rightColumn'>
              <LocalNews
                view='RightSideColumn'
                fullPageView={fullPageView}
                setFullPageView={setFullPageView}
                refreshLocalNews={refreshLocalNews}
              />
              <div className='news__rightColumn__weather'>
                <RightSideBar customStyling={{width: '100%'}} />
              </div>

              {fully_authenticated && (
                <CreatePostModalWrapper
                  isOpen={CREATE_POST_CONTEXT.showModal.visible}
                  onAfterOpen={() => {}}
                  onRequestClose={closePostModal}
                  style={customStyles}
                  contentLabel='Example Modal'
                  postType={CREATE_POST_CONTEXT.showModal.postType}
                  setPostType={(newPostType) => {
                    CREATE_POST_CONTEXT.setShowModal({visible: true, postType: newPostType})
                  }}
                  updateFeed={(newPost) => {
                    console.log('newPost', newPost)
                    if (newPost._id) {
                      setRefreshLocalNews(true)
                      // FEED_POSTS_CONTEXT.setFeedPosts((feedPosts) => [newPost, ...feedPosts])
                    }
                  }}
                />
              )}

              <FeedBar
                customStyling={{margin: 0, marginTop: 20}}
                PROFILE_CONTEXT={PROFILE_CONTEXT}
                userBasicProfileComplete={userBasicProfileComplete}
                CREATE_POST_CONTEXT={CREATE_POST_CONTEXT}
              />
            </div>
          </div>
        </div>
      </div>{' '}
    </>
  )
}
