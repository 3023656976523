import * as Yup from 'yup'

export const initialValues = {
  // USER INFO
  firstName: '',
  middleName: '',
  lastName: '',
  singleName: false,

  // DATE OF BIRTH
  birthMonth: '',
  birthDay: '',
  birthYear: '',

  // CONTACT INFO
  email: '',
  confirmEmail: '',
  phoneNumber: '',

  // RESIDENTIAL ADDRESS
  streetAddress: '',
  city: '',
  province: '',
  postalCode: '',
  unitNumber: '',

  // MAILING ADDRESS
  mailingAddress: '',
  mailingCity: '',
  mailingProvince: '',
  mailingPostalCode: '',
  mailingUnitNumber: '',

  // PHOTO ID
  photoID: null,
}

export const fullNameSchema = Yup.object().shape({
  // USER INFO
  firstName: Yup.string().required('First name is required!'),
  lastName: Yup.string().required('Last name is required!'),
  // DATE OF BIRTH
  birthMonth: Yup.string().required('Date of birth is required!'),
  birthDay: Yup.string().required('Date of birth is required!'),
  birthYear: Yup.string().required('Date of birth is required!'),
  // CONTACT INFO
  email: Yup.string().required('Email is required!'),
  confirmEmail: Yup.string().required('Email confirmation is required!'),
  phoneNumber: Yup.string().required('Phone number is required!'),
  // RESIDENTIAL ADDRESS
  streetAddress: Yup.string().required('Street Address is required!'),
  city: Yup.string().required('City is required!'),
  province: Yup.string().required('Province is required!'),
  postalCode: Yup.string().required('Postal Code is required!'),
  // MAILING ADDRESS
  mailingAddress: Yup.string().required('Street Address is required!'),
  mailingCity: Yup.string().required('City is required!'),
  mailingProvince: Yup.string().required('Province is required!'),
  mailingPostalCode: Yup.string().required('Postal Code is required!'),
})

export const singleNameSchema = Yup.object().shape({
  // USER INFO
  firstName: Yup.string().required('First name is required!'),
  lastName: Yup.string().notRequired(),
  // DATE OF BIRTH
  birthMonth: Yup.string().required('Date of birth is required!'),
  birthDay: Yup.string().required('Date of birth is required!'),
  birthYear: Yup.string().required('Date of birth is required!'),
  // CONTACT INFO
  email: Yup.string().required('Email is required!'),
  confirmEmail: Yup.string().required('Email confirmation is required!'),
  phoneNumber: Yup.string().required('Phone number is required!'),
  // RESIDENTIAL ADDRESS
  streetAddress: Yup.string().required('Street Address is required!'),
  city: Yup.string().required('City is required!'),
  province: Yup.string().required('Province is required!'),
  postalCode: Yup.string().required('Postal Code is required!'),
  // MAILING ADDRESS
  mailingAddress: Yup.string().required('Street Address is required!'),
  mailingCity: Yup.string().required('City is required!'),
  mailingProvince: Yup.string().required('Province is required!'),
  mailingPostalCode: Yup.string().required('Postal Code is required!'),
})
