/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext, useCallback, useRef} from 'react'
// import {IProfileDetails, profileDetailsInitValues as initialValues} from '../../../modules/profile/components/settings/SettingsModel'

import {useFormik} from 'formik'
import * as Yup from 'yup'

import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'

import Modal from 'react-modal'

import TextField from '../../../reusable/TextField'

import {ProfileContext} from '../../../../../contexts/profilecontext'
import POLITICIAN_SERIVCE from '../../../../../services/politician/PoliticianService'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/storage'

import AuthService from '../../../../../services/auth/AuthService'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
}

const profileDetailsSchema = Yup.object().shape({
  fName: Yup.string().required('First name is required'),
  lName: Yup.string().required('Last name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  phoneNumber: Yup.string().required('Phone number is required'),
})

export function EditStaffModal(props) {
  const PROFILE_CONTEXT = useContext(ProfileContext)
  const [loading, setLoading] = useState(false)
  const [picUrl, setPictureUrl] = useState('')

  const [initialValues, setInitialValues] = useState({
    fName: '',
    lName: '',
    position: '',
    email: '',
    phoneNumber: '',
    picture: '',
  })
  const [progress, setProgress] = useState(0)
  const uploadRef = useRef(null)

  const onImageClick = useCallback(() => {
    uploadRef.current.click()
  }, [uploadRef])

  const onFileUpload = async (e) => {
    try {
      e.persist()

      const file = e.target.files[0]
      console.log('file', file)
      if (!file) return

      //  If the file size (calculated in byte form) is than 1MB
      if (file.size > 1.0e6) {
        console.log('IMAGE SIZE MUST BE LESS THAN 1MB')
      }
      if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
        return console.log('IMAGE TYPE MUST BE PNG OR JPG')
      }

      console.log('uploading image now!....')

      // retrieve staff list from profile
      let staffList = PROFILE_CONTEXT.profile.staff
      let staffIndex = 0

      if (staffList) {
        console.log('list found')
        staffIndex = staffList.length + 1
      }

      console.log('staffIndex', staffIndex)
      console.log('staffList', staffList.length)

      let usr_civik_id = PROFILE_CONTEXT.profile._id
      const uploadTask = firebase
        .storage()
        .ref(`profile_pics/${usr_civik_id}/staff/staff_${staffIndex.toString(2)}`)
        .put(file)

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
          setProgress(progress)
        },
        (error) => {
          console.log(error)
        },
        async () => {
          const setURL = await firebase
            .storage()
            .ref(`profile_pics/${usr_civik_id}/staff/staff_${staffIndex.toString(2)}`)
            .getDownloadURL()

          setPictureUrl(setURL)
          console.log(`====> setURL is ${setURL} <=======`)
        }
      )
    } catch (error) {
      console.log(error)
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(async () => {
        const userInput = {
          _id: props.staffId,
          first_name: values.fName,
          last_name: values.lName,
          email: values.email,
          position: values.position,
          phone: values.phoneNumber,
          profile_picture: picUrl,
        }

        // retrieve staff list from profile
        let staffList = PROFILE_CONTEXT.profile.staff
        // find the staff info inside list
        for (let i = 0; i < staffList.length; i++) {
          // update the staff info
          if (staffList[i]._id == props.staffId) {
            staffList[i] = userInput
          }
        }

        console.log('staffList', staffList)

        // save updated staff list
        await POLITICIAN_SERIVCE.updatePendingProfile({staff: staffList})
        setLoading(false)

        // close modal
        props.onRequestClose()
      }, 1000)
    },
  })

  const fetchStaffInfo = () => {
    try {
      console.log('props.staffId', props.staffId)

      // retrieve staff using staff _id
      let staff = PROFILE_CONTEXT.profile.staff.find((staff) => staff._id === props.staffId)
      console.log('staff to update: ', staff)

      // set form fields
      formik.values.fName = staff.first_name
      formik.values.lName = staff.last_name
      formik.values.position = staff.position
      formik.values.email = staff.email
      formik.values.phoneNumber = staff.phone

      console.log('UPDATE STFF PROFILE PIC', staff.profile_picture)

      setPictureUrl(staff.profile_picture)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (props.staffId) {
      fetchStaffInfo()
    }
  }, [props.staffId])

  useEffect(() => {
    // Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
    Modal.setAppElement('#EditStaff')
  }, [])

  return (
    <Modal
      ariaHideApp={false}
      isOpen={props.isOpen}
      onAfterOpen={props.onAfterOpen}
      onRequestClose={props.onRequestClose}
      style={customStyles}
      contentLabel='Example Modal'
    >
      <form className='civik-modal' onSubmit={formik.handleSubmit} noValidate>
        <div className='civik-modal-header civik-flex-row civik-justify-between'>
          <h1 className='civik-modal-title'>Edit Staff</h1>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/design/modal-x-btn.svg')}
            className='civik-modal-x-btn'
            onClick={props.onRequestClose}
          />
        </div>

        <div className='civik-modal-content'>
          {/* ROW 1 */}
          <div
            className='civik-flex-row-wrap'
            style={{justifyContent: 'space-between', marginTop: 20}}
          >
            {/* FIRST NAME & LAST NAME */}
            <div style={{width: '100%'}}>
              <div className='mb-6'>
                <TextField
                  formik={formik}
                  type='text'
                  field='fName'
                  placeholder='First Name'
                  label='First Name'
                  isRequired={true}
                />
              </div>
              <div className=' mb-6'>
                <TextField
                  formik={formik}
                  type='text'
                  field='lName'
                  placeholder='Last Name'
                  label='Last Name'
                  isRequired={true}
                />
              </div>
            </div>
            <div className='civik-concil-pic mobile-mb-20'>
              <label className=' col-form-label required fw-bold fs-6'>Profile Picture</label>

              <div className='civik-flex-column'>
                <img
                  className='civik-councilor-profile-pic'
                  src={picUrl ? picUrl : toAbsoluteUrl('/media/design/upload-img-sample.png')}
                  alt=''
                  onClick={onImageClick}
                />
                <progress value={progress} max='100' />
                <input
                  type='file'
                  onChange={onFileUpload}
                  ref={uploadRef}
                  style={{display: 'none'}}
                />
              </div>
              {picUrl && (
                <div className='profile-pic-edit-remove-container'>
                  <img
                    src={toAbsoluteUrl('/media/design/icon-edit.svg')}
                    alt='edit'
                    className='profile-pic-edit-icon'
                    onClick={onImageClick}
                  />

                  <div className='profile-pic-remove-text' onClick={() => setPictureUrl('')}>
                    Remove
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* ROW 2 */}
          <div className=' mb-6'>
            <TextField
              formik={formik}
              type='text'
              field='position'
              placeholder='Position'
              label='Position'
            />
          </div>
          {/* ROW 3 */}
          <div className='civik-flex-row-wrap '>
            {/* START */}
            <div className='w-100 mb-6 mr-40-responsive'>
              <TextField
                formik={formik}
                type='email'
                field='email'
                placeholder='Enter email'
                label='Email Address'
                isRequired={true}
              />
            </div>
            {/* END */}
            {/* START */}
            <div className='w-100 mb-6'>
              <TextField
                formik={formik}
                type='text'
                field='phoneNumber'
                placeholder='Phone Number'
                label='Phone Number'
                isRequired={true}
              />
            </div>
            {/* END */}
          </div>
          {/* ROW 4 */}
          <div className='civik-flex-row' style={{justifyContent: 'flex-end'}}>
            <button
              type='submit'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-active-light-primary w-100  mt-7 btn btn-light  '
              style={{maxWidth: 110}}
              onClick={props.onRequestClose}
            >
              <span className='indicator-label'>Cancel</span>
            </button>
            <button
              type='submit'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-primary w-100  mt-7'
              style={{marginLeft: 20, maxWidth: 110}}
            >
              {!loading && <span className='indicator-label'>Save</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </form>
    </Modal>
  )
}
