import axios from 'axios';
import environment from './environment'
import firebase from 'firebase/compat/app'

// axios.defaults.withCredentials = true;

const instance = axios.create({
  baseURL: `${environment.resolveApi().rest}`,
  withCredentials: true,
})

// attach access token
instance.interceptors.request.use(
  async config => {
    const access_token = await firebase.auth().currentUser?.getIdToken(true);
    if (access_token) {
      config.headers = { 
        'Authorization': `Bearer ${access_token}`,
        // 'Accept': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
    return config;
  },
  error => {    
    Promise.reject(error)
  });
    
// flattens the layer of nested introduced by axios
// the responses look like { data, error }, but axios nests the whole response under 'data'
instance.interceptors.response.use(
  (res) => res.data,
  (err) => {
    const error = {
      ...err.response.data.error,
      ...err,
    }

    // console.error(error);
    return Promise.reject(error)
  }
)

// monkey patch for image uploads
instance.uploadImage = async function uploadImage(route, formData, options = {}) {
  let headers = {'Content-Type': 'multipart/form-data'}

  if (options.headers) headers = {...headers, ...options.headers}

  const opts = {
    ...options,
    headers,
  }

  return this.post(route, formData, opts)
}

export default instance
