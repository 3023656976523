import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import civikApi from '../../../utils/civikAPI'

const VoterService = {
  createPost: async (payload) => {
    try {
      console.log('creating post...')
      let response = await civikApi.post(`/posts/basic`, payload)

      return response
      // return {code: 200, status: 'auth/success', message: 'logged in'}
    } catch (error) {
      console.log(error)

      // return ERROR_CODES.firebaseErrors[error.code]
    }
  },

  createShareNews: async (payload) => {
    try {
      console.log('creating share news post...')
      let response = await civikApi.post(`/posts/article`, payload)
      return response
    } catch (error) {
      console.log(error)
    }
  },

  updateProfile: async (payload) => {
    try {
      // update pending profile
      let response = await civikApi.put(`/users/`, payload)

      console.log('response', response)

      // return {code: 200, status: 'auth/success', message: 'logged in'}
    } catch (error) {
      console.log(error)
      console.log(error)
      // return ERROR_CODES.firebaseErrors[error.code]
    }
  },

  getProfileById: async (payload) => {
    try {
      // update pending profile
      let response = await civikApi.get(`/users/id/${payload}`)

      return response

      // return {code: 200, status: 'auth/success', message: 'logged in'}
    } catch (error) {
      console.log(error)
      // return ERROR_CODES.firebaseErrors[error.code]
    }
  },
  getProfileByNameUrl: async (payload) => {
    try {
      // update pending profile
      let response = await civikApi.get(`/users/${payload}`)

      return response;

      // return {code: 200, status: 'auth/success', message: 'logged in'}
    } catch (error) {
      console.log(error)
      // return ERROR_CODES.firebaseErrors[error.code]
    }
  },
}

export default VoterService
