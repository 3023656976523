import React from 'react';

const ProfileContact = (props) => {
  const {profile} = props
  return (
    <div className='profile__contact w-100'>
      <h5 className='mb-5'>Contact</h5>
      <div className='civik-flex-row'>
        <div className='civik-flex-column'>
          <span className='civik-dark-grey fw-bold mb-5' style={{marginRight: 50}}>
            Office Address:
          </span>
          {profile.contactNumber && (
            <span className='civik-dark-grey fw-bold mb-5' style={{marginRight: 50}}>
              Phone No.:
            </span>
          )}
          {profile.contactNumber && (
            <span className='civik-dark-grey fw-bold mb-5' style={{marginRight: 50}}>
              Email:
            </span>
          )}
          {profile.fax && (
            <span className='civik-dark-grey fw-bold mb-5' style={{marginRight: 50}}>
              Fax No.:
            </span>
          )}
        </div>

        <div className='civik-flex-column'>
          <span className=' fw-bold mb-5'>{profile.officeAddress}</span>
          {profile.contactNumber && <span className=' fw-bold mb-5'>{profile.contactNumber}</span>}

          {profile.email && <span className=' fw-bold mb-5'>{profile.email}</span>}

          {profile.fax && <span className=' fw-bold mb-5'>{profile.fax}</span>}
        </div>
      </div>
    </div>
  )
}

export default ProfileContact
