import {useState, useEffect, useContext} from 'react'
import {useParams, useHistory} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

import PetitionService from '../../../services/petition/PetitionService'
import {ProfileContext} from '../../../contexts/profilecontext'
import {ProfilePageContext} from '../../../contexts/profilePageContext'
import UserPicture from '../../../modules/reusable/UserPicture'
import './PetitionSign.scss'
import './PetitionDetails.scss'
import dateFormat, {masks} from 'dateformat'
import {useActivePetition} from '../../../contexts/ActivePetitionContext'

export function PetitionPreview() {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const PROFILE_CONTEXT = useContext(ProfileContext)
  const {currentTab, setCurrentTab} = useContext(ProfilePageContext)

  const {activePetition, clearActivePetition} = useActivePetition()

  const onPublish = async () => {
    try {
      setLoading(true)
      await PetitionService.createPetition({petition: activePetition})
      setCurrentTab('My Petitions')
      history.push('/myprofile')
      setLoading(false)
      clearActivePetition()
    } catch (error) {
      console.error(error)
    }
  }
  if (!activePetition) {
    return <div />
  }
  return (
    <div className='PetitionDetails__Outer'>
      <div className=' PetitionPreview'>
        <div className='petitionDetails__innerWrap'>
          <div style={{width: 700}}>
            <h1 className='petitionDetails_title'>{activePetition.title}</h1>

            <div className='petitionDetails__coverImgWrap'>
              {activePetition.cover_photo.source_url && (
                <img
                  className='petitionDetails__coverImg'
                  src={toAbsoluteUrl(activePetition.cover_photo.source_url)}
                />
              )}
              <div className='petitionDetails__socialsBar'>
                <div>
                  <img
                    className='petitionDetails__megaphoneIcon'
                    src={toAbsoluteUrl('/media/design/megaphone_icon.svg')}
                  ></img>
                  <span className='petitionDetails__socialsBar__txt'>
                    This petition needs{' '}
                    <span className='petitionDetails__socialsBar__signGoalTxt'>
                      {activePetition.signatureGoal ? activePetition.signatureGoal : 300}
                    </span>{' '}
                    Signatures to reach target
                  </span>
                </div>
              </div>
            </div>

            <div className='petitionDetails__toWrap'>
              <span className='petitionDetails__toLabel'>Petition to:</span>
              <span className='petitionDetails__toValue'>
                {activePetition.location_city} City Council
              </span>
            </div>

            <div className='petitionDetails__descWrap'>
              <p className='petitionDetails__desc'>{activePetition.description}</p>
            </div>
          </div>

          <div className='petitionDetails__signBar'>
            <span className='petitionDetails__signBarStatsTxt'>
              <span className='petitionDetails__signBar__numOfSigns'>0</span> of{' '}
              <span className='petitionDetails__signBar__numOfSignsGoal'>
                {' '}
                {activePetition.signatureGoal ? activePetition.signatureGoal : 300}
              </span>{' '}
              Signed
            </span>
            <button
              className='petitionDetails__signBar__editBtn'
              onClick={() => {
                history.goBack()
              }}
            >
              Continue Editing
            </button>
            {!loading && (
              <button className='petitionDetails__signBar__publishBtn' onClick={onPublish}>
                Publish
              </button>
            )}
            {loading && (
              <button className='petitionDetails__signBar__publishBtn'>Please wait...</button>
            )}

            <div className='petitionDetails__signBar__date'>
              <span className='petitionDetails__signBar__dateLabel'>Date Created</span>
              <span className='petitionDetails__signBar__dateValue'>
                {dateFormat(
                  activePetition.draftCreatedAt ? activePetition.draftCreatedAt : Date.now(),
                  'mmm dS, yyyy'
                )}
              </span>
            </div>

            {/* <div className=' petitionDetails__signBar__sch'>
              <span className='petitionDetails__signBar__dateLabel'>
                Schedule in the city council
              </span>
              <span className='petitionDetails__signBar__dateValue'>Mar 09, 2022</span>
            </div> */}

            <div className='petitionDetails__organizerContentWrap'>
              <UserPicture
                isReply={false}
                profile={PROFILE_CONTEXT.profile}
                styleClasses={{
                  profilePic: ' petitionDetails__organizerImg',
                  nameLetter: ' navbar__barNameLetter petitionDetails__organizerImg',
                  letterWrap: 'feed__postCommentBarLetterWrap petitionDetails__organizerImg',
                }}
              />

              <div className='createPetitions__organizerTxtWrap'>
                <span className='petitionDetails__organizerTxtLabel'>Organized By</span>
                <span className='petitionDetails__organizerTxtValue'>
                  {PROFILE_CONTEXT.profile.full_name}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
