/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext, useCallback, useRef} from 'react'
// import {IProfileDetails, profileDetailsInitValues as initialValues} from '../../../modules/profile/components/settings/SettingsModel'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useHistory} from 'react-router-dom'

import ReactPlayer from 'react-player'

import dateFormat, {masks} from 'dateformat'

import {Comments} from '../../../pages/Comments'

import '../../../pages/Feed.scss'

import POST_TYPE_COLORS from '../../../constants/PostTypeColors'

import {CopyToClipboard} from 'react-copy-to-clipboard'
import UserPictureItem from '../../reusable/UserPictureItem'
import {ProfileContext} from '../../../contexts/profilecontext'
import {GlobalPrivateModalContext} from '../../../contexts/GlobalModalContext'
import MODAL_NAMES from '../../../constants/ModalNames'
import FEED_SERVICE from '../../../services/feed/FeedService'
import NewsGuardLabel from '../../../pages/newsGuardLabel/NewsGuardLabel'
import ReadMoreText from '../../reusable/ReadMoreText'

import Linkify from 'linkify-react'
import PostActionBar from './PostActionBar'
import PostReactions from './PostReactions'

import './PostReactions.scss'

const linkify_options = {defaultProtocol: 'https', target: '_blank', rel: 'noopener noreferrer'}

type Props = {
  feed: any
  index?: number
  profilePage?: boolean
  isSinglePost: boolean
}

const PostItem = ({feed, index, profilePage = false, isSinglePost}: Props) => {
  const history = useHistory()
  const postOptionsRef = useRef(null)
  const [postOptionsDropdown, setPostOptionsDropdown] = useState(null)
  const [actionPopupContent, setActionPopupContent] = useState('Copied to clipboard')
  const [showElement, setShowElement] = React.useState(false)
  const [commentIndex, setCommentIndex] = useState(null)
  const [likeDisabled, setLikedDisabled] = useState<boolean>()
  const [liked, setLiked] = useState(feed.userPostVote ? true : false)
  const [numLikes, setNumLikes] = useState(feed.upvotes)

  const PROFILE_CONTEXT = useContext(ProfileContext)
  const userBasicProfileComplete = PROFILE_CONTEXT.profile?.basicProfileComplete
  const {setIsOpenGlobalModal} = useContext(GlobalPrivateModalContext)
  const setIsOpenCompleteProfile = (o) => setIsOpenGlobalModal(MODAL_NAMES.COMPLETE_PROFILE, o)

  const setIsOpenWrongCommunity = (o) => setIsOpenGlobalModal(MODAL_NAMES.WRONG_COMMUNITY, o)
  const userInCommunity = PROFILE_CONTEXT.profile?.communities?.includes(feed.community)

  const [newCommentTrigger, setNewCommentTrigger] = useState(false)

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        postOptionsDropdown &&
        postOptionsRef.current &&
        !postOptionsRef.current.contains(e.target)
      ) {
        setPostOptionsDropdown(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [postOptionsDropdown])

  useEffect(() => {
    setTimeout(function () {
      setShowElement(false)
    }, 5000)
  }, [showElement])

  const likePost = async (postId) => {
    setLikedDisabled(true)
    setLiked(true)
    setNumLikes(numLikes + 1)
    let response = await FEED_SERVICE.likePost(postId)
    if (response.code !== 200) {
      setLiked(false)
      setNumLikes(numLikes - 1)
    }
    setLikedDisabled(false)
  }

  const unlikePost = async (postId) => {
    setLikedDisabled(true)
    setLiked(false)
    setNumLikes(numLikes - 1)
    let response = await FEED_SERVICE.unlikePost(postId)
    if (response.code !== 200) {
      setLiked(true)
      setNumLikes(numLikes + 1)
    }
    setLikedDisabled(false)
  }

  const reportPost = async (postId) => {
    try {
      let response = await FEED_SERVICE.reportPost(postId)
      console.log('reported post response', response, postId)
    } catch (error) {
      console.log(error)
    }
  }

  const HASHTAG_FORMATTER = (string) => {
    return string
      .split(/(#[A-Za-z0-9-_]+)/gi)
      .filter(Boolean)
      .map((v, i) => {
        if (v.includes('#')) {
          return (
            <span key={i} className='civik-primary-blue'>
              {v}
            </span>
          )
        } else {
          return (
            <Linkify as='span' options={linkify_options}>
              {v}
            </Linkify>
          )
        }
      })
  }

  return (
    <div className={`feed__post ${profilePage ? 'MyPosts' : ''}`} key={feed._id}>
      {showElement && <div className='feed__postCopiedTooltip'>{actionPopupContent}</div>}

      <div className='feed__postHeader'>
        <div className='feed__postProfileOuterWrap'>
          <UserPictureItem
            item={feed}
            styleClasses={{
              anonPicStyle: 'feed__postProfileImg',
              profilePicStyle: 'feed__postProfileImg',
              letterPicStyle: ' navbar__barNameLetter',
              letterPicWrapperStyle: 'feed__postCommentBarLetterWrap feed__postHeaderLetterWrap',
            }}
          />
          <div className='feed__postProfileInnerWrap'>
            {feed.anonymous && (
              <span className='feed__postProfileInnerWrapName'>{feed.anon_username}</span>
            )}

            {feed.user && !feed.anonymous && (
              <span className='feed__postProfileInnerWrapName'>
                {feed.user.first_name + ' ' + feed.user.last_name}
              </span>
            )}

            <span className='feed__postProfileInnerWrapDate'>
              {feed.community ? feed.community + ', ' : ''}{' '}
              {dateFormat(feed.created_at, 'mmmm dS, h:MM TT')}
            </span>
          </div>
        </div>
        <div className='feed__postTypeOuterWrap '>
          {feed.topic && feed.topic !== 'Select a topic' && POST_TYPE_COLORS[feed.topic] && (
            <div
              className='feed__postTypeWrap mobile__hide'
              style={{borderColor: POST_TYPE_COLORS[feed.topic].borderColor}}
            >
              <span
                className='feed__postTypeTxt'
                style={{color: POST_TYPE_COLORS[feed.topic].color}}
              >
                {feed.topic}
              </span>
            </div>
          )}

          {feed.featured_post && (
            <div
              className='feed__postTypeWrap mobile-hide'
              style={{borderColor: POST_TYPE_COLORS.Featured.borderColor}}
            >
              <span
                className='feed__postTypeTxt'
                style={{color: POST_TYPE_COLORS.Featured.borderColor}}
              >
                Featured
              </span>
            </div>
          )}

          <div>
            <div
              className='feed__postElipsisContainer'
              onClick={() => {
                !userBasicProfileComplete
                  ? setIsOpenCompleteProfile(true)
                  : !userInCommunity
                  ? setIsOpenWrongCommunity(true)
                  : setPostOptionsDropdown(true)
              }}
            >
              <svg
                className='feed__postElipsis'
                width='18'
                height='5'
                viewBox='0 0 18 5'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <circle cx='15.8567' cy='2.14286' r='2.14286' fill='#6C7289' />
                <circle cx='9.00028' cy='2.14286' r='2.14286' fill='#6C7289' />
                <circle cx='2.14286' cy='2.14286' r='2.14286' fill='#6C7289' />
              </svg>
            </div>

            {postOptionsDropdown && (
              <div className='navbar__dropdown dropdown__postOptions' ref={postOptionsRef}>
                <div className='civik-flex-column dropdown__txtWrap'>
                  <div className='civik-flex-column '>
                    {feed.shareURL && (
                      <CopyToClipboard text={feed.shareURL}>
                        <div
                          className='dropdown__iconOptionWrap '
                          onClick={() => {
                            if (!userBasicProfileComplete) setIsOpenCompleteProfile(true)
                            else if (!userInCommunity) setIsOpenWrongCommunity(true)
                            else {
                              setShowElement(true)
                              setActionPopupContent('Copied to clipboard')
                              setPostOptionsDropdown(false)
                            }
                          }}
                        >
                          <span className='createPostModal_optionTxt'>Copy Post Link</span>
                        </div>
                      </CopyToClipboard>
                    )}

                    <div
                      className='dropdown__iconOptionWrap '
                      onClick={() => {
                        if (!userBasicProfileComplete) setIsOpenCompleteProfile(true)
                        else if (!userInCommunity) setIsOpenWrongCommunity(true)
                        else {
                          reportPost(feed._id)
                          setShowElement(true)
                          setActionPopupContent('Post Reported')
                          setPostOptionsDropdown(false)
                        }
                      }}
                    >
                      <span className='createPostModal_optionTxt'>Report Post</span>
                    </div>
                    {PROFILE_CONTEXT.profile?.admin && (
                      <div
                        className='dropdown__iconOptionWrap '
                        onClick={() => {
                          FEED_SERVICE.deletePost(feed._id)
                          setShowElement(true)
                          setActionPopupContent('Post Deleted, Refresh Feed')
                          setPostOptionsDropdown(false)
                        }}
                      >
                        <span className='createPostModal_optionTxt'>Delete Post</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className='feed__postBody'>
        <div className='feed__postTypeWrapMobile '>
          {feed.featured_post && (
            <div
              className='feed__postTypeWrap mobile-show'
              style={{borderColor: POST_TYPE_COLORS.Featured.borderColor}}
            >
              <span
                className='feed__postTypeTxt'
                style={{color: POST_TYPE_COLORS.Featured.borderColor}}
              >
                Featured
              </span>
            </div>
          )}

          {feed.topic && feed.topic !== 'Select a topic' && (
            <div
              className='feed__postTypeWrap mobile__show'
              style={{borderColor: POST_TYPE_COLORS[feed.topic].borderColor}}
            >
              <span
                className='feed__postTypeTxt'
                style={{color: POST_TYPE_COLORS[feed.topic].color}}
              >
                {feed.topic}
              </span>
            </div>
          )}
        </div>

        <h5 className='feed__postBodyTitle'>{feed.title}</h5>

        {feed.description ? (
          <ReadMoreText text={HASHTAG_FORMATTER(feed.description)} className='feed__postBodyTxt' />
        ) : (
          <div style={{marginBottom: 20}}></div>
        )}

        {/* if the post is a basic post */}
        {feed.post_type == 6 && feed.preview_image !== '' && (
          <div>
            {/* if a video was attached to this post */}
            {feed.media[0] && feed.media[0].display_type == 'video' && (
              <ReactPlayer
                url={feed.media[0].source_url}
                className='feed_postVideo'
                style={{
                  marginTop: feed.description ? 20 : 0,
                }}
              />
            )}
            {/*  if an image was attached to this post */}
            {feed.media[0] &&
              feed.media[0].display_type == 'image' &&
              feed.media[0].source_url !== '' && (
                <img
                  className='feed__postBodyPreviewImg'
                  style={{marginTop: feed.description ? 20 : 0}}
                  src={feed.preview_image}
                ></img>
              )}
          </div>
        )}

        {/* if the post is a news post */}
        {feed.post_type == 2 && feed.preview_image !== '' && (
          <div>
            <a href={feed.url} target='_blank' rel='noopener noreferrer'>
              <div className='feed__postBodyNewsImg'>
                <img
                  className='feed__postBodyPreviewImg'
                  style={{marginTop: feed.description ? 20 : 0}}
                  src={feed.preview_image}
                ></img>
              </div>
            </a>
            {feed.linkPreview && (
              <div className='feed__postBodyNewsOverlay'>
                <a href={feed.url} target='_blank' rel='noopener noreferrer'>
                  <span className='feed__postBodyNewsMetaTitle'>{feed.linkPreview.title}</span>
                </a>
                <br></br>
                <NewsGuardLabel post={feed} />
              </div>
            )}
          </div>
        )}
      </div>

      <PostReactions view='feed' numLikes={numLikes} feed={feed} history={history} />

      <PostActionBar
        post={feed}
        index={index}
        numLikes={numLikes}
        setNumLikes={setNumLikes}
        setNewCommentTrigger={setNewCommentTrigger}
        view='feed'
      />

      {isSinglePost ? (
        <Comments
          numOfComments={feed.no_of_comments}
          postId={feed._id}
          sortBy={'recent'}
          community={feed.community}
          triggerComment={newCommentTrigger}
          isSinglePost={true}
        />
      ) : (
        <div className='mobile-hide'>
          <Comments
            numOfComments={feed.no_of_comments}
            postId={feed._id}
            sortBy={'recent'}
            community={feed.community}
            triggerComment={newCommentTrigger}
            comments={feed.comments}
          />
        </div>
      )}
    </div>
  )
}
export default PostItem
