import React, {useContext, useEffect, useState} from 'react'
import '../News.scss'
import './NewsTopStories.scss'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import FEED_SERVICE from '../../../services/feed/FeedService'
import TwoColumnList from './TwoColumnList'
import NewsSectionHeader from './NewsSectionHeader'
import Slider from 'react-slick'

// Import css files
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import useMobileView from '../../../hooks/UseMobileView'
import NewsTopStoryCard from './NewsTopStoryCard'
import NoNews from './NoNews'
import useTabletView from '../../../hooks/UseTabletView'
import {FeedLocationContext} from '../../../contexts/feedLocationContext'
import {parseFeedFilters} from '../../../helperfunctions/FeedHelpers'

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}

const NewsTopStories = ({fullPageView, setFullPageView}) => {
  const [topStories, setTopStories] = useState([])
  const [feedLoading, setFeedLoading] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(null)
  const isMobileView = useMobileView()
  const isTabletView = useTabletView()

  const {feedLocation} = useContext(FeedLocationContext)

  const getTopStories = async () => {
    try {
      // Set feed loading state to true
      setFeedLoading(true)

      // Fetch news posts based on user location
      const locationFilter = parseFeedFilters('All', feedLocation)
      let response = await FEED_SERVICE.getNewsPosts({
        ...locationFilter,
        trending: true,
        limit: fullPageView !== 'NewsTopStories' ? 2 : null,
        // news source
      })

      let topStories = response

      // Update news headlines or set it to an empty array if response is falsy
      setTopStories(topStories || [])

      // Set feed loading state back to false
      setFeedLoading(false)
    } catch (error) {
      // Handle any errors and log them
      console.error(error)
    }
  }

  // This function will be called when the Carousel changes its selected index
  const handleCarouselChange = (index) => {
    setSelectedIndex(index)
  }

  useEffect(() => {
    console.log('FETCHING THE Top stories _________')

    getTopStories()
  }, [feedLocation, fullPageView])

  if (feedLoading) {
    return (
      <div className='feed_loadingContainer'>
        <img
          className='feed__loader'
          src={toAbsoluteUrl('/media/design/feed_loader.svg')}
          alt='loading'
        />
      </div>
    )
  }

  if (!(topStories.length > 0)) {
    return <NoNews section={'Top Stories'} />
  }

  return (
    <>
      {fullPageView == 'NewsTopStories' ? (
        <div className='news__headlines__header'>
          <div
            className='news__section__chevronRightBtn'
            onClick={() => {
              setFullPageView('')
            }}
          >
            <span className='news__section__title'>{'< Back'}</span>
          </div>

          <span className='news__section__title'>Top Stories</span>

          <div className='news__section__title--invisibleRightBlock'></div>
        </div>
      ) : (
        <div className='news__headlines__header'>
          <NewsSectionHeader
            title='Top Stories'
            routeTo='NewsTopStories'
            setFullPageView={setFullPageView}
          />
        </div>
      )}
      {/* FULL PAGE VIEW */}
      {fullPageView == 'NewsTopStories' ? (
        <TwoColumnList data={topStories} />
      ) : (
        <div className='news__topStory'>
          {!isMobileView ? (
            // WEB VIEW
            <div className='news__topStory--web'>
              {topStories.map((post, index) => {
                return (
                  <NewsTopStoryCard
                    fullPageView={fullPageView}
                    key={post._id}
                    post={post}
                    index={index}
                    view='web-home'
                    customStyling={{height: !isTabletView ? 370 : 452}}
                  />
                )
              })}
            </div>
          ) : (
            // MOBILE VIEW
            <>
              {topStories.length > 0 && (
                <Slider {...settings}>
                  {topStories.map((post, index) => {
                    return (
                      <NewsTopStoryCard
                        fullPageView={fullPageView}
                        post={post}
                        key={post._id}
                        index={index}
                        view='mobile'
                        customStyling={{}}
                      />
                    )
                  })}
                </Slider>
              )}
            </>
          )}
        </div>
      )}
    </>
  )
}

export default NewsTopStories
