import React, {useState, useEffect, useContext, useRef, useCallback} from 'react'
import {ProfileHeader} from '../../modules/profile/ProfileHeader'
import {ProfilePageContext} from '../../contexts/profilePageContext'

import {toAbsoluteUrl} from '../../../_metronic/helpers'
import TextField from '../../../app/modules/auth/reusable/TextField'
import SelectField from '../../../app/modules/auth/reusable/SelectField'
import {useFormik} from 'formik'
import * as Yup from 'yup'

import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/storage'
import {MyPosts} from './MyPosts'
import {useLocation, useParams, useHistory} from 'react-router-dom'

import VOTER_SERVICE from '../../services/voter/VoterService'

import AUTH_SERVICE from '../../services/auth/AuthService'
import ROUTE_PATHS from '../../routing/RoutePaths'
import ProfileBasicInfo from '../../modules/profile/ProfileBasicInfo'
import ProfileContact from '../../modules/profile/ProfileContact'
import ProfileSocials from '../../modules/profile/ProfileSocials'

export function PublicProfile() {
  const [profile, setProfile] = useState({
    full_name: '',
    location_city: '',
    location_region: '',
    postal_code: '',
    contactNumber: '',
    officeAddress: '',
    user_politician: false,
    user_pendingPolitician: false,
    address: '',
    phone: '',
    _id: '',
    __t: undefined,
    electedOrCandidate: undefined,
    bio: undefined,
    office: undefined,
  })
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const {currentTab, setCurrentTab} = useContext(ProfilePageContext)
  let {handle} = useParams() as {handle: string}

  const fetchProfile = async () => {
    try {
      setLoading(true)
      let publicUser = await VOTER_SERVICE.getProfileByNameUrl(handle)

      setProfile({...profile, ...publicUser})
      setLoading(false)
      if (!publicUser) {
        history.push(ROUTE_PATHS.HOME)
      }
      // console.log('publicUser', publicUser)
    } catch (error) {
      console.log(error)
      history.push(ROUTE_PATHS.HOME)
    }
  }

  const isCouncilorType = () => {
    if (profile?.electedOrCandidate == 'elected') {
      return 'City Councillor'
    } else if (profile?.electedOrCandidate == 'candidate' && profile?.office) {
      return `${profile.office} Candidate`
    } else if (profile?.electedOrCandidate == 'candidate') {
      return `City Candidate`
    } else {
      return null
    }
  }

  useEffect(() => {
    fetchProfile()
  }, [profile._id])

  if (currentTab == 'My Petitions') {
    return (
      <div className='Profile form w-100 fv-plugins-bootstrap5 fv-plugins-framework'>
        <ProfileHeader
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          profile={profile}
          publicProfile
        />
        <div
          className='civik-flex-row mb-10'
          style={{justifyContent: 'space-between', alignItems: 'center', marginTop: 30}}
        >
          <h3 className='profile__title'>Petitions</h3>
        </div>
      </div>
    )
  }

  if (currentTab == 'My Posts') {
    return (
      <div className='Profile form w-100 fv-plugins-bootstrap5 fv-plugins-framework'>
        <ProfileHeader
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          profile={profile}
          publicProfile
        />{' '}
        <h3 className='profile__title'>Posts</h3>
        <div className='profile__myPosts'>
          <MyPosts profileId={profile._id} />
        </div>
      </div>
    )
  }
  if (loading) {
    return <div />
  }
  //currenttab about
  return (
    <div className='Profile form w-100 fv-plugins-bootstrap5 fv-plugins-framework '>
      <ProfileHeader
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        profile={profile}
        publicProfile
      />

      <div className='profile__titleBar'>
        <h3 className='profile__title'>Profile Details</h3>
      </div>

      <div className='profile__contentOuterWrap w-100'>
        <div className='civik-flex-column w-100'>
          <ProfileBasicInfo
            profile={profile}
            isCouncilorType={isCouncilorType}
            publicProfile={true}
          />

          {isCouncilorType() !== null && (
            <div className=' profile__about'>
              <h5 className='mb-5'>About</h5>
              <p>{profile.bio}</p>
            </div>
          )}
        </div>

        {/* COUNCILOR INFO */}

        {isCouncilorType() !== null && (
          <div className='civik-flex-column w-100' style={{marginRight: 20}}>
            <ProfileContact profile={profile} />
            <ProfileSocials profile={profile} />
          </div>
        )}
      </div>
    </div>
  )
}
