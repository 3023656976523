const CreatePetitionStep = ({step, activeStepNum}) => {
  const {num, title, subtitle} = step

  return (
    <div className=' createPetition__leftBar__tab  '>
      <div
        className={
          activeStepNum === num
            ? 'createPetition__leftBar__tabNum '
            : 'createPetition__leftBar__tabNum createPetition__leftBar__tabInactive'
        }
      >
        <span
          className={
            activeStepNum === num
              ? 'createPetition__leftBar__tabNumVal '
              : 'createPetition__leftBar__tabNumVal createPetition__leftBar__tabNumValInactive'
          }
        >
          {num}
        </span>
      </div>

      <div className='createPetition__leftBar__tabLabel'>
        <span className='createPetition__leftBar__tabLabelVal'>{title}</span>
        <span className='createPetition__leftBar__tabLabelSm'>{subtitle}</span>
      </div>
    </div>
  )
}

export default CreatePetitionStep
