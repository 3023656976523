import React, {useState, useEffect, useContext, useRef, useCallback} from 'react'

import {ProfileContext} from '../../contexts/profilecontext'
import {useActivePetition} from '../../contexts/ActivePetitionContext'

import {Step1} from './create_petition/Step1'
import {Step2} from './create_petition/Step2'
import {Step3} from './create_petition/Step3'
import {TermsError} from './create_petition/components/terms_error/TermsError'
import CreatePetitionStep from './create_petition/SideBarStep'
import SideBarPrivacy from './create_petition/SideBarPrivacy'

import './CreatePetition.scss'

const steps = [
  {
    num: 1,
    title: 'Petition Info',
    subtitle: 'Petition basic info',
  },
  {
    num: 2,
    title: 'Petition Details',
    subtitle: 'Explain the problem',
  },
  {
    num: 3,
    title: 'Publish',
    subtitle: 'Preview and publish',
  },
]

export function CreatePetition(props) {
  const [loading, setLoading] = useState(false)
  const PROFILE_CONTEXT = useContext(ProfileContext)
  const [initializing, setInitializing] = useState(true)
  const {activePetition, setActivePetition, initializePetition, termsError} = useActivePetition()

  useEffect(() => {
    setInitializing(true)
    if (!activePetition) {
      initializePetition()
    }
    setInitializing(false)
  }, [])

  if (initializing || !activePetition) {
    return <div />
  }

  return (
    <div id='CreatePetition' style={{height: '100%'}}>
      {termsError && <TermsError />}

      <div className='CreatePetition' style={{height: '100%'}}>
        {/* LEFT SIDE BAR  ---------------------------------------------------------------------- */}
        <div className='createPetition__leftSideBar'>
          <div>
            {steps.map((step) => (
              <CreatePetitionStep step={step} activeStepNum={activePetition.step} />
            ))}
          </div>
          <SideBarPrivacy />
        </div>
        {/* LEFT SIDE BAR END  ---------------------------------------------------------------------- */}
        {/* Right/ Main View */}
        {loading ? (
          <div className='civik-wh-card civik-profile-edit-right-bar  createPetition__loadingScreen'>
            <span>Loading...</span>
          </div>
        ) : activePetition.step === 1 ? (
          <Step1 setPageLoading={setLoading} />
        ) : activePetition.step === 2 ? (
          <Step2 setPageLoading={setLoading} />
        ) : activePetition.step === 3 ? (
          <Step3 setPageLoading={setLoading} />
        ) : null}
        {/* End Right/ Main View */}
      </div>
    </div>
  )
}

export default CreatePetition
