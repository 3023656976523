import {toAbsoluteUrl} from '../../../_metronic/helpers'
import React, {useEffect, useState} from 'react'
import FileUploadModal, {STATUS_TYPES} from '../../pages/modals/FileUploadModal'
import AdminService from '../../services/admin/AdminService'
import VoterListHistory from './VoterListHistory'

const EmailUploadBanner = ({onClickButton}) => (
  <div
    className='profile__councilDisclaimer profile__disclaimerPrimary'
    style={{justifyContent: 'space-between'}}
  >
    <div className='disclaimerInnterContainer '>
      <img
        className='profile__warningIcon'
        src={toAbsoluteUrl('/media/design/feather-mail.svg')}
        alt='upload'
      />
      <div className='profile__councilDisclaimerTxtWrap'>
        <span className='profile__councilDisclaimerTxtTitle'>
          If you did <span style={{fontWeight: '900'}}>not automatically send emails</span> when
          assigning voter pins,
          <br />
          upload the file you downloaded from Assign Voter Pins to send emails now
        </span>
      </div>
    </div>
    <div style={{marginRight: 20}}>
      <button className='civik-btn-outline-primary' onClick={onClickButton}>
        Upload File
      </button>
    </div>
  </div>
)

const VoterListEmailPins = (props) => {
  const {uploadHistory, getUploadHistory} = props
  const history = uploadHistory ? uploadHistory : []

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false)
  const [status, setStatus] = useState(STATUS_TYPES.upload)
  const [downloadURL, setSetDownloadURL] = useState(null)

  const sendCredentialEmails = async (selectedFile) => {
    setStatus(STATUS_TYPES.loading)
    const formData = new FormData()
    formData.set('voterlist', selectedFile)
    const res = await AdminService.sendVoterPins(formData)
    if (res?.success) {
      // @ts-ignore
      const href = URL.createObjectURL(res.data)
      setSetDownloadURL(href)
      setStatus(STATUS_TYPES.success)
    } else {
      setStatus(STATUS_TYPES.error)
    }
  }

  const onCloseModal = () => {
    URL.revokeObjectURL(downloadURL)
    setSetDownloadURL(null)
    setIsUploadModalOpen(false)
    setStatus(STATUS_TYPES.upload)
  }

  useEffect(() => {
    if (!isUploadModalOpen) {
      onCloseModal()
      getUploadHistory()
    }
  }, [isUploadModalOpen])

  return (
    <div className='w-100 civik-wh-card'>
      <EmailUploadBanner onClickButton={() => setIsUploadModalOpen(true)} />
      <FileUploadModal
        title={'Email Voter Pins'}
        isOpen={isUploadModalOpen}
        setIsOpen={setIsUploadModalOpen}
        onClickAdd={sendCredentialEmails}
        status={status}
        downloadUrl={downloadURL}
        uploadConfirmText='Send Emails'
      />
      <VoterListHistory history={history} />
    </div>
  )
}

export default VoterListEmailPins
