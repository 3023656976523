// src/components/NotLoggedInModal.js
import React, {useContext, useEffect, useRef} from 'react'
import styled from 'styled-components'
import ROUTE_PATHS from '../../../routing/RoutePaths'
import './NotLoggedInModal.scss'
import {useHistory} from 'react-router-dom'
import {NotLoggedInModalContext} from '../../../contexts/NotLoggedInModalContext'
import FeatherIcon from 'feather-icons-react'

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99999999999;
`

const ModalContent = styled.div`
  display: flex;
  flex-flow: column;
  background-color: white;
  padding: 50px;
  border-radius: 10px;
  width: 400px;
  text-align: center;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: column;
  margin-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
`

const NotLoggedInModal = () => {
  const history = useHistory()
  const NOT_LOGGED_IN_MODAL_CONTEXT = useContext(NotLoggedInModalContext)

  return (
    <ModalWrapper>
      <ModalContent>
        <div className='notLoggedInModal__closeBtn'>
          <FeatherIcon
            icon='x'
            color='#000'
            onClick={() => {
              NOT_LOGGED_IN_MODAL_CONTEXT.setIsModalOpen(false)
            }}
          />
        </div>

        <span className='notLoggedInModal__title'>Oops! You're not logged in.</span>
        <span className='notLoggedInModal__subtitle'>Please login or signup to continue.</span>

        <ButtonWrapper>
          <button
            className='notLoggedInModal__loginBtn'
            onClick={() => {
              history.push('/auth/login')
              NOT_LOGGED_IN_MODAL_CONTEXT.setIsModalOpen(false)
            }}
          >
            Login
          </button>
          <button
            className='notLoggedInModal__signupBtn'
            onClick={() => {
              history.push('/auth/signup')
              NOT_LOGGED_IN_MODAL_CONTEXT.setIsModalOpen(false)
            }}
          >
            Sign Up
          </button>
        </ButtonWrapper>
      </ModalContent>
    </ModalWrapper>
  )
}

export default NotLoggedInModal
