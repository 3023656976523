import {toAbsoluteUrl} from '../../../_metronic/helpers'

import './CivikModal.scss'

export function ModalHeader(props) {
  return (
    <div className='civikModal__header'>
      <span className='civikModal__headerTitle'>{props.title}</span>

      <img
        alt='Logo'
        src={toAbsoluteUrl('/media/design/modal-x-btn.svg')}
        className='civik-modal-x-btn'
        onClick={props.onRequestClose}
      />
    </div>
  )
}
