import React from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

const NewsSectionHeader = ({title, routeTo, setFullPageView}) => {
  return (
    <div className='NewsSectionHeader'>
      <span className='news__section__title'>{title}</span>
      <img
        onClick={() => {
          setFullPageView(routeTo)
        }}
        alt=''
        className='news__section__chevronRightBtn'
        src={toAbsoluteUrl('/media/design/icon_circle_right.svg')}
      />
    </div>
  )
}

export default NewsSectionHeader
