export function CustomSVG({view, width, height, className}) {
  const LikeActive = (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.5949 0H6.40514C2.86701 0 0 2.86701 0 6.40514V18.5949C0 22.133 2.86701 25 6.40514 25H18.5949C22.133 25 25 22.133 25 18.5949V6.40514C25 2.86701 22.133 0 18.5949 0ZM24.357 18.2804C24.357 21.636 21.636 24.357 18.2804 24.357H6.71965C3.36403 24.357 0.643042 21.636 0.643042 18.2804V6.71965C0.643042 3.36403 3.36403 0.643042 6.71965 0.643042H18.2804C21.636 0.643042 24.357 3.36403 24.357 6.71965V18.2804Z'
        fill='#6C7289'
      />
      <path
        d='M21.5267 10.8308C21.639 9.55592 20.4512 9.18245 19.9794 9.18245C18.1514 9.18245 16.3234 9.18245 14.4953 9.18245C14.7593 8.60961 15.0794 7.09046 14.675 4.99847C14.515 4.1701 14.0264 3.62253 13.4058 3.63096C12.906 3.63938 12.4258 4.02689 12.3753 4.50706C12.3191 5.03778 12.1899 5.79034 12.0664 6.30702C11.6732 7.95534 9.56721 10.1316 8.2362 10.8926V10.915L8.21093 10.9038V10.1793C8.21093 9.86481 7.9582 9.61208 7.6437 9.61208H3.99887C3.68436 9.61208 3.43164 9.86481 3.43164 10.1793V19.2886C3.43164 19.6031 3.68717 19.8558 3.99887 19.8558H7.6437C7.9582 19.8558 8.21093 19.6003 8.21093 19.2886V18.946H8.23901C9.14039 19.4543 10.1204 19.634 11.165 19.6143C12.8189 19.5834 17.5392 19.6115 18.3957 19.6059C19.0022 19.6031 19.606 19.3139 19.8952 18.581C20.2911 17.5841 19.269 17.0843 19.269 17.0843C19.269 17.0843 20.3894 17.1404 20.6449 16.1352C20.9061 15.1046 19.8783 14.6974 19.8783 14.6974C20.5747 14.6216 21.0212 14.4391 21.1897 13.8129C21.3891 13.0744 20.9117 12.5184 20.1591 12.1955C20.2743 12.1843 21.4228 11.9989 21.5267 10.8308Z'
        fill='#6C7289'
      />
    </svg>
  )

  const LikeDefault = (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.5949 0H6.40514C2.86701 0 0 2.86701 0 6.40514V18.5949C0 22.133 2.86701 25 6.40514 25H18.5949C22.133 25 25 22.133 25 18.5949V6.40514C25 2.86701 22.133 0 18.5949 0ZM24.357 18.2804C24.357 21.636 21.636 24.357 18.2804 24.357H6.71965C3.36403 24.357 0.643042 21.636 0.643042 18.2804V6.71965C0.643042 3.36403 3.36403 0.643042 6.71965 0.643042H18.2804C21.636 0.643042 24.357 3.36403 24.357 6.71965V18.2804Z'
        fill='#6C7289'
      />
      <path
        d='M21.5267 10.8308C21.639 9.55592 20.4512 9.18245 19.9794 9.18245C18.1514 9.18245 16.3234 9.18245 14.4953 9.18245C14.7593 8.60961 15.0794 7.09046 14.675 4.99847C14.515 4.1701 14.0264 3.62253 13.4058 3.63096C12.906 3.63938 12.4258 4.02689 12.3753 4.50706C12.3191 5.03778 12.1899 5.79034 12.0664 6.30702C11.6732 7.95534 9.56721 10.1316 8.2362 10.8926V10.915L8.21093 10.9038V10.1793C8.21093 9.86481 7.9582 9.61208 7.6437 9.61208H3.99887C3.68436 9.61208 3.43164 9.86481 3.43164 10.1793V19.2886C3.43164 19.6031 3.68717 19.8558 3.99887 19.8558H7.6437C7.9582 19.8558 8.21093 19.6003 8.21093 19.2886V18.946H8.23901C9.14039 19.4543 10.1204 19.634 11.165 19.6143C12.8189 19.5834 17.5392 19.6115 18.3957 19.6059C19.0022 19.6031 19.606 19.3139 19.8952 18.581C20.2911 17.5841 19.269 17.0843 19.269 17.0843C19.269 17.0843 20.3894 17.1404 20.6449 16.1352C20.9061 15.1046 19.8783 14.6974 19.8783 14.6974C20.5747 14.6216 21.0212 14.4391 21.1897 13.8129C21.3891 13.0744 20.9117 12.5184 20.1591 12.1955C20.2743 12.1843 21.4228 11.9989 21.5267 10.8308Z'
        fill='#6C7289'
      />
    </svg>
  )

  const renderView = () => {
    if (view == 'like-active') {
      return LikeActive
    }

    if (view == 'like-default') {
      return LikeDefault
    }
  }

  return <>{renderView()}</>
}
