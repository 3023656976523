import {useState, useEffect, useContext} from 'react'
import {useHistory} from 'react-router-dom'
import {ProfilePageContext} from '../../../../contexts/profilePageContext'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {GlobalPrivateModalContext} from '../../../../contexts/GlobalModalContext'
import MODAL_NAMES from '../../../../constants/ModalNames'
import {ProfileContext} from '../../../../contexts/profilecontext'

export function StartPetitionBanner() {
  const history = useHistory()
  const {currentTab, setCurrentTab} = useContext(ProfilePageContext)
  const PROFILE_CONTEXT = useContext(ProfileContext)
  const userBasicProfileComplete = PROFILE_CONTEXT.profile?.basicProfileComplete
  const {setIsOpenGlobalModal} = useContext(GlobalPrivateModalContext)
  const setIsOpenCompleteProfile = (o) => setIsOpenGlobalModal(MODAL_NAMES.COMPLETE_PROFILE, o)

  return (
    <div className='mobile-hide'>
      <div
        className=' petitions__createBanner petitionsDetails__createBanner '
        style={{width: 700}}
      >
        <div className='civik-flex-row'>
          <img
            className='petitions__createBannerImgSmall'
            src={toAbsoluteUrl('/media/design/petition_logo.svg')}
          ></img>
          <div className='petitions__createBannerTxt'>
            <p className=' mobile-hide petitions__createBannerSubTxt'>
              Have an issue you want to raise?
            </p>
            <h2 className='petitions__createBannerTitle'>
              Create official petition issue
              <span className='mobile-show petitions__createBannerReadMore'>More info</span>
            </h2>
          </div>
        </div>

        <button
          className='petitionsDetails__createPetBtn mobile-hide'
          onClick={() => {
            if (!userBasicProfileComplete) setIsOpenCompleteProfile(true)
            else {
              setCurrentTab('Create Petition')
              history.push('/myprofile')
            }
          }}
        >
          Start a petition
        </button>
      </div>
    </div>
  )
}
