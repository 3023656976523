/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useContext} from 'react'

import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useHistory} from 'react-router-dom'
import {useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

import AUTH_SERVICE from '../../../services/auth/AuthService'
import CONFIG from '../../../../config.json'

import {ProfileContext} from '../../../contexts/profilecontext'
const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function LoginWithEmail() {
  const history = useHistory()
  const {setProfile} = useContext(ProfileContext)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setError(null)
      setTimeout(async () => {
        const userInput = {
          email: values.email,
          password: values.password,
        }

        let response = await AUTH_SERVICE.loginWithEmailAndPassword(userInput)

        // if successful
        if (response && response.code === 200) {
          console.log('succesfully logged in')
        }

        // error
        if (response && response.code === 400) {
          setLoading(false)
          setSubmitting(false)
          setStatus(response.message)
        }

        setLoading(false)
      }, 1000)
    },
  })

  async function onSocialLogin(socialType) {
    try {
      setError(null)
      console.log('socialType', socialType)

      let response = await AUTH_SERVICE.loginWithSocial(socialType, history, setProfile)
      // if successful
      if (response && response.code === 200) {
        console.log('succesfully logged in')
      } else if (response?.code === 400) {
        setError(response.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function onLinkedinLogin() {
    try {
      let linkedinWindow = window.open(
        `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${CONFIG.LINKEDIN_CLIENT_ID}&redirect_uri=${CONFIG.LINKEDIN_REDIRECT_URI}&scope=r_liteprofile,`,
        '',
        'width=320,height=320'
      )

      console.log(linkedinWindow)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <form
      className='form w-100 civik-login-email-form civik-auth-form'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className=' mb-10'>
        <h1 className='text-dark mb-3' style={{fontSize: '24px'}}>
          Welcome to Civik
        </h1>
        <div className='login__linkWrap'>
          <span className='login__newHereTxt'>New Here?</span>{' '}
          <Link to='/auth/signup' className='link-primary fw-bolder' style={{fontSize: '16px'}}>
            Create an Account
          </Link>
        </div>
      </div>
      {/* begin::Heading */}
      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          //   placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div
            className='d-flex flex-stack mb-2'
            style={{justifyContent: 'space-between', width: '100%'}}
          >
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>

            <Link to='/auth/forgot-password' className='link-primary fs-6 fw-bolder'>
              Forgot Password ?
            </Link>
          </div>
        </div>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5 '
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Login</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>{' '}
      <div className='civik-or-separator'>
        {' '}
        <hr></hr>
        <span>OR</span>
        <hr></hr>{' '}
      </div>
      <div className='civik-social-login-wrapper'>
        <img
          onClick={async () => {
            await onSocialLogin('google')
          }}
          alt='Logo'
          src={toAbsoluteUrl('/media/design/civik-icon-google.svg')}
          className='civik-social-btn'
        />

        <img
          onClick={async () => {
            await onSocialLogin('facebook')
          }}
          alt='Logo'
          src={toAbsoluteUrl('/media/design/civik-icon-facebook.svg')}
          className='civik-social-btn'
        />
        {/* 
        <img
          onClick={async () => {
            await onLinkedinLogin()
          }}
          alt='Logo'
          src={toAbsoluteUrl('/media/design/civik-icon-linkedin.svg')}
          className='civik-social-btn'
        /> */}

        <img
          onClick={async () => {
            await onSocialLogin('twitter')
          }}
          className='civik-social-btn'
          alt='Logo'
          src={toAbsoluteUrl('/media/design/civik-icon-twitter.svg')}
        />

        <img
          onClick={async () => {
            await onSocialLogin('apple')
          }}
          className='civik-social-btn'
          alt='Logo'
          src={toAbsoluteUrl('/media/design/icon-Apple.svg')}
        />
      </div>
      {error && (
        <div className='mb-lg-15 alert alert-danger mb-10 mt-10' style={{maxWidth: 350}}>
          <div className='alert-text font-weight-bold'>{error}</div>
        </div>
      )}
    </form>
  )
}
