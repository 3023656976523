import NewsHeadlinerTxt from './NewsHeadlinerTxt' // Import your NewsHeadlinerTxt component
import PostReactions from '../../feed/posts/PostReactions'
import PostActionBar from '../../feed/posts/PostActionBar'
import RedirectToArticle from './RedirectToArticle'

import {useHistory} from 'react-router-dom'
import {useState} from 'react'
import ReadMoreText from '../../reusable/ReadMoreText'

function NewsHeadlineCard({post, index}) {
  const history = useHistory()
  const [numLikes, setNumLikes] = useState(post.upvotes)

  return (
    <div className='news__headlines__card' key={index}>
      <div className='news__headlines__content'>
        <div className='mobile-show'>
          <NewsHeadlinerTxt customStyling={{marginTop: 0}} postDetails={post} />
        </div>
        <div className='news__headlines__content__txtOuterCont'>
          <div className='news__headlines__content__txtContainer'>
            <div className='mobile-hide'>
              <NewsHeadlinerTxt customStyling={{marginTop: 0}} postDetails={post} />
            </div>

            <RedirectToArticle url={post.url}>
              <span className='news__headline__card__description'>
                <ReadMoreText
                  text={post.linkPreview?.title || post.title}
                  className='news__headline__card__description'
                />
              </span>
            </RedirectToArticle>
          </div>
          <RedirectToArticle url={post.url}>
            <img className='news__headlines__content__img' alt='' src={post.preview_image} />
          </RedirectToArticle>
        </div>
      </div>

      <div className='newsHeadlines__headline'>
        <PostReactions view='headline' numLikes={numLikes} feed={post} history={history} />

        <PostActionBar
          post={post}
          index={index}
          numLikes={numLikes}
          setNumLikes={setNumLikes}
          setNewCommentTrigger={false}
          view='headline'
        />
      </div>
    </div>
  )
}

export default NewsHeadlineCard
