/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext, useCallback} from 'react'
// import {IProfileDetails, profileDetailsInitValues as initialValues} from '../../../modules/profile/components/settings/SettingsModel'
import {useHistory} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'

import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../_metronic/helpers'
import {PasswordMeterComponent} from '../../_metronic/assets/ts/components'

import Modal from 'react-modal'

import {UserContext} from '../contexts/usercontext'
import {ProfilePageContext} from '../contexts/profilePageContext'

import FeatherIcon from 'feather-icons-react'
import TextField from '../modules/auth/reusable/TextField'

import POLITICIAN_SERIVCE from '../services/politician/PoliticianService'
import {ProfileContext} from '../contexts/profilecontext'
import AUTH_SERVICE from '../services/auth/AuthService'

import VOTER_SERVICE from '../services/voter/VoterService'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

import './LeftSideBar.scss'
import ROUTE_PATHS from '../routing/RoutePaths'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
}

const initialValues = {
  newEmail: '',
}

const emailSchema = Yup.object().shape({
  newEmail: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function LeftSideBar(props) {
  const history = useHistory()

  const {currentTab, setCurrentTab} = useContext(ProfilePageContext)

  const [loading, setLoading] = useState(false)
  const PROFILE_CONTEXT = useContext(ProfileContext)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)

  return (
    <div className='LeftSideBar hide-mobile'>
      <div
        className='leftSideBar__link civik-flex-row'
        onClick={() => {
          setCurrentTab('My Posts')
          history.push(ROUTE_PATHS.MY_PROFILE)
        }}
      >
        <img
          className='navbar__searchIcon'
          src={toAbsoluteUrl('/media/design/my_posts_icon.svg')}
        />
        <span className='leftSideBar__linkTxt'>My Posts</span>
      </div>
      <div
        className='leftSideBar__link civik-flex-row'
        onClick={() => {
          setCurrentTab('My Petitions')
          history.push(ROUTE_PATHS.MY_PROFILE)
        }}
      >
        <img
          className='navbar__searchIcon'
          src={toAbsoluteUrl('/media/design/my_petitions_icon.svg')}
        />
        <span className='leftSideBar__linkTxt'>My Petitions</span>
      </div>
      <div
        className='leftSideBar__link civik-flex-row'
        onClick={() => {
          setCurrentTab('About')
          history.push(ROUTE_PATHS.MY_PROFILE)
        }}
      >
        <img className='navbar__searchIcon' src={toAbsoluteUrl('/media/design/about_icon.svg')} />
        <span className='leftSideBar__linkTxt'>My Profile</span>
      </div>

      <div className='leftSideBar__onlineVotingBox'>
        <p>Find your electoral district, look at the map, profile, etc.</p>
        <button>Online Voting</button>
      </div>
    </div>
  )
}
