import {useState} from 'react'
import {ProgressBar} from 'react-bootstrap-v5'

import dateFormat from 'dateformat'
import UserPicture from '../../../../modules/reusable/UserPicture'

export function PetitionSignBar(props) {
  const petition = props.data
  const {onClickSign} = props
  const [loading, setLoading] = useState(false)

  const getPercentage = (partialValue, totalValue) => {
    try {
      return (100 * partialValue) / totalValue
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className='petitionDetails__signBar' style={{minWidth: 291, width: '100'}}>
      <div className='petitionDetails__signBarStats'>
        <span className='petitionDetails__signBarStatsTxt'>
          <span className='petitionDetails__signBar__numOfSigns'>{petition.signatures}</span> of{' '}
          <span className='petitionDetails__signBar__numOfSignsGoal'>
            {' '}
            {petition.signatureGoal}
          </span>{' '}
          Signed
        </span>

        {petition.signatures >= petition.signatureGoal ? (
          <div className='petitionDetails__progressBarComplete'>
            <ProgressBar
              className='petitionDetails__signBar__percentBar '
              now={Number(getPercentage(petition.signatures, petition.signatureGoal))}
            />
          </div>
        ) : (
          <div className='petitionDetails__progressBarInProgress'>
            <ProgressBar
              className='petitionDetails__signBar__percentBar '
              now={Number(getPercentage(petition.signatures, petition.signatureGoal))}
            />
          </div>
        )}

        <div className='petitionDetails__percentWrap'>
          {petition.signatures >= petition.signatureGoal ? (
            <span className='petitionDetails__successTxt'>Great Success!</span>
          ) : (
            <div></div>
          )}

          <span className='petitionDetails__signBar__percent'>
            {' '}
            {getPercentage(petition.signatures, petition.signatureGoal).toFixed()}%
          </span>
        </div>
      </div>
      {!loading && (
        <button
          className={
            !petition.userSignedPetition
              ? `petitionDetails__signBar__publishBtn mobile-hide`
              : 'petitionDetails__signBar__publishBtn petitionDetails__signBar__signedBtn mobile-hide'
          }
          onClick={petition.userSignedPetition ? null : onClickSign}
          disabled={petition.userSignedPetition === true}
        >
          {petition.userSignedPetition ? 'You Signed' : 'Sign Petition'}
        </button>
      )}
      {loading && <button className='petitionDetails__signBar__publishBtn'>Please wait...</button>}

      <div className='petitionDetails__signBar__statusWrap mobile-hide'>
        <span className='petitionDetails__signBar__statusLabel'>Status</span>
        <div className='petitionDetails__signBar__status'>
          <span className='petitionDetails__signBar__statusTxt'>Published</span>
        </div>
      </div>

      <div className='mobile-hide'>
        <div className='petitionDetails__signBar__date'>
          <span className='petitionDetails__signBar__dateLabel'>Date Created</span>
          <span className='petitionDetails__signBar__dateValue'>
            {dateFormat(petition.draftCreatedAt, 'mmm dS, yyyy')}
          </span>
        </div>

        <div className=' petitionDetails__signBar__sch'>
          <span className='petitionDetails__signBar__dateLabel'>Date Published</span>
          <span className='petitionDetails__signBar__dateValue'>
            {' '}
            {dateFormat(petition.createdAt, 'mmm dS, yyyy')}
          </span>
        </div>
      </div>

      <div className='petitionDetails__signBar__dateMobile mobile-show'>
        <div className=' petitionDetails__signBar__sch mobile-show'>
          <span className='petitionDetails__signBar__dateLabel mobile-show'>Date Published</span>
          <span className='petitionDetails__signBar__dateValue mobile-show'>
            {' '}
            {dateFormat(petition.createdAt, 'mmm dS, yyyy')}
          </span>
        </div>

        <div className='petitionDetails__signBar__statusWrap mobile-show'>
          <span className='petitionDetails__signBar__statusLabel'>Status</span>
          <div className='petitionDetails__signBar__status'>
            <span className='petitionDetails__signBar__statusTxt'>Published</span>
          </div>
        </div>
      </div>

      <div className='petitionDetails__organizerContentWrap'>
        <UserPicture
          isReply={false}
          profile={petition.user}
          styleClasses={{
            profilePic: ' petitionDetails__organizerImg',
            nameLetter: ' navbar__barNameLetter petitionDetails__organizerImg',
            letterWrap: 'feed__postCommentBarLetterWrap petitionDetails__organizerImg',
          }}
        />

        <div className='createPetitions__organizerTxtWrap'>
          <span className='petitionDetails__organizerTxtLabel'>Organized By</span>
          <span className='petitionDetails__organizerTxtValue'>{petition.user.full_name}</span>
        </div>
      </div>
    </div>
  )
}
