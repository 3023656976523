import {useState, useEffect} from 'react'

function useTabletView() {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 900)

  useEffect(() => {
    function handleResize() {
      setIsMobileView(window.innerWidth <= 900)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return isMobileView
}

export default useTabletView
