const CivikStyles = {
  modalCustomStyles: {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '12px',
      padding: '0px 0px',
      width: '100%',
      maxWidth: 600,
      overflowY: 'auto',
      maxHeight: '100vh',
      paddingVertical: 50,
    },
    overlay: {
      background: 'rgba(0, 0, 0, 0.5)',
      zIndex: '9999999999',
    },
  },
}
export default CivikStyles
