import TextField from '../../../../../app/modules/auth/reusable/TextField'

import '../../pages/VoterRegister.scss'
import {useVoterStyles} from '../../../../contexts/VoterStylesContext'

import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import './ContactInfo.scss'

export function ContactInfo(props) {
  const {formik, view} = props

  const {customTxtClass} = useVoterStyles()

  const VoterRegister = (
    <>
      {' '}
      <div className='voterRegister__emailInputsWrap'>
        <div className='voterRegister__emailWrap'>
          <TextField
            formik={formik}
            type='text'
            field='email'
            placeholder='Email Address'
            label='Email Address'
            isRequired={true}
            view='voter'
          />
          {((formik.touched['email'] && formik.errors['email']) ||
            (formik.touched['confirmEmail'] && formik.errors['confirmEmail'])) && (
            <div className='voterRegister__singleInputErr'>
              <div className='TextField__voterErrWrap'>
                <span className='TextField__voterErrTxt' role='alert'>
                  Email Address is required!
                </span>
              </div>
            </div>
          )}

          {formik.values.email !== formik.values.confirmEmail && (
            <div className='voterRegister__singleInputErr'>
              <div className='TextField__voterErrWrap'>
                <span className='TextField__voterErrTxt' role='alert'>
                  Emails don't match!
                </span>
              </div>
            </div>
          )}
        </div>
        <div className='voterRegister__confirmEmailWrap'>
          <TextField
            formik={formik}
            type='text'
            field='confirmEmail'
            placeholder='Confirm Email Address'
            label='Confirm Email Address'
            isRequired={true}
            view='voter'
          />
        </div>
      </div>
      <div className='voterRegister__phoneWrap'>
        <div className='voterRegister__phoneInputWrap'>
          <TextField
            formik={formik}
            type='text'
            field='phoneNumber'
            placeholder='Phone Number'
            label='Phone Number'
            isRequired={true}
            view='voter'
          />
          {formik.touched['phoneNumber'] && formik.errors['phoneNumber'] && (
            <div className='voterRegister__singleInputErr'>
              <div className='TextField__voterErrWrap'>
                <span className='TextField__voterErrTxt' role='alert'>
                  {formik.errors['phoneNumber']}
                </span>
              </div>
            </div>
          )}
        </div>
        <div className='voterRegister__phoneDummyWrap'></div>
      </div>
    </>
  )

  const AdminEdit = (
    <>
      <div className='voterRegister__emailInputsWrap'>
        <div className='voterRegister__emailWrap'>
          <TextField
            formik={formik}
            type='text'
            field='email'
            placeholder='Email Address'
            label='Email Address'
            isRequired={true}
            view='voter'
          />
          {((formik.touched['email'] && formik.errors['email']) ||
            (formik.touched['confirmEmail'] && formik.errors['confirmEmail'])) && (
            <div className='voterRegister__singleInputErr'>
              <div className='TextField__voterErrWrap'>
                <span className='TextField__voterErrTxt' role='alert'>
                  Email Address is required!
                </span>
              </div>
            </div>
          )}

          {formik.values.email !== formik.values.confirmEmail && (
            <div className='voterRegister__singleInputErr'>
              <div className='TextField__voterErrWrap'>
                <span className='TextField__voterErrTxt' role='alert'>
                  Emails don't match!
                </span>
              </div>
            </div>
          )}
        </div>

        <div className='voterRegister__phoneInputWrap'>
          <TextField
            formik={formik}
            type='text'
            field='phoneNumber'
            placeholder='Phone Number'
            label='Phone Number'
            isRequired={true}
            view='voter'
          />
          {formik.touched['phoneNumber'] && formik.errors['phoneNumber'] && (
            <div className='voterRegister__singleInputErr'>
              <div className='TextField__voterErrWrap'>
                <span className='TextField__voterErrTxt' role='alert'>
                  {formik.errors['phoneNumber']}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='adminEdit__verifyFooter'>
        <div className='adminEdit__verifyFooter__labelWrap'>
          <span className='adminEdit__verifyFooter__label--verified'>Address verified</span>

          <img
            src={toAbsoluteUrl('/media/design/voter_verified_check.svg')}
            className='adminEdit__verifyFooter__checkIcon'
          />
        </div>

        <span className='adminEdit__verifyRequest__label'>Ask for verification</span>
      </div>
    </>
  )

  const renderView = () => {
    if (view == 'VoterRegister') {
      return VoterRegister
    }

    if (view == 'AdminEdit') {
      return AdminEdit
    }
  }

  return (
    <div className='voterRegister__contactRow'>
      <div className={'voterRegister__divider'}>
        <span
          className={
            customTxtClass
              ? 'voterRegister__dividerLabel' + ' ' + customTxtClass
              : 'voterRegister__dividerLabel'
          }
        >
          Contact Information
        </span>
      </div>

      {renderView()}
    </div>
  )
}
