import React from 'react'
import RedirectToArticle from './RedirectToArticle' // Import the RedirectToArticle component
import NewsHeadlinerTxt from './NewsHeadlinerTxt' // Import the NewsHeadlinerTxt component

const LocalNewsRow = ({post, index}) => {
  return (
    <div className='localNews__post' key={index}>
      <div className='localNews__post__content'>
        <RedirectToArticle
          url={post.url}
          customStyling={{display: 'flex', justifyContent: 'space-between', width: '100%'}}
        >
          <span className='localNews__post__content__txt'>
            {post.linkPreview?.title || post.title}
          </span>
          <img className='localNews__post__content__img' alt='' src={post.preview_image} />
        </RedirectToArticle>
      </div>
      <NewsHeadlinerTxt customStyling={{}} postDetails={post} />
    </div>
  )
}

export default LocalNewsRow
