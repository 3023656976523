/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext, useCallback} from 'react'
import {VoterAdminNavbar} from './reusable/VoterAdminNavbar'
import {useFormik} from 'formik'
import './VoterAdminLogin.scss'
import TextField from '../../../app/modules/auth/reusable/TextField'
import * as Yup from 'yup'
import {useHistory} from 'react-router-dom'

export const initialValues = {
  username: '',
  password: '',
}

export const loginSchema = Yup.object().shape({
  username: Yup.string().required('Username is required!'),
  password: Yup.string().required('Password is required!'),
})

export function VoterAdminLogin(props) {
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(async () => {
        try {
          const userInput = {
            username: values.username,
            password: values.password,
          }

          console.log('userInput', userInput)

          setLoading(false)
        } catch (error) {
          console.log(error)
        }
      }, 10)
    },
  })

  return (
    <div className='VoterAdminLogin'>
      <VoterAdminNavbar />

      {/* LOGIN FORM */}
      <div className='voterAdminLogin__form'>
        <div className='voterAdminLogin__inputsWrap'>
          <div className='voterAdminLogin__usernameWrap'>
            <TextField
              formik={formik}
              type='text'
              field='username'
              placeholder=''
              label='Username'
              isRequired={true}
              view='voter'
            />
            {formik.touched['username'] && formik.errors['username'] && (
              <div className='TextField__voterErrTxt mt-2'>
                <span role='alert'>{formik.errors['username']}</span>
              </div>
            )}
          </div>
          <div className='voterAdminLogin__passwordWrap'>
            <div className='voterAdminLogin__passwordLabelsWrap'>
              <label className='form-label fs-6 fw-bold text-dark required'>Password</label>
              <span className='voterAdminLogin__forgotPassTxt'>Forgot Password?</span>
            </div>
            <TextField
              formik={formik}
              type='password'
              field='password'
              placeholder=''
              label=''
              noLabel={true}
              isRequired={true}
              view='voter'
            />{' '}
            {formik.touched['password'] && formik.errors['password'] && (
              <div className='TextField__voterErrTxt mt-2'>
                <span role='alert'>{formik.errors['password']}</span>
              </div>
            )}
          </div>
          <button
            type='submit'
            className='voterAdminLogin__loginBtn'
            onClick={() => {
              history.push('/voter-registration/admin-dashboard')
              // formik.submitForm()
            }}
            disabled={loading}
          >
            Login
          </button>
        </div>
      </div>
    </div>
  )
}
