/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext, useCallback} from 'react'
import Modal from 'react-modal'
import './ExportAddressList.scss'

import {toAbsoluteUrl} from '../../../../../_metronic/helpers'

export function ExportAddressList(props) {
  return (
    <>
      <div className='exportAddrList__banner'>
        <div className='importAddrList__banner__imgWrap'>
          <img
            className='importAddrList__bannerImg'
            src={toAbsoluteUrl('/media/design/file_upload_green.svg')}
          />

          <div className='importAddrList__bannerTxtWrap'>
            <span className='importAddrList__bannerTxt__instruction'>
              Export address list database (CSV)
            </span>
            <span className='exportAddrList__bannerTxt__date'>Last Imported Nov 24, 2024</span>
          </div>
        </div>
        <button className='exportAddrList__bannerBtn'>Export List</button>
      </div>

      {/* CONSOLE */}
      <div className='importAddrList__tableContanier'>
        {/* TABLE */}
        <div className='voterAddrList__table'>
          <div className='voterAddrList__tableHeader'>
            <span className='importAddrList__tableHeader__columnLabel--1'>Date Imported</span>
            <span className='importAddrList__tableHeader__columnLabel--2'>File Name</span>

            <span className='importAddrList__tableHeader__columnLabel--5'></span>
          </div>

          <div className='voterAddrList__tableBody'>
            <div className='voterAddrList__tableBody__row'>
              <span className='importAddrList__tableBody__rowItem--1'>
                September 22, 2024 12:00:00 PM
              </span>
              <span className='importAddrList__tableBody__rowItem--2'>
                Addresslist_sep-24-2022.json
              </span>

              <button className='importAddrList__tableBody__rowItem--5 exportAddrList__downloadBtn exportAddrList__downloadBtn--active'>
                Download this version
              </button>
            </div>

            <div className='voterAddrList__tableBody__row'>
              <span className='importAddrList__tableBody__rowItem--1'>
                September 22, 2024 12:00:00 PM
              </span>
              <span className='importAddrList__tableBody__rowItem--2'>
                Addresslist_sep-24-2022.json
              </span>

              <button className='importAddrList__tableBody__rowItem--5 exportAddrList__downloadBtn exportAddrList__downloadBtn--active'>
                Download this version
              </button>
            </div>

            <div className='voterAddrList__tableBody__row'>
              <span className='importAddrList__tableBody__rowItem--1'>
                September 22, 2024 12:00:00 PM
              </span>
              <span className='importAddrList__tableBody__rowItem--2'>
                Addresslist_sep-24-2022.json
              </span>

              <button className='importAddrList__tableBody__rowItem--5 exportAddrList__downloadBtn exportAddrList__downloadBtn--active'>
                Download this version
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
