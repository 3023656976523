import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useHistory} from 'react-router-dom'
import './AdminTopbar.scss'

export function AdminTopbar(props) {
  const history = useHistory()

  return (
    <div className='AdminTopbar'>
      <div className='adminTopbar__btnWrap'>
        <span
          className='adminTopbar__logoutTxt'
          onClick={() => {
            history.push('/voter-registration/adminlogin')
          }}
        >
          Logout
        </span>
      </div>
    </div>
  )
}
