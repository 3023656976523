/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext, useCallback, useRef} from 'react'

import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {ActivePetitionContext} from '../../../../../contexts/ActivePetitionContext'

import {DeleteIcon, EditIcon} from '../../../../../../assets/Icons'
import {v4 as uuidv4} from 'uuid'
import {uploadToFirebase, validateFile} from '../../../../../helperfunctions/FirebaseUploadHelpers'

export function SupportingDocuments(props) {
  const {activePetition, setActivePetition} = useContext(ActivePetitionContext)
  const uploadRef = useRef(null)
  const [isUploading, setIsUploading] = useState(false)

  const onImageClick = useCallback(() => {
    uploadRef.current.click()
  }, [uploadRef])

  const onFileUpload = async (e) => {
    try {
      setIsUploading(true)
      let file = await validateFile(e)
      const uploadPath = `${activePetition.filesFolderPath}/${uuidv4()}`
      const {downloadURL, path} = await uploadToFirebase(file, uploadPath)
      let fileNum = activePetition.supportDocs.length
      let tempSupportDocs = activePetition.supportDocs.slice()
      tempSupportDocs.push({
        source_url: downloadURL,
        source_path: path,
        id: fileNum,
        file_name: file.name,
        file_type: file.type,
      })
      setActivePetition({
        ...activePetition,
        supportDocs: tempSupportDocs,
      })
      setIsUploading(false)
    } catch (error) {
      console.error(error)
    }
  }

  const deleteSupportDoc = (index) => {
    try {
      // delete at nth index
      let supportDocsClone = activePetition.supportDocs.slice()
      supportDocsClone.splice(index, 1)

      setActivePetition({
        ...activePetition,
        supportDocs: supportDocsClone,
      })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div style={{marginTop: 39}}>
      <h1 className='createPetition__details__headerTitle'>Supported Documents</h1>

      <span className='createPetition__details__headerSubtxt'>
        Upload images or documents that you think will prove your issue and support your petition.
      </span>

      {isUploading && (
        <div className='createPetition__docUploadWrap'>
          <h1>Uploading...</h1>
        </div>
      )}

      {!isUploading && (
        <div className=' createPetition__docUploadWrap' onClick={onImageClick}>
          <img src={toAbsoluteUrl('/media/design/file_upload_icon.svg')} />
          <h4 className='addImageModal__uploadTxt'>File Uploader</h4>
          <span className='addImageModal__uploadSubTxt'>Drag & Drop files here 5MB Max</span>

          <input type='file' onChange={onFileUpload} ref={uploadRef} style={{display: 'none'}} />
        </div>
      )}

      {activePetition.supportDocs &&
        activePetition.supportDocs.map((document, index) => {
          return (
            <div className='createPetition__point createPetition__docDetailsRow' key={index}>
              <div>
                <span className=' createPetition__docTxt'>
                  {document.file_name ? document.file_name : `File #${index + 1}`}
                </span>
              </div>

              <div className='createPetition__pointOuterWrap'>
                <div className='createPetition__pointIconContainer'>
                  <a
                    className='createPetition__btnWrap'
                    href={document.source_url}
                    style={{marginRight: 20.5}}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <span className='createPetition__btnTxt'>View</span>
                  </a>
                </div>
                <div
                  className='createPetition__pointIconContainer'
                  onClick={() => {
                    deleteSupportDoc(index)
                  }}
                >
                  <DeleteIcon className='createPetition__pointDeleteIcon' fill='#6C7289' />
                </div>
              </div>
            </div>
          )
        })}
    </div>
  )
}
