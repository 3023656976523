import {useState, useEffect, useContext} from 'react'
import {useParams, useHistory} from 'react-router-dom'
import PetitionService from '../../../services/petition/PetitionService'
import {ProfileContext} from '../../../contexts/profilecontext'
import './PetitionSign.scss'
import './PetitionDetails.scss'
import {GlobalPrivateModalContext} from '../../../contexts/GlobalModalContext'
import MODAL_NAMES from '../../../constants/ModalNames'
import {PetitionTabs} from './components/PetitionTabs'
import {PetitionDescription} from './components/PetitionDescription'
import {PetitionSupporters} from './components/PetitionSupporters'

export function PetitionDetails(props) {
  const history = useHistory()
  let {petition_id} = useParams() as {petition_id: string}
  const [petition, setPetition] = useState(null)
  const [loading, setLoading] = useState(false)

  const [petitionTab, setPetitionTab] = useState('Description')

  const PROFILE_CONTEXT = useContext(ProfileContext)
  const userBasicProfileComplete = PROFILE_CONTEXT.profile?.basicProfileComplete
  const {setIsOpenGlobalModal} = useContext(GlobalPrivateModalContext)
  const setIsOpenCompleteProfile = (o) => setIsOpenGlobalModal(MODAL_NAMES.COMPLETE_PROFILE, o)
  const setIsOpenWrongCommunity = (o) => setIsOpenGlobalModal(MODAL_NAMES.WRONG_COMMUNITY, o)
  const userInCommunity = PROFILE_CONTEXT.profile?.communities?.includes(petition?.community)

  const [actionPopupContent, setActionPopupContent] = useState('Copied to clipboard')
  const [showElement, setShowElement] = useState(false)

  const getPetition = async () => {
    setLoading(true)
    let petitionData = [] as any
    petitionData = await PetitionService.getPetitionDetails(petition_id)
    setPetition(petitionData)
    setLoading(false)
  }

  const onClickSign = async () => {
    if (!userBasicProfileComplete) setIsOpenCompleteProfile(true)
    else if (!userInCommunity) setIsOpenWrongCommunity(true)
    else {
      setLoading(true)
      let signLink = await PetitionService.getPetitionSignLink(petition_id)
      if (signLink) {
        history.push(`/petitions/${petition_id}/sign`, {petition_id, signLink})
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    setTimeout(function () {
      setShowElement(false)
    }, 5000)
  }, [showElement])

  useEffect(() => {
    getPetition()
  }, [petition_id])

  //  TODO loading & petition not found
  if (!petition) {
    return <div />
  }

  if (petition) {
    return (
      <div>
        <div className='PetitionDetails__Outer' id='Petitions'>
          {showElement && <div className='feed__postCopiedTooltip'>{actionPopupContent}</div>}

          {petitionTab == 'Description' ? (
            <PetitionDescription
              petition={petition}
              petitionTab={petitionTab}
              setPetitionTab={setPetitionTab}
              setShowElement={setShowElement}
              onClickSign={onClickSign}
              setActionPopupContent={setActionPopupContent}
            />
          ) : (
            <PetitionSupporters
              petition={petition}
              petitionTab={petitionTab}
              setPetitionTab={setPetitionTab}
              setShowElement={setShowElement}
              onClickSign={onClickSign}
              setActionPopupContent={setActionPopupContent}
            />
          )}
        </div>
      </div>
    )
  }
}
