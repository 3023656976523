import React, {useEffect} from 'react'
import {getNewsGuardIcon, newsguardCriteria, getOverviewLine, getCheckORX} from './helperfunctions'
import './NewsGuard.scss'
import {MenuComponent} from '../../../_metronic/assets/ts/components'
/// NOTE: TODO fix this popup it isn't user friendly

const NewsGuardLabel = (props) => {
  const {post, hideSource, hideLogo} = props

  const getIndexItem = (criteria, name) => {
    return criteria.findIndex((x) => x.title === name)
  }

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])
  if (!post.newsguard_rank) {
    return <div />
  }

  return (
    <div className=''>
      <button
        type='button'
        className='newsguard__btn btn'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement={window.innerWidth < 768 ? 'top' : 'right-end'}
      >
        <div className='shield_container_div '>
          {getNewsGuardIcon(post.newsguard_rank)}
          {post.newsguard_rank.score ? (
            <div className='shield_child_div'>
              <span>{post.newsguard_rank.score}%</span>
            </div>
          ) : null}
        </div>

        {!hideLogo && (
          <img
            src='/icons/newsguard/NewsGuardLogo_SMALL-Black.svg'
            alt='NewsGuard logo'
            style={{width: 60}}
          />
        )}
      </button>

      <div
        className='newsGuardLabel__card menu  menu-sub menu-sub-dropdown rounded-menu w-300px w-md-400px px-5 mt-10'
        data-kt-menu='true'
        style={{
          backgroundColor: 'white',
          maxHeight: 400,
          display: !post.newsguard_rank.rank ? 'none' : '',
        }}
      >
        <div
          className='menu-item px-3 item pt-5'
          style={{overflowY: 'scroll', overflowX: 'hidden'}}
        >
          <div
            style={{
              marginTop: 5,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              fontWeight: '400',
              fontSize: 14,
            }}
          >
            <p style={{fontWeight: 'bold'}}>{post.newsguard_rank.identifier}</p>
            <img
              src='/icons/newsguard/NewsGuardLogo_SMALL-Black.svg'
              alt='NewsGuard logo'
              style={{width: 100}}
            ></img>
          </div>
          <div className={`divider`} style={{borderBottomWidth: 2.5}} />

          <p style={{marginTop: 15, marginBottom: 7.5}}>
            {post.newsguard_rank.topline ? post.newsguard_rank.topline.replace(/&nbsp;/g, ' ') : ''}
          </p>

          {post.newsguard_rank.criteria ? (
            <>
              <div className='shield_container_div ' style={{marginBottom: 7.5, marginTop: 0}}>
                {getNewsGuardIcon(post.newsguard_rank, 25)}
                <div className='shield_child_div' style={{height: 25, fontSize: 23}}>
                  <strong>{post.newsguard_rank.score}</strong> / 100
                </div>
              </div>
              <p style={{marginBottom: 15}}>{getOverviewLine(post.newsguard_rank.score)}</p>
              <div
                className={`divider`}
                style={{borderBottomWidth: 5, borderBottomColor: 'black'}}
              />

              <div style={{marginTop: 15, marginBottom: 15}}>
                {newsguardCriteria.map((criteria, index) => (
                  <p key={index}>
                    {getCheckORX(
                      post.newsguard_rank.criteria[
                        getIndexItem(post.newsguard_rank.criteria, criteria.key)
                      ].body
                    )}{' '}
                    {criteria.string}
                  </p>
                ))}
              </div>
            </>
          ) : null}
        </div>
        <p>
          <a
            className='btn btn-primary mt-5'
            target='_blank'
            rel='noopener noreferrer'
            href={`https://api.newsguardtech.com/v3/${post.newsguard_rank.labelToken}`}
            style={{width: '100%', backgroundColor: 'rgb(51, 85, 221)'}}
          >
            See Full Nutrition Label
          </a>
        </p>
      </div>
    </div>
  )
}

export default NewsGuardLabel
