export const DeleteIcon = (props) => {
  let {className, fill} = props
  className = className ? className : ''
  fill = fill ? fill : '#6C7289'
  return (
    <svg
      className={className ? className : ''}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.3335 6.6665V21.1665C5.3335 21.9949 6.00507 22.6665 6.8335 22.6665H17.1668C17.9953 22.6665 18.6668 21.9949 18.6668 21.1665V6.6665H5.3335Z'
        fill={fill}
      />
      <path
        opacity='0.3'
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M14.2857 3.3335V2.3335C14.2857 1.78121 13.838 1.3335 13.2857 1.3335H10.7143C10.162 1.3335 9.71429 1.78121 9.71429 2.3335V3.3335H4.5C4.22386 3.3335 4 3.55735 4 3.8335V4.8335C4 5.10964 4.22386 5.3335 4.5 5.3335H19.5C19.7761 5.3335 20 5.10964 20 4.8335V3.8335C20 3.55735 19.7761 3.3335 19.5 3.3335H14.2857Z'
        fill={fill}
      />
    </svg>
  )
}
