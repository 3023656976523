import React from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import './NoNews.scss'
import useMobileView from '../../../hooks/UseMobileView'

function NoNews({section, customStyling = {}}) {
  return (
    <div style={customStyling}>
      <span className='news__section__title'>{section}</span>
      <div className='noNews__card' style={{height: section == 'Local News' ? 352 : 370}}>
        <img alt='no news icon' src={toAbsoluteUrl('/media/design/icon_no_news_default.svg')} />
        <span className='noNews__card__title'>This section has no posts yet</span>
      </div>
    </div>
  )
}

export default NoNews
