import {useState, useEffect} from 'react'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {useActivePetition} from '../../../../../contexts/ActivePetitionContext'

export const SignatureTarget = (props) => {
  const {setActivePetition, activePetition} = useActivePetition()
  const [supportTarget, setSupportTarget] = useState(activePetition.signatureGoal ? activePetition.signatureGoal : 300)

  useEffect(() => {
    setActivePetition({...activePetition, signatureGoal: supportTarget})
  }, [supportTarget])

  const decreaseTarget = () => {
    let result = supportTarget - 50
    if (result <= 0) {
      result = 0
    }
    setSupportTarget(result)
  }

  const increaseTarget = () => {
    setSupportTarget(supportTarget + 50)
  }

  return (
    <div className='createPetition__supportTargetWrap'>
      <span className='createPetition__supportTargetLabel'> your support target:</span>
      <div className='createPetition__supportTargetBtnWrap'>
        <img
          className='createPetition__supportTargetBtn'
          src={toAbsoluteUrl('/media/design/minus_icon.svg')}
          onClick={decreaseTarget}
          alt='decrease'
        ></img>
        <span className='createPetition__supportTargetVal'>{supportTarget}</span>
        <img
          className='createPetition__supportTargetBtn'
          src={toAbsoluteUrl('/media/design/plus_icon.svg')}
          onClick={increaseTarget}
          alt='increase'
        ></img>
      </div>
      <span className='createPetition__supportTargetLabel'> Signatures</span>
    </div>
  )
}
