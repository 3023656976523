import {createContext, useContext, useEffect, useState} from 'react'
import {v4 as uuidv4} from 'uuid'
import {ProfileContext} from './profilecontext'

export const ActivePetitionContext = createContext()

export const ActivePetitionProvider = ({children}) => {
  const [fetched, setFecthed] = useState(false)
  const {profile} = useContext(ProfileContext)
  const [activePetition, setActivePetition] = useState(null)
  const [termsError, setTermsError] = useState(false)

  // fetch petition from localstorage on first page load
  useEffect(() => {
    fetchSavedPetition()
  }, [])

  const fetchSavedPetition = () => {
    try {
      const savedPetition = localStorage.getItem('petition')
      const localPetition = JSON.parse(savedPetition)
      if (localPetition) {
        setActivePetition({...activePetition, ...localPetition})
      }
      setFecthed(true)
    } catch (error) {
      console.error('fetch saved petition error', error)
    }
  }

  // update storage on petition change
  useEffect(() => {
    if (fetched) {
      localStorage.setItem('petition', JSON.stringify(activePetition))
    }
  }, [activePetition])

  const initializePetition = () => {
    setActivePetition({
      step: 1,
      title: '',
      topic: '',
      keypoints: [],
      supportDocs: [],
      cover_photo: {
        source_url: '',
        source_path: '',
      },
      filesFolderPath: `petitions/${profile._id}/${uuidv4()}/files/`,
      signatureGoal: 300,
      draftCreatedAt: new Date(),
    })
  }

  const clearActivePetition = () => {
    setActivePetition(null)
    setTermsError(false)
    localStorage.removeItem('petition')
  }

  return (
    <ActivePetitionContext.Provider
      value={{
        activePetition,
        setActivePetition,
        clearActivePetition,
        initializePetition,
        termsError,
        setTermsError,
      }}
    >
      {children}
    </ActivePetitionContext.Provider>
  )
}

const PROVIDER_ERROR = 'useActivePetition must be used within a ActivePetitionProvider'

export const useActivePetition = () => {
  const context = useContext(ActivePetitionContext)

  if (!context) {
    throw new Error(PROVIDER_ERROR)
  }
  return context
}

export default ActivePetitionContext
