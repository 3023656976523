import React from 'react'

export const newsguardCriteria = [
  {
    key: 'falseContent',
    string: 'Does not repeatedly publish false content',
  },
  {
    key: 'basicStandards',
    string: 'Gathers and presents information responsibly',
  },
  {
    key: 'accountabilityPractices',
    string: 'Regularly corrects or clarifies errors',
  },
  {
    key: 'newsOpinion',
    string: 'Handles the difference between news and opinion responsibly',
  },
  {key: 'deceptiveHeadlines', string: 'Avoids deceptive headlines'},
  {key: 'ownership', string: 'Website discloses ownership and financing'},
  {key: 'labelsAdvertising', string: 'Clearly labels advertising'},
  {
    key: 'management',
    string: "Reveals who's in charge, including any possible conflicts of interest",
  },
  {
    key: 'contentCreators',
    string:
      'The site provides names of content creators, along with either contact or biographical information',
  },
]

export const getOverviewLine = (score) => {
  if (score === 100) {
    return "This website adheres to all nine of NewsGuard's standards of credibility and transparency."
  } else if (score >= 80) {
    return 'This website mostly adheres to basic standards of credibility and transparency.'
  } else if (score >= 60) {
    return 'This website generally maintains basic standards of credibility and transparency—with significant exceptions.'
  } else if (score >= 40) {
    return 'Proceed with caution: This website fails to adhere to several basic journalistic standards.'
  } else {
    return 'Proceed with caution: This website severely violates basic journalistic standards.'
  }
}

export const getNewsGuardIcon = (rank, height = 16) => {
  switch (rank.rank) {
    case 'T':
      return (
        <img
          src='/icons/newsguard/shield/neutral shield.svg'
          alt='shield'
          style={{height: height}}
        />
      )
    case 'N':
      return (
        <img
          src='/icons/newsguard/shield/neutral shield.svg'
          alt='shield'
          style={{height: height}}
        />
      )
    case 'P':
      return (
        <img
          src='/icons/newsguard/shield/label=platform.svg'
          alt='platform'
          style={{height: height}}
        />
      )
    case 'S':
      return (
        <img src='/icons/newsguard/shield/label=satire.svg' alt='satire' style={{height: height}} />
      )
    default:
      return (
        <img
          src='/icons/newsguard/shield/label=unrated.svg'
          alt='unrated'
          style={{height: height}}
        />
      )
  }
}

export const getCheckORX = (str) => {
  switch (str) {
    case 'Yes':
      return (
        <img
          src='/icons/newsguard/icons8-checkmark-48.png'
          alt='green-checkmark'
          style={{height: 20}}
        />
      )
    case 'No':
      return <img src='/icons/newsguard/icons8-x-50.png' alt='red-x' style={{height: 15}} />
    default:
      return <p>N/A</p>
  }
}
