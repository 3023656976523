/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext, useCallback} from 'react'
// import {IProfileDetails, profileDetailsInitValues as initialValues} from '../../../modules/profile/components/settings/SettingsModel'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'

import {ProfileContext} from '../../contexts/profilecontext'
import AUTH_SERVICE from '../../services/auth/AuthService'

const initialValues = {
  newEmail: '',
}

const emailSchema = Yup.object().shape({
  newEmail: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function InlineEmailAdd(props) {
  const [loading, setLoading] = useState(false)
  const {profile, setProfile} = useContext(ProfileContext)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)

  const formik = useFormik({
    initialValues,
    validationSchema: emailSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setStatus(undefined)
      
      const userInput = {
        email: values.newEmail,
      }

      let response = await AUTH_SERVICE.addEmailSocialLogin(userInput)

      if (response && response.code == 200) {
        setHasErrors(false)
        await fetchProfile()
        props.setIsAddEmail(false)
      }

      // error
      if (response && response.code == 400) {
        setLoading(false)
        setSubmitting(false)
        setStatus(response.message)
      }

      setLoading(false)
    },
  })

  const fetchProfile = async () => {
    try {
      let userProfile = await AUTH_SERVICE.getCurrentUser()
      setProfile(userProfile)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className='civik-modal'>
      <div
        className='civik-flex-row mb-5'
        style={{justifyContent: 'space-between', minWidth: '100%'}}
      >
        <span className=''>Email Address</span>
        <div style={{width: '70%'}}>
          <input
            {...formik.getFieldProps('newEmail')}
            placeholder={'Enter email'}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.newEmail && formik.errors.newEmail},
              {
                'is-valid': formik.touched.newEmail && !formik.errors.newEmail,
              }
            )}
            type='email'
            name='newEmail'
            autoComplete='off'
          />
          {formik.touched.newEmail && formik.errors.newEmail && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.newEmail}</span>
            </div>
          )}
        </div>
      </div>
      <div style={{float: 'right'}}>
        {formik.status && (
          <div className='alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}
        <button
          type='button'
          className=' btn btn-light btn-primary civik-edit-btn fw-bold '
          style={{minWidth: 115}}
          onClick={() => {
            formik.handleSubmit()
          }}
        >
          {/* will add email verification laters */}
          <span className='fw-bold'>Save email</span>
        </button>
      </div>
    </div>
  )
}
