import React, {useState, useEffect, useContext, useRef, useCallback} from 'react'
import {ProfileHeader} from '../../modules/profile/ProfileHeader'
import ProfileEdit from '../../modules/profile/ProfileEdit'
import CreatePetition from '../../pages/petitions/CreatePetition'

import {ProfileContext} from '../../contexts/profilecontext'
import {ProfilePageContext} from '../../contexts/profilePageContext'

import {toAbsoluteUrl} from '../../../_metronic/helpers'

import {ChangePasswordModal} from './modals/ChangePasswordModal'

import {InlineEmailAdd} from './InlineEmailAdd'

import {MyPetitions} from './MyPetitions'

import TextField from '../../../app/modules/auth/reusable/TextField'
import {MyPosts} from './MyPosts'

import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/storage'

import AUTH_SERVICE from '../../services/auth/AuthService'

import './Profile.scss'
import {useLocation} from 'react-router-dom'
import ProfileSocials from '../../modules/profile/ProfileSocials'
import ProfileContact from '../../modules/profile/ProfileContact'
import ProfileBasicInfo from '../../modules/profile/ProfileBasicInfo'
import MODAL_NAMES from '../../constants/ModalNames'
import {GlobalPrivateModalContext} from '../../contexts/GlobalModalContext'
import VoterListTab from './VoterListTab'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
}

// TODO possibly different file for each tab?

export function Profile() {
  interface StateType {
    from: {pathname: string}
    isEdit: Boolean
  }
  const {state} = useLocation<StateType>()

  const {profile, setProfile} = useContext(ProfileContext)
  const {currentTab, setCurrentTab} = useContext(ProfilePageContext)

  const [isEdit, setIsEdit] = useState(state?.isEdit || false)
  const [loading, setLoading] = useState(false)

  const [passModalOpen, setPassModalOpen] = useState(false)
  const [isPasswordSignup, setIsPasswordSignup] = useState(false)
  const [isAddEmail, setIsAddEmail] = useState(false)

  const {setIsOpenGlobalModal} = useContext(GlobalPrivateModalContext)
  const setIsOpenCompleteProfile = (o) => setIsOpenGlobalModal(MODAL_NAMES.COMPLETE_PROFILE, o)
  const userBasicProfileComplete = profile?.basicProfileComplete

  const isCouncilorApproved = () => {
    // if the account is a councilor type
    if (profile.__t == 'politician') {
      return true
    }
    return false
  }

  const fetchProfile = async () => {
    try {
      let userProfile = await AUTH_SERVICE.getCurrentUser()
      console.log('UPDATED PROFILE...', userProfile)
      // set profile in context
      setProfile({...profile, ...userProfile})
    } catch (error) {
      console.log(error)
    }
  }

  const accountHasPassword = async () => {
    try {
      let response = await firebase.auth().currentUser

      if (response.providerData[0].providerId == 'password') {
        setIsPasswordSignup(true)
      } else {
        setIsPasswordSignup(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const isCouncilorType = () => {
    if (profile?.electedOrCandidate == 'elected') {
      return 'City Councillor'
    } else if (profile?.electedOrCandidate == 'candidate' && profile?.office) {
      return `${profile.office} Candidate`
    } else if (profile?.electedOrCandidate == 'candidate') {
      return `City Candidate`
    } else {
      return null
    }
  }
  useEffect(() => {
    accountHasPassword()
    // profile fetched from context that can be used within components
    fetchProfile()
  }, [])

  useEffect(() => {
    setIsEdit(state?.isEdit)
  }, [state?.isEdit])

  if (currentTab == 'Create Petition') {
    return (
      <div
        className='  form w-100 fv-plugins-bootstrap5 fv-plugins-framework Profile'
        style={{height: '100%'}}
      >
        {/* <div className='mobile-hide'> */}
        <ProfileHeader currentTab={currentTab} setCurrentTab={setCurrentTab} profile={profile} />
        {/* </div> */}
        <CreatePetition />
      </div>
    )
  }

  if (currentTab == 'Help & Support') {
    return (
      <div className='Profile form w-100 fv-plugins-bootstrap5 fv-plugins-framework'>
        <ProfileHeader currentTab={currentTab} setCurrentTab={setCurrentTab} profile={profile} />

        <div
          className='civik-flex-row mb-10'
          style={{
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 30,
            marginLeft: 20,
          }}
        >
          <h3>Help & Support</h3>
        </div>
      </div>
    )
  }

  // TODO: own file
  if (currentTab == 'Settings') {
    return (
      <div
        id='ChangePaswordModal'
        className='Profile form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      >
        <ChangePasswordModal
          isOpen={passModalOpen}
          onRequestClose={() => {
            setPassModalOpen(false)
          }}
          style={customStyles}
        />

        <ProfileHeader currentTab={currentTab} setCurrentTab={setCurrentTab} profile={profile} />
        <div className='civik-flex-row civik-profile-edit-content-wrapper'>
          {/* LEFT SIDE BAR */}
          <div
            className='civik-wh-card civik-flex-column civik-profile-edit-left-bar'
            style={{
              width: '30%',
              alignItems: 'flex-start',
              paddingLeft: 50,
              paddingRight: 50,
              height: '100%',
            }}
          >
            <div className='civik-flex-row mt-10'>
              <div className='civik-step-number ' style={{marginRight: 25}}>
                <img src={toAbsoluteUrl('/media/design/my-account-wh-icon.svg')} alt='' />
              </div>

              <div className='civik-flex-column'>
                <span className='fw-bold civik-primary-blue'>My Account</span>
              </div>
            </div>
          </div>
          {/* RIGHT SIDE BAR - EDIT PROFILE FORM */}
          <div className='civik-wh-card civik-profile-edit-right-bar'>
            <h1 className='mb-10'>My Account</h1>

            <div className='civik-flex-column civik-justify-between'>
              <div className='civik-flex-row mb-5' style={{justifyContent: 'space-between'}}>
                {profile.email && (
                  <div className='civik-flex-row  w-100' style={{justifyContent: 'space-between'}}>
                    <span className='  '>Email</span>
                    <span className='fw-bold'>{profile.email}</span>
                  </div>
                )}
                {!profile.email && !isAddEmail && (
                  <span className=' fw-bold '>please add email address</span>
                )}
                {isAddEmail && <InlineEmailAdd setIsAddEmail={setIsAddEmail} />}

                {!profile.email && !isAddEmail && (
                  <button
                    type='button'
                    className=' btn btn-light btn-active-light-primary civik-edit-btn fw-bold '
                    style={{minWidth: 110}}
                    onClick={() => {
                      setIsAddEmail(true)
                    }}
                  >
                    <span className='fw-bold'>Add email</span>
                  </button>
                )}
              </div>

              <div className='civik-flex-row mb-5' style={{justifyContent: 'space-between'}}>
                {isPasswordSignup && <span className=''>Password</span>}

                {isPasswordSignup && <span className='fw-bold '>*********</span>}

                {isPasswordSignup && (
                  <button
                    type='button'
                    className=' btn btn-light btn-active-light-primary civik-edit-btn fw-bold mt-5'
                    onClick={() => {
                      setPassModalOpen(true)
                    }}
                  >
                    <span className='fw-bold'>Edit</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (currentTab == 'My Petitions') {
    return <MyPetitions />
  }

  if (currentTab == 'My Posts') {
    return (
      <div className='Profile form w-100 fv-plugins-bootstrap5 fv-plugins-framework'>
        <ProfileHeader currentTab={currentTab} setCurrentTab={setCurrentTab} profile={profile} />

        <div className='profile__titleBar'>
          <h3 className='profile__title'>My Posts</h3>
        </div>
        <div className='profile__myPosts'>
          <MyPosts profileId={profile._id} />
        </div>
      </div>
    )
  }

  if (isEdit && currentTab == 'About') {
    return (
      <div className='ProfileEdit form fv-plugins-bootstrap5 fv-plugins-framework '>
        <ProfileEdit
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          fetchProfile={fetchProfile}
          loading={loading}
          setLoading={setLoading}
          isCouncilorType={isCouncilorType()}
        />
      </div>
    )
  }

  if (currentTab == 'Voter List') {
    return <VoterListTab profile={profile} setCurrentTab={setCurrentTab} currentTab={currentTab} />
  }

  //currenttab about
  return (
    <div>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div className='Profile form w-100 fv-plugins-bootstrap5 fv-plugins-framework '>
          <ProfileHeader currentTab={currentTab} setCurrentTab={setCurrentTab} profile={profile} />

          <div className='profile__titleBar'>
            <h3 className='profile__title'>Profile Details</h3>
            {(isCouncilorType() !== null || !isCouncilorApproved()) && (
              <button
                className='btn btn-sm btn-primary me-3'
                onClick={() => {
                  setIsEdit(true)
                }}
              >
                Edit Profile
              </button>
            )}
          </div>

          {isCouncilorType() !== null && !isCouncilorApproved() && (
            <div className='profile__councilDisclaimer'>
              <img
                className='profile__warningIcon'
                src={toAbsoluteUrl('/media/design/warning_icon.svg')}
                alt=''
              />
              <div className='profile__councilDisclaimerTxtWrap'>
                <span className='profile__councilDisclaimerTxtTitle'>
                  Your account is being verified by the city admin.
                </span>
                <span className='profile__councilDisclaimerTxtSubtitle'>
                  You will receive an official email from us once your profile is been approved.
                </span>
              </div>
            </div>
          )}

          {isCouncilorType() === null && !profile.address && (
            <div className='profile__councilDisclaimer' style={{justifyContent: 'space-between'}}>
              <div className='disclaimerInnterContainer'>
                <img
                  className='profile__warningIcon'
                  src={toAbsoluteUrl('/media/design/warning_icon.svg')}
                  alt=''
                />
                <div className='profile__councilDisclaimerTxtWrap'>
                  <span className='profile__councilDisclaimerTxtTitle'>
                    Please fill out your address to be able to post, comment
                  </span>
                  <span
                    className='profile__locationDisclaimerTxtSubtitle'
                    onClick={() => setIsOpenCompleteProfile(true)}
                  >
                    Why do I need to fill out my address?
                  </span>
                </div>
              </div>
              <button className='profile__disclaimerButton' onClick={() => setIsEdit(true)}>
                {' '}
                Take me there
              </button>
            </div>
          )}

          <div className='profile__contentOuterWrap w-100'>
            <div className='civik-flex-column w-100'>
              <ProfileBasicInfo
                profile={profile}
                isCouncilorType={isCouncilorType}
                publicProfile={false}
              />

              {isCouncilorType() !== null && (
                <div className=' profile__about'>
                  <h5 className='mb-5'>About</h5>
                  <p>{profile.bio}</p>
                </div>
              )}
            </div>

            {/* COUNCILOR INFO */}

            {isCouncilorType() !== null && (
              <div className='civik-flex-column w-100' style={{marginRight: 20}}>
                <ProfileContact profile={profile} />
                <ProfileSocials profile={profile} />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
