/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext, useCallback, useRef} from 'react'
import Modal from 'react-modal'

import FeatherIcon from 'feather-icons-react'

const districtOptions = [
  {value: 0, label: 'All Districts'},
  {value: '1. Annapolis', label: '1. Annapolis'},
  {value: '2. Antigonish', label: '2. Antigonish'},
  {value: '3. Argyle', label: '3. Argyle'},
  {value: '4. Bedford Basin', label: '4. Bedford Basin'},
  {value: '5. Bedford South', label: '5. Bedford South'},
  {value: '6. Cape Breton Centre-Whitney Pier', label: '6. Cape Breton Centre-Whitney Pier'},
  {value: '7. Cape Breton East', label: '7. Cape Breton East'},
  {value: "8. Chester-St. Margaret's", label: "8. Chester-St. Margaret's"},
  {value: '9. Clare', label: '9. Clare'},
  {value: '10. Clayton Park West', label: '10. Clayton Park West'},
  {value: '11. Colchester-Musquodoboit Valley', label: '11. Colchester-Musquodoboit Valley'},
  {value: '12. Colchester North', label: '12. Colchester North'},
  {value: '13. Cole Harbour', label: '13. Cole Harbour'},
  {value: '14. Cole Harbour-Dartmouth', label: '14. Cole Harbour-Dartmouth'},
  {value: '15. Cumberland North', label: '15. Cumberland North'},
  {value: '16. Cumberland South', label: '16. Cumberland South'},
  {value: '17. Dartmouth East', label: '17. Dartmouth East'},
  {value: '18. Dartmouth North', label: '18. Dartmouth North'},
  {value: '19. Dartmouth South', label: '19. Dartmouth South'},
  {value: '20. Digby-Annapolis', label: '20. Digby-Annapolis'},
  {value: '21. Eastern Passage', label: '21. Eastern Passage'},
  {value: '22. Eastern Shore', label: '22. Eastern Shore'},
  {value: '23. Fairview-Clayton Park', label: '23. Fairview-Clayton Park'},
  {value: '24. Glace Bay-Dominion', label: '24. Glace Bay-Dominion'},
  {value: '25. Guysborourgh-Tracadie', label: '25. Guysborourgh-Tracadie'},
  {value: '26. Halifax Armdale', label: '26. Halifax Armdale'},
  {value: '27. Halifax Atlantic', label: '27. Halifax Atlantic'},
  {value: '28. Halifax Chebucto', label: '28. Halifax Chebucto'},
  {value: '29. Halifax Citadel-Sable Island', label: '29. Halifax Citadel-Sable Island'},
  {value: '30. Halifax Needham', label: '30. Halifax Needham'},
  {value: '31. Hammonds Plains-Lucasville', label: '31. Hammonds Plains-Lucasville'},
  {value: '32. Hants East', label: '32. Hants East'},
  {value: '33. Hants West', label: '33. Hants West'},
  {value: '34. Inverness', label: '34. Inverness'},
  {value: '35. Kings North', label: '35. Kings North'},
  {value: '36. Kings South', label: '36. Kings South'},
  {value: '37. Kings West', label: '37. Kings West'},
  {value: '38. Lunenburg', label: '38. Lunenburg'},
  {value: '39. Lunenburg West', label: '39. Lunenburg West'},
  {value: '40. Northside-Westmount', label: '40. Northside-Westmount'},
  {value: '41. Pictou Centre', label: '41. Pictou Centre'},
  {value: '42. Pictou East', label: '42. Pictou East'},
  {value: '43. Pictou West', label: '43. Pictou West'},
  {value: '44. Preston', label: '44. Preston'},
  {value: '45. Queens', label: '45. Queens'},
  {value: '46. Richmond', label: '46. Richmond'},
  {value: '47. Sackville-Cobequid', label: '47. Sackville-Cobequid'},
  {value: '48. Sackville-Uniacke', label: '48. Sackville-Uniacke'},
  {value: '49. Shelburne', label: '49. Shelburne'},
  {value: '50. Sydney-Membertou', label: '50. Sydney-Membertou'},
  {value: '51. Timberlea-Prospect', label: '51. Timberlea-Prospect'},
  {
    value: '52. Truro-Bible Hill-Millbrook-Salmon River	',
    label: '52. Truro-Bible Hill-Millbrook-Salmon River	',
  },
  {value: '53. Victoria-The Lakes', label: '53. Victoria-The Lakes'},
  {value: '54. Waverley-Fall River-Beaver Bank', label: '54. Waverley-Fall River-Beaver Bank'},
  {value: '55. Yarmouth', label: '55. Yarmouth'},
]

const statusFilterOptions = [
  {value: 0, label: 'All'},
  {value: 'Approved', label: 'Approved'},
  {value: 'Pending', label: 'Pending'},
  {value: 'Declined', label: 'Declined'},
]

export function AdminDropdown(props) {
  let {view, customStyling} = props

  const [openTopicsDropdown, setOpenTopicsDropdown] = useState(false)
  const topicOpRef = useRef(null)

  const [value, setValue] = useState(view == 'districts' ? 'All Districts' : 'All')

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (openTopicsDropdown && topicOpRef.current && !topicOpRef.current.contains(e.target)) {
        setOpenTopicsDropdown(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [openTopicsDropdown])

  const districts = (
    <div>
      <div
        style={customStyling}
        className='addressList__districtDropdown '
        onClick={() => {
          setOpenTopicsDropdown(true)
        }}
      >
        <span className={'createPostModal_optionTxt'}>{value}</span>
        <FeatherIcon icon={'chevron-down'} style={{marginLeft: 5}} width='20' color='' />
      </div>

      {openTopicsDropdown && (
        <div className='navbar__dropdown addressList__districtDropdown__options' ref={topicOpRef}>
          <div className='civik-flex-column dropdown__txtWrap'>
            <div className='civik-flex-column ' onClick={() => {}}>
              {districtOptions.map((topic, index) => {
                return (
                  <div
                    key={index}
                    className='dropdown__iconOptionWrap '
                    onClick={() => {
                      setValue(topic.label)
                      setOpenTopicsDropdown(false)
                    }}
                  >
                    <span className='createPostModal_optionTxt'>{topic.label}</span>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  )

  const statusFilter = (
    <div>
      <div
        style={customStyling}
        className='addressList__districtDropdown '
        onClick={() => {
          setOpenTopicsDropdown(true)
        }}
      >
        <span className={'createPostModal_optionTxt'}>{value}</span>
        <FeatherIcon icon={'chevron-down'} style={{marginLeft: 5}} width='20' color='' />
      </div>

      {openTopicsDropdown && (
        <div className='navbar__dropdown addressList__districtDropdown__options' ref={topicOpRef}>
          <div className='civik-flex-column dropdown__txtWrap'>
            <div className='civik-flex-column ' onClick={() => {}}>
              {statusFilterOptions.map((topic, index) => {
                return (
                  <div
                    key={index}
                    className='dropdown__iconOptionWrap '
                    onClick={() => {
                      setValue(topic.label)
                      setOpenTopicsDropdown(false)
                    }}
                  >
                    <span className='createPostModal_optionTxt'>{topic.label}</span>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  )

  const renderView = () => {
    console.log('view', view)
    if (view == 'districts') {
      return districts
    }

    if (view == 'statusFilter') {
      return statusFilter
    }
  }

  useEffect(() => {
    renderView()
  }, [view])

  return <>{renderView()}</>
}
