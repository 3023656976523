import React from 'react'
import PublicIcon from '../../../ReactIcons/PublicIcon'
import PrivateIcon from '../../../ReactIcons/PrivateIcon'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import './PrivacyModal.scss'

const CommentPrivacyModal = ({
  setIsOpen,
  modalRef,
  setPrivacyType,
  isOpen,
  privacyType,
  addedClassName,
}) => {
  if (!isOpen) {
    return <div />
  }

  return (
    <div
      className={`navbar__dropdown dropdown__privacyOptions privacy-modal ${addedClassName}`}
      ref={modalRef}
    >
      <div className='civik-flex-column dropdown__txtWrap'>
        <div
          className='dropdown__iconOptionWrap'
          onClick={() => {
            setIsOpen(false)
            setPrivacyType('public')
          }}
        >
          <PublicIcon className='dropdown__iconOptionImg' />
          <span className='dropdown__iconOptionTxt'>Comment with Public Name</span>
          {privacyType === 'public' && <img src={toAbsoluteUrl('/media/design/bluecheck.svg')} />}
        </div>
        <div
          className='dropdown__iconOptionWrap'
          onClick={() => {
            setIsOpen(false)
            setPrivacyType('anonymous')
          }}
        >
          <PrivateIcon />
          <span className='dropdown__iconOptionTxt'>Comment Anonymously</span>
          {privacyType === 'anonymous' && (
            <img src={toAbsoluteUrl('/media/design/bluecheck.svg')} />
          )}
        </div>
      </div>
    </div>
  )
}

export default CommentPrivacyModal
