import React from 'react'
import NewsHeadlinerTxt from './NewsHeadlinerTxt'
import './TwoColumnList.scss'
import NewsGuardLabel from '../../../pages/newsGuardLabel/NewsGuardLabel'
import RedirectToArticle from './RedirectToArticle'
import NewsTopStoryCard from './NewsTopStoryCard'
const TwoColumnList = ({data}) => {
  // Split the data into two arrays for each column
  let column1Data = []
  let column2Data = []
  for (var i = 0; i < data.length; i++) {
    if (i % 2 === 0) {
      column1Data.push(data[i])
    } else {
      column2Data.push(data[i])
    }
  }

  return (
    <div className='twoColumnList' style={{marginTop: 27}}>
      <div className='twoColumnList__column--1'>
        {column1Data.map((item, index) => (
          <div style={{marginBottom: 27}}>
            <NewsTopStoryCard
              post={item}
              key={item._id}
              index={index}
              view='web-fullpage'
              customStyling={{}}
              fullPageView={'NewsTopStories'}
            />
          </div>
        ))}
      </div>
      <div className='twoColumnList__column--2'>
        {column2Data.map((item, index) => (
          <div style={{marginBottom: 27}}>
            <NewsTopStoryCard
              post={item}
              key={item._id}
              index={index}
              view='web-fullpage'
              customStyling={{}}
              fullPageView={'NewsTopStories'}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default TwoColumnList
