import React, {FC, useState, useRef, useEffect} from 'react'

import GenericModal from './GenericModal'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

export const STATUS_TYPES = {
  success: 'Success',
  error: 'Error',
  loading: 'Loading',
  default: 'Upload',
  upload: 'Upload',
}
type FileUploadProps = {
  selectedFile: any
  setSelectedFile: Function
}

const FileUpload: FC<FileUploadProps> = ({children, selectedFile, setSelectedFile}) => {
  const hiddenFileInput = useRef(null)
  const handleClick = (event) => {
    hiddenFileInput.current.click()
  }
  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0])
  }
  return (
    <>
      {selectedFile ? (
        <div>
          <div>
            <p>Filename: {selectedFile.name}</p>
            <p>Filetype: {selectedFile.type}</p>
            <p>Size in bytes: {selectedFile.size}</p>
            <p>lastModifiedDate: {selectedFile.lastModifiedDate.toLocaleDateString()}</p>
          </div>
          {children}
        </div>
      ) : (
        <div className='addImageModal__uploadContainer' onClick={handleClick}>
          <img src={toAbsoluteUrl('/media/design/file_upload_icon.svg')} alt='upload' />
          <h4 className='addImageModal__uploadTxt'>File Uploader</h4>
          <span className='addImageModal__uploadSubTxt'>Click to add a file</span>
          <input
            type='file'
            onChange={changeHandler}
            style={{display: 'none'}}
            ref={hiddenFileInput}
          />
        </div>
      )}
    </>
  )
}

const Loading = () => (
  <div
    className='m-15'
    style={{justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}
  >
    <div className='text-gray-800 fs-2 fw-bolder mb-1'>File processing in progress...</div>
    <div className='text-gray-800 fs-3'>Please wait. Do not close this window.</div>
    <span className='spinner-border spinner-border-lg align-middle ms-2 m-10'></span>
  </div>
)

const Error = () => (
  <div
    className='m-15'
    style={{justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}
  >
    <img src={toAbsoluteUrl('/media/design/Icon-Warning.svg')} alt='error' className='mb-10' />
    <div className='text-gray-800 fs-2 fw-bolder mb-1'>Something went wrong.</div>
    <div className='text-gray-800 fs-3'>Please try again.</div>
  </div>
)

const Success = ({downloadUrl}) => (
  <div
    className='m-15'
    style={{justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}
  >
    <div className='text-gray-800 fs-2 fw-bolder mb-1'>Success!</div>
    <div className='text-gray-800 fs-3 m-1'>Your file is ready to download.</div>
    <img className='mt-10' src={toAbsoluteUrl('/media/design/Icon-Download.svg')} alt='download' />
    <div className='mt-15'>
      <a className='civik-btn-outline-primary' href={downloadUrl}>
        Dowload File
      </a>
    </div>
  </div>
)

const Body = ({
  status,
  selectedFile,
  setSelectedFile,
  downloadUrl,
  customFileUploadDisplayElements,
}) => {
  switch (status) {
    case STATUS_TYPES.loading:
      return <Loading />
    case STATUS_TYPES.error:
      return <Error />
    case STATUS_TYPES.success:
      return <Success downloadUrl={downloadUrl} />
    case STATUS_TYPES.upload:
      return (
        <FileUpload selectedFile={selectedFile} setSelectedFile={setSelectedFile}>
          {customFileUploadDisplayElements && customFileUploadDisplayElements()}
        </FileUpload>
      )
    default:
      return (
        <FileUpload selectedFile={selectedFile} setSelectedFile={setSelectedFile}>
          {customFileUploadDisplayElements && customFileUploadDisplayElements()}
        </FileUpload>
      )
  }
}

interface Props {
  title?: String
  isOpen: Boolean
  setIsOpen: Function
  onClickAdd: Function
  status?: String
  downloadUrl?: Function
  uploadConfirmText?: String
  customFileUploadDisplayElements?: any
}

const FileUploadModal: FC<Props> = ({
  title,
  isOpen,
  setIsOpen,
  onClickAdd,
  status,
  downloadUrl,
  uploadConfirmText,
  customFileUploadDisplayElements,
}) => {
  const [selectedFile, setSelectedFile] = useState(null)
  const buttonTextOk =
    status === STATUS_TYPES.upload && uploadConfirmText && selectedFile
      ? uploadConfirmText
      : STATUS_TYPES.upload
      ? 'Add'
      : 'Ok'

  useEffect(() => {
    if (!isOpen) {
      setSelectedFile(null)
    }
  }, [isOpen])

  return (
    <GenericModal
      title={title ? title : 'File Upload'}
      id='upload-modal-id'
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      includeCancel
      buttonTextOk={buttonTextOk}
      onClickOk={() => {
        if (status === STATUS_TYPES.upload) {
          onClickAdd(selectedFile)
          setSelectedFile(null)
        } else {
          setIsOpen(false)
        }
      }}
      body={
        <Body
          status={status}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          downloadUrl={downloadUrl}
          customFileUploadDisplayElements={customFileUploadDisplayElements}
        />
      }
      hideFooter={status !== STATUS_TYPES.upload && status !== STATUS_TYPES.error}
    />
  )
}
export default FileUploadModal
