import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PrivacyPolicyLink} from '../../../constants/PrivacyTermsLinks'

export const SideBarPrivacy = () => (
  <div className='createPetition__leftSideBar__footer'>
    <img
      className='createPetition__leftSideBar__footerImg'
      src={toAbsoluteUrl('/media/design/safe_blue_sheild.svg')}
      alt='privacy-shield'
    />
    <span className='createPetition__leftSideBar__footerTxt'>
      We do not share personal information with third parties{' '}
      <a
        className='createPetition__leftSideBar__footerLink'
        href={PrivacyPolicyLink}
        target='_blank'
        rel='noopener noreferrer'
      >
        Privacy Policy
      </a>
    </span>
  </div>
)
export default SideBarPrivacy
