/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, useContext} from 'react'
// import {IProfileDetails, profileDetailsInitValues as initialValues} from '../../../modules/profile/components/settings/SettingsModel'
import * as Yup from 'yup'

import {ProfileContext} from '../contexts/profilecontext'

import 'firebase/compat/auth'

import './RightSideBar.scss'

import OPEN_WEATHER_CONFIG from '../openWeatherConfig.json'

import {geocodeByAddress, getLatLng} from 'react-places-autocomplete'
import {FeedLocationContext} from '../contexts/feedLocationContext'

import dateFormat from 'dateformat'
const now = new Date()

export function RightSideBar(props) {
  let {customStyling} = props
  const PROFILE_CONTEXT = useContext(ProfileContext)
  const [weatherInfo, setWeatherInfo] = useState({
    main: {temp: 0, feels_like: 0},
    weather: [{icon: ''}],
  })

  const {feedLocation} = useContext(FeedLocationContext)

  const getCurrentWeather = async (coordinates) => {
    try {
      let response = await fetch(
        `https://api.openweathermap.org/data/2.5/weather?lat=${coordinates.lat}&lon=${coordinates.lng}&appid=${OPEN_WEATHER_CONFIG.key}&units=metric`
      )

      let result = await response.json()

      // console.log('result', result)

      setWeatherInfo(result)
      // console.log(result)
    } catch (error) {
      console.log(error)
    }
  }

  const getCoordinatesProfile = async () => {
    try {
      let address =
        PROFILE_CONTEXT.profile.location_city +
        ', ' +
        PROFILE_CONTEXT.profile.location_region +
        ', ' +
        PROFILE_CONTEXT.profile.postal_code

      // console.log('address', address)

      const results = await geocodeByAddress(address)

      const latLng = await getLatLng(results[0])

      getCurrentWeather(latLng)

      // console.log(latLng)
    } catch (error) {
      console.log(error)
    }
  }

  const getCoordinatesFeedLocation = async () => {
    try {
      let address = feedLocation.city + ', ' + feedLocation.region
      const results = await geocodeByAddress(address)
      const latLng = await getLatLng(results[0])

      getCurrentWeather(latLng)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    // console.log('PROFILE_CONTEXT.profile', PROFILE_CONTEXT.profile)
    if (
      feedLocation.city &&
      feedLocation.region &&
      (feedLocation.city !== PROFILE_CONTEXT.profile.location_city ||
        feedLocation.region !== PROFILE_CONTEXT.profile.location_region)
    ) {
      getCoordinatesFeedLocation()
    } else if (PROFILE_CONTEXT.profile.coords) {
      getCurrentWeather(PROFILE_CONTEXT.profile.coords)
    } else if (PROFILE_CONTEXT.profile.location_city && PROFILE_CONTEXT.profile.location_region) {
      getCoordinatesProfile()
    }

    // console.log('feedLocation', feedLocation)
  }, [feedLocation, PROFILE_CONTEXT.profile.location_city])

  return (
    <div className='RightSideBar hide-mobile'>
      {feedLocation.city && (
        <div className='rightSideBar__weather' style={customStyling}>
          <div className=' '>
            <h3 className='rightSideBar__weatherLocation'>{feedLocation.city}</h3>
            <small className='rightSideBar__weatherDate'>
              {dateFormat(now, 'dddd, mmmm dS, yyyy')}
            </small>
          </div>

          <div className='civik-flex-column'>
            <hr></hr>
            <span className='rightSideBar__weatherLabel'>TEMPERATURE</span>
          </div>

          <div className='rightSideBar__weatherInfoContainer civik-flex-row'>
            <div className='civik-flex-column'>
              <h1 className='rightSideBar__weatherTemperature'>
                {Math.trunc(weatherInfo.main.temp)}
              </h1>
              <small className='rightSideBar__weatherFeelsLike'>
                Feels like: {Math.ceil(weatherInfo.main.feels_like)}
              </small>
            </div>
            <img
              alt='weather-icon'
              src={`http://openweathermap.org/img/w/${weatherInfo.weather[0].icon}.png`}
            />
          </div>
        </div>
      )}

      <div></div>
    </div>
  )
}
