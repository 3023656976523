/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext, useCallback, useRef} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'

import {useActivePetition} from '../../../contexts/ActivePetitionContext'
import {ProfilePageContext} from '../../../contexts/profilePageContext'
import {useHistory} from 'react-router-dom'

import PETITION_SERVICE from '../../../services/petition/PetitionService'

import {PublishPetitionModal} from './PublishPetitionModal'
import {CreatePetitionBottomButtons} from './BottomButtons'
import {SignatureTarget} from './components/step3/SignatureTarget'
import {CoverPhotoUpload} from './components/step3/CoverPhotoUpload'
import {Terms} from './components/step3/Terms'
import {TermsError} from './components/terms_error/TermsError'
// import './Home.scss'

const petitionCreationStep3ValidationSchema = Yup.object().shape({
  acceptTerms: Yup.bool().oneOf([true], 'You need to accept the terms and conditions'),
})

const initialValues = {
  acceptTerms: false,
}

export function Step3(props) {
  const {loading, setLoading} = props
  const {pageLoading, setPageLoading} = props
  const {activePetition, clearActivePetition, setTermsError} = useActivePetition()
  const {currentTab, setCurrentTab} = useContext(ProfilePageContext)
  const [showPublishModal, setShowPublishModal] = useState(false)

  const history = useHistory()

  const formik = useFormik({
    initialValues,
    validationSchema: petitionCreationStep3ValidationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setPageLoading(true)
      setShowPublishModal(true)
      try {
        const data = {
          petition: {
            ...activePetition,
          },
        }
        await PETITION_SERVICE.createPetition(data)
        setPageLoading(false)

        setCurrentTab('My Petitions')
        clearActivePetition()

        // redirect to my petitions page
      } catch (error) {
        console.log(error)
      }
    },
  })

  const onPreview = () => {
    if (formik.values.acceptTerms) {
      history.push('/petitions/drafts/preview')
    } else {
      setTermsError(true)
      console.log('YOU must accept terms')
    }
  }
  return (
    <form
      className='civik-wh-card civik-profile-edit-right-bar profileEdit__rightEditForm'
      onSubmit={(e) => {
        e.preventDefault()
        if (formik.values.acceptTerms) {
          setShowPublishModal(true)
        } else {
          setTermsError(true)
          console.log('YOU must accept terms')
        }
      }}
      noValidate
      id='createPetition__step3'
    >
      <PublishPetitionModal
        onSubmit={() => {
          formik.submitForm()
        }}
        isOpen={showPublishModal}
        onRequestClose={() => {
          setShowPublishModal(false)
        }}
        onPreview={onPreview}
      />

      <div className='createPetition__details__header'>
        <h1 className='createPetition__details__headerTitle'>Publish</h1>
        <SignatureTarget />
        <CoverPhotoUpload />

        <Terms formik={formik} fieldName='acceptTerms' />

        <CreatePetitionBottomButtons loading={loading} isLastStep onPreview={onPreview} />
      </div>
    </form>
  )
}
