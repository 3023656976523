/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext, useCallback} from 'react'
// import {IProfileDetails, profileDetailsInitValues as initialValues} from '../../../modules/profile/components/settings/SettingsModel'
import {useParams, useHistory} from 'react-router-dom'
import {LeftSideBar} from './LeftSideBar'

import {RightSideBar} from './RightSideBar'
import './Home.scss'

import FeedService from '../services/feed/FeedService'
import PostItem from '../modules/feed/posts/PostItem'
import ROUTE_PATHS from '../routing/RoutePaths'

const Post = (props) => {
  let {postId} = useParams() as {postId: string}
  const [loading, setLoading] = useState(true)
  const [post, setPost] = useState({_id: ''})
  const history = useHistory()

  const getCurrentPost = async () => {
    setLoading(true)
    let postData = await FeedService.getPostDetails(postId)
    postData && setPost(postData)
    setLoading(false)
  }

  useEffect(() => {
    getCurrentPost()
  }, [postId])

  if (!loading && !post._id) {
    history.push(ROUTE_PATHS.HOME)
  }
  return (
    <div className='Post'>
      <LeftSideBar />
      <div className='Feed SinglePost'>
        {loading ? (
          <span className='spinner-border spinner-border-md align-middle ms-2'></span>
        ) : (
          <PostItem feed={post} isSinglePost={true} />
        )}
      </div>
      <RightSideBar />
    </div>
  )
}

export default Post
