/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext, useCallback} from 'react'
import Modal from 'react-modal'
import {useFormik} from 'formik'
import './VoterInfoModal.scss'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {UserInfo} from '../../../pages/voter/components/registration/UserInfo'
import {ContactInfo} from '../../../pages/voter/components/registration/ContactInfo'

import {
  initialValues,
  fullNameSchema,
  singleNameSchema,
} from '../../../pages/voter/pages/VoterRegisterSchema'
import {AddressInfo} from '../../voter/components/registration/AddressInfo'
import {MailingInfo} from '../../voter/components/registration/MailingInfo'
import {UploadPhotoID} from '../../../pages/voter/components/registration/UploadPhotoID'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
    padding: 0,
    marginTop: 20,
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: 3,
  },
}

export function VoterInfoModal(props) {
  const {modalView, setModalView} = props

  const [loading, setLoading] = useState(false)
  const [singleName, setSingleName] = useState(false)
  const [modalIsOpen, setIsOpen] = useState(false)
  const [photoID, setPhotoID] = useState(null)

  const formik = useFormik({
    initialValues,
    validationSchema: !singleName ? fullNameSchema : singleNameSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(async () => {
        try {
          //   console.log('photoID', photoID)
          //   if (photoID == null) {
          //     console.log('ERROR: photo ID not uploaded!')
          //     setIsPhotoError(true)
          //     return
          //   }
          //   const userInput = {
          //     // USER INFO
          //     firstName: values.firstName,
          //     middleName: values.middleName,
          //     lastName: values.lastName,
          //     singleName: singleName,
          //     // BIRTH DAYE
          //     birthMonth: values.birthMonth,
          //     birthDay: values.birthDay,
          //     birthYear: values.birthYear,
          //     dateOfBirth: new Date(`${values.birthDay} ${values.birthMonth} ${values.birthYear}`),
          //     // CONTACT INFO
          //     email: values.email,
          //     confirmEmail: values.confirmEmail,
          //     phone: values.phoneNumber,
          //     // RESIDENTIAL ADDRESS
          //     address: {
          //       streetAddress: values.streetAddress,
          //       city: values.city,
          //       province: values.province,
          //       postalCode: values.postalCode,
          //       unitNumberPOBox: values.unitNumber,
          //     },
          //     // MAILING ADDRESS
          //     mailingAddress: {
          //       streetAddress: values.mailingAddress,
          //       city: values.mailingCity,
          //       province: values.mailingProvince,
          //       postalCode: values.mailingPostalCode,
          //       unitNumberPOBox: values.mailingUnitNumber,
          //     },
          //     photoID: photoID,
          //   }
          //   console.log('userInput', userInput)
          //   let result = await VoterRegistrationService.registerVoter(userInput)
          //   if (result?.success) {
          //     history.push('/voter-registration/verify-method')
          //   }
          //   setLoading(false)
        } catch (error) {
          console.log(error)
        }
      }, 10)
    },
  })

  useEffect(() => {
    Modal.setAppElement('#Voter')
  }, [])

  return (
    <Modal
      ariaHideApp={false}
      isOpen={props.isOpen}
      onAfterOpen={props.onAfterOpen}
      onRequestClose={props.onRequestClose}
      style={customStyles}
    >
      <div className='VoterInfoModal'>
        <div className='propInfoModal__header'>
          {/* TITLE */}

          <div className='voterInfoModal__header__leftWrap'>
            <span className='voterInfoModal__header__title '>Adam Harold</span>

            <span className='importAddrList__statusLabel importAddrList__statusLabel--success'>
              Approved
            </span>
          </div>

          <div className='voterInfoModal__header__rightWrap'>
            <span className='voterInfoModal__header__electorId'>
              Elector ID: <span className='civik-primary-blue'>123455</span>
            </span>

            {/* X BUTTON */}
            <img
              src={toAbsoluteUrl('/media/design/modal-x-btn.svg')}
              className='civik-modal-x-btn'
              onClick={props.onRequestClose}
            />
          </div>
        </div>

        {/* ROW 1 - USER INFO */}
        <div style={{marginLeft: 30, marginRight: 30}}>
          <UserInfo
            formik={formik}
            onSingleName={(value) => {
              console.log('singleName change: ', value)
              setSingleName(value)
            }}
          />
        </div>

        {/* ROW 2 - CONTACT INFO */}
        <div style={{marginLeft: 30, marginRight: 30}}>
          <ContactInfo formik={formik} view='AdminEdit' />
        </div>

        {/* ROW 3 - RESIDENTIAL ADDRESS */}
        <div style={{marginLeft: 30, marginRight: 30}}>
          <AddressInfo formik={formik} view='AdminEdit' />
        </div>

        {/* ROW 4 - MAILING ADDRESS */}
        <div style={{marginLeft: 30, marginRight: 30}}>
          <MailingInfo formik={formik} view='AdminEdit' />
        </div>
        <div style={{marginLeft: 30, marginRight: 30, marginBottom: 50}}>
          {/* ROW 5 - UPLOAD PHOTO ID */}
          <UploadPhotoID
            setModalView={setModalView}
            setIsOpen={setIsOpen}
            setPhotoID={setPhotoID}
            view='AdminEdit'
          />
        </div>
        {/* ROW 6 - Voting access code notif */}

        <div className='voterInfoModal__dividerWrap'>
          <div className='voterInfoModal__divider--top'></div>
          <div className='voterInfoModal__accessCodeWrap'>
            <div className='voterInfoModal__accessCode__txtWrap'>
              <span className='voterInfoModal__accessCode__label'>
                Voting access code has been sent
              </span>
              <span className='voterInfoModal__accessCode__date'>Nov, 23 2024</span>
            </div>

            <span className='voterInfoModal__accessCode__link'>Send New Access Code</span>
          </div>

          <div className='voterInfoModal__divider--bottom'></div>
        </div>

        <div className='ModalFooter'>
          {/* CANCEL BUTTON */}
          <button className='voterRegister__cancelBtn'>Cancel</button>
          <button
            type='submit'
            className={'modalFooter__primaryBtn'}
            onClick={props.onRequestClose}
          >
            Save
          </button>
        </div>
      </div>
    </Modal>
  )
}
